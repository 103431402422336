import * as React from 'react';
import { useState, useEffect } from 'react';
import { useTranslation } from "react-i18next";
import PropTypes from 'prop-types';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import dayjs from 'dayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DateField } from '@mui/x-date-pickers/DateField';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { Card, Button, Grid } from '@mui/material';
import './custom.css';

CustomDateFormat.propTypes = {
    startDate: PropTypes.string,
    endDate: PropTypes.string,
    onCallbackValue: PropTypes.func,
    onSearchClick: PropTypes.func,
  };

export default function CustomDateFormat({
  startDate,
  endDate,
  onCallbackValue,
  onSearchClick
}) {
  const [valueStart, setValueStart] = useState(dayjs(startDate));
  const [valueEnd, setValueEnd] = useState(dayjs(endDate));
  const [dateRange, setDateRange] = useState([dayjs(startDate),dayjs(endDate)])
  const { i18n, t } = useTranslation();

  const onchangeStartHandler = (value)  => {
    console.log('onchangeStartHandler value ->',value);
    setValueStart(value)
    const tmpDateRange = dateRange;
    tmpDateRange[0] = value;
    if( (new Date(value.$d) ) > (new Date(tmpDateRange[1].$d) ) ) {
      let tmpDate = new Date(value.$d)
      tmpDate = tmpDate.setDate(tmpDate.getDate()+1);
      const tmpDateEnd = dayjs(tmpDate)
      console.log('tmpDateEnd ->',tmpDateEnd)
      tmpDateRange[1] = tmpDateEnd;
      setValueEnd(tmpDateEnd);
    }
    setDateRange(tmpDateRange);
    onCallbackValue(tmpDateRange);
  };

  const onchangeEndHandler = (value)  => {
    console.log('onchangeEndHandler value ->',value);
    setValueEnd(value)
    const tmpDateRange = dateRange;
    tmpDateRange[1] = value;
    if( (new Date(value.$d) ) < (new Date(tmpDateRange[0].$d) ) ) {
      let tmpDate = new Date(value.$d)
      tmpDate = tmpDate.setDate(tmpDate.getDate()-1);
      const tmpDateStart = dayjs(tmpDate)
      console.log('tmpDateStart ->',tmpDateStart)
      tmpDateRange[0] = tmpDateStart;
      setValueStart(tmpDateStart);
    }
    setDateRange(tmpDateRange);
    onCallbackValue(tmpDateRange);
  };

  useEffect(() => {
    // console.log('Chang value ->',dateRange)
  },[dateRange])

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <div className='conf-date-picker'>
        <DemoContainer components={['DatePicker', 'DatePicker']}>
          <DatePicker
            label={t("filter.Start Date")}
            value={valueStart}
            onChange={(newValue) => onchangeStartHandler(newValue)}
            format="DD/MM/YYYY"
          />
          <DatePicker
            label={t("filter.End Date")}
            value={valueEnd}
            onChange={(newValue) => onchangeEndHandler(newValue)}
            format="DD/MM/YYYY"
          />
          {/* <div className="conf-btn"> */}
            {/* <Button size="large" variant="contained" color="primary" onClick={onSearchClick}>{t("filter.Search")}</Button> */}
            {/* <Button variant="contained" size="large"  onClick={onSearchClick}>{t("filter.Search")}</Button> */}
          {/* </div> */}
        </DemoContainer>
      </div>
    </LocalizationProvider>
  );
}