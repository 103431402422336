import { useState, useEffect, useCallback } from 'react';
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";
import {Button} from '@mui/material'
import { useTranslation } from "react-i18next";

export const ExportToExcel = ({ apiData, colWidths, fileName, onCallback }) => {
  const fileType = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
  const fileExtension = ".xlsx";
  const { i18n, t } = useTranslation();
  // const colWidths = [{ wch: 12 }];
  
  const func = (dataExp, cols, fName) => {
    // console.log("func");
    if (dataExp) {
      // console.log("func dataExp ->",dataExp);
      // console.log("func colWidths ->",cols);
      // console.log("func fName ->",fName);
      const ws = XLSX.utils.json_to_sheet(dataExp, {skipHeader: true});
      if(cols) ws['!cols'] = cols;
      const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
      const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
      const data = new Blob([excelBuffer], { type: fileType });
      FileSaver.saveAs(data, fName + fileExtension);
    }
  };


  return (
    <Button variant="contained" size="large" onClick={(e) => onCallback(func)}>{t('export.buttonExport')}</Button>
  );

};


