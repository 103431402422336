import { Helmet } from 'react-helmet-async';
import { filter } from 'lodash';
import { format } from 'date-fns';
import { sentenceCase } from 'change-case';
import { useState, useEffect } from 'react';
// import { Link } from 'react-router-dom';
import { SnackbarProvider, enqueueSnackbar } from 'notistack';
import { useNavigate, useParams, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
// @mui
import {
  Grid,
  Card,
  Table,
  Stack,
  Paper,
  Select,
  Avatar,
  Button,
  Popover,
  Checkbox,
  TableRow,
  MenuItem,
  TableBody,
  TableCell,
  Container,
  Typography,
  IconButton,
  InputLabel,
  FormControl,
  TableContainer,
  TablePagination,
} from '@mui/material';
// components
import Label from '../components/label';
import Iconify from '../components/iconify';
import Scrollbar from '../components/scrollbar';
import { ExportToExcel } from '../components/exportExcel/ButtonExports';
// sections
import { UserListHead, UserListToolbar, UserMoreMenu, ConfirmDialog } from '../sections/@dashboard/detail';
// mock
import USERLIST from '../_mock/branchDetail';

// ----------------------------------------------------------------------

const config = window.config;
const showFirstButtonConf = true;
const showLastButtonConf = true;

const TABLE_HEAD = [
  { id: 'name', label: 'Name', alignRight: false },
  { id: 'organization', label: 'Organization', alignRight: false },
  { id: 'role', label: 'Role', alignRight: false },
  { id: 'verified', label: 'Verified', alignRight: false },
  { id: 'status', label: 'Status', alignRight: false },
  { id: '' },
];

// ----------------------------------------------------------------------

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilterOld(array, comparator, query) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  if (query) {
    return filter(array, (_user) => _user.name.toLowerCase().indexOf(query.toLowerCase()) !== -1);
  }
  return stabilizedThis.map((el) => el[0]);
}

function applySortFilter(array, comparator, query, columnFilter) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  if (query && columnFilter) {
    const tmpArray = stabilizedThis.map((el) => el[0]);
    // console.log('========columnFilter ->', columnFilter);
    return filter(tmpArray, (_user) => _user[columnFilter].toLowerCase().indexOf(query.toLowerCase()) !== -1);
  }
  return stabilizedThis.map((el) => el[0]);
}

let dataSort = [];

export default function BranchDetail({ permission, username }) {
  // console.log('====== BranchDetail: permission ->', permission, username);
  if (!permission) permission = { create: false, update: false, delete: false };

  const [open, setOpen] = useState(null);

  const [page, setPage] = useState(0);

  const [order, setOrder] = useState('asc');

  const [selected, setSelected] = useState([]);

  const [orderBy, setOrderBy] = useState('channelId');

  const [filterName, setFilterName] = useState('');

  const [rowsPerPage, setRowsPerPage] = useState(10);

  const [userList, setUserlist] = useState([]);

  const [branch, setBranch] = useState({});

  const [confirmOpen, setConfirmOpen] = useState(false);

  const [confirmOpenClear, setConfirmOpenClear] = useState(false);

  const [userForDelete, setUserForDelete] = useState({});

  const [dataResAPI, setDataResAPI] = useState([]);

  const [branchDetailId, setBranchDetailId] = useState('');

  const [dataExport, setDataExport] = useState([]);

  const [fileName, setFileName] = useState('Export');

  const [fileSelect, set] = useState('Export');

  const [colWidths, setColWidths] = useState([
    { wch: 10 },
    { wch: 20 },
    { wch: 20 },
    { wch: 20 },
    { wch: 20 },
    { wch: 20 },
  ]);

  const { pathname } = useLocation();

  const isEdit = pathname.includes('detail');

  const { branchId } = useParams();

  const showPageDetail = false;

  const { i18n, t } = useTranslation();

  const filterList = [
    { lable: t('branchDetail.filter.barcode'), value: 'barcode' },
    { lable: t('branchDetail.filter.channelName'), value: 'channelName' },
    { lable: t('branchDetail.filter.bookName'), value: 'name' },
    { lable: t('branchDetail.filter.size'), value: 'size' },
    { lable: t('branchDetail.filter.status'), value: 'status' },
  ];

  const [columnFilter, setColumnFilter] = useState('barcode');

  const [filterSelect, setFilterSelect] = useState('barcode');

  // const tableHead = [
  //   { id: 'name', label: t('userList.table.name'), alignRight: false },
  //   { id: 'organization', label: t('userList.table.organization'), alignRight: false },
  //   { id: 'role', label: t('userList.table.role'), alignRight: false },
  //   { id: 'verified', label: t('userList.table.verified'), alignRight: false },
  //   { id: 'status', label: t('userList.table.status'), alignRight: false },
  //   { id: '' },
  // ];

  // "table": {
  //   "branchID": "รหัสตู้",
  //   "name": "ชื่อตู้",
  //   "organization": "องค์กร",
  //   "channelId": "ช่อง",
  //   "channelName": "ชื่อช่อง",
  //   "status": "สถานะ",
  //   "size": "ขนาด",
  //   "layer": "ชั่น",
  //   "bookName": "ชื่อหนังสือ",
  //   "barcode": "บาร์โค้ด",
  //   "categorythai": "หมวดหนังสือ",
  //   "receivedDate": "วันที่",
  //   "callNo": "เลขเรียกหนังสือ",
  //   "language" : "ภาษา",
  //   "state" : "สถานะ"
  // },
  const tableHead = [
    // { id: 'channelId', label: t('branchDetail.table.channelId'), alignRight: false, sx: { maxWidth: "60px", textAlign: "center" }},
    // { id: 'channelName', label: t('branchDetail.table.channelName'), alignRight: false, sx: { minWidth: "80px", maxWidth: "80px", textAlign: "center" }},
    { id: 'channelId', label: t('branchDetail.table.channelId'), alignRight: false, sx: { textAlign: 'center' } },
    {
      id: 'channelName',
      label: t('branchDetail.table.channelName'),
      alignRight: false,
      sx: { minWidth: '160px', textAlign: 'center' },
    },
    { id: 'layer', label: t('branchDetail.table.layer'), alignRight: false, sx: { textAlign: 'center' } },
    { id: 'size', label: t('branchDetail.table.size'), alignRight: false, sx: { textAlign: 'center' } },
    { id: 'barcode', label: t('branchDetail.table.barcode'), alignRight: false, sx: { textAlign: 'center' } },
    { id: 'bookName', label: t('branchDetail.table.bookName'), alignRight: false, sx: { textAlign: 'center' } },
    { id: 'categorythai', label: t('branchDetail.table.categorythai'), alignRight: false, sx: { textAlign: 'center' } },
    { id: 'callNo', label: t('branchDetail.table.callNo'), alignRight: false, sx: { textAlign: 'center' } },
    { id: 'language', label: t('branchDetail.table.language'), alignRight: false, sx: { textAlign: 'center' } },
    { id: 'receivedDate', label: t('branchDetail.table.receivedDate'), alignRight: false, sx: { textAlign: 'center' } },
    { id: 'status', label: t('branchDetail.table.status'), alignRight: false, sx: { textAlign: 'center' } },
    // { id: 'clear', label: '', alignRight: false, sx: { textAlign: "center" }},
  ];

  function showDate(date) {
    if (date === undefined) return '--/--/-- --:--:--';
    if (date === null) return '--/--/-- --:--:--';
    if (date === '') return '--/--/-- --:--:--';
    if (date === '-') return '--/--/-- --:--:--';
    return format(new Date(date), 'dd/MM/yy HH:mm:ss');
  }

  function fileNameDate(date) {
    if (date === undefined) return '';
    if (date === null) return '';
    if (date === '-') return '';
    return format(new Date(date), 'dd_MM_yyyy');
  }

  function showDateExcel(date) {
    if (date === undefined) return '--/--/---- --:--:--';
    if (date === null) return '--/--/---- --:--:--';
    if (date === '') return '--/--/---- --:--:--';
    if (date === '-') return '--/--/---- --:--:--';
    return format(new Date(date), 'dd/MM/yyyy HH:mm:ss');
  }

  function fileNameDates(date) {
    if (date === undefined) return '';
    if (date === null) return '';
    if (date === '') return '';
    if (date === '-') return '';
    return format(new Date(date), 'dd_MM_yyyy_HH_mm_ss');
  }

  const handleOpenMenu = (event) => {
    setOpen(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setOpen(null);
  };

  const handleSearchClick = (event) => {
    // console.log('handleSearchClick ->', event);
    apiGetUsers();
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = userList.map((n) => n.channelId);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, name) => {
    // console.log('handleClick name->', name, selected);
    const selectedIndex = selected.indexOf(name);
    // console.log('handleClick selectedIndex->', selectedIndex);
    let newSelected = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1));
    }
    // console.log('handleClick newSelected->', newSelected);
    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setPage(0);
    setRowsPerPage(parseInt(event.target.value, 10));
  };

  const handleFilterByName = (event) => {
    setPage(0);
    setFilterName(event.target.value);
  };

  const handleDeleteUser = (userId) => {
    // console.log('userId ->', userId);
    setConfirmOpen(true);
    const user = userList.find((u) => u._id === userId);
    setUserForDelete(user);
  };

  const handleChangeStatus = (e) => {
    // console.log('handleChangeStatus e..target.value', e.target.value);
    setColumnFilter(e.target.value);
    // const filteredUsers = applySortFilter(userList, getComparator(order, orderBy), filterName, e.target.value);
  };

  const handleClearSelected = () => {
    // console.log('handleClearSelected selected ->', selected);
    // console.log('handleClearSelected userList ->', userList);

    setConfirmOpenClear(true);
    // setDataResAPI(dataResAPITemp);
  };

  const clearUserList = () => {
    // console.log('clearUserList select ->', selected);
    const d = new Date()

    const apiSocketEmit = (data) => {
      console.log('apiSocketEmit: data ->', data);

      const url = `${config.backendUrl}/api/realtime/socket-emit`;
      // console.log('apiCreateBranch: url ->', url);
      try {
        fetch(url, {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({
            branchID: data.branchID,
            command: 'clear-channel',
            message: data,
          }),
        })
          .then((response) => {
            return response.json();
          })
          .then((dataRes) => {
            // console.log(`clearUserList: dataRes ->`, dataRes);
            if (dataRes.error) {
              const error = dataRes.error.message;
              console.error('apiSocketEmit: dataRes.error ->', error);
            }
          })
          .catch((err) => {
            console.error('apiSocketEmit: catch Error ->', err);
            enqueueSnackbar(!isEdit ? 'Create Error' : 'Update Error', { variant: 'error' });
          });
      } catch (e) {
        console.error('apiSocketEmit: try Error -> ', e);
      }
    };

    const postEmployeeAccess = (datas) => {
      // console.log('postEmployeeAccess: datas ->', datas);
      const url = `${config.backendUrl}/api/employee-access/`;
      datas.forEach( arrayItem => {
      try {
        fetch(url, {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify(arrayItem),
        })
          .then((response) => {
            return response.json();
          })
          .then((dataRes) => {
            // console.log(`postEmployeeAccess: dataRes ->`, dataRes);
            if (dataRes.error) {
              const error = dataRes.error.message;
              console.error('postEmployeeAccess: dataRes.error ->', error);
            }
          })
          .catch((err) => {
            console.error('postEmployeeAccess: catch Error ->', err);         
          });
      } catch (e) {
        console.error('postEmployeeAccess: try Error -> ', e);
      }
    })
    };

    const apiEditUsers = (branchID) => {
      
      const values = {
        branchID,
        saveDate: d,
        syncDate: null,
        channel: channelClearList, // userListBuf
      };
      // console.log('apiEditUsers: channelClearList ->',channelClearList);
      const url = `${config.backendUrl}/api/vending-channel-status/update-clear`;
      try {
        // console.log('apiEditUsers: url ->', url, values);
        fetch(url, {
          method: 'PATCH',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify(values),
        })
          .then((response) => {
            return response.json();
          })
          .then((dataRes) => {
            // console.log(`apiEditUsers: dataRes ->`, dataRes);
            
            if (dataRes.error) {
              const error = dataRes.error.message;
              enqueueSnackbar(`Update Error: ${error}`, {
                autoHideDuration: 2000,
                variant: 'error',
                persist: false,
              });
              return;
            }

            const data = {
              branchID: branchId,
              clearChannels: channelClearList,
              saveDate: d,
            };
            apiSocketEmit(data);

            const channels = dataRes.channel
            const result = channels.filter((channel) => {
              return channelClearList.findIndex(c => channel.channelId === c) >= 0
            });
            // console.log('result ->', result);           
            // [{
            //   "_id": "6468859afdc1161335cb2356",
            //   "channelId": 2,
            //   "channelName": "A2",
            //   "status": "clear",
            //   "size": "A4+",
            //   "layer": 1,
            //   "refNo": "",
            //   "userId": "",
            //   "firstName": "",
            //   "lastName": "",
            //   "bookName": "",
            //   "receivedDate": null,
            //   "itemId": "",
            //   "barcode": "",
            //   "callNo": "",
            //   "coverPicpath": "",
            //   "categorythai": "",
            //   "categoryeng": "",
            //   "language": "",
            //   "state": "",
            //   "officerId": "",
            //   "officerFName": "",
            //   "officerLName": ""
            // }]

            const exportData = result.map( (b) => {
              // console.log('b ->', b);
              const eData = {
                "date": d,
                "branchID": branchId,
                "employeeName": username,
                "barcode": b.barcode,
                "bookName": b.bookName,
                "bookNumber": b.callNo,
                "bookCategory": b.categorythai,
                "status":  b.status,
                "bookSize": b.channelName,
                "channel": b.channelId,
                "location": branch.location,
                "event": "export"
              }
              // console.log('eData ->', eData);
              return eData
            })
            // console.log('exportData ->', exportData);
            postEmployeeAccess(exportData)           

            enqueueSnackbar(`Update success`, {
              autoHideDuration: 2000,
              variant: 'success',
              persist: false,
            });
          })
          .catch((err) => {
            console.error('apiEditUsers: catch Error ->', err);
            // enqueueSnackbar('Update Error', { variant: 'error' });
          });
      } catch (e) {
        console.error('apiEditUsers: try Error -> ', e);
      }
    };

    const userListBuf = JSON.parse(JSON.stringify(dataResAPI));
    const channelClearList = [];
    for (let i = 0; i < userListBuf.length; i += 1) {
      if (selected.indexOf(userListBuf[i].channelId) >= 0) {
        userListBuf[i].status = 'clear';
        channelClearList.push(userListBuf[i].channelId);
        // console.log('handleClearSelected dataResAPI[i] i ->', userListBuf[i], i);
      }
    }
    // console.log('handleClearSelected userListBuf ->', userListBuf, branchDetailId);

    apiEditUsers(branchId);

    setUserlist(userListBuf);
    setSelected([]);
    setConfirmOpenClear(false);
  };

  const deleteUser = () => {
    // console.log('deleteUser userForDelete._id ->', userForDelete._id);

    apiDeleteUsers(userForDelete._id);
  };

  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - userList.length) : 0;

  const filteredUsers = applySortFilter(userList, getComparator(order, orderBy), filterName, columnFilter);

  dataSort = filteredUsers;

  const isNotFound = !filteredUsers.length && !!filterName;

  const apiDeleteUsers = (_id) => {
    // const _id = userForDelete._id;
    const url = `${config.backendUrl}/api/branch/${_id}`;
    try {
      // console.log('apiDeleteUsers: url ->', url);

      fetch(url, {
        method: 'DELETE',
      })
        .then((response) => {
          return response.json();
        })
        .then((dataRes) => {
          // console.log(`apiDeleteUsers: dataRes ->`, dataRes);
          const _id = dataRes._id;
          setUserForDelete({});
          // setUserlist(userList.filter((u) => u._id !== _id));
          setDataResAPI(userList.filter((u) => u._id !== _id));
        })
        .catch((err) => {
          console.error('apiDeleteUsers: catch Error ->', err);
        });
    } catch (e) {
      console.error('apiDeleteUsers: try Error -> ', e);
    }
  };

  const apiGetUsers = () => {
    const url = `${config.backendUrl}/api/vending-channel-status/range?branchID=${branchId}`;
    try {
      // console.log('apiGetUsers: url ->', url);

      fetch(url, {
        method: 'GET',
      })
        .then((response) => {
          return response.json();
        })
        .then((dataRes) => {
          // console.log(`apiGetUsers: dataRes ->`, dataRes);
          // setUserlist(dataRes);
          if (config.production) {
            if (dataRes.length) {
              const tmpDataRes = dataRes[0];
              // console.log('tmpDataRes.channel ->',tmpDataRes);
              if (tmpDataRes.channel.length) {
                // console.log('tmpDataRes.channel ->', tmpDataRes.channel[0]);
                // console.log('USERLIST[0] ->', USERLIST[0]);
                setDataResAPI(tmpDataRes.channel);
                setBranchDetailId(tmpDataRes._id);
              } else {
                setDataResAPI([]);
              }
            } else {
              setDataResAPI([]);
            }
          } else {
            setDataResAPI(USERLIST);
          }
        })
        .catch((err) => {
          console.error('apiGetUsers: catch Error ->', err);
          if (!config.production) {
            setDataResAPI(USERLIST);
          }
        });
    } catch (e) {
      console.error('apiGetUsers: try Error -> ', e);
    }
  };

  const apiGetBranch = () => {
    const url = `${config.backendUrl}/api/branch/range?branchID=${branchId}`;
    try {
      // console.log('apiGetUsers: url ->', url);
      fetch(url, {
        method: 'GET',
      })
        .then((response) => {
          return response.json();
        })
        .then((dataRes) => {
          // console.log(`apiGetBranch: dataRes ->`, dataRes);
          if(dataRes.length) setBranch(dataRes[0])
        })
        .catch((err) => {
          console.error('apiGetBranch: catch Error ->', err);
        });
    } catch (e) {
      console.error('apiGetBranch: try Error -> ', e);
    }
  };

  useEffect(() => {
    // console.log('useEffect config =>', config, USERLIST[0]);
    // console.log('UserCreate: useEffect config, isEdit, d =>', config, isEdit, branchId);
    apiGetUsers();
    apiGetBranch(branchId);
  }, []);

  // useEffect(() => {
  //   console.log('useEffect selected =>', selected);
  // }, [selected]);

  useEffect(() => {
    // console.log('change dataResAPI.length =>', dataResAPI.length);
    const tmpDataList = [];
    if (dataResAPI.length) {
      dataResAPI.forEach((dataRow, count) => {
        const tmpObj = {
          channelId: '-',
          channelName: '-',
          layer: '-',
          size: '-',
          barcode: '-',
          name: '-',
          categorythai: '-',
          callNo: '-',
          language: '-',
          receivedDate: '-',
          status: '-',
        };
        // console.log('dataRow [',count,'] ->',dataRow);
        tmpObj.id = dataRow._id;
        tmpObj._id = dataRow._id;

        if (dataRow.channelId == null) tmpObj.channelId = '-';
        else tmpObj.channelId = dataRow.channelId;
        if (dataRow.channelName == null) tmpObj.channelName = '-';
        else tmpObj.channelName = dataRow.channelName;
        if (dataRow.layer == null) tmpObj.layer = '-';
        else tmpObj.layer = dataRow.layer;
        if (dataRow.size == null) tmpObj.size = '-';
        else tmpObj.size = dataRow.size;
        if (dataRow.barcode == null) tmpObj.barcode = '-';
        else tmpObj.barcode = dataRow.barcode;
        if (dataRow.bookName == null) tmpObj.name = '-';
        else tmpObj.name = dataRow.bookName;
        if (dataRow.categorythai == null) tmpObj.categorythai = '-';
        else tmpObj.categorythai = dataRow.categorythai;
        if (dataRow.callNo == null) tmpObj.callNo = '-';
        else tmpObj.callNo = dataRow.callNo;
        if (dataRow.language === null || dataRow.language === undefined) tmpObj.language = '-';
        else tmpObj.language = dataRow.language;
        if (dataRow.receivedDate === null) tmpObj.receivedDate = '-';
        else tmpObj.receivedDate = dataRow.receivedDate;
        if (dataRow.status == null) tmpObj.status = '-';
        else tmpObj.status = dataRow.status;

        tmpDataList.push(tmpObj);
      });
    }
    setFileName('');
    setDataExport([]);
    setUserlist(tmpDataList);
  }, [dataResAPI]);

  // useEffect(() => {
  //   console.log('change userList =>', userList.length);
  //   const filteredUsers = applySortFilter(userList, getComparator(order, orderBy), filterName, columnFilter);

  //   const nowDate = new Date();
  //   if (filteredUsers.length) {
  //     const tmpColWidths = [
  //       { wch: 10 },
  //       { wch: 10 },
  //       { wch: 10 },
  //       { wch: 10 },
  //       { wch: 20 },
  //       { wch: 100 },
  //       { wch: 30 },
  //       { wch: 20 },
  //       { wch: 10 },
  //       { wch: 20 },
  //       { wch: 15 },
  //     ];
  //     setColWidths(tmpColWidths);
  //     const tmpDataList = [];
  //     const tmpEmty = {
  //       channelId: '',
  //       channelName: '',
  //       layer: '',
  //       size: '',
  //       barcode: '',
  //       name: '',
  //       categorythai: '',
  //       callNo: '',
  //       language: '',
  //       receivedDate: '',
  //       status: '',
  //     };
  //     tmpDataList.push(tmpEmty);
  //     const reportName = JSON.parse(JSON.stringify(tmpEmty));
  //     reportName.name = t('branchDetail.page.title');
  //     setFileName(`${t('branchDetail.page.title')}_${fileNameDate(nowDate)}`);

  //     const criteria = JSON.parse(JSON.stringify(tmpEmty));
  //     criteria.name = `${t('export.date')} ${showDateExcel(nowDate)}`;

  //     const tmpHeader = JSON.parse(JSON.stringify(tmpEmty));
  //     tmpHeader.channelId = t('branchDetail.table.channelId');
  //     tmpHeader.channelName = t('branchDetail.table.channelName');
  //     tmpHeader.layer = t('branchDetail.table.layer');
  //     tmpHeader.size = t('branchDetail.table.size');
  //     tmpHeader.barcode = t('branchDetail.table.barcode');
  //     tmpHeader.name = t('branchDetail.table.name');
  //     tmpHeader.categorythai = t('branchDetail.table.categorythai');
  //     tmpHeader.callNo = t('branchDetail.table.callNo');
  //     tmpHeader.language = t('branchDetail.table.language');
  //     tmpHeader.receivedDate = t('branchDetail.table.receivedDate');
  //     tmpHeader.status = t('branchDetail.table.status');

  //     tmpDataList.push(reportName);
  //     tmpDataList.push(criteria);
  //     tmpDataList.push(tmpEmty);
  //     tmpDataList.push(tmpHeader);

  //     filteredUsers.forEach((dataRow, count) => {
  //       const tmpObj = {
  //         channelId: dataRow.channelId,
  //         channelName: dataRow.channelName,
  //         layer: dataRow.layer,
  //         size: dataRow.size,
  //         barcode: dataRow.barcode,
  //         name: dataRow.name,
  //         categorythai: dataRow.categorythai,
  //         callNo: dataRow.callNo,
  //         language: dataRow.language,
  //         receivedDate: !dataRow.receivedDate ? '' : showDateExcel(dataRow.receivedDate),
  //         status: dataRow.status,
  //       };
  //       tmpDataList.push(tmpObj);
  //     });
  //     setDataExport(tmpDataList);
  //   }
  // }, [userList]);

  const genDataExport = (callback) => {
    // console.log('change dataSort =>', dataSort.length);
    // const filteredUsers = applySortFilter(userList, getComparator(order, orderBy), filterName, columnFilter);

    const nowDate = new Date();
    if (dataSort.length) {
      const tmpColWidths = [
        { wch: 10 },
        { wch: 10 },
        { wch: 10 },
        { wch: 10 },
        { wch: 20 },
        { wch: 100 },
        { wch: 30 },
        { wch: 20 },
        { wch: 10 },
        { wch: 20 },
        { wch: 15 },
      ];
      setColWidths(tmpColWidths);
      const tmpDataList = [];
      const tmpEmty = {
        channelId: '',
        channelName: '',
        layer: '',
        size: '',
        barcode: '',
        name: '',
        categorythai: '',
        callNo: '',
        language: '',
        receivedDate: '',
        status: '',
      };
      tmpDataList.push(tmpEmty);
      const reportName = JSON.parse(JSON.stringify(tmpEmty));
      reportName.name = t('branchDetail.page.title');
      setFileName(`${t('branchDetail.page.title')}_${fileNameDate(nowDate)}`);

      const criteria = JSON.parse(JSON.stringify(tmpEmty));
      criteria.name = `${t('export.date')} ${showDateExcel(nowDate)}`;

      const tmpHeader = JSON.parse(JSON.stringify(tmpEmty));
      tmpHeader.channelId = t('branchDetail.table.channelId');
      tmpHeader.channelName = t('branchDetail.table.channelName');
      tmpHeader.layer = t('branchDetail.table.layer');
      tmpHeader.size = t('branchDetail.table.size');
      tmpHeader.barcode = t('branchDetail.table.barcode');
      tmpHeader.name = t('branchDetail.table.name');
      tmpHeader.categorythai = t('branchDetail.table.categorythai');
      tmpHeader.callNo = t('branchDetail.table.callNo');
      tmpHeader.language = t('branchDetail.table.language');
      tmpHeader.receivedDate = t('branchDetail.table.receivedDate');
      tmpHeader.status = t('branchDetail.table.status');

      tmpDataList.push(reportName);
      tmpDataList.push(criteria);
      tmpDataList.push(tmpEmty);
      tmpDataList.push(tmpHeader);

      dataSort.forEach((dataRow, count) => {
        const tmpObj = {
          channelId: dataRow.channelId,
          channelName: dataRow.channelName,
          layer: dataRow.layer,
          size: dataRow.size,
          barcode: dataRow.barcode,
          name: dataRow.name,
          categorythai: dataRow.categorythai,
          callNo: dataRow.callNo,
          language: dataRow.language,
          receivedDate: !dataRow.receivedDate ? '' : showDateExcel(dataRow.receivedDate),
          status: dataRow.status,
        };
        tmpDataList.push(tmpObj);
      });
      // setDataExport(tmpDataList);
      const fName = `${t('branchDetail.page.title')}_${fileNameDates(new Date())}`;
      callback(tmpDataList, tmpColWidths, fName);
    } else {
      callback([], [], '');
    }
  };

  return (
    <>
      <Helmet>
        <title>
          {' '}
          {t('userList.title')} | {t('title')}{' '}
        </title>
      </Helmet>

      <Container maxWidth="xl">
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            {t('branchDetail.title')} ID: {branchId}
          </Typography>
          {/* {permission.create && (
            <Button variant="contained" startIcon={<Iconify icon="eva:plus-fill" />}  component={Link} to={"/branch/new"}>
              {t('branchList.btnNewUser')}
            </Button>
          )} */}
        </Stack>

        <Card>
          {/* <UserListToolbar numSelected={selected.length} filterName={filterName} onFilterName={handleFilterByName} /> */}
          <Grid container spacing={2}>
            <Grid item xs={12} sm={7} md={7}>
              <Grid container spacing={1}>
                <Grid item xs={12} sm={8} md={8}>
                  <UserListToolbar
                    numSelected={selected.length}
                    filterName={filterName}
                    onFilterName={handleFilterByName}
                    searchPlaceholder={t('branchDetail.search')}
                    onDelete={handleClearSelected}
                  />
                </Grid>
                {!selected.length && (
                  <Grid item xs={12} sm={4} md={4} sx={{ mt: 2.5 }}>
                    <Select
                      // labelId="demo-simple-select-label"
                      // id="demo-simple-select"
                      value={columnFilter} // {columnFilter}
                      // label="Column Filter"
                      onChange={handleChangeStatus}
                    >
                      {filterList.map((fl, i) => (
                        <MenuItem key={i} value={fl.value}>
                          {fl.lable}
                        </MenuItem>
                      ))}
                    </Select>
                  </Grid>
                )}
              </Grid>
            </Grid>
            <Grid item xs={12} sm={5} md={5}>
              <Grid container spacing={1} sx={{ mt: 3, pr: 3 }} justifyContent="flex-end">
                <Button
                  variant="contained"
                  startIcon={<Iconify icon="eva:refresh-fill" />}
                  size="large"
                  onClick={handleSearchClick}
                  sx={{ mr: 1 }}
                />
                <ExportToExcel
                  apiData={dataExport}
                  colWidths={colWidths}
                  fileName={fileName}
                  onCallback={genDataExport}
                />
              </Grid>
            </Grid>
          </Grid>

          <Scrollbar>
            <TableContainer sx={{ minWidth: 800, maxHeight: 500 }}>
              <Table stickyHeader>
                <UserListHead
                  order={order}
                  orderBy={orderBy}
                  // headLabel={TABLE_HEAD}
                  headLabel={tableHead}
                  rowCount={dataSort.length}
                  numSelected={selected.length}
                  onRequestSort={handleRequestSort}
                  onSelectAllClick={handleSelectAllClick}
                />
                <TableBody>
                  {filteredUsers.length ? (
                    filteredUsers.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => {
                      const {
                        channelId,
                        channelName,
                        layer,
                        size,
                        barcode,
                        name,
                        categorythai,
                        callNo,
                        language,
                        receivedDate,
                        status,
                        _id,
                      } = row;
                      const selectedUser = selected.indexOf(channelId) !== -1;

                      return (
                        <TableRow hover key={_id} tabIndex={-1} role="checkbox" selected={selectedUser}>
                          <TableCell padding="checkbox">
                            <Checkbox checked={selectedUser} onChange={(event) => handleClick(event, channelId)} />
                          </TableCell>

                          <TableCell align="center">{channelId}</TableCell>

                          <TableCell align="center">{channelName}</TableCell>

                          <TableCell align="center">{layer}</TableCell>

                          <TableCell align="center">{size}</TableCell>

                          <TableCell align="center">{barcode}</TableCell>
                          {/* 
                        <TableCell component="th" scope="row" padding="none">
                          <Stack direction="row" alignItems="center" spacing={2}>
                            <Typography variant="subtitle2" noWrap>
                              {name}
                            </Typography>
                          </Stack>
                        </TableCell> */}

                          <TableCell align="left">{name}</TableCell>

                          <TableCell align="left">{categorythai}</TableCell>

                          <TableCell align="left">{callNo}</TableCell>

                          <TableCell align="left">{language}</TableCell>

                          <TableCell align="left">{showDate(receivedDate)}</TableCell>

                          <TableCell align="left" sx={{ color: status === 'clear' ? 'primary.main' : '' }}>
                            {status}
                          </TableCell>
                          {/* <TableCell align="left">
                          <Label
                            color={(status === true && 'success' ) || 'error'}
                          >
                            {status === true?sentenceCase('True'):sentenceCase('False')}
                          </Label>
                        </TableCell> */}

                          {/* <TableCell align="right">
                          <UserMoreMenu
                            onDelete={() => handleDeleteUser(_id)}
                            editUrl={`/branch/edit/${_id}`}
                            txtEdit={t('branchList.table.edit')}
                            txtDelete={t('branchList.table.delete')}
                            detailUrl={`/branch/detail/${_id}`}
                            showEdit={showPageDetail}
                            showDelete={showPageDetail}
                            showDetail= {showPageDetail}
                          />
                        </TableCell> */}
                        </TableRow>
                      );
                    })
                  ) : (
                    <TableRow>
                      <TableCell align="center" colSpan={12} sx={{ py: 3 }}>
                        <Paper
                          sx={{
                            textAlign: 'center',
                          }}
                        >
                          <Typography variant="h6" paragraph>
                            Data not found
                          </Typography>
                        </Paper>
                      </TableCell>
                    </TableRow>
                  )}

                  {emptyRows > 0 && (
                    <TableRow style={{ height: 53 * emptyRows }}>
                      <TableCell colSpan={6} />
                    </TableRow>
                  )}
                </TableBody>

                {isNotFound && (
                  <TableBody>
                    <TableRow>
                      <TableCell align="center" colSpan={12} sx={{ py: 3 }}>
                        <Paper
                          sx={{
                            textAlign: 'center',
                          }}
                        >
                          {/* <Typography variant="h6" paragraph>
                            Not found
                          </Typography> */}

                          <Typography variant="body2">
                            No results found for &nbsp;
                            <strong>&quot;{filterName}&quot;</strong>.
                            <br /> Try checking for typos or using complete words.
                          </Typography>
                        </Paper>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                )}
              </Table>
            </TableContainer>
          </Scrollbar>

          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={dataSort.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            showFirstButton={showFirstButtonConf}
            showLastButton={showLastButtonConf}
            labelRowsPerPage={t('branchDetail.Pagination.rowsPerPage')}
          />
        </Card>
      </Container>

      <Popover
        open={Boolean(open)}
        anchorEl={open}
        onClose={handleCloseMenu}
        anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        PaperProps={{
          sx: {
            p: 1,
            width: 140,
            '& .MuiMenuItem-root': {
              px: 1,
              typography: 'body2',
              borderRadius: 0.75,
            },
          },
        }}
      >
        <MenuItem>
          <Iconify icon={'eva:edit-fill'} sx={{ mr: 2 }} />
          {t('branchList.table.edit')}
        </MenuItem>

        <MenuItem sx={{ color: 'error.main' }}>
          <Iconify icon={'eva:trash-2-outline'} sx={{ mr: 2 }} />
          {t('branchList.table.delete')}
        </MenuItem>
      </Popover>

      <ConfirmDialog
        title={t('branchDetail.ConfirmDialogClear.title')}
        open={confirmOpenClear}
        setOpen={setConfirmOpenClear}
        onConfirm={clearUserList}
        txtYes={t('btYes')}
        txtNo={t('btNo')}
      >
        {t('branchDetail.ConfirmDialogClear.content')} {selected.length} {t('branchDetail.ConfirmDialogClear.content2')}
      </ConfirmDialog>
    </>
  );
}
