import { Helmet } from 'react-helmet-async';
import { filter } from 'lodash';
import { sentenceCase } from 'change-case';
import { useState, useEffect } from 'react';
import { withCookies, Cookies } from 'react-cookie';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
// @mui
import {
  Card,
  Table,
  Stack,
  Paper,
  Avatar,
  Button,
  Popover,
  Checkbox,
  TableRow,
  MenuItem,
  TableBody,
  TableCell,
  Container,
  Typography,
  IconButton,
  TableContainer,
  TablePagination,
} from '@mui/material';
// components
import Label from '../components/label';
import Iconify from '../components/iconify';
import Scrollbar from '../components/scrollbar';
// sections
import { UserListHead, UserListToolbar, UserMoreMenu, ConfirmDialog } from '../sections/@dashboard/user';
// mock
import USERLIST from '../_mock/userList';

// ----------------------------------------------------------------------

const config = window.config;
const showFirstButtonConf = true;
const showLastButtonConf = true;

const TABLE_HEAD = [
  { id: 'username', label: 'Username', alignRight: false },
  { id: 'name', label: 'Name', alignRight: false },
  { id: 'organization', label: 'Organization', alignRight: false },
  { id: 'role', label: 'Role', alignRight: false },
  // { id: 'verified', label: 'Verified', alignRight: false },
  { id: 'status', label: 'Status', alignRight: false },
  { id: '' },
];

// ----------------------------------------------------------------------

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
  // console.log('applySortFilter array, comparator, query ->', array, comparator, query)
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  if (query) {
    return filter(array, (_user) => _user.name.toLowerCase().indexOf(query.toLowerCase()) !== -1);
  }
  return stabilizedThis.map((el) => el[0]);
}

export default function UserList({ permission }) {
  // console.log('====== UserList: permission ->', permission);
  if (!permission) permission = { create: false, update: false, delete: false };

  const [open, setOpen] = useState(null);

  const [page, setPage] = useState(0);

  const [order, setOrder] = useState('asc');

  const [selected, setSelected] = useState([]);

  const [orderBy, setOrderBy] = useState('name');

  const [filterName, setFilterName] = useState('');

  const [rowsPerPage, setRowsPerPage] = useState(10);

  const [userList, setUserlist] = useState([]);

  const [confirmOpen, setConfirmOpen] = useState(false);
  const [confirmOpenDelete, setConfirmOpenDelete] = useState(false);
  const [userForDelete, setUserForDelete] = useState({});

  const cookies = new Cookies();
  const currentUser = cookies.get('user');
  // console.log('===== cookies.get("user") ->', cookies.get('user'), currentUser);

  const { i18n, t } = useTranslation();

  const tableHead = [
    { id: 'username', label: t('userList.table.username'), alignRight: false },
    { id: 'name', label: t('userList.table.name'), alignRight: false },
    { id: 'organization', label: t('userList.table.organization'), alignRight: false },
    { id: 'role', label: t('userList.table.role'), alignRight: false },
    // { id: 'verified', label: t('userList.table.verified'), alignRight: false },
    { id: 'status', label: t('userList.table.status'), alignRight: false },
    { id: '' },
  ];

  const handleOpenMenu = (event) => {
    setOpen(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setOpen(null);
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = userList.map((n) => n._id);
      console.log('handleSelectAllClick ->', newSelecteds);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, _id) => {
    const selectedIndex = selected.indexOf(_id);
    let newSelected = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, _id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1));
    }
    // console.log('handleClick newSelected ->',newSelected)
    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setPage(0);
    setRowsPerPage(parseInt(event.target.value, 10));
  };

  const handleFilterByName = (event) => {
    setPage(0);
    setFilterName(event.target.value);
  };

  const handleDeleteUser = (userId) => {
    console.log('userId ->', userId);
    setConfirmOpen(true);
    const user = userList.find((u) => u._id === userId);
    setUserForDelete(user);
  };

  const deleteUser = () => {
    console.log('deleteUser userForDelete._id ->', userForDelete._id);

    apiDeleteUsers(userForDelete._id);
  };

  const deleteUserList = () => {
    // console.log('deleteUserList select ->', selected);
    const apiDeleteUser = async (_id) => {
      const url = `${config.backendUrl}/api/user/${_id}`;
      const response = await fetch(url, {
        method: 'DELETE',
      });
      if (!response.ok) {
        throw new Error(`apiDeleteUser HTTP error! status: ${response.status}`);
      }
      const data = await response.json();
    };

    const ids = selected;
    let userListBuf = JSON.parse(JSON.stringify(userList));
    for (let i = 0; i < ids.length; i += 1) {
      apiDeleteUser(ids[i]);
      userListBuf = userListBuf.filter((u) => u._id !== ids[i]);
    }
    setUserlist(userListBuf);
    setSelected([]);
    setConfirmOpenDelete(false);
  };

  const handleDeleteSelected = () => {
    // console.log('handleDeleteSelected selected ->', selected);
    setConfirmOpenDelete(true);
  };

  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - userList.length) : 0;

  const filteredUsers = applySortFilter(userList, getComparator(order, orderBy), filterName);

  const isNotFound = !filteredUsers.length && !!filterName;

  const apiDeleteUsers = (_id) => {
    // const _id = userForDelete._id;
    const url = `${config.backendUrl}/api/user/${_id}`;
    try {
      // console.log('apiDeleteUsers: url ->', url);

      fetch(url, {
        method: 'DELETE',
      })
        .then((response) => {
          return response.json();
        })
        .then((dataRes) => {
          console.log(`apiDeleteUsers: dataRes ->`, dataRes);
          const _id = dataRes._id;
          setUserForDelete({});
          setUserlist(userList.filter((u) => u._id !== _id));
        })
        .catch((err) => {
          console.error('apiDeleteUsers: catch Error ->', err);
        });
    } catch (e) {
      console.error('apiDeleteUsers: try Error -> ', e);
    }
  };

  useEffect(() => {
    // console.log('useEffect config =>', config, USERLIST[0]);
    // console.log('useEffect config =>', config);

    const apiGetUsers = () => {
      const url = `${config.backendUrl}/api/user?token=${currentUser.token}`;
      // console.log('url ->', url);
      try {
        // console.log('apiGetUsers: url ->', url);

        fetch(url, {
          method: 'GET',
        })
          .then((response) => {
            return response.json();
          })
          .then((dataRes) => {
            // console.log(`apiGetUsers: dataRes ->`, dataRes);
            if (dataRes.error) console.error('dataRes.error ->', dataRes.error);
            else setUserlist(dataRes);
          })
          .catch((err) => {
            console.error('apiGetUsers: catch Error ->', err);
          });
      } catch (e) {
        console.error('apiGetUsers: try Error -> ', e);
      }
    };
    apiGetUsers();
  }, []);

  // useEffect(() => {
  //   console.log('------ useEffect userList =>', userList);
  // }, []);

  return (
    <>
      <Helmet>
        <title>
          {' '}
          {t('userList.title')} | {t('title')}{' '}
        </title>
      </Helmet>

      <Container maxWidth="xl">
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            {t('userList.title')}
          </Typography>
          {permission.create && (
            <Button
              variant="contained"
              startIcon={<Iconify icon="eva:plus-fill" />}
              component={Link}
              to={'/user/users/new'}
            >
              {t('userList.btnNewUser')}
            </Button>
          )}
        </Stack>

        <Card>
          <UserListToolbar
            numSelected={selected.length}
            filterName={filterName}
            onFilterName={handleFilterByName}
            searchPlaceholder={t('userList.search')}
            onDelete={handleDeleteSelected}
          />

          <Scrollbar>
            <TableContainer sx={{ minWidth: 800, maxHeight: 500 }}>
              <Table stickyHeader >
                <UserListHead
                  order={order}
                  orderBy={orderBy}
                  // headLabel={TABLE_HEAD}
                  headLabel={tableHead}
                  rowCount={userList.length}
                  numSelected={selected.length}
                  onRequestSort={handleRequestSort}
                  onSelectAllClick={handleSelectAllClick}
                />
                <TableBody>
                  {filteredUsers.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => {
                    const {
                      id,
                      name,
                      role,
                      status,
                      company,
                      avatarUrl,
                      isVerified,
                      organization,
                      verified,
                      _id,
                      username,
                    } = row;
                    const selectedUser = selected.indexOf(_id) !== -1;
                    if (username === 'admin' || username === 'superadmin') return <></>;
                    return (
                      <TableRow hover key={_id} tabIndex={-1} role="checkbox" selected={selectedUser}>
                        <TableCell padding="checkbox">
                          <Checkbox checked={selectedUser} onChange={(event) => handleClick(event, _id)} />
                        </TableCell>

                        <TableCell align="left">{username}</TableCell>

                        <TableCell component="th" scope="row" padding="none">
                          <Stack direction="row" alignItems="center" spacing={2}>
                            {/* <Avatar alt={name} src={avatarUrl} /> */}
                            <Typography variant="subtitle2" noWrap>
                              {name}
                            </Typography>
                          </Stack>
                        </TableCell>

                        <TableCell align="left">{organization}</TableCell>

                        <TableCell align="left">{role}</TableCell>

                        {/* <TableCell align="left">{verified ? 'Yes' : 'No'}</TableCell> */}

                        <TableCell align="left">
                          <Label
                            color={
                              (status === 'banned' && 'error') || (status === 'inactive' && 'default') || 'success'
                            }
                          >
                            {sentenceCase(status)}
                          </Label>
                        </TableCell>

                        {/* <TableCell align="right">
                          <IconButton size="large" color="inherit" onClick={handleOpenMenu}>
                            <Iconify icon={'eva:more-vertical-fill'} />
                          </IconButton>
                        </TableCell> */}

                        <TableCell align="right">
                          <UserMoreMenu
                            onDelete={() => handleDeleteUser(_id)}
                            editUrl={`/user/users/edit/${_id}`}
                            txtEdit={t('userList.table.edit')}
                            txtDelete={t('userList.table.delete')}
                            showEdit={permission.update}
                            showDelete={permission.delete}
                          />
                        </TableCell>
                      </TableRow>
                    );
                  })}
                  {emptyRows > 0 && (
                    <TableRow style={{ height: 53 * emptyRows }}>
                      <TableCell colSpan={6} />
                    </TableRow>
                  )}
                </TableBody>

                {isNotFound && (
                  <TableBody>
                    <TableRow>
                      <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                        <Paper
                          sx={{
                            textAlign: 'center',
                          }}
                        >
                          <Typography variant="h6" paragraph>
                            Not found
                          </Typography>

                          <Typography variant="body2">
                            No results found for &nbsp;
                            <strong>&quot;{filterName}&quot;</strong>.
                            <br /> Try checking for typos or using complete words.
                          </Typography>
                        </Paper>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                )}
              </Table>
            </TableContainer>
          </Scrollbar>

          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={userList.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            showFirstButton={showFirstButtonConf}
            showLastButton={showLastButtonConf}
            labelRowsPerPage={t('branchDetail.Pagination.rowsPerPage')}
          />
        </Card>
      </Container>

      <Popover
        open={Boolean(open)}
        anchorEl={open}
        onClose={handleCloseMenu}
        anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        PaperProps={{
          sx: {
            p: 1,
            width: 140,
            '& .MuiMenuItem-root': {
              px: 1,
              typography: 'body2',
              borderRadius: 0.75,
            },
          },
        }}
      >
        <MenuItem>
          <Iconify icon={'eva:edit-fill'} sx={{ mr: 2 }} />
          {t('userList.table.edit')}
        </MenuItem>

        <MenuItem sx={{ color: 'error.main' }}>
          <Iconify icon={'eva:trash-2-outline'} sx={{ mr: 2 }} />
          {t('userList.table.delete')}
        </MenuItem>
      </Popover>

      <ConfirmDialog
        title={t('userList.ConfirmDialog.title')}
        open={confirmOpen}
        setOpen={setConfirmOpen}
        onConfirm={deleteUser}
        txtYes={t('ConfirmDialog.yes')}
        txtNo={t('ConfirmDialog.no')}
      >
        {t('userList.ConfirmDialog.content')} {userForDelete.name}
      </ConfirmDialog>

      <ConfirmDialog
        title={t('userList.ConfirmDialogDelete.title')}
        open={confirmOpenDelete}
        setOpen={setConfirmOpenDelete}
        onConfirm={deleteUserList}
        txtYes={t('btYes')}
        txtNo={t('btNo')}
      >
        {t('userList.ConfirmDialogDelete.content')} {selected.length} {t('userList.ConfirmDialogDelete.content2')}
      </ConfirmDialog>
    </>
  );
}
