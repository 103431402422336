import { useState } from 'react';
import { withCookies, Cookies } from 'react-cookie';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { Icon } from '@iconify/react';
import { NavLink as RouterLink, matchPath, useLocation } from 'react-router-dom';
import arrowIosForwardFill from '@iconify/icons-eva/arrow-ios-forward-fill';
import arrowIosDownwardFill from '@iconify/icons-eva/arrow-ios-downward-fill';
// material
import { alpha, useTheme, styled } from '@mui/material/styles';
import { Box, List, Collapse, ListItemText, ListItemIcon, ListSubheader, ListItemButton } from '@mui/material';
// hooks
// import useLocales from '../../../hooks/useLocales';
// import useLocales from '../hooks/useLocales';

// ----------------------------------------------------------------------

const ListSubheaderStyle = styled((props) => <ListSubheader disableSticky disableGutters {...props} />)(
  ({ theme }) => ({
    ...theme.typography.overline,
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(2),
    paddingLeft: theme.spacing(5),
    color: theme.palette.text.primary,
  })
);

const ListItemStyle = styled((props) => <ListItemButton disableGutters {...props} />)(({ theme }) => ({
  ...theme.typography.body2,
  height: 48,
  position: 'relative',
  textTransform: 'capitalize',
  paddingLeft: theme.spacing(5),
  paddingRight: theme.spacing(2.5),
  color: theme.palette.text.secondary,
  '&:before': {
    top: 0,
    right: 0,
    width: 3,
    bottom: 0,
    content: "''",
    display: 'none',
    position: 'absolute',
    borderTopLeftRadius: 4,
    borderBottomLeftRadius: 4,
    backgroundColor: theme.palette.primary.main,
  },
}));

const ListItemIconStyle = styled(ListItemIcon)({
  width: 22,
  height: 22,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
});

// ----------------------------------------------------------------------

NavItem.propTypes = {
  active: PropTypes.func,
  isShow: PropTypes.bool,
  item: PropTypes.object,
};

function NavItem({ item, active, isShow, authUser }) {
  const theme = useTheme();
  const isActiveRoot = active(item.path);
  const { title, path, icon, info, children } = item;
  const [open, setOpen] = useState(isActiveRoot);
  // const { allLang, currentLang, translate, onChangeLang } = useLocales();
  const { i18n, t } = useTranslation();

  // console.log('======= NavSection: authUser ->', authUser);
  const checkOnlyUser = (onlyUser) => {
    if (!onlyUser) {
      // console.log('1 xxxxxxx checkOnlyUser: onlyUser->', onlyUser);
      return true;
    }

    if (!authUser) {
      // console.log('2 xxxxxxx checkOnlyUser: onlyUser->', onlyUser);
      return false;
    }

    if (!authUser.username) {
      // console.log('2 xxxxxxx checkOnlyUser: onlyUser->', onlyUser);
      return false;
    }

    if (onlyUser.indexOf(authUser.username) < 0) {
      // console.log('3 xxxxxxx checkOnlyUser: onlyUser->', onlyUser);
      return false;
    }

    // console.log('4 xxxxxxx checkOnlyUser: onlyUser->', onlyUser);
    return true;
  };

  const handleOpen = () => {
    setOpen((prev) => !prev);
  };

  const activeRootStyle = {
    color: 'primary.main',
    fontWeight: 'fontWeightMedium',
    bgcolor: alpha(theme.palette.primary.main, theme.palette.action.selectedOpacity),
    '&:before': { display: 'block' },
  };

  const activeSubStyle = {
    color: 'text.primary',
    // color: 'text.tkpark',
    fontWeight: 'fontWeightMedium',
  };

  if (children) {
    return (
      <>
        <ListItemStyle
          onClick={handleOpen}
          sx={{
            ...(isActiveRoot && activeRootStyle),
          }}
        >
          {/* <ListItemIconStyle>{icon && icon}</ListItemIconStyle> */}

          {isShow && (
            <>
              <ListItemIconStyle>{icon && icon}</ListItemIconStyle>
              <ListItemText disableTypography primary={t(title)} />
              {info && info}
              <Box
                component={Icon}
                icon={open ? arrowIosDownwardFill : arrowIosForwardFill}
                sx={{ width: 16, height: 16, ml: 1 }}
              />
            </>
          )}
        </ListItemStyle>

        {isShow && (
          <Collapse in={open} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              {children.map((item) => {
                const { title, path } = item;
                const isActiveSub = active(path);

                return (
                  checkOnlyUser(item.onlyUser) && (
                    <ListItemStyle
                      key={title}
                      component={RouterLink}
                      to={path}
                      sx={{
                        ...(isActiveSub && activeSubStyle),
                      }}
                    >
                      <ListItemIconStyle>
                        <Box
                          component="span"
                          sx={{
                            width: 4,
                            height: 4,
                            display: 'flex',
                            borderRadius: '50%',
                            alignItems: 'center',
                            justifyContent: 'center',
                            bgcolor: 'text.disabled',
                            transition: (theme) => theme.transitions.create('transform'),
                            ...(isActiveSub && {
                              transform: 'scale(2)',
                              bgcolor: 'primary.main',
                            }),
                          }}
                        />
                      </ListItemIconStyle>
                      <ListItemText disableTypography primary={t(title)} />
                    </ListItemStyle>
                  )
                );
              })}
            </List>
          </Collapse>
        )}
      </>
    );
  }

  return (
    <ListItemStyle
      component={RouterLink}
      to={path}
      sx={{
        ...(isActiveRoot && activeRootStyle),
      }}
    >
      <ListItemIconStyle>{icon && icon}</ListItemIconStyle>
      {isShow && (
        <>
          <ListItemText disableTypography primary={t(title)} />
          {info && info}
        </>
      )}
    </ListItemStyle>
  );
}

NavSection.propTypes = {
  isShow: PropTypes.bool,
  navConfig: PropTypes.array,
};

export default function NavSection({ navConfig, isShow = true, authUserOld, ...other }) {
  const { pathname } = useLocation();
  const match = (path) => (path ? !!matchPath({ path, end: false }, pathname) : false);
  
  const cookies = new Cookies();
  const authUser = cookies.get('user');

  // console.log('======= NavSection: authUser ->', authUser);
  const checkOnlyUser = (onlyUser) => {
    if (!onlyUser) {
      // console.log('1 xxxxxxx checkOnlyUser: onlyUser->', onlyUser);
      return true;
    }

    if (!authUser) {
      // console.log('2 xxxxxxx checkOnlyUser: onlyUser->', onlyUser);
      return false;
    }

    if (!authUser.username) {
      // console.log('2 xxxxxxx checkOnlyUser: onlyUser->', onlyUser);
      return false;
    }

    if (onlyUser.indexOf(authUser.username) < 0) {
      // console.log('3 xxxxxxx checkOnlyUser: onlyUser->', onlyUser);
      return false;
    }

    // console.log('4 xxxxxxx checkOnlyUser: onlyUser->', onlyUser);
    return true;
  };

  return (
    <Box {...other}>
      {navConfig.map((list) => {
        const { subheader, items } = list;
        return (
          <List key={subheader} disablePadding>
            {isShow && <ListSubheaderStyle>{subheader}</ListSubheaderStyle>}
            {items.map((item) => (
              <NavItem
                key={item.title}
                item={item}
                active={match}
                authUser={authUser}
                isShow={checkOnlyUser(item.onlyUser)}
              /> // isShow={isShow} />
            ))}
          </List>
        );
      })}
    </Box>
  );
}
