import { Children, cloneElement } from 'react';
import { Navigate, useRoutes, useLocation } from 'react-router-dom';
import { useCookies } from 'react-cookie';
// layouts
import DashboardLayout from './layouts/dashboard';
import SimpleLayout from './layouts/simple';
//
import BlogPage from './pages/BlogPage';
import UserPage from './pages/UserPage';
import LoginPage from './pages/LoginPage';
import Page404 from './pages/Page404';
import ProductsPage from './pages/ProductsPage';
import PermissionDenied from './pages/PermissionsDenied';

import DashboardAppPage from './pages/DashboardAppPage';
import MonitoringPage from './pages/MonitoringPage';

import SystemLogReport from './pages/systemLogReport';
import BooksNotPicketUpReport from './pages/BooksNotPicketUpReport';
import MemberBookBorrowingReport from './pages/MemberBookBorrowingReport';
import StaffAccessToTheBoxReport from './pages/StaffAccessToTheBoxReport';
import StaffImportBookReport from './pages/StaffImportBookReport';
import StaffBookRemovalReport from './pages/StaffBookRemovalReport';
import MemberBoxAccessReport from './pages/MemberBoxAccessReport';
import NoMemberBoxAccessReport from './pages/NoMemberBoxAccessReport';
import MemberBookSelectionReport from './pages/MemberBookSelectionReport';
import GroupCallNumberReport from './pages/GroupCallNumberReport';

import UserList from './pages/UserList';
import UserCreate from './pages/UserCreate';
import BranchList from './pages/BranchList';
import BranchCreate from './pages/BranchCreate';
import RoleList from './pages/RoleList';
import RoleCreate from './pages/RoleCreate';
import PermissionList from './pages/PermissionList';
import PermissionCreate from './pages/PermissionCreate';
import BranchDetail from './pages/BranchDetail';

import SettingSmtp from './pages/SettingSmtp';
import SettingIssueEmail from './pages/SettingIssueEmail';

// ----------------------------------------------------------------------

// ----------------------------------------------------------------------
const config = window.config;
export default function Router() {
  // console.log('config ->', config);
  const [cookies, setCookie] = useCookies(['user']);

  // console.log('cookies.user ->', cookies.user);
  // console.log('cookies.permissions ->', cookies.permissions);

  function RequireAuth({ children, permissionName }) {
    const auth = {
      user: { ...cookies.user, permissions: cookies.permissions },
    };
    const location = useLocation();

    // console.log('auth.user ->', auth.user);
    if (!auth.user) {
      // Redirect them to the /login page, but save the current location they were
      // trying to go to when they were redirected. This allows us to send them
      // along to that page after they login, which is a nicer user experience
      // than dropping them off on the home page.
      return <Navigate to="/login" state={{ from: location }} replace />;
    }

    if (!auth.user.username) {
      return <Navigate to="/login" state={{ from: location }} replace />;
    }

    const username = auth.user.username.toLowerCase();
    if (username === 'superadmin' || username === 'admin') {
      // if (username === 'superadmin') {
      // console.log('>>>>> I am admin <<<<<', auth.user);
      return cloneElement(children, { ...auth.user, permission: { create: true, update: true, delete: true } }, null);
    }

    if (!permissionName) {
      // console.log('xxxxxxxxxxx route !permissionName');
      return cloneElement(children, { ...auth.user, permission: { create: true, update: true, delete: true } }, null);
    }

    if (!auth.user.permissions) {
      // console.log('1 xxxxxxxxxxx route !auth.user.permissions');
      return <Navigate to="/permissionsdenied" state={{ from: location }} replace />;
    }

    if (!auth.user.permissions.length) {
      // console.log('2 xxxxxxxxxxx route !auth.user.permissions.length');
      return <Navigate to="/permissionsdenied" state={{ from: location }} replace />;
    }

    const permission = auth.user.permissions.find((u) => u.name === permissionName);
    // console.log('==== RequireAuth permission ->', permission, permissionName);
    // return children;
    if (!permission) {
      // console.log('3 xxxxxxxxxxx route permissionName !permission');
      return <Navigate to="/permissionsdenied" state={{ from: location }} replace />;
    }

    return cloneElement(children, { ...auth.user, permission }, null);
    // third parameter is null
    // Because we are not adding any children
  }

  const routes = useRoutes([
    {
      path: '/dashboard',
      element: (
        <RequireAuth>
          <DashboardLayout />
        </RequireAuth>
      ),
      children: [
        { element: <Navigate to="/dashboard/app" />, index: true },
        {
          path: 'app',
          element: (
            <>
              {/* <RequireAuth permissionName="Dashboard"> */}
              <DashboardAppPage />
              {/* </RequireAuth> */}
            </>
          ),
        },
        {
          path: 'monitoring',
          element: (
            <RequireAuth permissionName="Monitoring">
              <MonitoringPage />
            </RequireAuth>
          ),
        },
        // { path: 'user', element: <UserPage /> },
        // { path: 'products', element: <ProductsPage /> },
        // { path: 'blog', element: <BlogPage /> },
      ],
    },
    {
      path: '/user/users',
      element: <DashboardLayout />,
      children: [
        { element: <Navigate to="/user/users/list" replace />, index: true },
        // { path: 'profile', element: <UserProfile /> },
        // { path: 'cards', element: <UserCards /> },
        {
          path: 'list',
          element: (
            <RequireAuth permissionName="Users">
              <UserList />
            </RequireAuth>
          ),
        },
        {
          path: 'new',
          element: (
            <>
              <UserCreate />
            </>
          ),
        },
        {
          path: 'edit/:userId',
          element: (
            <>
              <UserCreate />
            </>
          ),
        },
        // { path: 'user', element: <><UserList /></> },
        // { path: 'permission', element: <><UserList /></> },
        // { path: 'role', element: <><UserList /></> },
        // { path: 'account', element: <UserAccount /> }
      ],
    },
    {
      path: '/user/role',
      element: <DashboardLayout />,
      children: [
        { element: <Navigate to="/user/role/list" replace />, index: true },
        {
          path: 'list',
          element: (
            <RequireAuth permissionName="Roles">
              <RoleList />
            </RequireAuth>
          ),
        },
        { path: 'new', element: <RoleCreate /> },
        { path: 'edit/:userId', element: <RoleCreate /> },
      ],
    },
    {
      path: '/user/permission',
      element: <DashboardLayout />,
      children: [
        { element: <Navigate to="/user/permission/list" replace />, index: true },
        {
          path: 'list',
          element: (
            <RequireAuth permissionName="Permissions">
              <PermissionList />
            </RequireAuth>
          ),
        },
        { path: 'new', element: <PermissionCreate /> },
        { path: 'edit/:userId', element: <PermissionCreate /> },
      ],
    },
    {
      path: '/report',
      element: <DashboardLayout />,
      children: [
        { element: <Navigate to="/report/systemlogreport" />, index: true },
        {
          path: 'systemlogreport',
          element: (
            <RequireAuth permissionName="ReportVendingIssue">
              <SystemLogReport />
            </RequireAuth>
          ),
        },
        {
          path: 'BooksNotPicketUpReport',
          element: (
            <RequireAuth permissionName="ReportNoPickupBook">
              <BooksNotPicketUpReport />
            </RequireAuth>
          ),
        },
        {
          path: 'memberBookBorrowingReport',
          element: (
            <RequireAuth permissionName="ReportsMemberBookBorrowing">
              <MemberBookBorrowingReport />
            </RequireAuth>
          ),
        },
        {
          path: 'memberBookSelectionReport',
          element: (
            <RequireAuth permissionName="ReportMemberBookSelection">
              <MemberBookSelectionReport />
            </RequireAuth>
          ),
        },
        {
          path: 'memberBoxAccessReport',
          element: (
            <RequireAuth permissionName="ReportMemberAccess">
              <MemberBoxAccessReport />
            </RequireAuth>
          ),
        },
        {
          path: 'noMemberBoxAccessReport',
          element: (
            <RequireAuth permissionName="ReportAnonymousAccess">
              <NoMemberBoxAccessReport />
            </RequireAuth>
          ),
        },
        {
          path: 'staffBookRemovalReport',
          element: (
            <RequireAuth permissionName="ReportStaffExportBook">
              <StaffBookRemovalReport />
            </RequireAuth>
          ),
        },
        {
          path: 'staffImportBookReport',
          element: (
            <RequireAuth permissionName="ReportStaffImportBook">
              <StaffImportBookReport />
            </RequireAuth>
          ),
        },
        {
          path: 'staffAccessToTheBoxReport',
          element: (
            <RequireAuth permissionName="ReportStaffAccess">
              <StaffAccessToTheBoxReport />
            </RequireAuth>
          ),
        },
        { path: 'groupCallNumberReport', element: <GroupCallNumberReport /> },
      ],
    },
    {
      path: '/branch',
      element: <DashboardLayout />,
      children: [
        { element: <Navigate to="/branch/list" replace />, index: true },
        {
          path: 'list',
          element: (
            <RequireAuth permissionName="BookDispenserMachine">
              <BranchList />
            </RequireAuth>
          ),
        },
        {
          path: 'new',
          element: (
            <>
              <BranchCreate />
            </>
          ),
        },
        {
          path: 'edit/:branchId',
          element: (
            <>
              <BranchCreate />
            </>
          ),
        },
        {
          path: 'detail/:branchId',
          element: (
            <RequireAuth permissionName="BookDispenserMachineDetail">
              <BranchDetail />
            </RequireAuth>
          ),
        },
      ],
    },
    // {
    //   path: '/user',
    //   element: <DashboardLayout />,
    //   children: [
    //     { element: <Navigate to="/user/list" />, index: true },
    //     { path: 'list', element: <><UserPage /></> },
    //     { path: 'profile', element: <> <UserPage /></>},
    //     { path: 'create', element: <><UserPage /></> },
    //     { path: 'edit', element: <><UserPage /> </>},
    //     { path: 'account', element: <><UserPage /></> },
    //   ]
    // },
    {
      path: '/setting',
      element: <DashboardLayout />,
      children: [
        { element: <Navigate to="/setting/smtp" />, index: true },
        {
          path: 'smtp',
          element: (
            <RequireAuth permissionName="SettingIssueToEmail">
              <SettingSmtp />
            </RequireAuth>
          ),
        },
        {
          path: 'issue-email',
          element: (
            <RequireAuth permissionName="SettingSmtpServer">
              <SettingIssueEmail />
            </RequireAuth>
          ),
        },
      ],
    },
    {
      path: 'login',
      element: <LoginPage />,
    },
    {
      path: 'register',
      element: <UserCreate />,
    },
    // {
    //   path: '/register',
    //   element: <SimpleLayout />,
    //   children: [
    //     { element: <Navigate to="/register" />, index: true },
    //     { path: 'new', element: <UserCreate /> }
    //   ],
    // },
    {
      element: <SimpleLayout />,
      children: [
        { element: <Navigate to="/dashboard/app" />, index: true },
        { path: '404', element: <Page404 /> },
        { path: 'permissionsdenied', element: <PermissionDenied /> },
        { path: '*', element: <Navigate to="/404" /> },
      ],
    },
    {
      path: '*',
      element: <Navigate to="/404" replace />,
    },
  ]);

  return routes;
}
