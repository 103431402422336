import { faker } from '@faker-js/faker';
import { sample } from 'lodash';

// ----------------------------------------------------------------------

// ### get all รายงานการเข้าใช้งานตู้ของเจ้าหน้าที่
// GET http://localhost:3001/api/employee-login-logout/range?__start=2023-05-18T04:17:44.234Z&__end=2023-05-18T04:19:20.040Z

// [
//   {
//     "_id": "6465a6e843bde8595512dff3",
//     "loginDate": "2023-05-18T04:17:44.234Z",
//     "employeeName": "อารยา  สุขใจ",
//     "loginStatus": "เข้าใช้งาน",
//     "logoutDate": "2023-05-18T05:17:44.234Z",
//     "logoutStatus": "ออกจากระบบ",
//     "event": "ยืมหนังสือ",
//     "location": "Central World",
//     "__v": 0
//   },

const logReport = [...Array(24)].map((_, index) => ({
  _id: faker.datatype.uuid(),
  loginDate: new Date().toISOString(),
  employeeName: sample(['อารยา  สุขใจ','ลุกมัน อะแดม','อิสมาแอ อาแว','พีรมล  นิ่มนวล','พิทักษ์  ดำรงนาน']),
  loginStatus: "เข้าใช้งาน",
  logoutDate: new Date().toISOString(),
  logoutStatus: "ออกจากระบบ",
  event: sample(['นำหนังสือเข้า', 'นำหนังสือออก']),
  location: sample([
    'Central World',
    'True  Digital  Park',
  ])
}));

export default logReport;
