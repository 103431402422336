import { Helmet } from 'react-helmet-async';
import { useNavigate } from 'react-router-dom';
// @mui
import { styled } from '@mui/material/styles';
import { Link, Container, Typography, Divider, Stack, Button } from '@mui/material';

import { useTranslation } from 'react-i18next';

// hooks
import useResponsive from '../hooks/useResponsive';
// components
import Logo from '../components/logo';
import Iconify from '../components/iconify';
// sections
import { LoginForm } from '../sections/auth/login';

import LanguagePopover from '../layouts/dashboard/header/LanguagePopover';

// ----------------------------------------------------------------------

const StyledRoot = styled('div')(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    display: 'flex',
  },
}));

const StyledSection = styled('div')(({ theme }) => ({
  width: '100%',
  maxWidth: 480,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  boxShadow: theme.customShadows.card,
  backgroundColor: theme.palette.background.default,
}));

const StyledContent = styled('div')(({ theme }) => ({
  maxWidth: 480,
  margin: 'auto',
  minHeight: '100vh',
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
  padding: theme.spacing(12, 0),
}));

// ----------------------------------------------------------------------

export default function LoginPage() {
  const { i18n, t } = useTranslation();

  const mdUp = useResponsive('up', 'md');

  const navigate = useNavigate();

  const onLinkClick = () => {
    // navigate('/register', { replace: true });
  };

  return (
    <>
      <Helmet>
        <title>
          {' '}
          {t('loginPage.title')} | {t('title')}{' '}
        </title>
      </Helmet>

      <StyledRoot>
        <Logo
          sx={{
            position: 'fixed',
            top: { xs: 16, sm: 24, md: 40 },
            left: { xs: 16, sm: 24, md: 40 },
          }}
        />

        {mdUp && (
          <StyledSection>
            <Typography variant="h3" sx={{ px: 5, mt: 10, mb: 5 }}>
              {t('loginPage.welcom')}
            </Typography>
            <img src="/assets/illustrations/illustration_login.png" alt="login" />
          </StyledSection>
        )}

        <Container maxWidth="sm">
          <StyledContent>
            <Stack direction="row" justifyContent="space-between">
              <Typography variant="h4" gutterBottom>
                {t('loginPage.title')}
              </Typography>
              <LanguagePopover />
            </Stack>

            <Typography variant="body2" sx={{ mb: 5 }}>
              {/* {t('loginPage.subTitle1')} {''} // เกตให้ปิดใว้
              <Link variant="subtitle2" to="/register" onClick={onLinkClick}>
                {t('loginPage.subTitle2')}
              </Link> */}
              {/* <Typography variant="subtitle2" sx={{ color: 'text.primary' }}>
                {t('loginPage.subTitle2')}
              </Typography> */}
            </Typography>

            {/* <Stack direction="row" spacing={2}>
              <Button fullWidth size="large" color="inherit" variant="outlined">
                <Iconify icon="eva:google-fill" color="#DF3E30" width={22} height={22} />
              </Button>

              <Button fullWidth size="large" color="inherit" variant="outlined">
                <Iconify icon="eva:facebook-fill" color="#1877F2" width={22} height={22} />
              </Button>

              <Button fullWidth size="large" color="inherit" variant="outlined">
                <Iconify icon="eva:twitter-fill" color="#1C9CEA" width={22} height={22} />
              </Button>
            </Stack> */}

            <Divider sx={{ my: 3 }}>
              <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                {t('loginPage.labelTitle')}
              </Typography>
            </Divider>

            <LoginForm
              labelUsername={t('loginPage.labelUsername')}
              labelPassword={t('loginPage.labelPassword')}
              btLogin={t('loginPage.btLogin')}
            />
          </StyledContent>
        </Container>
      </StyledRoot>
    </>
  );
}
