import * as Yup from 'yup';
import { Helmet } from 'react-helmet-async';
import { filter } from 'lodash';
import { sentenceCase } from 'change-case';
import { useState, useEffect, useCallback } from 'react';
import { withCookies, Cookies } from 'react-cookie';
// import { useSnackbar } from 'notistack5'; // notistack5
import { SnackbarProvider, enqueueSnackbar } from 'notistack';
import { useNavigate, useParams, useLocation } from 'react-router-dom';
import { Form, FormikProvider, useFormik } from 'formik'; //
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
// @mui
import {
  Box,
  Card,
  Grid,
  Table,
  Stack,
  Switch,
  Select,
  Paper,
  Avatar,
  Button,
  Popover,
  Checkbox,
  TableRow,
  MenuItem,
  TableBody,
  TableCell,
  TextField,
  Container,
  FormControl,
  Typography,
  IconButton,
  InputLabel,
  InputAdornment,
  TableContainer,
  TablePagination,
  FormControlLabel,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
// components
import Label from '../components/label';
import Iconify from '../components/iconify';
import Scrollbar from '../components/scrollbar';
// sections
import { UserListHead, UserListToolbar, UserMoreMenu, ConfirmDialog } from '../sections/@dashboard/user';
// mock
import USERLIST from '../_mock/userList';

// ----------------------------------------------------------------------

const PAGE_ID = 'UserCreate';
const config = window.config;

export default function UserCreate() {
  const { i18n, t } = useTranslation();

  const { pathname } = useLocation();
  const isEdit = pathname.includes('edit');
  const { userId } = useParams();
  const [currentUser, setCurrentUser] = useState({});

  const [status, setStatus] = useState('active');
  const [roles, setRoles] = useState([]);
  const [role, setRole] = useState('default');

  const [newPassword, setNewPassword] = useState('');
  const [newPasswordError, setNewPasswordError] = useState(false);
  const [newPasswordErrorText, setNewPasswordErrorText] = useState('');

  const [passwordAgain, setPasswordAgain] = useState('');
  const [passwordAgainError, setPasswordAgainError] = useState(false);
  const [passwordAgainErrorText, setPasswordAgainErrorText] = useState('');

  const [showPassword, setShowPassword] = useState(false);
  const [changePassword, setChengePassword] = useState(false);

  const cookies = new Cookies();
  const authUser = cookies.get('user');

  // const [isEdit, setIsEdit] = useState(false);

  const navigate = useNavigate();
  // const { enqueueSnackbar } = useSnackbar();

  const NewUserSchema = Yup.object().shape({
    username: Yup.string().required('Username is required'),
    password: Yup.string().required('Password is required'),
    name: Yup.string().required('Name is required'),
    // email: Yup.string().required('Email is required').email(),
    // phoneNumber: Yup.string().required('Phone number is required'),
    // address: Yup.string().required('Address is required'),
    // country: Yup.string().required('country is required'),
    // company: Yup.string().required('Company is required'),
    organization: Yup.string().required('Organization is required'),
    // state: Yup.string().required('State is required'),
    // city: Yup.string().required('City is required'),
    role: Yup.string().required('Role Number is required'),
    avatarUrl: Yup.mixed().required('Avatar is required'),
  });

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      username: currentUser?.username || '',
      password: currentUser?.password || '',
      name: currentUser?.name || '',
      // email: currentUser?.email || '',
      // phoneNumber: currentUser?.phoneNumber || '',
      // address: currentUser?.address || '',
      // country: currentUser?.country || '',
      // state: currentUser?.state || '',
      // city: currentUser?.city || '',
      // zipCode: currentUser?.zipCode || '',
      avatarUrl: currentUser?.avatarUrl || '/images/avantar/tkpark.jpg', // null,
      // isVerified: currentUser?.isVerified || true,
      verified: currentUser?.verified || false,
      status: currentUser?.status || 'inactive',
      // company: currentUser?.company || '',
      organization: currentUser?.organization || 'TKPark',
      role: currentUser?.role || '',
    },
    validationSchema: NewUserSchema,
    onSubmit: async (values, { setSubmitting, resetForm, setErrors }) => {
      // console.log('onSubmit values ->', values);

      try {
        // await fakeRequest(500);
        const apiCreateUsers = (user) => {
          // const _id = userForDelete._id;
          const url = `${config.backendUrl}/api/user/register`;
          try {
            // console.log('apiCreateUsers: url ->', url);

            fetch(url, {
              method: 'POST',
              headers: { 'Content-Type': 'application/json' },
              body: JSON.stringify(values),
            })
              .then((response) => {
                return response.json();
              })
              .then((dataRes) => {
                // console.log(`apiCreateUsers: dataRes ->`, dataRes);
                if (dataRes.error) {
                  const error = dataRes.error.message;
                  setSubmitting(false);
                  enqueueSnackbar(!isEdit ? `Create Error: ${error}` : `Update Error: ${error}`, {
                    autoHideDuration: 2000,
                    variant: 'error',
                    persist: false,
                  });
                  return;
                }
                resetForm();
                setSubmitting(false);
                enqueueSnackbar(!isEdit ? `Create success` : `Update success`, {
                  autoHideDuration: 2000,
                  variant: 'success',
                  persist: false,
                });
                navigate('/user/users/list');
              })
              .catch((err) => {
                console.error('apiCreateUsers: catch Error ->', err);
                enqueueSnackbar(!isEdit ? 'Create Error' : 'Update Error', { variant: 'error' });
              });
          } catch (e) {
            console.error('apiCreateUsers: try Error -> ', e);
          }
        };

        const apiEditUsers = (userId) => {
          // const _id = userForDelete._id;
          const url = `${config.backendUrl}/api/user/${userId}`;
          try {
            // console.log('apiEditUsers: url ->', url);

            fetch(url, {
              method: 'PUT',
              headers: { 'Content-Type': 'application/json' },
              body: JSON.stringify(values),
            })
              .then((response) => {
                return response.json();
              })
              .then((dataRes) => {
                // console.log(`apiEditUsers: dataRes ->`, dataRes);
                if (dataRes.error) {
                  const error = dataRes.error.message;
                  setSubmitting(false);
                  enqueueSnackbar(!isEdit ? `Create Error: ${error}` : `Update Error: ${error}`, {
                    autoHideDuration: 2000,
                    variant: 'error',
                    persist: false,
                  });
                  return;
                }
                resetForm();
                setSubmitting(false);
                enqueueSnackbar(!isEdit ? `Create success` : `Update success`, {
                  autoHideDuration: 2000,
                  variant: 'success',
                  persist: false,
                });
                navigate('/user/users/list');
              })
              .catch((err) => {
                console.error('apiEditUsers: catch Error ->', err);
                enqueueSnackbar(!isEdit ? 'Create Error' : 'Update Error', { variant: 'error' });
              });
          } catch (e) {
            console.error('apiEditUsers: try Error -> ', e);
          }
        };

        if (isEdit) {
          apiEditUsers(currentUser._id);
        } else {
          apiCreateUsers();
        }

        // resetForm();
        // setSubmitting(false);
        // enqueueSnackbar(!isEdit ? 'Create success' : 'Update success', { variant: 'success' });
        // navigate(PATH_DASHBOARD.user.list);
      } catch (error) {
        console.error(error);
        setSubmitting(false);
        setErrors(error);
      }
    },
  });

  const { errors, values, touched, handleSubmit, isSubmitting, getFieldProps, setFieldValue } = formik;

  const handleChangeStatus = (e) => {
    // console.log('handleChangeStatus e..target.value', e.target.value);
    setStatus(e.target.value);
    setFieldValue('status', e.target.value);
  };

  const handleChangeRole = (e) => {
    console.log('handleChangeRole e..target.value', e.target.value);
    setRole(e.target.value);
    setFieldValue('role', e.target.value);
  };

  const handleResetPassword = () => {
    if (newPassword === '') {
      setNewPasswordErrorText('Password is empty.');
      setNewPasswordError(true);
      return;
    }
    if (passwordAgain === '') {
      setPasswordAgainErrorText('Password is empty.');
      setPasswordAgainError(true);
      return;
    }

    if (newPassword !== passwordAgain) {
      setNewPasswordErrorText('Passwords are not the same.');
      setNewPasswordError(true);
      setPasswordAgainErrorText('Passwords are not the same.');
      setPasswordAgainError(true);
      return;
    }

    setNewPasswordErrorText('');
    setNewPasswordError(false);
    setPasswordAgainErrorText('');
    setPasswordAgainError(false);
    setChengePassword(false);

    const apiChangePassword = (_id, token) => {
      // console.log('_id, token ->', _id, token)

      const url = `${config.backendUrl}/api/user/change-password/${_id}`;
      try {
        console.log('apiChangePassword: url ->', url);

        fetch(url, {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({ newPassword, token }),
        })
          .then((response) => {
            return response.json();
          })
          .then((dataRes) => {
            console.log(`apiChangePassword: dataRes ->`, dataRes);
            if (dataRes.error) {
              const error = dataRes.error.message;

              enqueueSnackbar(!isEdit ? `Reset Password Error: ${error}` : `Reset Password Error: ${error}`, {
                autoHideDuration: 2000,
                variant: 'error',
                persist: false,
              });
              return;
            }

            enqueueSnackbar(!isEdit ? `Reset Password success` : `Reset Password success`, {
              autoHideDuration: 2000,
              variant: 'success',
              persist: false,
            });
            // navigate('/user/users/list');
          })
          .catch((err) => {
            console.error('apiChangePassword: catch Error ->', err);
            enqueueSnackbar(!isEdit ? 'Create Error' : 'Update Error', { variant: 'error' });
          });
      } catch (e) {
        console.error('apiChangePassword: try Error -> ', e);
      }
    };
    apiChangePassword(currentUser._id, authUser.token);
  };

  useEffect(() => {
    // console.log('UserCreate: useEffect config, isEdit, userId =>', config, isEdit, userId);

    const apiGetUser = (userId) => {
      // const _id = userForDelete._id;
      const url = `${config.backendUrl}/api/user/${userId}`;
      try {
        // console.log('apiGetUser: url ->', url);

        fetch(url, {
          method: 'GET',
          // headers: { 'Content-Type': 'application/json' },
          // body: JSON.stringify(values),
        })
          .then((response) => {
            return response.json();
          })
          .then((dataRes) => {
            // console.log(`apiGetUser: dataRes ->`, dataRes);
            if (dataRes.error) {
              const error = dataRes.error.message;
            } else {
              setCurrentUser(dataRes);
              setStatus(dataRes.status);
              setRole(dataRes.role);
            }
          })
          .catch((err) => {
            console.error('apiGetUser: catch Error ->', err);
            enqueueSnackbar(!isEdit ? 'Create Error' : 'Update Error', { variant: 'error' });
          });
      } catch (e) {
        console.error('apiGetUser: try Error -> ', e);
      }
    };
    if (userId) apiGetUser(userId);

    const apiGetRoles = () => {
      const url = `${config.backendUrl}/api/role`;
      try {
        // console.log('apiGetRoles: url ->', url);

        fetch(url, {
          method: 'GET',
          // headers: { 'Content-Type': 'application/json' },
          // body: JSON.stringify(values),
        })
          .then((response) => {
            return response.json();
          })
          .then((dataRes) => {
            // console.log(`apiGetRoles: dataRes ->`, dataRes);
            if (dataRes.error) {
              const error = dataRes.error.message;
            } else {
              setRoles(dataRes);
            }
          })
          .catch((err) => {
            console.error('apiGetRoles: catch Error ->', err);
            enqueueSnackbar(!isEdit ? 'Create Error' : 'Update Error', { variant: 'error' });
          });
      } catch (e) {
        console.error('apiGetRoles: try Error -> ', e);
      }
    };
    apiGetRoles();

    const defaultFormik = () => {
      formik.setFieldValue('status', status);
      formik.setFieldValue('role', role);
    };
    if (!isEdit) defaultFormik();

    setNewPasswordError(false);
    setPasswordAgainError(false);
  }, []);

  return (
    <>
      <Helmet>
        <title>
          {!isEdit ? t('userCreate.titleCreate') : t('userCreate.titleEdit')} | {t('title')}
        </title>
      </Helmet>

      <Container maxWidth="xl">
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            {!isEdit
              ? t('userCreate.titleCreate')
              : changePassword
              ? t('userCreate.btChangePassword')
              : t('userCreate.titleEdit')}
          </Typography>
        </Stack>

        <FormikProvider value={formik}>
          {/* {console.log('formik ->', values)} */}
          <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
            <Grid container spacing={3}>
              {!changePassword && (
                <Grid item xs={12} md={8}>
                  <Card sx={{ p: 3 }}>
                    <Stack spacing={3}>
                      <Stack direction={{ xs: 'column', sm: 'row' }} spacing={{ xs: 3, sm: 2 }}>
                        <TextField
                          fullWidth
                          label={t('userCreate.username')}
                          {...getFieldProps('username')}
                          error={Boolean(touched.username && errors.username)}
                          helperText={touched.username && errors.username}
                          disabled={isEdit}
                        />
                        {isEdit ? (
                          // <TextField
                          //   fullWidth
                          //   label={t('userCreate.password')}
                          //   // {...getFieldProps('password')}
                          //   // error={Boolean(touched.password && errors.password)}
                          //   // helperText={touched.password && errors.password}
                          //   defaultValue="********"
                          //   disabled
                          // />
                          <Button
                            fullWidth
                            variant="outlined"
                            color="primary"
                            onClick={() => {
                              setChengePassword(true);
                            }}
                            sx={{ mr: 2 }}
                          >
                            {t('userCreate.btChangePassword')}
                          </Button>
                        ) : (
                          <TextField
                            fullWidth
                            label={t('userCreate.password')}
                            {...getFieldProps('password')}
                            error={Boolean(touched.password && errors.password)}
                            helperText={touched.password && errors.password}
                          />
                        )}
                      </Stack>
                      <Stack direction={{ xs: 'column', sm: 'row' }} spacing={{ xs: 3, sm: 2 }}>
                        <TextField
                          fullWidth
                          label={t('userCreate.fullName')}
                          {...getFieldProps('name')}
                          error={Boolean(touched.name && errors.name)}
                          helperText={touched.name && errors.name}
                        />
                        <TextField
                          fullWidth
                          label={t('userCreate.organization')}
                          {...getFieldProps('organization')}
                          error={Boolean(touched.organization && errors.organization)}
                          helperText={touched.organization && errors.organization}
                        />
                      </Stack>

                      <Stack direction={{ xs: 'column', sm: 'row' }} spacing={{ xs: 3, sm: 2 }}>
                        <FormControl fullWidth>
                          <InputLabel id="role-simple-select-label">{t('userCreate.role')}</InputLabel>
                          <Select
                            labelId="role-simple-select-label"
                            // id="demo-simple-select"
                            value={role}
                            label="Role"
                            onChange={handleChangeRole}
                          >
                            {roles.map((r) => (
                              <MenuItem key={r._id} value={r.name}>
                                {r.name}
                              </MenuItem>
                            ))}
                            {/* {console.log('getFieldProps(role)', getFieldProps('role').value)} */}
                          </Select>
                        </FormControl>
                        <FormControl fullWidth>
                          <InputLabel id="demo-simple-select-label">{t('userCreate.status')}</InputLabel>
                          <Select
                            labelId="demo-simple-select-label"
                            // id="demo-simple-select"
                            value={status}
                            label="Status"
                            onChange={handleChangeStatus}
                          >
                            <MenuItem value="active">Active</MenuItem>
                            <MenuItem value="inactive">Inactive</MenuItem>
                          </Select>
                        </FormControl>
                      </Stack>

                      <Box sx={{ mt: 3, display: 'flex', justifyContent: 'flex-end' }}>
                        <Button
                          variant="outlined"
                          color="primary"
                          onClick={() => {
                            navigate('/user/users/list');
                          }}
                          sx={{ mr: 2 }}
                        >
                          {t('btCancel')}
                        </Button>
                        <LoadingButton type="submit" variant="contained" loading={isSubmitting}>
                          {!isEdit ? t('userCreate.btCreateUser') : t('userCreate.btSaveChanges')}
                        </LoadingButton>
                      </Box>
                    </Stack>
                    <SnackbarProvider
                      anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'center',
                      }}
                    />
                  </Card>
                </Grid>
              )}
              {changePassword && (
                <Grid item xs={12} md={4}>
                  <Card sx={{ p: 3 }}>
                    <Stack spacing={3}>
                      <Stack direction={{ xs: 'column', sm: 'row' }} spacing={{ xs: 3, sm: 2 }}>
                        <TextField
                          fullWidth
                          label={t('userCreate.username')}
                          {...getFieldProps('username')}
                          error={Boolean(touched.username && errors.username)}
                          helperText={touched.username && errors.username}
                          disabled={isEdit}
                        />
                      </Stack>
                      <Stack direction={{ xs: 'column', sm: 'row' }} spacing={{ xs: 3, sm: 2 }}>
                        <TextField
                          fullWidth
                          label={t('userCreate.newPassword')}
                          value={newPassword}
                          error={newPasswordError}
                          helperText={newPasswordErrorText}
                          onChange={(e) => {
                            setNewPassword(e.target.value);
                            setNewPasswordError(false);
                            setNewPasswordErrorText('');
                          }}
                          type={showPassword ? 'text' : 'password'}
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                <IconButton
                                  onClick={() => {
                                    setShowPassword(!showPassword);
                                  }}
                                  edge="end"
                                >
                                  <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                                </IconButton>
                              </InputAdornment>
                            ),
                          }}
                        />
                      </Stack>
                      <Stack direction={{ xs: 'column', sm: 'row' }} spacing={{ xs: 3, sm: 2 }}>
                        <TextField
                          fullWidth
                          label={t('userCreate.passwordAgain')}
                          value={passwordAgain}
                          error={passwordAgainError}
                          helperText={passwordAgainErrorText}
                          onChange={(e) => {
                            setPasswordAgain(e.target.value);
                            setPasswordAgainError(false);
                            setPasswordAgainErrorText('');
                          }}
                          type={showPassword ? 'text' : 'password'}
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                <IconButton onClick={() => setShowPassword(!showPassword)} edge="end">
                                  <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                                </IconButton>
                              </InputAdornment>
                            ),
                          }}
                        />
                      </Stack>
                      <Box sx={{ mt: 3, display: 'flex', justifyContent: 'flex-end' }}>
                        <Button
                          variant="outlined"
                          color="primary"
                          onClick={() => {
                            setChengePassword(false);
                          }}
                          sx={{ mr: 2 }}
                        >
                          {t('btCancel')}
                        </Button>
                        <Button variant="contained" color="primary" onClick={handleResetPassword}>
                          {t('userCreate.btChangePassword')}
                        </Button>
                      </Box>
                    </Stack>
                    <SnackbarProvider
                      anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'center',
                      }}
                    />
                  </Card>
                </Grid>
              )}
            </Grid>
          </Form>
        </FormikProvider>
      </Container>
    </>
  );
}
