import React, { useEffect, useState } from 'react';

import { Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField } from '@mui/material';
import dayjs from 'dayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';

const FixIssueDialog = (props) => {
  const {
    title,
    children,
    open,
    setOpen,
    onConfirm,
    txtYes,
    txtNo,
    fixDateLable,
    fixDateValue,
    setFixDateValue,
    onFixIssueSave,
    noteDate,
    noteDateLable,
    noteBy,
    noteByLabel,
    fixNote,
    setFixNote,
    fixNoteLabel,
    permission,
  } = props;

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <Dialog fullWidth maxWidth="xs" open={open} onClose={() => setOpen(false)} aria-labelledby="confirm-dialog">
        <DialogTitle>{title}</DialogTitle>
        <DialogContent>
          {/* {children} */}
          <DateTimePicker
            ampm={false}
            format="DD/MM/YYYY HH:mm"
            label={fixDateLable}
            value={dayjs(fixDateValue)}
            onChange={(value) => {
              setFixDateValue(value.toDate());
            }}
            sx={{ mt: 3, width: '100%' }}
          />
          <TextField
            fullWidth
            sx={{ mt: 2, width: '100%' }}
            label={fixNoteLabel}
            multiline
            rows={4}
            defaultValue={fixNote}
            onChange={(e) => {
              // console.log("e.target.value ->", e.target.value)
              setFixNote(e.target.value);
            }}
          />
          <TextField
            fullWidth
            sx={{ mt: 2, width: '100%' }}
            disabled
            label={noteDateLable}
            defaultValue={dayjs(noteDate).format('DD/MM/YYYY HH:mm')}
          />
          <TextField fullWidth sx={{ mt: 2, width: '100%' }} disabled label={noteByLabel} defaultValue={noteBy} />
        </DialogContent>
        <DialogActions>
          <Button variant="outlined" onClick={() => setOpen(false)} color="primary">
            {txtNo}
          </Button>
          <Button disabled={!permission.update} variant="contained" onClick={onFixIssueSave} color="error">
            {txtYes}
          </Button>
        </DialogActions>
      </Dialog>
    </LocalizationProvider>
  );
};

export default FixIssueDialog;
