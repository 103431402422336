import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
// @mui
import { alpha } from '@mui/material/styles';
import { Box, MenuItem, Stack, IconButton, Popover } from '@mui/material';

// ----------------------------------------------------------------------

const LANGS = [
  {
    value: 'th',
    label: 'Thailand',
    icon: '/assets/icons/ic_flag_th.svg',
  },
  {
    value: 'en',
    label: 'English',
    icon: '/assets/icons/ic_flag_en.svg',
  },
];

// ----------------------------------------------------------------------

export default function LanguagePopover() {
  const [open, setOpen] = useState(null);
  const [language, setLanguage] = useState(1);
  const { i18n, t } = useTranslation();

  const handleOpen = (event) => {
    // console.log(event.currentTarget);
    setOpen(event.currentTarget);
  };

  const handleClose = () => {
    setOpen(null);
  };

  const handleChange = (event) => {
    // console.log('event ->', event);
    switch (event.value) {
      case 'th':
        setLanguage(0);
        i18n.changeLanguage('th');
        break;
      case 'en':
        setLanguage(1);
        i18n.changeLanguage('en');
        break;
      default:
        setLanguage(0);
        i18n.changeLanguage('th');
        break;
    }
    setOpen(null);
  };

  useEffect(() => {
    // console.log('i18n.language ->', i18n.language);
    switch (i18n.language) {
      case 'th':
        setLanguage(0);
        break;
      case 'en':
        setLanguage(1);
        break;
      default:
        setLanguage(0);
        i18n.changeLanguage('th');
        break;
    }
  }, []);

  return (
    <>
      <IconButton
        onClick={handleOpen}
        sx={{
          padding: 0,
          width: 44,
          height: 44,
          ...(open && {
            bgcolor: (theme) => alpha(theme.palette.primary.main, theme.palette.action.focusOpacity),
          }),
        }}
      >
        <img src={LANGS[language].icon} alt={LANGS[language].label} />
      </IconButton>

      <Popover
        open={Boolean(open)}
        anchorEl={open}
        onClose={handleClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        PaperProps={{
          sx: {
            p: 1,
            mt: 1.5,
            ml: 0.75,
            width: 180,
            '& .MuiMenuItem-root': {
              px: 1,
              typography: 'body2',
              borderRadius: 0.75,
            },
          },
        }}
      >
        <Stack spacing={0.75}>
          {LANGS.map((option) => (
            <MenuItem
              key={option.value}
              selected={option.value === LANGS[language].value}
              onClick={() => handleChange(option)}
            >
              <Box component="img" alt={option.label} src={option.icon} sx={{ width: 28, mr: 2 }} />

              {option.label}
            </MenuItem>
          ))}
        </Stack>
      </Popover>
    </>
  );
}
