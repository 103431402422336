// import * as React from 'react';
import { useState, useEffect } from 'react';
import { useTranslation } from "react-i18next";
import dayjs from 'dayjs';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import PropTypes from 'prop-types';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { Card, Button, Grid, TextField, MenuItem } from '@mui/material';

const confStyle = {
  dateField: {
    marginTop: '0.5rem',
    marginBottom: '1rem',
    marginLeft: '1rem',
    
  },
  containerDateRange: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start'
  },
  btn : {
    marginTop: '1.2rem',
    marginLeft: '1rem'
  },
  textFildAll : {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'flex-start'
  },
  textFild : {
    marginLeft: '1rem',
    marginBottom: '1.5rem'
  },
  textFildSelect : {
    marginTop: '1rem',
    marginLeft: '1rem',
    marginBottom: '1.5rem'
  },
  line : {
    marginLeft: '1rem',
    marginRight: '1rem',
    marginBottom: '1rem',
    borderColor: '#F9FAFB',
    opacity: 0.48
  },
  title : {
    marginLeft: '1rem',
    marginRight: '1rem',
  }
}

const criteriaSelectConst = {
  id: 'searchBy',
  label: 'filter.SearchBy',
  defaultValue: "day",
  helperText: "",
  options: [
    {
      label: 'filter.hours',
      value: 'hours'
    },
    {
      label: 'filter.day',
      value: 'day'
    },
    {
      label: 'filter.week',
      value: 'week'
    },
    {
      label: 'filter.month',
      value: 'month'
    },
    {
      label: 'filter.quarter',
      value: 'quarter'
    },
    {
      label: 'filter.year',
      value: 'year'
    }
  ]
}

const timeout = 1;

DateTimeFieldFilter.propTypes = {
  startDate: PropTypes.string,
  endDate: PropTypes.string,
  onCallbackValue: PropTypes.func,
  onSearchClick: PropTypes.func,
  onHandleChange: PropTypes.func,
  onSearchByCallback: PropTypes.func
};

export default function DateTimeFieldFilter({
  startDate,
  endDate,
  onCallbackValue,
  onSearchClick,
  fildFilter,
  onHandleChange,
  onSearchByCallback
}) {
  const [valueStart, setValueStart] = useState(dayjs(startDate));
  const [valueEnd, setValueEnd] = useState(dayjs(endDate));
  const [dateRange, setDateRange] = useState([dayjs(startDate),dayjs(endDate)])
  const { i18n, t } = useTranslation();

  const onchangeStartHandler = (value)  => {
    // console.log('onchangeStartHandler value ->',value);
    setValueStart(value)
    const tmpDateRange = dateRange;
    tmpDateRange[0] = value;
    if( (new Date(value.$d) ) > (new Date(tmpDateRange[1].$d) ) ) {
      let tmpDate = new Date(value.$d)
      tmpDate = tmpDate.setDate(tmpDate.getDate()+1);
      const tmpDateEnd = dayjs(tmpDate)
      // console.log('tmpDateEnd ->',tmpDateEnd)
      tmpDateRange[1] = tmpDateEnd;
      setValueEnd(tmpDateEnd);
    }
    setDateRange(tmpDateRange);
    onCallbackValue(tmpDateRange);
  };

  const onchangeEndHandler = (value)  => {
    // console.log('onchangeEndHandler value ->',value);
    setValueEnd(value)
    const tmpDateRange = dateRange;
    tmpDateRange[1] = value;
    if( (new Date(value.$d) ) < (new Date(tmpDateRange[0].$d) ) ) {
      let tmpDate = new Date(value.$d)
      tmpDate = tmpDate.setDate(tmpDate.getDate()-1);
      const tmpDateStart = dayjs(tmpDate)
      // console.log('tmpDateStart ->',tmpDateStart)
      tmpDateRange[0] = tmpDateStart;
      setValueStart(tmpDateStart);
    }
    setDateRange(tmpDateRange);
    onCallbackValue(tmpDateRange);
  };

  const handleChange = (event) => {
    // console.log('even ->',event);
    if(event.target === undefined ) return;
    if(event.target.value === undefined ) return;
    const val = event.target.value;
    const sDate = new Date();
    const eDate = new Date();
    const lDate = new Date()
    let tmpQuarter = 0;
    const nextQuarter = new Date();
    switch (val) {
      case 'year':
        sDate.setMonth(0);
        sDate.setDate(1);
        sDate.setHours(0);
        sDate.setMinutes(0);
        sDate.setSeconds(0);

        eDate.setMonth(11);
        eDate.setDate(31);
        eDate.setHours(23);
        eDate.setMinutes(59);
        eDate.setSeconds(59);
      break;
      case 'quarter':
        tmpQuarter = Math.floor(sDate.getMonth() / 3 + 1);
        switch (tmpQuarter) {
          case 1:
            sDate.setFullYear(sDate.getFullYear(), 0, 1);
            eDate.setFullYear(eDate.getFullYear(), 2, 31);
          break;
          case 2:
            sDate.setFullYear(sDate.getFullYear(), 3, 1);
            eDate.setFullYear(eDate.getFullYear(), 5, 30);
          break;
          case 3:
            sDate.setFullYear(sDate.getFullYear(), 6, 1);
            eDate.setFullYear(eDate.getFullYear(), 8, 30);
          break;
          case 4:
            sDate.setFullYear(sDate.getFullYear(), 9, 1);
            eDate.setFullYear(eDate.getFullYear(), 11, 31);
          break;
          default:
          break;

        }

        sDate.setHours(0);
        sDate.setMinutes(0);
        sDate.setSeconds(0);

        eDate.setHours(23);
        eDate.setMinutes(59);
        eDate.setSeconds(59);
      break;
      case 'month':
        sDate.setDate(1); 
        sDate.setMonth(sDate.getMonth()); 
        sDate.setHours(0); 
        sDate.setMinutes(0); 
        sDate.setSeconds(0)

        eDate.setMonth(eDate.getMonth()+1); 
        eDate.setDate(0); 
        eDate.setHours(23); 
        eDate.setMinutes(59); 
        eDate.setSeconds(59);
        // console.log("sDate date -->",sDate)
        // console.log("eDate date -->",eDate)

      break;
      case 'week':
        sDate.setDate( lDate.getDate() - (lDate.getDay() ) );
        eDate.setDate( lDate.getDate() + (6- lDate.getDay() ) );

        sDate.setHours(0);
        sDate.setMinutes(0);
        sDate.setSeconds(0);

        eDate.setHours(23);
        eDate.setMinutes(59);
        eDate.setSeconds(59);
      break;
      case 'day':
        sDate.setHours(0);
        sDate.setMinutes(0);
        sDate.setSeconds(0);

        eDate.setHours(23);
        eDate.setMinutes(59);
        eDate.setSeconds(59);
      break;
      case 'hours':
        sDate.setMinutes(0);
        sDate.setSeconds(0);

        eDate.setMinutes(59);
        eDate.setSeconds(59);
      break;
      default:
      break

    }
    setValueStart(dayjs(sDate))
    setValueEnd(dayjs(eDate));
    const tmpDateRange = dateRange;
    tmpDateRange[0] = dayjs(sDate);
    tmpDateRange[1] = dayjs(eDate);
    setDateRange(tmpDateRange);
    onCallbackValue(tmpDateRange);
    onSearchByCallback({sDate,eDate,val});
  }


  useEffect(() => {
    // console.log('useEffect')
    const sDate = new Date(startDate);
    const eDate = new Date(endDate);
    sDate.setHours(0);
    sDate.setMinutes(0);
    sDate.setSeconds(0);
    eDate.setHours(23);
    eDate.setMinutes(59);
    eDate.setSeconds(59);
    setValueStart(dayjs(sDate));
    setValueEnd(dayjs(eDate));
  },[])

  
  // useEffect(() => {
  //   console.log('Chang value ->',dateRange)
  // },[dateRange])

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <>
        <h3 style={confStyle.title} >{t("filter.Search")}</h3>
      </>
      <div style={confStyle.containerDateRange} >
        {/* <div style={confStyle.textFildSelect}>
          <TextField
            style={{'min-width': '16.5rem'}}
            id={criteriaSelectConst.id}
            name={criteriaSelectConst.id}
            select
            label={t(criteriaSelectConst.label)}
            defaultValue={criteriaSelectConst.defaultValue}
            helperText={criteriaSelectConst.helperText}
            onChange={(val) => handleChange(val)}
            >
            {criteriaSelectConst.options.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {t(option.label)}
              </MenuItem>
            ))}
            </TextField>
        </div> */}

        <div style={confStyle.dateField}>
          <DateTimePicker
            ampm={false}
            format="DD/MM/YYYY HH:mm"
            label={t("filter.Start Date")}
            value={valueStart}
            onChange={(newValue) => onchangeStartHandler(newValue)}
          />
        </div>

        <div style={confStyle.dateField}>
          <DateTimePicker
            ampm={false}
            format="DD/MM/YYYY HH:mm"
            label={t("filter.End Date")}
            value={valueEnd}
            onChange={(newValue) => onchangeEndHandler(newValue)}
          />
        </div>

        <div style={confStyle.btn}>
          <Button variant="contained" size="large"  onClick={onSearchClick}>{t("filter.Search")}</Button>
        </div>

      </div>
      {fildFilter && 
        <>
          <hr style={confStyle.line} />
          <h3 style={confStyle.title} >{t("filter.filter")}</h3>
        </>
      }

      {fildFilter && 
          <div style={confStyle.textFildAll}>
            {fildFilter.map((item) => (
              <div style={confStyle.textFild}>
               {item.type === 'select' ? (
                <TextField
                  style={{'min-width': '16.5rem'}}
                  id={item.id}
                  select
                  label={t(item.label)}
                  defaultValue={item.defaultValue}
                  value={item.value}
                  helperText={item.helperText}
                  onChange={(val) => onHandleChange({item, val})}
                >
                  {item.currencies.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </TextField> 
                ) : (
                  <TextField
                    style={{'min-width': '16.5rem'}}
                    id={item.id}
                    label={t(item.label)}
                    defaultValue={item.defaultValue}
                    value={item.value}
                    helperText={item.helperText}
                    onChange={(val) => onHandleChange({item, val})}
                  />
                )}
              </div>
            ))}
          </div>
        }
    </LocalizationProvider>
  );
}
