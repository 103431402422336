import { Helmet } from 'react-helmet-async';
import { useTranslation } from "react-i18next";
import { format } from "date-fns";
import { filter } from 'lodash';
import { sentenceCase } from 'change-case';
import { useState, useEffect } from 'react';
// @mui
import {
  Grid,
  Card,
  CardActions,
  Table,
  Stack,
  Paper,
  Avatar,
  Button,
  Popover,
  Checkbox,
  TableRow,
  MenuItem,
  TableBody,
  TableCell,
  Container,
  Typography,
  IconButton,
  TableContainer,
  TablePagination,
} from '@mui/material';
// components
import Label from '../components/label';
import Iconify from '../components/iconify';
import Scrollbar from '../components/scrollbar';
// import FilterDate from '../components/filter'
import { ExportToExcel } from '../components/exportExcel/ButtonExports';
import { DateFieldFilter, DateTimeFieldFilter } from '../components/filter';
import { ChartBarReports, ChartReports, ChartPieReports, ChartDonutReports, ChartBarReportNew }  from '../components/chartReport';
// sections
import { UserListHead, UserListToolbar } from '../sections/@dashboard/report';
// mock
// import USERLIST from '../_mock/user';
// import USERLIST from '../_mock/logReport';

import LOGLIST from '../_mock/memberBookSelections';

// ----------------------------------------------------------------------

const defaultChartDataObj = [
  {
    year: 'Year',
    data: [
      { name: 'Check In', data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0] }
    ]
  }
];

// ### get all รายงานการเลือกดูหนังสือของสมาชิก
// GET http://localhost:3001/api/member-book-selections/range?__start=2023-05-17T09:20:40.461Z&__end=2023-05-17T09:26:05.853Z

// [
//   {
//     "memberName": "",
//     "_id": "64649d1590326ecc5f3a2e30",
//     "date": "2023-05-17T09:23:33.049Z",
//     "barcode": "32005002107028",
//     "bookName": "บันทึกเหตุการณ์สำคัญของโลก",
//     "bookNumber": "909 .ก25",
//     "bookCategory": "ประวัติศาสตร์",
//     "location": "Central World",
//     "__v": 0
//   },

// {
//     "memberBookSelection": {
//       "order": "Order",
//       "date": "Date",
//       "time": "Time",
//       "barcode": "Barcode",
//       "bookName": "Book name",
//       "bookNumber": "Book number",
//       "bookCategory": "Book category",
//       "location": "Location"
//     }
//   }

// style={{  position: "sticky", left: 0, background: "#ffe6e6"}} align="center">
const TABLE_HEAD = [
  { id: 'order', label: 'memberBookSelection.order', alignRight: false , sx:{minWidth: '50px', textAlign: 'center' }},
  { id: 'date', label: 'memberBookSelection.date', alignRight: false , sx:{minWidth: '180px' }},
  // { id: 'time', label: 'memberBookSelection.time', alignRight: false , sx:{minWidth: '130px', position: "sticky", left: 185, zIndex: 99}},
  { id: 'barcode', label: 'memberBookSelection.barcode', alignRight: false , sx:{minWidth: '120px'}},
  { id: 'bookName', label: 'memberBookSelection.bookName', alignRight: false , sx:{minWidth: '350px'}},
  { id: 'bookNumber', label: 'memberBookSelection.bookNumber', alignRight: false , sx:{minWidth: '130px'}},
  { id: 'bookCategory', label: 'memberBookSelection.bookCategory', alignRight: false , sx:{minWidth: '130px'}},
  { id: 'location', label: 'memberBookSelection.location', alignRight: false , sx:{minWidth: '100px'}},
];
// ----------------------------------------------------------------------

const arryFilter = [];
const ConstFildFilter = [
  {
    type : "select",
    id: "location",
    label: "memberBookSelection.location",
    defaultValue: "All",
    helperText: "",
    currencies: [
      {
        value: 'All',
        label: 'All',
      }
    ]
  },
  {
    type : "select",
    id: "bookCategory",
    label: "memberBookSelection.bookCategory",
    defaultValue: "All",
    helperText: "",
    currencies: [
      {
        value: 'All',
        label: 'All',
      }
    ]
  },
  {
    type : "input",
    id: "bookName",
    label: "memberBookSelection.bookName",
    defaultValue: "",
    helperText: "",
    currencies: []
  }

]

function showDate (date) {
  if(date === undefined) return '--/--/---- --:--:--';
  if(date === null) return '--/--/---- --:--:--';
  if(date === '') return '--/--/---- --:--:--';
  if(date === '-') return '--/--/---- --:--:--';
  return  format(new Date(date), "dd/MM/yyyy HH:mm:ss");
}

function showDateExcel (date) {
  if(date === undefined) return '--/--/---- --:--:--';
  if(date === null) return '--/--/---- --:--:--';
  if(date === '') return '--/--/---- --:--:--';
  if(date === '-') return '--/--/---- --:--:--';
  return  format(new Date(date), "dd/MM/yyyy HH:mm:ss");
}

function showTime (date) {
  if(date === undefined) return '--:--:--';
  if(date === null) return '--:--:--';
  if(date === '') return '--:--:--';
  if(date === '-') return '--:--:--';
  return  format(new Date(date), "HH:mm:ss");
}

function fileNameDate (date) {
  if(date === undefined) return '';
  if(date === null) return '';
  if(date === '') return '';
  if(date === '-') return '';
  return  format(new Date(date), "dd_MM_yyyy");
}

function fileNameDates (date) {
  if(date === undefined) return '';
  if(date === null) return '';
  if(date === '') return '';
  if(date === '-') return '';
  return  format(new Date(date), "dd_MM_yyyy_HH_mm_ss");
}

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  if (query) {
    return filter(array, (_user) => _user.name.toLowerCase().indexOf(query.toLowerCase()) !== -1);
  }
  return stabilizedThis.map((el) => el[0]);
}

const fetchUserData = (dataIn, fn) => {
  try {
    console.log('dataIn ->',dataIn)
    fetch(dataIn.url,{
      method: dataIn.method,
      body: JSON.stringify(dataIn.body),
      headers: {
          'Content-type': 'application/json; charset=UTF-8'
      }
    })
    .then(response => {
      return response.json()
    })
    .then(dataRes => {
      console.log('dataRes ->',dataRes)
      fn(dataRes.error, dataRes.data);
    })
    .catch(err => {
      console.log('catch Error ->' ,err);
      fn(err, null);
    })
  } catch (e) {
    console.log('try Error -> ',e);
    fn(e, null)
  }
}


const getAll = (dataIn, fn) => {
  try {
    console.log('dataIn ->',dataIn)
    fetch(dataIn.url,{
      method: 'GET',
    })
    .then(response => {
      return response.json()
    })
    .then(dataRes => {
      // console.log('dataRes ->',dataRes);
      if ( Array.isArray(dataRes) ) { 
        fn(null, dataRes);
      } else {
        fn(null, [])
      }
    })
    .catch(err => {
      console.log('catch Error ->' ,err);
      fn(err, null);
    })
  } catch (e) {
    console.log('try Error -> ',e);
    fn(e, null)
  }
}


const getbyTime = (dataIn, fn) => {
  try {
    // console.log('dataIn ->',dataIn)
    const url = dataIn.url.concat("__start=", dataIn.startDate, "&__end=", dataIn.endDate);
    // console.log('url ->',url)
    fetch(url,{
      method: 'GET',
    })
    .then(response => {
      return response.json()
    })
    .then(dataRes => {
      // console.log('dataRes ->',dataRes);
      if ( Array.isArray(dataRes) ) { 
        fn(null, dataRes);
      } else {
        fn(null, [])
      }
    })
    .catch(err => {
      console.log('catch Error ->' ,err);
      fn(err, null);
    })
  } catch (e) {
    console.log('try Error -> ',e);
    fn(e, null)
  }
}

const getbyId = (dataIn, fn) => {
  try {
    console.log('dataIn ->',dataIn)
    const url = dataIn.url.concat(dataIn.id);
    console.log('url ->',url)
    fetch(url,{
      method: 'GET',
    })
    .then(response => {
      return response.json()
    })
    .then(dataRes => {
      // console.log('dataRes ->',dataRes);
      if ( Array.isArray(dataRes) ) { 
        fn(null, dataRes);
      } else {
        fn(null, [])
      }
    })
    .catch(err => {
      console.log('catch Error ->' ,err);
      fn(err, null);
    })
  } catch (e) {
    console.log('try Error -> ',e);
    fn(e, null)
  }
}

let nowSearchBy = '';

const config = window.config;
const showFirstButtonConf = true;
const showLastButtonConf = true;

const timeout = 1000;

let startDate = new Date();

let endDate = new Date();

let dataSort = [];

export default function UserPage() {
  const [open, setOpen] = useState(null);

  const [page, setPage] = useState(0);

  const [order, setOrder] = useState('asc');

  const [selected, setSelected] = useState([]);

  const [orderBy, setOrderBy] = useState('id');

  const [filterName, setFilterName] = useState('');

  const [rowsPerPage, setRowsPerPage] = useState(10);

  const [dataResAPI, setDataResAPI] = useState([]);

  const [dataList, setDataList] = useState([]); 

  // const [startDate, setStartDate] = useState(new Date);

  // const [endDate, setEndDate] = useState(new Date);

  const [dataExport, setDataExport] = useState([]);

  const [fileName, setFfileName] = useState('Export');

  const [colWidths, setColWidths] = useState([{ wch: 5}, { wch: 20 }, { wch: 20 }, { wch: 20 }, { wch: 20 }, { wch: 20 }]);

  const [xaxis, setXaxis] = useState(["ม.ค.", "ก.พ.", "มี.ค.", "เม.ย.", "พ.ค.", "มิ.ย.", "ก.ค.", "ส.ค.", "ก.ย.", "ต.ค.", "พ.ย.", "ธ.ค."]);

  const [chartData, setChartData] = useState([]);

  const [onShow, setOonShow] = useState(true);
 
  const [fildFilter, setFildFilter] = useState([]);
 
  const [dataFilterList, setDataFilterList] = useState([])
 
  const [searchBy, setSearchBy] = useState('day');
 
  const [subheader, setSubheader] = useState('barChart.day');

  const { i18n, t } = useTranslation();


  useEffect(() => {
    const sDate = new Date();
    const eDate = new Date();
    sDate.setHours(0);
    sDate.setMinutes(0);
    sDate.setSeconds(0);
    eDate.setHours(23);
    eDate.setMinutes(59);
    eDate.setSeconds(59);
    // setStartDate(sDate)
    // setEndDate(eDate)
    startDate = sDate;
    endDate = eDate;
    setChartData(defaultChartDataObj);
    clearOptionSelect();
    setTimeout(() => {
      refresh();
      setSearchBy('day');
    }, timeout);
  }, []);

  useEffect(() => {
    // console.log('change searchBy')
    // console.log('startDate ->', startDate);
    // console.log('endDate ->', endDate);
    if(searchBy !== nowSearchBy) { 
      nowSearchBy = searchBy;
      // refresh();
    }
    switch (searchBy) {
      case 'year': 
        setSubheader('barChart.year')
      break;
      case 'quarter': 
        setSubheader('barChart.quarter')
      break;
      case 'month': 
        setSubheader('barChart.month')
      break;
      case 'week': 
        setSubheader('barChart.week')
      break;
      case 'day': 
        setSubheader('barChart.day')
      break;
      case 'hours': 
        setSubheader('barChart.hours')
      break;
      default: 
        setSubheader('barChart.day')
      break;
    }
  },[searchBy]);

// ### get all รายงานการเลือกดูหนังสือของสมาชิก
// GET http://localhost:3001/api/member-book-selections/range?__start=2023-05-17T09:20:40.461Z&__end=2023-05-17T09:26:05.853Z

// [
//   {
//     "memberName": "",
//     "_id": "64649d1590326ecc5f3a2e30",
//     "date": "2023-05-17T09:23:33.049Z",
//     "barcode": "32005002107028",
//     "bookName": "บันทึกเหตุการณ์สำคัญของโลก",
//     "bookNumber": "909 .ก25",
//     "bookCategory": "ประวัติศาสตร์",
//     "location": "Central World",
//     "__v": 0
//   },

// {
//     "memberBookSelection": {
//       "order": "Order",
//       "date": "Date",
//       "time": "Time",
//       "barcode": "Barcode",
//       "bookName": "Book name",
//       "bookNumber": "Book number",
//       "bookCategory": "Book category",
//       "location": "Location"
//     }
//   }

  useEffect(() => {
    // console.log('change dataResAPI =>',dataResAPI)
    const tmpDataList = []
    const tmpLocation = [];
    const tmpBookCategory = [];

    if (dataResAPI.length) {
      dataResAPI.forEach(((dataRow, count) => {
        const tmpObj = {
          id: '',
          date: '-',
          time: '-',
          bookName: '-',
          barcode: '-',
          bookNumber: '-',
          bookCategory: '-',
          location: '-',
          dateTr: ''
        }
        // console.log('dataRow [',count,'] ->',dataRow);
        tmpObj.id = dataRow._id;
        tmpObj.dateTr = dataRow.date;
        tmpObj.groupCallNo = dataRow.bookNumber;

        if(dataRow.date == null) tmpObj.date = '-'; else  tmpObj.date = dataRow.date;
        if(dataRow.date == null) tmpObj.time = '-'; else  tmpObj.time = dataRow.date;
        if(dataRow.barcode == null) tmpObj.barcode = '-'; else  tmpObj.barcode = dataRow.barcode;
        if(dataRow.bookName == null) tmpObj.bookName = '-'; else  tmpObj.bookName = dataRow.bookName;
        if(dataRow.bookNumber == null) tmpObj.bookNumber = '-'; else  tmpObj.bookNumber = dataRow.bookNumber;
        if(dataRow.bookCategory == null) tmpObj.bookCategory = '-'; else  tmpObj.bookCategory = dataRow.bookCategory;
        if(dataRow.location == null) tmpObj.location = '-'; else  tmpObj.location = dataRow.location;
        tmpDataList.push(tmpObj);
       // add option filter
        // filter first
        // ===========================
        if ( tmpLocation.length ) {
          const resultObject = tmpLocation.findIndex(x => x.value === dataRow.location);
          if (resultObject === -1) {
            const tmpOption = { value: dataRow.location, label: dataRow.location }
            tmpLocation.push(tmpOption);
          }
        } else {
          tmpLocation.push({value: 'All',label: t('filter.all')});
          const tmpOption = { value: dataRow.location, label: dataRow.location};
          tmpLocation.push(tmpOption);
        }
        // ===========================
        // filter second
        // ===========================
        if ( tmpBookCategory.length ) {
          const resultObject = tmpBookCategory.findIndex(x => x.value === dataRow.bookCategory);
          if (resultObject === -1) {
            const tmpOption = { value: dataRow.bookCategory, label: dataRow.bookCategory }
            tmpBookCategory.push(tmpOption);
          }
        } else {
          tmpBookCategory.push({value: 'All',label: t('filter.all')});
          const tmpOption = { value: dataRow.bookCategory, label: dataRow.bookCategory};
          tmpBookCategory.push(tmpOption);
        }
        // ===========================


      }))

      // update option filter
      // =======================================
      ConstFildFilter.forEach((item, index) => {
        // filter first
        // =========================
        if(item.type === 'select') {
          if (item.id === 'location') {
            ConstFildFilter[index].currencies = tmpLocation;
          }
        }
        // ==========================
        // filter second
        // =========================
        if(item.type === 'select') {
          if (item.id === 'bookCategory') {
            ConstFildFilter[index].currencies = tmpBookCategory;
          }
        }
        // ==========================
        // fiter more

        // ==========================
      })

      setFildFilter(ConstFildFilter);

    }
    setDataFilterList(tmpDataList);
    setDataList(tmpDataList)
  },[dataResAPI])

  // useEffect(() => {
  //   // console.log('change dataFilterList =>',dataFilterList.length);
  //   if (dataFilterList.length) {
  //     const tmpColWidths = [
  //       { wch: 5 }, 
  //       { wch: 15 }, 
  //       { wch: 15 }, 
  //       { wch: 20 }, 
  //       { wch: 100 },
  //       { wch: 30 },
  //       { wch: 20 },
  //       { wch: 20 }
  //     ]
  //     setColWidths(tmpColWidths);
  //     const tmpDataList = [];
  //     const tmpEmty = {
  //       order: '',
  //       date: '',
  //       time: '',
  //       barcode: '',
  //       bookName: '',
  //       bookNumber: '',
  //       bookCategory: '',
  //       location: ''
  //     }

  //     const reportName = JSON.parse(JSON.stringify(tmpEmty));
  //     reportName.bookName =t('memberBookSelection.page.title')
  //     setFfileName(`${t('memberBookSelection.page.title')}_${fileNameDate(new Date())}`);

  //     const criteria = JSON.parse(JSON.stringify(tmpEmty));
  //     criteria.bookName = `${t('filter.between')} ${showDateExcel(startDate)} - ${showDateExcel(endDate)}`;

  //     const tmpHeader = JSON.parse(JSON.stringify(tmpEmty));
  //     tmpHeader.order =  t('memberBookSelection.order');
  //     tmpHeader.date =  t('memberBookSelection.date');
  //     tmpHeader.time =  t('memberBookSelection.time');
  //     tmpHeader.barcode =  t('memberBookSelection.barcode');
  //     tmpHeader.bookName =  t('memberBookSelection.bookName');
  //     tmpHeader.bookNumber =  t('memberBookSelection.bookNumber');
  //     tmpHeader.bookCategory =  t('memberBookSelection.bookCategory');
  //     tmpHeader.location =  t('memberBookSelection.location');

  //     tmpDataList.push(tmpEmty);
  //     tmpDataList.push(reportName);
  //     tmpDataList.push(criteria);
  //     tmpDataList.push(tmpEmty);
  //     tmpDataList.push(tmpHeader);

  //     dataFilterList.forEach(((dataRow, count) => {
  //       const tmpObj = {
  //         order: (count + 1),
  //         date: showDateExcel(dataRow.date),
  //         time: showTime(dataRow.time),
  //         barcode: dataRow.barcode,
  //         bookName: dataRow.bookName,
  //         bookNumber: dataRow.bookNumber,
  //         bookCategory: dataRow.bookCategory,
  //         location: dataRow.location,
  //       }  
  //       tmpDataList.push(tmpObj);
  //     }))
  //     setDataExport(tmpDataList);
  //   }
  // },[dataFilterList]);

  const genDataExport = (callback) => {
    // console.log('change dataSort =>',dataSort.length);
    if (dataSort.length) {
      const tmpColWidths = [
        { wch: 5 }, 
        { wch: 20 }, 
        // { wch: 15 }, 
        { wch: 20 }, 
        { wch: 100 },
        { wch: 30 },
        { wch: 20 },
        { wch: 20 }
      ]
      // setColWidths(tmpColWidths);
      const tmpDataList = [];
      const tmpEmty = {
        order: '',
        date: '',
        // time: '',
        barcode: '',
        bookName: '',
        bookNumber: '',
        bookCategory: '',
        location: ''
      }

      const reportName = JSON.parse(JSON.stringify(tmpEmty));
      reportName.bookName =t('memberBookSelection.page.title')
      // setFfileName(`${t('memberBookSelection.page.title')}_${fileNameDate(new Date())}`);

      const criteria = JSON.parse(JSON.stringify(tmpEmty));
      criteria.bookName = `${t('filter.between')} ${showDateExcel(startDate)} - ${showDateExcel(endDate)}`;

      const tmpHeader = JSON.parse(JSON.stringify(tmpEmty));
      tmpHeader.order =  t('memberBookSelection.order');
      tmpHeader.date =  t('memberBookSelection.date');
      // tmpHeader.time =  t('memberBookSelection.time');
      tmpHeader.barcode =  t('memberBookSelection.barcode');
      tmpHeader.bookName =  t('memberBookSelection.bookName');
      tmpHeader.bookNumber =  t('memberBookSelection.bookNumber');
      tmpHeader.bookCategory =  t('memberBookSelection.bookCategory');
      tmpHeader.location =  t('memberBookSelection.location');

      tmpDataList.push(tmpEmty);
      tmpDataList.push(reportName);
      tmpDataList.push(criteria);
      tmpDataList.push(tmpEmty);
      tmpDataList.push(tmpHeader);

      dataSort.forEach(((dataRow, count) => {
        const tmpObj = {
          order: (count + 1),
          date: showDateExcel(dataRow.date),
          // time: showTime(dataRow.time),
          barcode: dataRow.barcode,
          bookName: dataRow.bookName,
          bookNumber: dataRow.bookNumber,
          bookCategory: dataRow.bookCategory,
          location: dataRow.location,
        }  
        tmpDataList.push(tmpObj);
      }))
      // setDataExport(tmpDataList);
      const fName = `${t('memberBookSelection.page.title')}_${fileNameDates(new Date())}`;
      callback(tmpDataList, tmpColWidths, fName);
    }else{
      callback([], [], '');
    }
  }

  // useEffect(() => {
  //   console.log('dataList =>',dataList)
  // },[dataList])

  const searchApi = ({sDate,eDate,val}) => {
    // console.log('searchApi ->',sDate,eDate,val);
    const data = {
      url:"http://localhost:3001/api/member-book-selections/range?",
      body: {}
    }

    // startDate.setHours(0); startDate.setMinutes(0); startDate.setSeconds(0)
    // endDate.setHours(23); endDate.setMinutes(59); endDate.setSeconds(59);

    // data.url = 'http://localhost:3001/api/borrow/range?notPickedUp=false&'
    data.url = `${config.backendUrl}/api/member-book-selections/range?`
    data.startDate = new Date(sDate).toISOString();
    data.endDate = new Date(eDate).toISOString();

    getbyTime (data, (err, fn) => {
      // console.log('err, fn ->',err, fn)
      if (config.production) {
        if(err === null){
          // console.log('getbyTime ->',fn)
          setDataResAPI(fn);
        }
      } else {
        setDataResAPI(LOGLIST);
      }
    })
  }

  const refresh = (search) => {
    // console.log('refresh ->',startDate,endDate)
    const data = {
      url:"http://localhost:3001/api/member-book-selections/range?",
      body: {}
    }

    // startDate.setHours(0); startDate.setMinutes(0); startDate.setSeconds(0)
    // endDate.setHours(23); endDate.setMinutes(59); endDate.setSeconds(59);

    // data.url = 'http://localhost:3001/api/borrow/range?notPickedUp=false&'
    data.url = `${config.backendUrl}/api/member-book-selections/range?`
    data.startDate = new Date(startDate).toISOString();
    data.endDate = new Date(endDate).toISOString();

    getbyTime (data, (err, fn) => {
      // console.log('err, fn ->',err, fn)
      if (config.production) {
        if(err === null){
          // console.log('getbyTime ->',fn)
          setDataResAPI(fn);
        }
      } else {
        setDataResAPI(LOGLIST);
      }
    })
  }

  const handleCallbackValue = (dataIn) => {
    // console.log('onCallbackValue ->', dataIn);
    const tmpStartDate = new Date(dataIn[0].$d);
    // setStartDate(tmpStartDate)
    startDate = tmpStartDate;
    const tmpEndDate = new Date(dataIn[1].$d);
    // setEndDate(tmpEndDate)
    endDate = tmpEndDate;
  }

  const handleSearchClick = (event) => {
    // console.log('handleSearchClick event->', event);
    clearOptionSelect();
    const sDate = new Date(startDate);
    const eDate = new Date(endDate);
    const tDiff = eDate - sDate;
    const timePerDay = 24 * 60 * 60  * 1000;

    const tQuarter = timePerDay * 90;
    const tMonth = timePerDay * 30;
    const tWeek = timePerDay * 7;
    const tDay = timePerDay * 1;
    const tHours = 60 * 60 * 1000;
    if (tDiff > tQuarter) {
      setSearchBy('year');
    } else if (tDiff > tMonth) {
      setSearchBy('quarter');
    } else if (tDiff > tWeek) {
      setSearchBy('month');
    } else if (tDiff > tDay) {
      setSearchBy('week');
    } else if (tDiff > tHours) {
      setSearchBy('day');
    } else {
      setSearchBy('hours');
    }
    refresh(event);
  }

    const clearOptionSelect = () => {
    // console.log('=============clear')
    // clear option filter
    // =======================================
    ConstFildFilter.forEach((item, index) => {
      if(item.type === 'select') {
        ConstFildFilter[index].currencies = [{value: 'All',label: t('filter.all')}];
        ConstFildFilter[index].value = 'All';
      }
      if(item.type === 'input') {
        item.value = '';
      }
    })

    setFildFilter(ConstFildFilter);
  }

  const onSearchByCallback = ({sDate,eDate,val}) => {
    // console.log('onSearchByCallback val ->',val);
    // console.log('startDate ->', sDate);
    // console.log('endDate ->', eDate);
    clearOptionSelect();
    setSearchBy(val);
    searchApi({sDate,eDate,val});
    // refresh();
  }
  const handleOpenMenu = (event) => {
    setOpen(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setOpen(null);
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = dataList.map((n) => n.name);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  // filter HandleChange
  // ===================================
  const onHandleChangeFild = (even) => {
    // console.log('onHandleChangeFild ->',even);
    const filterIndex = arryFilter.findIndex(x => x.id === even.item.id);
    // console.log('filterIndex ->',filterIndex);
    if (filterIndex === -1) {
      if( (even.item.id !== undefined) && (even.val.target.value !== undefined) ) {
        const tmpObj = {id: even.item.id, value: even.val.target.value}
        arryFilter.push(tmpObj);
      }
    } else if (even.val.target.value !== undefined) {
      arryFilter[filterIndex].value = even.val.target.value;
    }
    // console.log('arryFilter ->',arryFilter)
    ConstFildFilter.forEach((item, index) => {
      if(item.id === even.item.id) {
        ConstFildFilter[index].value = even.val.target.value;
      }
    })
    setFildFilter(ConstFildFilter);

    setPage(0);
    let tmpFilter = dataList;
    let xFilter = [];
    if (arryFilter.length) {
      // console.log('1tmpFilter length ->', tmpFilter.length)
      arryFilter.forEach((query) => {
        // console.log('query ->', query)
        if (query.value !== 'All') {
          xFilter = filter(tmpFilter, (_user) => _user[query.id].toLowerCase().indexOf(query.value.toLowerCase()) !== -1);
          tmpFilter = xFilter;
        }
      })  
      // console.log('2tmpFilter length ->',tmpFilter.length)
    }
    setDataFilterList(tmpFilter);
  }

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1));
    }
    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setPage(0);
    setRowsPerPage(parseInt(event.target.value, 10));
  };

  const handleFilterByName = (event) => {
    setPage(0);
    setFilterName(event.target.value);
  };

  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - dataFilterList.length) : 0;

  const filteredUsers = applySortFilter(dataFilterList, getComparator(order, orderBy), filterName);

  // console.log('filteredUsers ->',filteredUsers)

  dataSort = filteredUsers;

  const isNotFound = !filteredUsers.length && !!filterName;

  // console.log('LOGLIST ->',LOGLIST)


  return (
    <>
      <Helmet>
        <title> {t('memberBookSelection.page.title')} | Minimal UI </title>
      </Helmet>

      <Container maxWidth="xl">
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            {t('memberBookSelection.page.title')}
          </Typography>
        </Stack>

        <Grid container spacing={3}>

          <Grid item xs={12} md={12} lg={12}>
            <ChartBarReportNew              
              isShow={onShow}
              title={t('memberBookSelection.page.borrowTitle')}
              subheader={t(subheader)}
              xaxis={xaxis}
              chartData={chartData}
              rowDataChart={filteredUsers}
              searchBy={searchBy}
              startDate={startDate}
              endDate={endDate}
            />
          </Grid>

          <Grid item xs={12} md={12} lg={12}>

            <Grid container spacing={3}>

              <Grid item xs={12} md={6} lg={6}>
                <ChartDonutReports
                  isShow={onShow}
                  title={t('memberBookSelection.page.categoryTitle')}
                  subheader={t(subheader)}
                  xaxis={xaxis}
                  chartData={chartData}
                  rowDataChart={filteredUsers}
                  searchBy={searchBy}
                />
              </Grid>

              <Grid item xs={12} md={6} lg={6}>
                <ChartPieReports
                  isShow={onShow}
                  title={t('memberBookSelection.page.callNoTitle')}
                  subheader={t(subheader)}
                  xaxis={xaxis}
                  chartData={chartData}
                  rowDataChart={filteredUsers}
                  searchBy={searchBy}
                />
              </Grid>

            </Grid>

          </Grid>

        </Grid>

        <Card sx={{
            marginTop: '2rem',
            marginBottom: '2rem'
          }}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12} md={12}>
              <DateTimeFieldFilter
                startDate={new Date(startDate).toISOString()}
                endDate={new Date(endDate).toISOString()}
                onCallbackValue={handleCallbackValue}
                onSearchClick={handleSearchClick}
                fildFilter={fildFilter}
                onHandleChange={onHandleChangeFild}
                onSearchByCallback={onSearchByCallback}
              />
            </Grid>
          </Grid>
        </Card>
      
        <Card>
          <CardActions sx={{
              alignSelf: "stretch",
              display: "flex",
              justifyContent: "flex-end",
              alignItems: "flex-start",
              // 👇 Edit padding to further adjust position
              p: 2,
            }}>
          <Button variant="contained" startIcon={<Iconify icon="eva:refresh-fill" />} size="large" onClick={handleSearchClick}/>
            {/* <ExportToExcel apiData={dataExport} colWidths={colWidths} fileName={fileName} /> */}
            <ExportToExcel apiData={dataExport} colWidths={colWidths} fileName={fileName} onCallback={genDataExport}/>
          </CardActions>
          {/* <UserListToolbar numSelected={selected.length} filterName={filterName} onFilterName={handleFilterByName} /> */}
          <Scrollbar>
            <TableContainer sx={{ minWidth: 800, maxHeight: 500 }}>
              <Table stickyHeader >
                <UserListHead
                  order={order}
                  orderBy={orderBy}
                  headLabel={TABLE_HEAD}
                  rowCount={dataFilterList.length}
                  numSelected={selected.length}
                  onRequestSort={handleRequestSort}
                  onSelectAllClick={handleSelectAllClick}
                />
                <TableBody>
                  {filteredUsers.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, coun) => {
                    const { id , date, time, employeeName, bookName,  barcode, bookNumber, 
                      bookCategory, bookSize, channel, location} = row;
                    const selectedUser = -1;

                    return (
                      <TableRow hover key={id} tabIndex={-1} role="checkbox" selected={selectedUser}>

                        {/* <TableCell padding="checkbox">
                          <Checkbox checked={selectedUser} onChange={(event) => handleClick(event, id)} />
                        </TableCell> */}

                        <TableCell align="center">{((page*rowsPerPage) + (coun+1))}</TableCell>

                        <TableCell align="center">{showDate(date)}</TableCell>

                        {/* <TableCell style={{  position: "sticky", left: 185, background: "#ffe6e6"}} align="center">{showTime(time)}</TableCell> */}

                        <TableCell align="left">{barcode}</TableCell>

                        <TableCell align="left">{bookName}</TableCell>

                        <TableCell align="left">{bookNumber}</TableCell>

                        <TableCell align="left">{bookCategory}</TableCell>

                        <TableCell align="left">{location}</TableCell>

                      </TableRow>
                    );
                  })}
                  {emptyRows > 0 && (
                    <TableRow style={{ height: 53 * emptyRows }}>
                      <TableCell colSpan={6} />
                    </TableRow>
                  )}
                </TableBody>

                {isNotFound && (
                  <TableBody>
                    <TableRow>
                      <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                        <Paper
                          sx={{
                            textAlign: 'center',
                          }}
                        >
                          <Typography variant="h6" paragraph>
                            Not found
                          </Typography>

                          <Typography variant="body2">
                            No results found for &nbsp;
                            <strong>&quot;{filterName}&quot;</strong>.
                            <br /> Try checking for typos or using complete words.
                          </Typography>
                        </Paper>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                )}
              </Table>
            </TableContainer>
          </Scrollbar>

          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={dataFilterList.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            showFirstButton={showFirstButtonConf}
            showLastButton={showLastButtonConf}
            labelRowsPerPage={t('branchDetail.Pagination.rowsPerPage')}
          />
        </Card>
      </Container>

      <Popover
        open={Boolean(open)}
        anchorEl={open}
        onClose={handleCloseMenu}
        anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        PaperProps={{
          sx: {
            p: 1,
            width: 140,
            '& .MuiMenuItem-root': {
              px: 1,
              typography: 'body2',
              borderRadius: 0.75,
            },
          },
        }}
      >
        <MenuItem>
          <Iconify icon={'eva:edit-fill'} sx={{ mr: 2 }} />
          Edit
        </MenuItem>

        <MenuItem sx={{ color: 'error.main' }}>
          <Iconify icon={'eva:trash-2-outline'} sx={{ mr: 2 }} />
          Delete
        </MenuItem>
      </Popover>
    </>
  );
}
