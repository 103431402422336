// import { merge } from 'lodash';
import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import ReactApexChart from 'react-apexcharts';
import PropTypes  from 'prop-types';
import { filter, find, groupBy } from 'lodash';
// material
import { Card, CardHeader, Box, TextField } from '@mui/material';
//
// import { BaseOptionChart } from '../../charts';
import { useChart } from '../chart';

// ----------------------------------------------------------------------

const CHART_DATA = [
  {
    year: 'Week',
    data: [
      { name: 'Check In', data: [10, 41, 35, 151, 49, 62, 69, 91, 48] },
      { name: 'Check Out', data: [10, 34, 13, 56, 77, 88, 99, 77, 45] }
    ]
  },
  {
    year: 'Month',
    data: [
      { name: 'Check In', data: [148, 91, 69, 62, 49, 51, 35, 41, 10] },
      { name: 'Check Out', data: [45, 77, 99, 88, 77, 56, 13, 34, 10] }
    ]
  },
  {
    year: 'Year',
    data: [
      { name: 'Check In', data: [76, 42, 29, 41, 27, 138, 117, 86, 63] },
      { name: 'Check Out', data: [80, 55, 34, 114, 80, 130, 15, 28, 55] }
    ]
  }
];

// const criteriaTh = {
//   "xaxis": {
//     "year": [ "ม.ค.", "ก.พ.", "มี.ค.", "เม.ย.", "พ.ค.", "มิ.ย.", "ก.ค.", "ส.ค.", "ก.ย.", "ต.ค.", "พ.ย.", "ธ.ค."],
//     "quarter": ["Q1", "Q2", "Q3", "Q4"],    
//     "month": ["1", "2", "3", "4", "5", "6", "7", "8", "9", "10", "11", "12", "13", "14", "15", "16", "17", "18", "19", "20", "21", "22", "23", "24", "25", "26", "27", "28", "29", "30", "31"],
//     "day": ["00", "01", "02", "03", "04", "05", "06", "07", "08", "09", "10", "11", "12", "13", "14", "15", "16", "17", "18", "19", "20", "21", "22", "23"],
//     "week": ["อา","จ", "อ", "พ", "พฤ", "ศ", "ส"],
//     "hours": ["05", "10", "15", "20", "25", "30", "35", "40", "45", "50", "55", "60"]
//   }
// }

// const criteriaEn = {
//   "xaxis": {
//     "year": ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep","Oct", "Nov", "Dec"],
//     "quarter": ["Q1", "Q2", "Q3", "Q4"],
//     "month": ["1", "2", "3", "4", "5", "6", "7", "8", "9", "10", "11", "12", "13", "14", "15", "16", "17", "18", "19", "20", "21", "22", "23", "24", "25", "26", "27", "28", "29", "30", "31"],
//     "day": ["00", "01", "02", "03", "04", "05", "06", "07", "08", "09", "10", "11", "12", "13", "14", "15", "16", "17", "18", "19", "20", "21", "22", "23"],
//     "week": ["Sun","Mon", "Tue", "Wed", "Thu", "Tri", "Sat"],
//     "hours": ["05", "10", "15", "20", "25", "30", "35", "40", "45", "50", "55", "60"]
//   }
// }

const criteriaTh = {
  "xaxis": {
    "year": [],
    "quarter": ["Q1", "Q2", "Q3", "Q4"],    
    "month": [ "ม.ค.", "ก.พ.", "มี.ค.", "เม.ย.", "พ.ค.", "มิ.ย.", "ก.ค.", "ส.ค.", "ก.ย.", "ต.ค.", "พ.ย.", "ธ.ค."],
    "day": ["1", "2", "3", "4", "5", "6", "7", "8", "9", "10", "11", "12", "13", "14", "15", "16", "17", "18", "19", "20", "21", "22", "23", "24", "25", "26", "27", "28", "29", "30", "31"],
    "week": ["อา","จ", "อ", "พ", "พฤ", "ศ", "ส"],
    "hours": ["00", "01", "02", "03", "04", "05", "06", "07", "08", "09", "10", "11", "12", "13", "14", "15", "16", "17", "18", "19", "20", "21", "22", "23"]
  }
}

const criteriaEn = {
  "xaxis": {
    "year": [],
    "quarter": ["Q1", "Q2", "Q3", "Q4"],
    "month": ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep","Oct", "Nov", "Dec"],
    "day": ["1", "2", "3", "4", "5", "6", "7", "8", "9", "10", "11", "12", "13", "14", "15", "16", "17", "18", "19", "20", "21", "22", "23", "24", "25", "26", "27", "28", "29", "30", "31"],
    "week": ["Sun","Mon", "Tue", "Wed", "Thu", "Tri", "Sat"],
    "hours": ["00", "01", "02", "03", "04", "05", "06", "07", "08", "09", "10", "11", "12", "13", "14", "15", "16", "17", "18", "19", "20", "21", "22", "23"]
  }
}

const criteriaSelectConst = {
  id: 'searchBy',
  label: 'filter.SearchBy',
  defaultValue: "hours",
  helperText: "",
  options: [
    {
      label: 'filter.hourly',
      value: 'hours'
    },
    {
      label: 'filter.daily',
      value: 'day'
    },
    {
      label: 'filter.weekly',
      value: 'week'
    },
    {
      label: 'filter.monthly',
      value: 'month'
    },
    {
      label: 'filter.quarterly',
      value: 'quarter'
    },
    {
      label: 'filter.yearly',
      value: 'year'
    }
  ]
}

let xaxis = ["00", "01", "02", "03", "04", "05", "06", "07", "08", "09", "10", "11", "12", "13", "14", "15", "16", "17", "18", "19", "20", "21", "22", "23"];

ChartBarReportNew .propTypes = {
  isShow: PropTypes.bool,
  title: PropTypes.string,
  subheader: PropTypes.string,
  chartData: PropTypes.array,
  rowDataChart: PropTypes.array,
  searchBy: PropTypes.string
}

export default function ChartBarReportNew ({
  isShow,
  title,
  subheader,
  chartData,
  rowDataChart,
  searchBy,
  startDate,
  endDate
}) {
  const [seriesData, setSeriesData] = useState('');

  const [dataChart, setDataChart] = useState(null);

  const [optionChart, setOptionChart] = useState();

  // const [xaxis, setXaxis] = useState(["ม.ค.", "ก.พ.", "มี.ค.", "เม.ย.", "พ.ค.", "มิ.ย.", "ก.ค.", "ส.ค.", "ก.ย.", "ต.ค.", "พ.ย.", "ธ.ค."])

  const { i18n, t } = useTranslation();

  const chartOptions = useChart({
    stroke: {
      show: true,
      width: 2,
      colors: ['transparent']
    },
    xaxis: {
      categories: xaxis
    },
    tooltip: {
      y: {
        formatter: (val) => `${val}`
      }
    },
    plotOptions: {
      bar: {
        dataLabels: {
          position: 'top', // top, center, bottom
        },
      }
    },
    dataLabels: {
      enabled: true,
      offsetX: 0,
      offsetY: -30,
      style: {
        fontSize: '12px',
        colors: ['#FE0200']
      }
    },
  });


  useEffect(() => {
    setSeriesData(searchBy);
    // setOptionChart(chartOptions);
  },[]);

  useEffect(() => {
    genDataChart(rowDataChart);
    // setOptionChart(chartOptions);
  },[seriesData]);

  useEffect(() => {
    genDataChart(rowDataChart);
    // setOptionChart(chartOptions);
  },[rowDataChart]);

  const handleChangeSeriesData = (event) => {
    // console.log('event.target.value ->',event.target.value)
    setSeriesData(event.target.value);
  };

  const getDataChartYearly = (rowData) => {
    const tmpChartDataObj = {
      year: 'Year',
      xaxis: [],
      data: []
    };

    if (rowData.length) {
      // 1. fetch to day data

      // 2. put criteria to array data
      const putCriteria = [];
      for (let i = 0; i < rowData.length; i += 1) {
        const data = rowData[i];
        data.criteria = `y${new Date(data.dateTr).getFullYear().toString()}`; //  the month (from 0 to 11) 
        putCriteria.push(data);
      }
      // console.log('1 criteriaMonthOfyear putCriteria =>', putCriteria);
  
      // 3. group by hour
      const groupCriteria = groupBy(putCriteria, ({ criteria }) => criteria);
      // console.log('2 criteriaMonthOfyear groupCriteria =>', groupCriteria);
  
      // 4. create hours object
      const sDate = new Date(startDate).getFullYear()
      const eDate = new Date(endDate).getFullYear()
      // console.log('sDate ->',sDate);
      // console.log('eDate ->',eDate);
      const dataArry = [];
      const tmpXaxis = [];
      const criteriaRange = eDate-sDate; // the month (from 0 to 11) 
      for(let i = 0; i <= criteriaRange; i += 1) {
        tmpXaxis.push(sDate+i);
        const key = `y${sDate+i}`;
        dataArry[i] = groupCriteria[key] ? groupCriteria[key].length : 0;
      }
      // console.log('getDataChartYearly dataArry ->',dataArry)
      const tmpDataObj = {
        name:'Transection',
        data: dataArry
      }

      tmpChartDataObj.data.push(tmpDataObj);
      if(i18n.language === 'th'){
        chartOptions.xaxis.categories = tmpXaxis
      }else{
        chartOptions.xaxis.categories = tmpXaxis
      }
      setOptionChart(chartOptions);
      // console.log('criteriaMonthOfyear =>',tmpChartDataObj)
    } else {
      const sDate = new Date(startDate).getFullYear()
      const eDate = new Date(endDate).getFullYear()
      const tmpXaxis = [];
      const criteriaRange = eDate-sDate; // the month (from 0 to 11) 
      for(let i = 0; i <= criteriaRange; i += 1) {
        tmpXaxis.push(sDate+i);
      }
      chartOptions.xaxis.categories = tmpXaxis;
      setOptionChart(chartOptions);
    }
    return tmpChartDataObj;
  }

  const getDataChartMonthly = (rowData) => {
    const tmpChartDataObj = {
      year: 'Year',
      xaxis: [],
      data: []
    };

    if (rowData.length) {
      // 1. fetch to day data

      // 2. put criteria to array data
      const putCriteria = [];
      for (let i = 0; i < rowData.length; i += 1) {
        const data = rowData[i];
        data.criteria = `c${new Date(data.dateTr).getMonth().toString()}`; //  the month (from 0 to 11) 
        putCriteria.push(data);
      }
      // console.log('1 criteriaMonthOfyear putCriteria =>', putCriteria);
  
      // 3. group by hour
      const groupCriteria = groupBy(putCriteria, ({ criteria }) => criteria);
      // console.log('2 criteriaMonthOfyear groupCriteria =>', groupCriteria);
  
      // 4. create hours object
      const dataArry = [];
      const criteriaRange = 12; // the month (from 0 to 11) 
      for (let i = 0; i < criteriaRange; i += 1) {
        const key = `c${i}`;
        dataArry[i] = groupCriteria[key] ? groupCriteria[key].length : 0;
      }
      // console.log('getDataChartMonthly dataArry ->',dataArry)
      const tmpDataObj = {
        name:'Transection',
        data: dataArry
      }

      tmpChartDataObj.data.push(tmpDataObj);
      // if(i18n.language === 'th'){
      //   xaxis = criteriaTh.xaxis.year;
      // }else{
      //   xaxis = criteriaEn.xaxis.year;
      // }
      // console.log('criteriaMonthOfyear =>',tmpChartDataObj)

    }
    return tmpChartDataObj;
  }

  const getDataChartQuarterly = (rowData) => {
    const tmpChartDataObj = {
      year: 'quarter',
      xaxis: [],
      data: []
    };

    if (rowData.length) {
      // 1. fetch to day data
  
      // 2. put criteria to array 
      // const sDate = new Date();
      // const eDate = new Date();
      const putCriteria = [];
      for (let i = 0; i < rowData.length; i += 1) {
        const data = rowData[i];
        const nDate = new Date(data.dateTr);
        data.criteria = `c${Math.floor(nDate.getMonth() / 3 + 1)}`; // 1 quarter (from 1 to 4
        putCriteria.push(data);
      }
      // console.log('1 criteriaHourOfDay putCriteria =>', putCriteria);
  
      // 3. group by hour
      const groupCriteria = groupBy(putCriteria, ({ criteria }) => criteria);
      // console.log('2 criteriaHourOfDay groupCriteria =>', groupCriteria);
  
      // 4. create hours object
      const dataArry = [];
      const criteriaRange = 4; // 1 quarter (from 1 to 4
      for (let i = 0; i < criteriaRange; i += 1) {
        const key = `c${i}`;
        dataArry[i] = groupCriteria[key] ? groupCriteria[key].length : 0;
      }
      const tmpDataObj = {
        name: 'Transection',
        data: dataArry
      }

      tmpChartDataObj.data.push(tmpDataObj);
      // if (i18n.language === 'th') {
      //   xaxis = criteriaTh.xaxis.quarter;
      // } else {
      //   xaxis = criteriaEn.xaxis.quarter;
      // }
      // console.log('criteriaQuaterOfyear =>',tmpChartDataObj)
    }
    return tmpChartDataObj;
  }

  // const getDataChartDayOfMonth = (rowData) => {
  const getDataChartDaily = (rowData) => {
    // console.log('getDataChartDaily rowData =>', rowData);
    const tmpChartDataObj = {
      year: 'month',
      xaxis: [],
      data: []
    };

    if (rowData.length) {
      // 1. fetch to day data
  
      // 2. put criteria to array 
      const putCriteria = [];
      for (let i = 0; i < rowData.length; i += 1) {
        const data = rowData[i];
        const nDate = new Date(data.dateTr);
        // if( (nDate >= sDate ) && (sDate <= eDate)) {
          // data.criteria = `c${nDate.getDate().toString()}`; // 1 day (from 0 to 23
          data.criteria = `c${nDate.getDate()-1}`; // 1 day (from 0 to 23
          putCriteria.push(data);
        // }
      }
      // console.log('1 criteriaHourOfDay putCriteria =>', putCriteria);
  
      // 3. group by hour
      const groupCriteria = groupBy(putCriteria, ({ criteria }) => criteria);
      // console.log('2 criteriaHourOfDay groupCriteria =>', groupCriteria);
  
      // 4. create hours object
      const sDate = new Date(startDate).getMonth();
      const eDate = new Date(endDate).getMonth();
      const ysDate = new Date(startDate).getFullYear();
      const yeDate = new Date(endDate).getFullYear();
      const nDate = new Date(startDate);
      let dayInFeb = new Date(startDate);
      // console.log('nDate ->',nDate);
      // console.log('setDate ->',nDate.setDate(-1));
      const dataArry = [];
      const tmpXaxis = [];
      let criteriaRange = 28; // 1 day (from 0 to 31
      for(let j = sDate; j <= eDate; j += 1) {
        switch (j) {
          case 0: case 2: case 4: case 6: case 7: case 9: case 11:
            criteriaRange = 31;  
          break;
          case 1:
            nDate.setDate(29);  // set find date
            dayInFeb = new Date(nDate);
            if( (dayInFeb.getDate().toString() ) === '1' ) {
              if(criteriaRange < 28) {
                criteriaRange = 28;  
              }
            } else if (criteriaRange < 29) {
              criteriaRange = 29;
            }
          break;
          case 3: case 5: case 8: case 10:
            if(criteriaRange < 30) {
              criteriaRange = 30;
            }
          break;
          default:
            criteriaRange = 28;
          break;
        }
      }

      if(ysDate !== yeDate) criteriaRange = 31; 

      for (let i = 0; i < criteriaRange; i += 1) {
        const key = `c${i}`;
        tmpXaxis.push(i+1);
        dataArry[i] = groupCriteria[key] ? groupCriteria[key].length : 0;
      }
      // console.log('dataArry ->',dataArry);
      const tmpDataObj = {
        name:'Transection',
        data:dataArry
      }
      tmpChartDataObj.data.push(tmpDataObj);
      if(i18n.language === 'th'){
        chartOptions.xaxis.categories = tmpXaxis
      }else{
        chartOptions.xaxis.categories = tmpXaxis
      }
      setOptionChart(chartOptions);
    } else {
      const sDate = new Date(startDate).getMonth();
      const eDate = new Date(endDate).getMonth();
      const ysDate = new Date(startDate).getFullYear();
      const yeDate = new Date(endDate).getFullYear();
      const nDate = new Date(startDate);
      let dayInFeb = new Date(startDate);
      // console.log('nDate ->',nDate);
      // console.log('setDate ->',nDate.setDate(-1));
      const tmpXaxis = [];
      let criteriaRange = 28; // 1 day (from 0 to 31
      for(let j = sDate; j <= eDate; j += 1) {
        switch (j) {
          case 0: case 2: case 4: case 6: case 7: case 9: case 11:
            criteriaRange = 31;  
          break;
          case 1:
            nDate.setDate(29);  // set find date
            dayInFeb = new Date(nDate);
            // console.log('dayInFeb.getDate().toString() ->',dayInFeb.getDate());
            if ( (dayInFeb.getDate().toString() ) === '1' ) {
              if(criteriaRange < 28) {
                criteriaRange = 28;  
              }
            } else if (criteriaRange < 29) {
              criteriaRange = 29;
            }
          break;
          case 3: case 5: case 8: case 10:
            if(criteriaRange < 30) {
              criteriaRange = 30;
            }
          break;
          default:
            criteriaRange = 28;
          break;
        }
      }

      if(ysDate !== yeDate) criteriaRange = 31; 

      for (let i = 0; i < criteriaRange; i += 1) {
        tmpXaxis.push(i+1);
      }
      chartOptions.xaxis.categories = tmpXaxis
      setOptionChart(chartOptions);
    }

    return tmpChartDataObj;
  
  }

  const getDataChartWeekly = (rowData) => {
      const tmpChartDataObj = {
        year: 'week',
        xaxis: [],
        data: []
      };

    if (rowData.length) {
      // 1. fetch to day data
  
      // 2. put criteria to array data
      // const lDate = new Date();
      // console.log('lDate ->',lDate)
      // const sDate = new Date();
      // const eDate = new Date();
      // sDate.setDate( (lDate.getDate()) - (6-lDate.getDay() ) );
      // eDate.setDate( (lDate.getDate()) + (6-lDate.getDay() ) );
      // console.log('2sDate ->',sDate)
      // console.log('2eDate ->',eDate)
  
      const putCriteria = [];
      for (let i = 0; i < rowData.length; i += 1) {
        const data = rowData[i];
        const nDate = new Date(data.dateTr);
        // if( (nDate >= sDate ) && (sDate <= eDate)) {
          data.criteria = `c${nDate.getDay().toString()}`; // day of the week (from 0 to 6
          putCriteria.push(data);
        // }
      }
      // console.log('1 criteriaDayOfWeek  putCriteria =>', putCriteria);
  
      // 3. group by hour
      const groupCriteria = groupBy(putCriteria, ({ criteria }) => criteria);
      // console.log('2 criteriaDayOfWeek groupCriteria =>', groupCriteria);
  
      // 4. create hours object
      const dataArry = [];
      const criteriaRange = 7; // day of the week (from 0 to 6
      for (let i = 0; i < criteriaRange; i += 1) {
        const key = `c${i}`;
        dataArry[i] = groupCriteria[key] ? groupCriteria[key].length : 0;
      }
      const tmpDataObj = {
        name:'Transection',
        data: dataArry
      }
      tmpChartDataObj.data.push(tmpDataObj);
      // tmpChartDataObj.xaxis = ["อา","จ", "อ", "พ", "พฤ", "ศ", "ส"];
      // if (i18n.language === 'th') {
      //   xaxis = criteriaTh.xaxis.week;
      // } else {
      //   xaxis = criteriaEn.xaxis.week;
      // }
  
      // console.log('criteriaDayOfWeek =>',tmpChartDataObj)
    }
    return tmpChartDataObj;
  }

  const getDataChartHourOfDay = (rowData) => {
    const tmpChartDataObj = {
      year: 'day',
      xaxis: [],
      data: []
    };

    if (rowData.length) {
      // 1. fetch to day data
  
      // 2. put criteria to array 
      // const sDate = new Date();
      // const eDate = new Date();
      // sDate.setHours(0);
      // sDate.setMinutes(0);
      // sDate.setSeconds(0);
      // eDate.setHours(23);
      // eDate.setMinutes(59);
      // eDate.setSeconds(59);
      const putCriteria = [];
      for (let i = 0; i < rowData.length; i += 1) {
        const data = rowData[i];
        const nDate = new Date(data.dateTr);
        // if( (nDate >= sDate ) && (sDate <= eDate)) {
          data.criteria = `c${nDate.getHours().toString()}`; // 1 day (from 0 to 23
          putCriteria.push(data);
        // }
      }
      // console.log('1 criteriaHourOfDay putCriteria =>', putCriteria);
  
      // 3. group by hour
      const groupCriteria = groupBy(putCriteria, ({ criteria }) => criteria);
      // console.log('2 criteriaHourOfDay groupCriteria =>', groupCriteria);
  
      // 4. create hours object
      const dataArry = [];
      const criteriaRange = 24; // 1 day (from 0 to 23
      for (let i = 0; i < criteriaRange; i += 1) {
        const key = `c${i}`;
        dataArry[i] = groupCriteria[key] ? groupCriteria[key].length : 0;
      }
      const tmpDataObj = {
        name:'Transection',
        data: dataArry
      }
      tmpChartDataObj.data.push(tmpDataObj);
      // tmpChartDataObj.xaxis = sumDay;
      if(i18n.language === 'th'){
        xaxis = criteriaTh.xaxis.day;
      }else{
        xaxis = criteriaEn.xaxis.day;
      }
  
      // console.log('criteriaHourOfDay =>',tmpChartDataObj)
    }
    return tmpChartDataObj;
  }

  const getDataChartHourly = (rowData) => {
    const tmpChartDataObj = {
      year: 'hour',
      xaxis: [],
      data: []
    };

    if (rowData.length) {
      // 1. fetch to day data
  
      // 2. put criteria to array 
      // const sDate = new Date();
      // const eDate = new Date();
      // sDate.setMinutes(0);
      // sDate.setSeconds(0);
      // eDate.setMinutes(59);
      // eDate.setSeconds(59);
      const putCriteria = [];
      for (let i = 0; i < rowData.length; i += 1) {
        const data = rowData[i];
        const nDate = new Date(data.dateTr);
        // if( (nDate >= sDate ) && (sDate <= eDate)) {
          // const Sample = Math.floor(nDate.getMinutes() / 5);
          const Sample = nDate.getHours();
          // console.log('Sample, data->',Sample, data);
          data.criteria = `c${Sample}`; // minutes (0 to 59) of a date.
          putCriteria.push(data);
        // }
      }
      // console.log('1 criteriaHourOfDay putCriteria =>', putCriteria);
  
      // 3. group by hour
      const groupCriteria = groupBy(putCriteria, ({ criteria }) => criteria);
      // console.log('2 criteriaHourOfDay groupCriteria =>', groupCriteria);
  
      // 4. create hours object
      const dataArry = [];
      const criteriaRange = 24; // minutes (0 to 59) of a date.
      for (let i = 0; i < criteriaRange; i += 1) {
        const key = `c${i}`;
        dataArry[i] = groupCriteria[key] ? groupCriteria[key].length : 0;
      }
      const tmpDataObj = {
        name:'Transection',
        data: dataArry
      }
      tmpChartDataObj.data.push(tmpDataObj);
      // tmpChartDataObj.xaxis = sumDay;
      // if(i18n.language === 'th'){
      //   xaxis = criteriaTh.xaxis.hours;
      // }else{
      //   xaxis = criteriaEn.xaxis.hours;
      // }
  
      // console.log('criteriaHourOfDay =>',tmpChartDataObj)
    }
    return tmpChartDataObj;
  }

  // const genDataChart = (rowData) => {
  //   let item = [];
  //   if( Array.isArray(rowData) ) { 
  //     switch (searchBy) {
  //       case 'year':
  //         item = getDataChartYear(rowData);
  //       break;
  //       case 'quarter':
  //         item = getDataChartQuarter(rowData);
  //       break;
  //       case 'month':
  //         item = getDataChartDayOfMonth(rowData);
  //       break;
  //       case 'week':
  //         item = getDataChartDayOfWeek(rowData);
  //       break;
  //       case 'day':
  //         item = getDataChartHourOfDay(rowData);  
  //       break;
  //       case 'hours':
  //         item = getDataChartHour(rowData);
  //       break;
  //       default:
  //         item = getDataChartHour(rowData);  
  //       break;
  //     }
  //   } else {
  //     item = {
  //       year: 'day',
  //       xaxis: [],
  //       data: []
  //     };
  //   }
    
  //   // item = {
  //   //   year: 'Year',
  //   //   data: [
  //   //     { name: 'Check In', data: [76, 42, 29, 0, 27, 138, 117, 86, 63] },
  //   //     { name: 'Check Out', data: [80, 55, 34, 114, 80, 130, 15, 28, 55] }
  //   //   ]
  //   // }
  //   // const item = getDataChartYear(rowData);
  //   // const item = getDataChartHourOfDay(rowData);
  //   // console.log('item ->',item);
  //   return(
  //     <>
  //       <Box key={item.year} sx={{ mt: 3, mx: 3 }} dir="ltr">
  //         {/* {item.data && ( */}
  //           <ReactApexChart type="bar" series={item.data} options={chartOptions} height={364} />
  //         {/* )} */}
  //       </Box>
  //     </>
  //   )
  // }

  const genDataChart = (rowData) => {
    let item = [];
    if( Array.isArray(rowData) ) { 
      switch (seriesData) {
        case 'year':
          // if(i18n.language === 'th'){
          //   chartOptions.xaxis.categories = criteriaTh.xaxis.year;
          // }else{
          //   chartOptions.xaxis.categories = criteriaEn.xaxis.year;
          // }
          // setOptionChart(chartOptions);
          item = getDataChartYearly(rowData);
          setDataChart(item);
        break;
        case 'quarter':
          if(i18n.language === 'th'){
            chartOptions.xaxis.categories = criteriaTh.xaxis.quarter;
          }else{
            chartOptions.xaxis.categories = criteriaEn.xaxis.quarter;
          }
          setOptionChart(chartOptions);
          item = getDataChartQuarterly(rowData);
          setDataChart(item);
        break;
        case 'month':
          if(i18n.language === 'th'){
            chartOptions.xaxis.categories = criteriaTh.xaxis.month;
          }else{
            chartOptions.xaxis.categories = criteriaEn.xaxis.month;
          }
          setOptionChart(chartOptions);
          item = getDataChartMonthly(rowData);
          setDataChart(item);
        break;
        case 'week':
          if(i18n.language === 'th'){
            chartOptions.xaxis.categories = criteriaTh.xaxis.week;
          }else{
            chartOptions.xaxis.categories = criteriaEn.xaxis.week;
          }
          setOptionChart(chartOptions);
          item = getDataChartWeekly(rowData);
          setDataChart(item);
        break;
        case 'day':
          // if(i18n.language === 'th'){
          //   chartOptions.xaxis.categories = criteriaTh.xaxis.day;
          // }else{
          //   chartOptions.xaxis.categories = criteriaEn.xaxis.day;
          // }
          // setOptionChart(chartOptions);
          item = getDataChartDaily(rowData);  
          setDataChart(item);
        break;
        case 'hours':
          if(i18n.language === 'th'){
            chartOptions.xaxis.categories = criteriaTh.xaxis.hours;
          }else{
            chartOptions.xaxis.categories = criteriaEn.xaxis.hours;
          }
          setOptionChart(chartOptions);
          item = getDataChartHourly(rowData);
          setDataChart(item);
        break;
        default:
          setOptionChart(chartOptions);
          item = getDataChartHourly(rowData);  
          setDataChart(item);
        break;
      }
      // console.log('item ->', item)
    } else {
      item = {
        year: 'day',
        xaxis: [],
        data: []
      };
      setDataChart(item);
    }
  }

  return (
    <>
      {isShow && (
        <Card>
          <CardHeader
            title={title}
            subheader={''}
            // action={
            //   <TextField
            //     select
            //     fullWidth
            //     value={seriesData}
            //     SelectProps={{ native: true }}
            //     onChange={handleChangeSeriesData}
            //     sx={{
            //       '& fieldset': { border: '0 !important' },
            //       '& select': { pl: 1, py: 0.5, pr: '24px !important', typography: 'subtitle2' },
            //       '& .MuiOutlinedInput-root': { borderRadius: 0.75, bgcolor: 'background.neutral' },
            //       '& .MuiNativeSelect-icon': { top: 4, right: 0, width: 20, height: 20 }
            //     }}
            //   >
            //     {chartData.map((option) => (
            //       <option key={option.year} value={option.year}>
            //         {option.year}
            //       </option>
            //     ))}
            //   </TextField>
            // }
            action={
              <TextField
                select
                fullWidth
                value={seriesData}
                SelectProps={{ native: true }}
                onChange={handleChangeSeriesData}
                sx={{
                  '& fieldset': { border: '0 !important' },
                  '& select': { pl: 1, py: 0.5, pr: '24px !important', typography: 'subtitle2' },
                  '& .MuiOutlinedInput-root': { borderRadius: 0.75, bgcolor: 'background.neutral' },
                  '& .MuiNativeSelect-icon': { top: 4, right: 0, width: 20, height: 20 }
                }}
              >
                {criteriaSelectConst.options.map((option) => (
                  <option key={option.value} value={option.value}>
                    {t(option.label)}
                  </option>
                ))}
              </TextField>
            }
          />

          {/* {chartData.map((item) => (
            <Box key={item.year} sx={{ mt: 3, mx: 3 }} dir="ltr">
              {item.data && (
                <ReactApexChart type="bar" series={item.data} options={chartOptions} height={364} />
              )}
            </Box>
          ))} */}
          {dataChart && 
          <Box sx={{ mt: 3, mx: 3 }} dir="ltr">
            <ReactApexChart type="bar" series={dataChart.data} options={optionChart} height={364} />
          </Box>
          }
        </Card>
      )}
    </>
  );
}
