import { faker } from '@faker-js/faker';
import { sample } from 'lodash';

// ----------------------------------------------------------------------

// ### get all รายงานการเข้าใช้งานตู้ของสมาชิก
// GET http://localhost:3001/api/member-access-period/range?__start=2023-05-17T09:20:40.461Z&__end=2023-05-17T09:26:05.853Z

// [
//   {
//     "_id": "6465917e957b9a1145f105ae",
//     "date": "2023-05-17T09:23:33.049Z",
//     "memberId": "TK0001",
//     "memberName": "อารยา  สุขใจ",
//     "period": 30,
//     "location": "Central World",
//     "__v": 0
//   }
// ]

const logReport = [...Array(24)].map((_, index) => ({
  _id: faker.datatype.uuid(),
  date: new Date().toISOString(),
  memberId: sample(['TK0001', 'TK0002', 'TK0003', 'TK0004', 'TK0005','TK0005']),
  memberName: sample(['อารยา  สุขใจ','ลุกมัน อะแดม','อิสมาแอ อาแว','พีรมล  นิ่มนวล','พิทักษ์  ดำรงนาน']),
  period: faker.datatype.number(10),
  location: sample([
    'Central World',
    'True  Digital  Park',
  ])
}));

export default logReport;
