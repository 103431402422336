import * as Yup from 'yup';
import { Helmet } from 'react-helmet-async';
import { filter } from 'lodash';
import { sentenceCase } from 'change-case';
import { useState, useEffect, useCallback } from 'react';
// import { useSnackbar } from 'notistack5'; // notistack5
import { SnackbarProvider, enqueueSnackbar } from 'notistack';
import { useNavigate, useParams, useLocation } from 'react-router-dom';
import { Form, FormikProvider, useFormik } from 'formik'; //
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
// @mui
import {
  Box,
  Card,
  Grid,
  Table,
  Stack,
  Switch,
  Paper,
  Avatar,
  Button,
  Popover,
  Checkbox,
  TableRow,
  MenuItem,
  TableBody,
  TableCell,
  TextField,
  Container,
  FormLabel,
  FormGroup,
  FormControl,
  Typography,
  IconButton,
  TableContainer,
  TablePagination,
  FormControlLabel,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
// components
import Label from '../components/label';
import Iconify from '../components/iconify';
import Scrollbar from '../components/scrollbar';
// sections
import { UserListHead, UserListToolbar, UserMoreMenu, ConfirmDialog } from '../sections/@dashboard/user';
// mock
import USERLIST from '../_mock/userList';

// ----------------------------------------------------------------------

const config = window.config;

export default function UserCreate() {
  const { i18n, t } = useTranslation();

  const { pathname } = useLocation();
  const isEdit = pathname.includes('edit');
  const { userId } = useParams();
  const [currentUser, setCurrentUser] = useState({});

  // const [isEdit, setIsEdit] = useState(false);

  const navigate = useNavigate();
  // const { enqueueSnackbar } = useSnackbar();

  const NewUserSchema = Yup.object().shape({
    name: Yup.string().required('Name is required'),
    description: Yup.string().required('Description is required'),
    approved: Yup.string().required('Approved is required'),
  });

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      name: currentUser?.name || '',
      description: currentUser?.description || '',
      approved: currentUser?.approved || false,
      isView: currentUser?.isView || true,
      isCreate: currentUser?.isCreate || false,
      isUpdate: currentUser?.isUpdate || false,
      isDelete: currentUser?.isDelete || false,
    },
    validationSchema: NewUserSchema,
    onSubmit: async (values, { setSubmitting, resetForm, setErrors }) => {
      // console.log('onSubmit values ->', values);

      try {
        // await fakeRequest(500);
        const apiCreateUsers = (user) => {
          // const _id = userForDelete._id;
          const url = `${config.backendUrl}/api/permission/`;
          try {
            // console.log('apiCreateUsers: url ->', url);

            fetch(url, {
              method: 'POST',
              headers: { 'Content-Type': 'application/json' },
              body: JSON.stringify(values),
            })
              .then((response) => {
                return response.json();
              })
              .then((dataRes) => {
                // console.log(`apiCreateUsers: dataRes ->`, dataRes);
                if (dataRes.error) {
                  const error = dataRes.error.message;
                  setSubmitting(false);
                  enqueueSnackbar(!isEdit ? `Create Error: ${error}` : `Update Error: ${error}`, {
                    autoHideDuration: 2000,
                    variant: 'error',
                    persist: false,
                  });
                  return;
                }
                resetForm();
                setSubmitting(false);
                enqueueSnackbar(!isEdit ? `Create success` : `Update success`, {
                  autoHideDuration: 2000,
                  variant: 'success',
                  persist: false,
                });
                navigate('/user/permission/list');
              })
              .catch((err) => {
                console.error('apiCreateUsers: catch Error ->', err);
                enqueueSnackbar(!isEdit ? 'Create Error' : 'Update Error', { variant: 'error' });
              });
          } catch (e) {
            console.error('apiCreateUsers: try Error -> ', e);
          }
        };

        const apiEditUsers = (userId) => {
          // const _id = userForDelete._id;
          const url = `${config.backendUrl}/api/permission/${userId}`;
          try {
            console.log('apiEditUsers: url ->', url);

            fetch(url, {
              method: 'PUT',
              headers: { 'Content-Type': 'application/json' },
              body: JSON.stringify(values),
            })
              .then((response) => {
                return response.json();
              })
              .then((dataRes) => {
                console.log(`apiEditUsers: dataRes ->`, dataRes);
                if (dataRes.error) {
                  const error = dataRes.error.message;
                  setSubmitting(false);
                  enqueueSnackbar(!isEdit ? `Create Error: ${error}` : `Update Error: ${error}`, {
                    autoHideDuration: 2000,
                    variant: 'error',
                    persist: false,
                  });
                  return;
                }
                resetForm();
                setSubmitting(false);
                enqueueSnackbar(!isEdit ? `Create success` : `Update success`, {
                  autoHideDuration: 2000,
                  variant: 'success',
                  persist: false,
                });
                navigate('/user/permission/list');
              })
              .catch((err) => {
                console.error('apiEditUsers: catch Error ->', err);
                enqueueSnackbar(!isEdit ? 'Create Error' : 'Update Error', { variant: 'error' });
              });
          } catch (e) {
            console.error('apiEditUsers: try Error -> ', e);
          }
        };

        if (isEdit) {
          apiEditUsers(currentUser._id);
        } else {
          apiCreateUsers();
        }

        // resetForm();
        // setSubmitting(false);
        // enqueueSnackbar(!isEdit ? 'Create success' : 'Update success', { variant: 'success' });
        // navigate(PATH_DASHBOARD.user.list);
      } catch (error) {
        console.error(error);
        setSubmitting(false);
        setErrors(error);
      }
    },
  });

  const { errors, values, touched, handleSubmit, isSubmitting, getFieldProps, setFieldValue } = formik;

  const handleChange = (event) => {
    if (event.target.name === 'isView') setFieldValue("isView", event.target.checked)
    if (event.target.name === 'isCreate') setFieldValue("isCreate", event.target.checked)
    if (event.target.name === 'isUpdate') setFieldValue("isUpdate", event.target.checked)
    if (event.target.name === 'isDelete') setFieldValue("isDelete", event.target.checked)
  };

  useEffect(() => {
    // console.log('UserCreate: useEffect config, isEdit, userId =>', config, isEdit, userId);

    const apiGetUser = (userId) => {
      // const _id = userForDelete._id;
      const url = `${config.backendUrl}/api/permission/${userId}`;
      try {
        // console.log('apiGetUser: url ->', url);

        fetch(url, {
          method: 'GET',
          // headers: { 'Content-Type': 'application/json' },
          // body: JSON.stringify(values),
        })
          .then((response) => {
            return response.json();
          })
          .then((dataRes) => {
            console.log(`apiGetUser: dataRes ->`, dataRes);
            if (dataRes.error) {
              const error = dataRes.error.message;
            } else {
              setCurrentUser(dataRes);
            }
          })
          .catch((err) => {
            console.error('apiGetUser: catch Error ->', err);
            enqueueSnackbar(!isEdit ? 'Create Error' : 'Update Error', { variant: 'error' });
          });
      } catch (e) {
        console.error('apiGetUser: try Error -> ', e);
      }
    };
    apiGetUser(userId);
  }, []);

  return (
    <>
      <Helmet>
        <title>
          {!isEdit ? t('permissionCreate.titleCreate') : t('permissionCreate.titleEdit')} | {t('title')}
        </title>
      </Helmet>

      <Container maxWidth="xl">
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            {!isEdit ? t('permissionCreate.titleCreate') : t('permissionCreate.titleEdit')}
          </Typography>
        </Stack>

        <FormikProvider value={formik}>
          {/* {console.log('formik ->', values)} */}
          <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
            <Grid container spacing={3}>
              <Grid item xs={12} md={8}>
                <Card sx={{ p: 3 }}>
                  <Stack spacing={3}>
                    <Stack direction={{ xs: 'column', sm: 'row' }} spacing={{ xs: 3, sm: 2 }}>
                      <TextField
                        fullWidth
                        disabled={isEdit}
                        label={t('permissionCreate.txtName')}
                        {...getFieldProps('name')}
                        error={Boolean(touched.name && errors.name)}
                        helperText={touched.name && errors.name}
                      />
                      <TextField
                        fullWidth
                        label={t('permissionCreate.txtDescription')}
                        {...getFieldProps('description')}
                        error={Boolean(touched.description && errors.description)}
                        helperText={touched.description && errors.description}
                      />
                    </Stack>
                    <Stack direction={{ xs: 'column', sm: 'row' }} spacing={{ xs: 3, sm: 2 }}>
                      {/* <TextField
                        fullWidth
                        label="Approved"
                        {...getFieldProps('approved')}
                        error={Boolean(touched.approved && errors.approved)}
                        helperText={touched.approved && errors.approved}
                      /> */}

                      {/* <Typography variant="subtitle2" sx={{ mb: 0 }}>
                        {t('permissionCreate.txtApproved')}{' '}
                        <Switch {...getFieldProps('approved')} checked={values.approved} />
                      </Typography> */}

                      <FormControl sx={{ m: 3 }} variant="standard">
                        <FormLabel>{t('permissionCreate.status')}</FormLabel>
                        <FormGroup>
                          <FormControlLabel
                            control={<Checkbox checked={values.isView} onChange={handleChange} name="isView" />}
                            label={t('permissionCreate.view')}
                          />
                          <FormControlLabel
                            control={<Checkbox checked={values.isCreate} onChange={handleChange} name="isCreate" />}
                            label={t('permissionCreate.create')}
                          />
                          <FormControlLabel
                            control={<Checkbox checked={values.isUpdate} onChange={handleChange} name="isUpdate" />}
                            label={t('permissionCreate.edit')}
                          />
                          <FormControlLabel
                            control={<Checkbox checked={values.isDelete} onChange={handleChange} name="isDelete" />}
                            label={t('permissionCreate.delete')}
                          />
                        </FormGroup>
                      </FormControl>
                    </Stack>

                    <Box sx={{ mt: 3, display: 'flex', justifyContent: 'flex-end' }}>
                      <Button
                        variant="outlined"
                        color="primary"
                        onClick={() => {
                          navigate('/user/permission/list');
                        }}
                        sx={{ mr: 2 }}
                      >
                        {t('btCancel')}
                      </Button>
                      <LoadingButton type="submit" variant="contained" loading={isSubmitting}>
                        {!isEdit ? t('permissionCreate.btCreate') : t('permissionCreate.btSave')}
                      </LoadingButton>
                    </Box>
                  </Stack>
                  <SnackbarProvider
                    anchorOrigin={{
                      vertical: 'bottom',
                      horizontal: 'center',
                    }}
                  />
                </Card>
              </Grid>
            </Grid>
          </Form>
        </FormikProvider>
      </Container>
    </>
  );
}
