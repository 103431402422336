import { faker } from '@faker-js/faker';
import { sample } from 'lodash';

// ----------------------------------------------------------------------

// ### get all event=export รายงานการนำหนังสือออกของเจ้าหน้าที่
// GET http://localhost:3001/api/employee-access/range?event=export&__start=2023-05-17T05:02:40.461Z&__end=2023-05-17T05:02:40.461Z

// const tableHead = [
//   { id: 'channelId', label: t('branchDetail.table.channelId'), alignRight: false },
//   { id: 'channelName', label: t('branchDetail.table.channelName'), alignRight: false },
//   { id: 'layer', label: t('branchDetail.table.layer'), alignRight: false },
//   { id: 'size', label: t('branchDetail.table.size'), alignRight: false },
//   { id: 'barcode', label: t('branchDetail.table.barcode'), alignRight: false },
//   { id: 'bookName', label: t('branchDetail.table.bookName'), alignRight: false },
//   { id: 'categorythai', label: t('branchDetail.table.categorythai'), alignRight: false },
//   { id: 'callNo', label: t('branchDetail.table.callNo'), alignRight: false },
//   { id: 'language', label: t('branchDetail.table.language'), alignRight: false },
//   { id: 'receivedDate', label: t('branchDetail.table.receivedDate'), alignRight: false },
//   { id: 'status', label: t('branchDetail.table.status'), alignRight: false },
//   { id: '' },
// ];

// const logReport = [...Array(24)].map((_, index) => ({
//   _id: faker.datatype.uuid(),
//   channelId: (index+1),
//   channelName: (`A${index+1}`),
//   layer: faker.datatype.number(1-4),
//   size: sample(['A4', 'A5']),
//   barcode: faker.datatype.number(10000000000000),
//   employeeName: sample(['อารยา  สุขใจ','ลุกมัน อะแดม','อิสมาแอ อาแว','พีรมล  นิ่มนวล','พิทักษ์  ดำรงนาน']),
//   bookName: sample(['บันทึกเหตุการณ์สำคัญของโลก','จะไม่ทนเงียบอีกต่อไป','Bitcoin Cryptocurrency ชนะขาดตลาดคริปโทและบิตคอยน์','ยุทธศาสตร์สุขภาวะ LGBTION+ ในประเทศไทย','33 กฎทองของการลงทุนเก็งกำไรคอนโดแบบตัวจริง','มันไม่ง่ายขึ้นหรอก แต่เธอจะเก่งขึ้น','วานรศึกษา']),
//   callNo: sample(['909 .ก25','362.76 .ฉ75','332.178 .ธ37','306.76 .ช55','333.3322 .ฐ34','895.7 .ค3624','599.8 .ว25']),
//   categorythai: sample(['ประวัติศาสตร์','สังคมและการเมือง','ธุรกิจและการลงทุน','เรื่องราวรอบตัว','ธุรกิจและการลงทุน','จิตวิทยาพัฒนาตนเอง','วิทยาศาสตร์']),
//   status: sample(['Item being held']),
//   language: sample(['th','en']),
//   receivedDate: new Date().toISOString()
// }));

const logReport = [...Array(360)].map((_, index) => ({
  _id: faker.datatype.uuid(),
  channelId: (index+1),
  channelName: (`A${index+1}`),
  layer: faker.datatype.number(4),
  size: sample(['A4', 'A5']),
  barcode: faker.datatype.number(10000000000000),
  employeeName: sample(['อารยา  สุขใจ','ลุกมัน อะแดม','อิสมาแอ อาแว','พีรมล  นิ่มนวล','พิทักษ์  ดำรงนาน']),
  bookName: sample(['บันทึกเหตุการณ์สำคัญของโลก','จะไม่ทนเงียบอีกต่อไป','Bitcoin Cryptocurrency ชนะขาดตลาดคริปโทและบิตคอยน์','ยุทธศาสตร์สุขภาวะ LGBTION+ ในประเทศไทย','33 กฎทองของการลงทุนเก็งกำไรคอนโดแบบตัวจริง','มันไม่ง่ายขึ้นหรอก แต่เธอจะเก่งขึ้น','วานรศึกษา']),
  callNo: sample(['909 .ก25','362.76 .ฉ75','332.178 .ธ37','306.76 .ช55','333.3322 .ฐ34','895.7 .ค3624','599.8 .ว25']),
  categorythai: sample(['ประวัติศาสตร์','สังคมและการเมือง','ธุรกิจและการลงทุน','เรื่องราวรอบตัว','ธุรกิจและการลงทุน','จิตวิทยาพัฒนาตนเอง','วิทยาศาสตร์']),
  status: sample(['Item being held']),
  language: sample(['th','en']),
  receivedDate: new Date().toISOString()
}));

export default logReport;
