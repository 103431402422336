import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

// import en from "./assets/locales/en.json"
// import th from "./assets/locales/th.json"

// const resources = {
//     en,
//     th
// }

// en language
import enGeneral from './assets/locales/en/en.json';
import enBooksNotPickeUpReport from './assets/locales/en/booksNotPickeUpReport.json';
import enSystemLogReport from './assets/locales/en/systemLogReport.json';
import enMemberBookBorrowingReport from './assets/locales/en/memberBookBorrowingReport.json';
import enStaffAccessToTheBoxReport from './assets/locales/en/staffAccessToTheBoxReport.json';
import enStaffImportBookReport from './assets/locales/en/staffImportBookReport.json';
import enStaffBookRemovalReport from './assets/locales/en/staffBookRemovalReport.json';
import enMemberBoxAccessReport from './assets/locales/en/memberBoxAccessReport.json';
import enNoMemberBoxAccessReport from './assets/locales/en/noMemberBoxAccessReport.json';
import enMemberBookSelectionReport from './assets/locales/en/memberBookSelectionReport.json';
import enGroupCallNumber from './assets/locales/en/groupCallNumber.json';

import enDashboardPage from './assets/locales/en/dashboardPage.json';
import enMonitoringPage from './assets/locales/en/monitoringPage.json';
import enUserList from './assets/locales/en/userList.json';
import enUserCreate from './assets/locales/en/userCreate.json';
import enBranchList from './assets/locales/en/branchList.json';
import enBranchCreate from './assets/locales/en/branchCreate.json';
import enRoleList from './assets/locales/en/roleList.json';
import enRoleCreate from './assets/locales/en/roleCreate.json';
import enPermissionList from './assets/locales/en/permissionList.json';
import enPermissionCreate from './assets/locales/en/permissionCreate.json';
import enBranchDetail from './assets/locales/en/branchDetail.json';
import enChartReport from './assets/locales/en/chartReport.json';

import enLoginPage from './assets/locales/en/loginPage.json';
import enPermissionsDenied from './assets/locales/en/permissionsDenied.json';

import enSettingSmtp from './assets/locales/en/settingSmtp.json';
import enSettingIssueEmail from './assets/locales/en/settingIssueEmail.json';


// th language
import thGeneral from './assets/locales/th/th.json';
import thBooksNotPickeUpReport from './assets/locales/th/booksNotPickeUpReport.json';
import thSystemLogReport from './assets/locales/th/systemLogReport.json';
import thMemberBookBorrowingReport from './assets/locales/th/memberBookBorrowingReport.json';
import thStaffAccessToTheBoxReport from './assets/locales/th/staffAccessToTheBoxReport.json';
import thStaffImportBookReport from './assets/locales/th/staffImportBookReport.json';
import thStaffBookRemovalReport from './assets/locales/th/staffBookRemovalReport.json';
import thMemberBoxAccessReport from './assets/locales/th/memberBoxAccessReport.json';
import thNoMemberBoxAccessReport from './assets/locales/th/noMemberBoxAccessReport.json';
import thMemberBookSelectionReport from './assets/locales/th/memberBookSelectionReport.json';
import thGroupCallNumber from './assets/locales/th/groupCallNumber.json';

import thDashboardPage from './assets/locales/th/dashboardPage.json';
import thMonitoringPage from './assets/locales/th/monitoringPage.json';
import thUserList from './assets/locales/th/userList.json';
import thUserCreate from './assets/locales/th/userCreate.json';
import thBranchList from './assets/locales/th/branchList.json';
import thBranchCreate from './assets/locales/th/branchCreate.json'
import thRoleList from './assets/locales/th/roleList.json';
import thRoleCreate from './assets/locales/th/roleCreate.json';
import thPermissionList from './assets/locales/th/permissionList.json';
import thPermissionCreate from './assets/locales/th/permissionCreate.json';
import thBranchDetail from './assets/locales/th/branchDetail.json';
import thChartReport from './assets/locales/th/chartReport.json';

import thLoginPage from './assets/locales/th/loginPage.json';
import thPermissionsDenied from './assets/locales/th/permissionsDenied.json';

import thSettingSmtp from './assets/locales/th/settingSmtp.json';
import thSettingIssueEmail from './assets/locales/th/settingIssueEmail.json';

const resources = {
  en: {
    translation: {
      ...enGeneral,
      ...enBooksNotPickeUpReport,
      ...enSystemLogReport,     
      ...enMemberBookBorrowingReport,

      ...enDashboardPage,
      ...enMonitoringPage,
      ...enUserList,
      ...enUserCreate,
      ...enRoleList,
      ...enRoleCreate,
      ...enPermissionList,
      ...enPermissionCreate,

      ...enStaffAccessToTheBoxReport,
      ...enStaffImportBookReport,
      ...enStaffBookRemovalReport,
      ...enMemberBoxAccessReport,
      ...enNoMemberBoxAccessReport,
      ...enMemberBookSelectionReport,
      ...enBranchList,
      ...enBranchCreate,
      ...enBranchDetail,
      ...enLoginPage,
      ...enGroupCallNumber,
      ...enChartReport,
      ...enPermissionsDenied,

      ...enSettingSmtp,
      ...enSettingIssueEmail,
    }

  },
  th: {
    translation: {
      ...thGeneral,
      ...thBooksNotPickeUpReport,
      ...thSystemLogReport,     
      ...thMemberBookBorrowingReport,

      ...thDashboardPage,
      ...thMonitoringPage,
      ...thUserList,
      ...thUserCreate,
      ...thRoleList,
      ...thRoleCreate,
      ...thPermissionList,
      ...thPermissionCreate,

      ...thStaffAccessToTheBoxReport,
      ...thStaffImportBookReport,
      ...thStaffBookRemovalReport,
      ...thMemberBoxAccessReport,
      ...thNoMemberBoxAccessReport,
      ...thMemberBookSelectionReport,
      ...thBranchList,
      ...thBranchCreate,
      ...thBranchDetail,
      ...thLoginPage,
      ...thGroupCallNumber,
      ...thChartReport,
      ...thPermissionsDenied,

      ...thSettingSmtp,
      ...thSettingIssueEmail,
    }
  }

};

// console.log('resources ->', resources);

i18n.use(initReactI18next).init({
  resources,
  lng: 'th',
  interpolation: {
    escapeValue: false,
  },
});

export default i18n;
