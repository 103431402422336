import PropTypes from 'prop-types';
import { set, sub } from 'date-fns';
import { noCase } from 'change-case';
import { faker } from '@faker-js/faker';
import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Cookies, useCookies } from 'react-cookie';
// @mui
import {
  Box,
  List,
  Badge,
  Button,
  Avatar,
  Tooltip,
  Divider,
  Popover,
  Typography,
  IconButton,
  ListItemText,
  ListSubheader,
  ListItemAvatar,
  ListItemButton,
} from '@mui/material';

import { useTranslation } from 'react-i18next';

// utils
import { fToNow } from '../../../utils/formatTime';
// components
import Iconify from '../../../components/iconify';
import Scrollbar from '../../../components/scrollbar';

// ----------------------------------------------------------------------

const config = window.config;

// const NOTIFICATIONS = [
//   {
//     id: faker.datatype.uuid(),
//     title: 'Your order is placed',
//     description: 'waiting for shipping',
//     avatar: null,
//     type: 'order_placed',
//     createdAt: set(new Date(), { hours: 10, minutes: 30 }),
//     isUnRead: true,
//   },
//   {
//     id: faker.datatype.uuid(),
//     title: faker.name.fullName(),
//     description: 'answered to your comment on the Minimal',
//     avatar: '/assets/images/avatars/avatar_2.jpg',
//     type: 'friend_interactive',
//     createdAt: sub(new Date(), { hours: 3, minutes: 30 }),
//     isUnRead: true,
//   },
//   {
//     id: faker.datatype.uuid(),
//     title: 'You have new message',
//     description: '5 unread messages',
//     avatar: null,
//     type: 'chat_message',
//     createdAt: sub(new Date(), { days: 1, hours: 3, minutes: 30 }),
//     isUnRead: false,
//   },
//   {
//     id: faker.datatype.uuid(),
//     title: 'You have new mail',
//     description: 'sent from Guido Padberg',
//     avatar: null,
//     type: 'mail',
//     createdAt: sub(new Date(), { days: 2, hours: 3, minutes: 30 }),
//     isUnRead: false,
//   },
//   {
//     id: faker.datatype.uuid(),
//     title: 'Delivery processing',
//     description: 'Your order is being shipped',
//     avatar: null,
//     type: 'order_shipped',
//     createdAt: sub(new Date(), { days: 3, hours: 3, minutes: 30 }),
//     isUnRead: false,
//   },
// ];

const NOTIFICATIONS = [
  // {
  //   "issueDate": "{{nowDate}}",
  //   "type": "Hardware",
  //   "log": "ภายในเครื่องสูงผิดปกติ",
  //   "fixDate": null,
  //   "location": "Central World",
  //   isUnRead: true
  // }
];

export default function NotificationsPopover() {
  const [cookies, setCookie, removeCookie] = useCookies(['notifications']);
  const notiCookies = cookies.notifications ? cookies.notifications : {};
  // console.log('--------- cookies, notiCookies->', cookies, notiCookies);

  const viewPath = '/report/systemlogreport';

  const [notifications, setNotifications] = useState(NOTIFICATIONS);

  const totalUnRead = notiCookies.totalUnRead === undefined ? 0 : notiCookies.totalUnRead;
  const isUnRead = notiCookies.isUnRead;

  const [open, setOpen] = useState(null);

  const { i18n, t } = useTranslation();

  const navigate = useNavigate();

  const handleOpen = (event) => {
    setOpen(event.currentTarget);
  };

  const handleClose = () => {
    setOpen(null);
  };

  const handleView = () => {
    const d = new Date();
    const cookieExpiresHour = config.cookieExpiresHour ? config.cookieExpiresHour : 1;
    d.setTime(d.getTime() + cookieExpiresHour * 60 * 60 * 1000);

    setCookie('notifications', { ...notiCookies, isUnRead: false }, { path: '/', expires: d });

    navigate(viewPath, { replace: true });
    setOpen(null);
  };

  const handleMarkAllAsRead = () => {
    setNotifications(
      notifications.map((notification) => ({
        ...notification,
        isUnRead: false,
      }))
    );
  };

  const handleRemoveCookie = () => {
    removeCookie('notifications', { path: '/' });
  };

  useEffect(() => {
    // console.log('useEffect config =>', config);
    const fetchSum = (dataObj) => {
      try {
        // console.log('fetchSum dataObj ->', dataObj);
        fetch(dataObj.url, {
          method: 'GET',
        })
          .then((response) => {
            return response.json();
          })
          .then((dataRes) => {
            console.log(`fetchSum: ${dataObj.cmd} dataRes ->`, dataRes); // {all:1}
            // console.log('1--------- cookies, notiCookies->', cookies, notiCookies);
            const d = new Date();
            const cookieExpiresHour = config.cookieExpiresHour ? config.cookieExpiresHour : 1;
            d.setTime(d.getTime() + cookieExpiresHour * 60 * 60 * 1000);

            if (!notiCookies) {
              // console.log(`1 xxxxxxxxxx !notiCookies notiCookies->`, notiCookies);
              setCookie('notifications', { isUnRead: true, totalUnRead: dataRes.all }, { path: '/', expires: d });
            } else if (notiCookies.totalUnRead === undefined) {
              // console.log(`2 xxxxxxxxxx !notiCookies.totalUnRead notiCookies->`, notiCookies);
              setCookie('notifications', { isUnRead: true, totalUnRead: dataRes.all }, { path: '/', expires: d });
            } else if (notiCookies.totalUnRead !== dataRes.all) {
              // console.log(`3 xxxxxxxxxx notiCookies.totalUnRead !== dataRes.all notiCookies->`, notiCookies);
              setCookie('notifications', { isUnRead: true, totalUnRead: dataRes.all }, { path: '/', expires: d });
            }
          })
          .catch((err) => {
            console.error('fetchSum: catch Error ->', err);
          });
      } catch (e) {
        console.error('fetchSum: try Error -> ', e);
      }
    };

    const d = new Date();
    // const d = new Date('2023-07-14T17:00:00')// test
    const start = new Date(d.setHours(0, 0, 0)).toISOString();
    const end = new Date(d.setHours(23, 59, 59)).toISOString();
    fetchSum({ url: `${config.backendUrl}/api/dashboard/issue?fixDate=null&__start=${start}&__end=${end}`, cmd: 'sumIssue' });
  });

  return (
    <>
      <IconButton color={open ? 'primary' : 'default'} onClick={handleOpen} sx={{ width: 40, height: 40 }}>
        {/* <Badge badgeContent={totalUnRead isUnRead} color="error">
          <Iconify icon="eva:bell-fill" />
        </Badge> */}
        {isUnRead ? (
          <Badge badgeContent={totalUnRead} color="error">
            <Iconify icon="eva:bell-fill" />
          </Badge>
        ) : (
          <Badge badgeContent={0} color="error">
            <Iconify icon="eva:bell-fill" />
          </Badge>
        )}
      </IconButton>

      <Popover
        open={Boolean(open)}
        anchorEl={open}
        onClose={handleClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        PaperProps={{
          sx: {
            mt: 1.5,
            ml: 0.75,
            width: 360,
          },
        }}
      >
        <Box sx={{ display: 'flex', alignItems: 'center', py: 2, px: 2.5 }}>
          <Box sx={{ flexGrow: 1 }}>
            <Typography variant="subtitle1">{t('notificationsPopover.header')}</Typography>
            <Typography variant="body2" sx={{ color: 'text.secondary' }}>
              {t('notificationsPopover.content1')} {totalUnRead || '0'} {t('notificationsPopover.content2')}
            </Typography>
          </Box>

          {/* {totalUnRead > 0 && (
            <Tooltip title=" Mark all as read">
              <IconButton color="primary" onClick={handleMarkAllAsRead}>
                <Iconify icon="eva:done-all-fill" />
              </IconButton>
            </Tooltip>
          )} */}
        </Box>

        <Divider sx={{ borderStyle: 'dashed' }} />

        {/* <Scrollbar sx={{ height: { xs: 340, sm: 'auto' } }}>
          <List
            disablePadding
            subheader={
              <ListSubheader disableSticky sx={{ py: 1, px: 2.5, typography: 'overline' }}>
                New
              </ListSubheader>
            }
          >
            {notifications.slice(0, 2).map((notification) => (
              <NotificationItem key={notification.id} notification={notification} />
            ))}
          </List>

          <List
            disablePadding
            subheader={
              <ListSubheader disableSticky sx={{ py: 1, px: 2.5, typography: 'overline' }}>
                Before that
              </ListSubheader>
            }
          >
            {notifications.slice(2, 5).map((notification) => (
              <NotificationItem key={notification.id} notification={notification} />
            ))}
          </List>
        </Scrollbar> */}

        <Divider sx={{ borderStyle: 'dashed' }} />

        <Box sx={{ p: 1 }}>
          <Button fullWidth disableRipple onClick={handleView}>
            {t('notificationsPopover.btViewAll')}
          </Button>
          {/* <Button fullWidth disableRipple onClick={handleRemoveCookie}>
            Remove cookie
          </Button> */}
        </Box>
      </Popover>
    </>
  );
}

// ----------------------------------------------------------------------

NotificationItem.propTypes = {
  notification: PropTypes.shape({
    createdAt: PropTypes.instanceOf(Date),
    id: PropTypes.string,
    isUnRead: PropTypes.bool,
    title: PropTypes.string,
    description: PropTypes.string,
    type: PropTypes.string,
    avatar: PropTypes.any,
  }),
};

function NotificationItem({ notification }) {
  const { avatar, title } = renderContent(notification);

  return (
    <ListItemButton
      sx={{
        py: 1.5,
        px: 2.5,
        mt: '1px',
        ...(notification.isUnRead && {
          bgcolor: 'action.selected',
        }),
      }}
    >
      <ListItemAvatar>
        <Avatar sx={{ bgcolor: 'background.neutral' }}>{avatar}</Avatar>
      </ListItemAvatar>
      <ListItemText
        primary={title}
        secondary={
          <Typography
            variant="caption"
            sx={{
              mt: 0.5,
              display: 'flex',
              alignItems: 'center',
              color: 'text.disabled',
            }}
          >
            <Iconify icon="eva:clock-outline" sx={{ mr: 0.5, width: 16, height: 16 }} />
            {fToNow(notification.createdAt)}
          </Typography>
        }
      />
    </ListItemButton>
  );
}

// ----------------------------------------------------------------------

function renderContent(notification) {
  const title = (
    <Typography variant="subtitle2">
      {notification.title}
      <Typography component="span" variant="body2" sx={{ color: 'text.secondary' }}>
        &nbsp; {noCase(notification.description)}
      </Typography>
    </Typography>
  );

  if (notification.type === 'order_placed') {
    return {
      avatar: <img alt={notification.title} src="/assets/icons/ic_notification_package.svg" />,
      title,
    };
  }
  if (notification.type === 'order_shipped') {
    return {
      avatar: <img alt={notification.title} src="/assets/icons/ic_notification_shipping.svg" />,
      title,
    };
  }
  if (notification.type === 'mail') {
    return {
      avatar: <img alt={notification.title} src="/assets/icons/ic_notification_mail.svg" />,
      title,
    };
  }
  if (notification.type === 'chat_message') {
    return {
      avatar: <img alt={notification.title} src="/assets/icons/ic_notification_chat.svg" />,
      title,
    };
  }
  return {
    avatar: notification.avatar ? <img alt={notification.title} src={notification.avatar} /> : null,
    title,
  };
}
