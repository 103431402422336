import * as Yup from 'yup';
import { Helmet } from 'react-helmet-async';
import { filter } from 'lodash';
import { sentenceCase } from 'change-case';
import { useState, useEffect, useCallback } from 'react';
// import { useSnackbar } from 'notistack5'; // notistack5
import { SnackbarProvider, enqueueSnackbar } from 'notistack';
import { useNavigate, useParams, useLocation } from 'react-router-dom';
import { Form, FormikProvider, useFormik } from 'formik'; //
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
// @mui
import {
  Box,
  Card,
  CardHeader,
  Grid,
  Table,
  Stack,
  Switch,
  Paper,
  Avatar,
  Button,
  Popover,
  Divider,
  Checkbox,
  TableRow,
  TableHead,
  MenuItem,
  TableBody,
  TableCell,
  TextField,
  Container,
  Typography,
  IconButton,
  TableContainer,
  TablePagination,
  FormControlLabel,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
// components
import Label from '../components/label';
import Iconify from '../components/iconify';
import Scrollbar from '../components/scrollbar';
// sections
import { UserListHead, UserListToolbar, UserMoreMenu, ConfirmDialog } from '../sections/@dashboard/user';
// mock
import USERLIST from '../_mock/userList';

// ----------------------------------------------------------------------

const config = window.config;

export default function UserCreate() {
  const { i18n, t } = useTranslation();

  const { pathname } = useLocation();
  const isEdit = pathname.includes('edit');
  const { userId } = useParams();
  const [currentUser, setCurrentUser] = useState({});
  const [permissions, setPermissions] = useState([]);

  const [state1, setState1] = useState(false);

  const [isChecked, setIsChecked] = useState(new Date());

  const navigate = useNavigate();
  // const { enqueueSnackbar } = useSnackbar();

  const NewUserSchema = Yup.object().shape({
    name: Yup.string().required('Name is required'),
    description: Yup.string().required('Description is required'),
    // permissions: Yup.string().required('permissions is required'),
  });

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      name: currentUser?.name || '',
      description: currentUser?.description || '',
      permissions: currentUser?.permissions || [],
    },
    validationSchema: NewUserSchema,
    onSubmit: async (values, { setSubmitting, resetForm, setErrors }) => {
      // console.log('onSubmit values ->', values);

      try {
        // await fakeRequest(500);
        const apiCreateUsers = () => {
          // const _id = userForDelete._id;
          const url = `${config.backendUrl}/api/role/`;
          try {
            // console.log('apiCreateUsers: url ->', url);

            fetch(url, {
              method: 'POST',
              headers: { 'Content-Type': 'application/json' },
              body: JSON.stringify(values),
            })
              .then((response) => {
                return response.json();
              })
              .then((dataRes) => {
                // console.log(`apiCreateUsers: dataRes ->`, dataRes);
                if (dataRes.error) {
                  const error = dataRes.error.message;
                  setSubmitting(false);
                  enqueueSnackbar(!isEdit ? `Create Error: ${error}` : `Update Error: ${error}`, {
                    autoHideDuration: 2000,
                    variant: 'error',
                    persist: false,
                  });
                  return;
                }
                resetForm();
                setSubmitting(false);
                enqueueSnackbar(!isEdit ? `Create success` : `Update success`, {
                  autoHideDuration: 2000,
                  variant: 'success',
                  persist: false,
                });
                navigate('/user/role/list');
              })
              .catch((err) => {
                console.error('apiCreateUsers: catch Error ->', err);
                enqueueSnackbar(!isEdit ? 'Create Error' : 'Update Error', { variant: 'error' });
              });
          } catch (e) {
            console.error('apiCreateUsers: try Error -> ', e);
          }
        };

        const apiEditUsers = (userId) => {
          // const _id = userForDelete._id;
          const url = `${config.backendUrl}/api/role/${userId}`;
          try {
            // console.log('apiEditUsers: url ->', url);

            fetch(url, {
              method: 'PUT',
              headers: { 'Content-Type': 'application/json' },
              body: JSON.stringify(values),
            })
              .then((response) => {
                return response.json();
              })
              .then((dataRes) => {
                // console.log(`apiEditUsers: dataRes ->`, dataRes);
                if (dataRes.error) {
                  const error = dataRes.error.message;
                  setSubmitting(false);
                  enqueueSnackbar(!isEdit ? `Create Error: ${error}` : `Update Error: ${error}`, {
                    autoHideDuration: 2000,
                    variant: 'error',
                    persist: false,
                  });
                  return;
                }
                resetForm();
                setSubmitting(false);
                enqueueSnackbar(!isEdit ? `Create success` : `Update success`, {
                  autoHideDuration: 2000,
                  variant: 'success',
                  persist: false,
                });
                navigate('/user/role/list');
              })
              .catch((err) => {
                console.error('apiEditUsers: catch Error ->', err);
                enqueueSnackbar(!isEdit ? 'Create Error' : 'Update Error', { variant: 'error' });
              });
          } catch (e) {
            console.error('apiEditUsers: try Error -> ', e);
          }
        };

        if (isEdit) {
          apiEditUsers(currentUser._id);
        } else {
          apiCreateUsers();
        }

        // resetForm();
        // setSubmitting(false);
        // enqueueSnackbar(!isEdit ? 'Create success' : 'Update success', { variant: 'success' });
        // navigate(PATH_DASHBOARD.user.list);
      } catch (error) {
        console.error(error);
        setSubmitting(false);
        setErrors(error);
      }
    },
  });

  const { errors, values, touched, handleSubmit, isSubmitting, getFieldProps, setFieldValue } = formik;

  const handleChange = (_id, action, e) => {
    // console.log('handleChange _id, action ->', _id, action);
    const permission = permissions.find((p) => p._id === _id);
    if (!permission) {
      console.log('!permission->', permission);
      return;
    }
    permission[action] = e.target.checked;
    if (action === 'view' && !e.target.checked) {
      permission.create = false;
      permission.update = false;
      permission.delete = false;
    }
    // console.log('----permissions->', permissions);

    const permissionsSelect = permissions.filter((p) => p.view);

    // console.log('----permissionSelect->', permissionsSelect);
    setFieldValue('permissions', permissionsSelect);
    // console.log('----values->', values);

    // sort by name
    // sort by name
    permissions.sort((a, b) => {
      if (a.name < b.name) {
        return -1;
      }
      if (a.name > b.name) {
        return 1;
      }
      return 0;
    });
    setPermissions(permissions);
    setIsChecked(new Date());
  };

  // useEffect(() => {
  //   console.log('useEffect permissions =>', permissions);
  // }, [permissions]);

  useEffect(() => {
    // console.log('UserCreate: useEffect config, isEdit, userId =>', config, isEdit, userId);
    let currentUserBuf = {};
    const apiGetUser = (userId) => {
      // const _id = userForDelete._id;
      const url = `${config.backendUrl}/api/role/${userId}`;
      try {
        // console.log('apiGetUser: url ->', url);

        fetch(url, {
          method: 'GET',
          // headers: { 'Content-Type': 'application/json' },
          // body: JSON.stringify(values),
        })
          .then((response) => {
            return response.json();
          })
          .then((dataRes) => {
            // console.log(`apiGetUser: dataRes ->`, dataRes);
            if (dataRes.error) {
              const error = dataRes.error.message;
            } else {
              setCurrentUser(dataRes);
              currentUserBuf = dataRes;
              if (isEdit) apiGetPermission();
            }
          })
          .catch((err) => {
            console.error('apiGetUser: catch Error ->', err);
            enqueueSnackbar(!isEdit ? 'Create Error' : 'Update Error', { variant: 'error' });
          });
      } catch (e) {
        console.error('apiGetUser: try Error -> ', e);
      }
    };
    if (isEdit) apiGetUser(userId);

    const apiGetPermission = () => {
      // const _id = userForDelete._id;
      const url = `${config.backendUrl}/api/permission`;
      try {
        // console.log('apiGetPermission: url ->', url);

        fetch(url, {
          method: 'GET',
          // headers: { 'Content-Type': 'application/json' },
          // body: JSON.stringify(values),
        })
          .then((response) => {
            return response.json();
          })
          .then((dataRes) => {
            // console.log(`apiGetPermission: dataRes ->`, dataRes);
            if (dataRes.error) {
              const error = dataRes.error.message;
              console.error(`apiGetPermission: error ->`, dataRes.error);
            } else {
              // console.log(`apiGetPermission: currentUserBuf role ->`, currentUserBuf);
              const checkUndefined = (value) => {
                const newValue = value === undefined ? false : value;
                //  console.log(`========= value, newValue ->`, value, newValue);
                return newValue;
              };

              for (let i = 0; i < dataRes.length; i += 1) {
                if (isEdit) {
                  const permission = currentUserBuf.permissions.find((p) => p.name === dataRes[i].name);
                  // console.log(`0 apiGetPermission: permission ->`, permission);
                  if (permission) {
                    // console.log(`01 apiGetPermission: dataRes[i] ->`, dataRes[i]);
                    dataRes[i] = {
                      ...dataRes[i],
                      view: true,
                      create: checkUndefined(permission.create),
                      update: checkUndefined(permission.update),
                      delete: checkUndefined(permission.delete),
                    };
                    // console.log(`02 apiGetPermission: dataRes[i] ->`, dataRes[i]);
                  }
                } else dataRes[i] = { ...dataRes[i], view: false, create: false, update: false, delete: false };
              }

              // sort by name
              dataRes.sort((a, b) => {
                if (a.name < b.name) {
                  return -1;
                }
                if (a.name > b.name) {
                  return 1;
                }
                return 0;
              });
              setPermissions(dataRes);
              setIsChecked(new Date());
              // console.log(`1 apiGetPermission: dataRes ->`, dataRes);
            }
          })
          .catch((err) => {
            console.error('apiGetPermission: catch Error ->', err);
            enqueueSnackbar(!isEdit ? 'Get perrmission Error' : 'Get perrmission Error', { variant: 'error' });
          });
      } catch (e) {
        console.error('apiGetPermission: try Error -> ', e);
      }
    };
    if (!isEdit) apiGetPermission();
  }, []);

  return (
    <>
      <Helmet>
        <title>
          {!isEdit ? t('roleCreate.titleCreate') : t('roleCreate.titleEdit')} | {t('title')}
        </title>
      </Helmet>

      <Container maxWidth="xl">
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            {!isEdit ? t('roleCreate.titleCreate') : t('roleCreate.titleEdit')}
          </Typography>
        </Stack>

        <FormikProvider value={formik}>
          {/* {console.log('formik ->', values)} */}
          <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
            <Grid container spacing={3}>
              <Grid item xs={12} md={12}>
                <Card sx={{ p: 3 }}>
                  <Stack spacing={3}>
                    <Stack direction={{ xs: 'column', sm: 'row' }} spacing={{ xs: 3, sm: 2 }}>
                      <TextField
                        fullWidth
                        disabled={isEdit}
                        label={t('roleCreate.txtName')}
                        {...getFieldProps('name')}
                        error={Boolean(touched.name && errors.name)}
                        helperText={touched.name && errors.name}
                      />
                      <TextField
                        fullWidth
                        label={t('roleCreate.txtDescription')}
                        {...getFieldProps('description')}
                        error={Boolean(touched.description && errors.description)}
                        helperText={touched.description && errors.description}
                      />
                    </Stack>
                    {/* <Stack direction={{ xs: 'column', sm: 'row' }} spacing={{ xs: 3, sm: 2 }}> */}
                    {/* <TextField
                        fullWidth
                        label="Permissions"
                        {...getFieldProps('permissions')}
                        error={Boolean(touched.permissions && errors.permissions)}
                        helperText={touched.permissions && errors.permissions}
                      /> */}
                    <Card>
                      {/* <CardHeader title="Permission" sx={{ mb: 3 }}/> */}
                      <Typography variant="h6" gutterBottom>
                        {t('roleCreate.permission')}
                      </Typography>
                      <Scrollbar>
                        <TableContainer sx={{ minWidth: '100%' }}>
                          <Table>
                            <TableHead>
                              <TableRow>
                                {/* <TableCell /> */}
                                <TableCell>{t('roleCreate.name')}</TableCell>
                                <TableCell>{t('roleCreate.description')}</TableCell>
                                <TableCell>{t('roleCreate.view')}</TableCell>
                                <TableCell>{t('roleCreate.create')}</TableCell>
                                <TableCell>{t('roleCreate.edit')}</TableCell>
                                <TableCell>{t('roleCreate.delete')}</TableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              {isChecked &&
                                permissions.map((row, index) => {
                                  return (
                                    <TableRow key={row._id}>
                                      {/* <TableCell>
                                        <Checkbox
                                          checked={row.view}
                                          onChange={(e) => handleChange(row._id, 'view', e)}
                                        />
                                      </TableCell> */}
                                      <TableCell>{row.name}</TableCell>
                                      <TableCell>{row.description}</TableCell>
                                      <TableCell>
                                        <Checkbox
                                          checked={row.view}
                                          onChange={(e) => handleChange(row._id, 'view', e)}
                                        />
                                      </TableCell>
                                      <TableCell>
                                        <Checkbox
                                          disabled={!row.view || !row.isCreate}
                                          checked={row.create}
                                          onChange={(e) => handleChange(row._id, 'create', e)}
                                        />
                                      </TableCell>
                                      <TableCell>
                                        <Checkbox
                                          disabled={!row.view || !row.isUpdate}
                                          checked={row.update}
                                          onChange={(e) => handleChange(row._id, 'update', e)}
                                        />
                                      </TableCell>
                                      <TableCell>
                                        <Checkbox
                                          disabled={!row.view || !row.isDelete}
                                          checked={row.delete}
                                          onChange={(e) => handleChange(row._id, 'delete', e)}
                                        />
                                      </TableCell>
                                    </TableRow>
                                  );
                                })}
                            </TableBody>
                          </Table>
                        </TableContainer>
                      </Scrollbar>
                    </Card>
                    {/* </Stack> */}

                    <Box sx={{ mt: 3, display: 'flex', justifyContent: 'flex-end' }}>
                      <Button
                        variant="outlined"
                        color="primary"
                        onClick={() => {
                          navigate('/user/role/list');
                        }}
                        sx={{ mr: 2 }}
                      >
                        {t('btCancel')}
                      </Button>
                      <LoadingButton type="submit" variant="contained" loading={isSubmitting}>
                        {!isEdit ? t('roleCreate.btCreate') : t('roleCreate.btSave')}
                      </LoadingButton>
                    </Box>
                  </Stack>
                  <SnackbarProvider
                    anchorOrigin={{
                      vertical: 'bottom',
                      horizontal: 'center',
                    }}
                  />
                </Card>
              </Grid>
            </Grid>
          </Form>
        </FormikProvider>
      </Container>
    </>
  );
}
