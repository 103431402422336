import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import { format } from 'date-fns';
import { filter, find, groupBy } from 'lodash';
import { sentenceCase } from 'change-case';
import { useState, useEffect } from 'react';
// @mui
import {
  Grid,
  Card,
  CardHeader,
  CardActions,
  Table,
  Stack,
  Paper,
  Avatar,
  Button,
  FlatButton,
  Popover,
  Checkbox,
  TableRow,
  MenuItem,
  TableBody,
  TableCell,
  Container,
  Typography,
  IconButton,
  TableContainer,
  TablePagination,
} from '@mui/material';
// components
import Label from '../components/label';
import Iconify from '../components/iconify';
import Scrollbar from '../components/scrollbar';
import { DateFieldFilter, DateTimeFieldFilter } from '../components/filter';
// import { DateTimeFieldFilter } from '../components/filter';
import { ExportToExcel } from '../components/exportExcel/ButtonExports';
// import {ChartReports} from '../components/chartReport';
import {
  ChartBarReports,
  ChartReports,
  ChartPieReports,
  ChartDonutReports,
  ChartBarReportNew,
} from '../components/chartReport';
// sections
import { UserListHead, UserListToolbar } from '../sections/@dashboard/report';
// mock
// import USERLIST from '../_mock/user';
// import USERLIST from '../_mock/logReport';

import LOGLIST from '../_mock/borrow';

const criteriaTh = {
  xaxis: {
    months: ['ม.ค.', 'ก.พ.', 'มี.ค.', 'เม.ย.', 'พ.ค.', 'มิ.ย.', 'ก.ค.', 'ส.ค.', 'ก.ย.', 'ต.ค.', 'พ.ย.', 'ธ.ค.'],
    dayOfWeek: ['อา', 'จ', 'อ', 'พ', 'พฤ', 'ศ', 'ส'],
    dayOfMonth: ['3', '6', '9', '12', '15', '18', '21', '24', '27', '30'],
    hourOfDay: [
      '0',
      '1',
      '2',
      '3',
      '4',
      '5',
      '6',
      '7',
      '8',
      '9',
      '10',
      '11',
      '12',
      '13',
      '14',
      '15',
      '16',
      '17',
      '18',
      '19',
      '20',
      '21',
      '22',
      '23',
    ],
  },
};

const criteriaEn = {
  xaxis: {
    months: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
    dayOfWeek: ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Tri', 'Sat'],
    dayOfMonth: ['3', '6', '9', '12', '15', '18', '21', '24', '27', '30'],
    hourOfDay: [
      '0',
      '1',
      '2',
      '3',
      '4',
      '5',
      '6',
      '7',
      '8',
      '9',
      '10',
      '11',
      '12',
      '13',
      '14',
      '15',
      '16',
      '17',
      '18',
      '19',
      '20',
      '21',
      '22',
      '23',
    ],
  },
};

const defaultChartDataObj = [
  {
    year: 'Year',
    data: [{ name: 'Check In', data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0] }],
  },
];

// ----------------------------------------------------------------------

const TABLE_HEAD = [
  {
    id: 'order',
    label: 'memberbookborrowing.order',
    alignRight: false,
    sx: { minWidth: '50px', position: 'sticky', left: 0, zIndex: 99 },
  },
  {
    id: 'borrowedDate',
    label: 'memberbookborrowing.borrowedDate',
    alignRight: false,
    sx: { minWidth: '180px', position: 'sticky', left: 65, zIndex: 99 },
  },
  // { id: 'borrowedTime', label: 'memberbookborrowing.borrowedTime', alignRight: false, sx:{minWidth: '130px', position: "sticky", left: 185, zIndex: 99}},
  { id: 'memberCode', label: 'memberbookborrowing.memberCode', alignRight: false, sx: { minWidth: '150px' } },
  { id: 'memberName', label: 'memberbookborrowing.memberName', alignRight: false, sx: { minWidth: '150px' } },
  { id: 'barcode', label: 'memberbookborrowing.barcode', alignRight: false, sx: { minWidth: '100px' } },
  { id: 'bookList', label: 'memberbookborrowing.bookList', alignRight: false, sx: { minWidth: '400px' } },
  { id: 'bookCallNumber', label: 'memberbookborrowing.bookCallNumber', alignRight: false, sx: { minWidth: '200px' } },
  { id: 'bookCategory', label: 'memberbookborrowing.bookCategory', alignRight: false, sx: { minWidth: '150px' } },
  { id: 'borrowingType', label: 'memberbookborrowing.borrowingType', alignRight: false, sx: { minWidth: '150px' } },
  { id: 'bookSize', label: 'memberbookborrowing.bookSize', alignRight: false, sx: { minWidth: '150px' } },
  { id: 'channelRemoved', label: 'memberbookborrowing.channelRemoved', alignRight: false, sx: { minWidth: '150px' } },
  // { id: 'returnDate', label: 'returnDate', alignRight: false },
  // { id: 'returnTime', label: 'returnTime', alignRight: false },
  { id: 'location', label: 'memberbookborrowing.location', alignRight: false, sx: { minWidth: '150px' } },
];

const arryFilter = [];
const ConstFildFilter = [
  {
    type: 'select',
    id: 'location',
    label: 'memberbookborrowing.location',
    defaultValue: 'All',
    helperText: '',
    currencies: [
      {
        value: 'All',
        label: 'All',
      },
    ],
  },
  {
    type: 'select',
    id: 'bookCategory',
    label: 'memberbookborrowing.bookCategory',
    defaultValue: 'All',
    helperText: '',
    currencies: [
      {
        value: 'All',
        label: 'All',
      },
    ],
  },
  {
    type: 'select',
    id: 'borrowingType',
    label: 'memberbookborrowing.borrowingType',
    defaultValue: 'All',
    helperText: '',
    currencies: [
      {
        value: 'All',
        label: 'All',
      },
    ],
  },
  {
    type: 'input',
    id: 'memberName',
    label: 'memberbookborrowing.memberName',
    defaultValue: '',
    helperText: '',
    currencies: [],
  },
];
// ----------------------------------------------------------------------

function showDate(date) {
  if (date === undefined) return '--/--/---- --:--:--';
  if (date === null) return '--/--/---- --:--:--';
  if (date === '') return '--/--/---- --:--:--';
  if (date === '-') return '--/--/---- --:--:--';
  return format(new Date(date), 'dd/MM/yyyy HH:mm:ss');
}

function showDateExcel(date) {
  if (date === undefined) return '--/--/---- --:--:--';
  if (date === null) return '--/--/---- --:--:--';
  if (date === '') return '--/--/---- --:--:--';
  if (date === '-') return '--/--/---- --:--:--';
  return format(new Date(date), 'dd/MM/yyyy HH:mm:ss');
}

function showTime(date) {
  if (date === undefined) return '--:--:--';
  if (date === null) return '--:--:--';
  if (date === '') return '--:--:--';
  if (date === '-') return '--:--:--';
  return format(new Date(date), 'HH:mm:ss');
}

function fileNameDate(date) {
  if (date === undefined) return '';
  if (date === null) return '';
  if (date === '') return '';
  if (date === '-') return '';
  return format(new Date(date), 'dd_MM_yyyy');
}

function fileNameDates(date) {
  if (date === undefined) return '';
  if (date === null) return '';
  if (date === '') return '';
  if (date === '-') return '';
  return format(new Date(date), 'dd_MM_yyyy_HH_mm_ss');
}

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  // console.log('order, orderBy ->',order, orderBy);
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
  // console.log('comparator, query ->',comparator, query);
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  if (query) {
    return filter(array, (_user) => _user.memberName.toLowerCase().indexOf(query.toLowerCase()) !== -1);
  }
  return stabilizedThis.map((el) => el[0]);
}

const fetchUserData = (dataIn, fn) => {
  try {
    console.log('dataIn ->', dataIn);
    fetch(dataIn.url, {
      method: dataIn.method,
      body: JSON.stringify(dataIn.body),
      headers: {
        'Content-type': 'application/json; charset=UTF-8',
      },
    })
      .then((response) => {
        return response.json();
      })
      .then((dataRes) => {
        console.log('dataRes ->', dataRes);
        fn(dataRes.error, dataRes.data);
      })
      .catch((err) => {
        console.log('catch Error ->', err);
        fn(err, null);
      });
  } catch (e) {
    console.log('try Error -> ', e);
    fn(e, null);
  }
};

const getAll = (dataIn, fn) => {
  try {
    console.log('dataIn ->', dataIn);
    fetch(dataIn.url, {
      method: 'GET',
    })
      .then((response) => {
        return response.json();
      })
      .then((dataRes) => {
        console.log('dataRes ->', dataRes);
        fn(null, dataRes);
      })
      .catch((err) => {
        console.log('catch Error ->', err);
        fn(err, null);
      });
  } catch (e) {
    console.log('try Error -> ', e);
    fn(e, null);
  }
};

const getbyTime = (dataIn, fn) => {
  try {
    // console.log('getbyTime dataIn ->', dataIn);
    const url = dataIn.url.concat('__start=', dataIn.startDate, '&__end=', dataIn.endDate);
    // console.log('getbyTime url ->', url);
    fetch(url, {
      method: 'GET',
    })
      .then((response) => {
        return response.json();
      })
      .then((dataRes) => {
        // console.log('getbyTime dataRes.length ->', dataRes.length);
        if (Array.isArray(dataRes)) {
          fn(null, dataRes);
        } else {
          fn(null, []);
        }
      })
      .catch((err) => {
        console.log('catch Error ->', err);
        fn(err, null);
      });
  } catch (e) {
    console.log('try Error -> ', e);
    fn(e, null);
  }
};

const getbyId = (dataIn, fn) => {
  try {
    console.log('dataIn ->', dataIn);
    const url = dataIn.url.concat(dataIn.id);
    console.log('url ->', url);
    fetch(url, {
      method: 'GET',
    })
      .then((response) => {
        return response.json();
      })
      .then((dataRes) => {
        console.log('dataRes ->', dataRes);
        fn(null, dataRes);
      })
      .catch((err) => {
        console.log('catch Error ->', err);
        fn(err, null);
      });
  } catch (e) {
    console.log('try Error -> ', e);
    fn(e, null);
  }
};

let nowSearchBy = '';

const config = window.config;
const showFirstButtonConf = true;
const showLastButtonConf = true;

const timeout = 1000;

let startDate = new Date();

let endDate = new Date();

let dataSort = [];

export default function UserPage() {
  const [open, setOpen] = useState(null);

  const [page, setPage] = useState(0);

  const [order, setOrder] = useState('asc');

  const [selected, setSelected] = useState([]);

  const [orderBy, setOrderBy] = useState('id');

  const [filterName, setFilterName] = useState('');

  const [rowsPerPage, setRowsPerPage] = useState(10);

  const [dataResAPI, setDataResAPI] = useState([]);

  const [dataList, setDataList] = useState([]);

  // const [startDate, setStartDate] = useState(new Date());

  // const [endDate, setEndDate] = useState(new Date());

  const [dataExport, setDataExport] = useState([]);

  const [fileName, setFfileName] = useState('Export');

  const [colWidths, setColWidths] = useState([
    { wch: 5 },
    { wch: 20 },
    { wch: 20 },
    { wch: 20 },
    { wch: 20 },
    { wch: 20 },
  ]);

  const [xaxis, setXaxis] = useState([
    'ม.ค.',
    'ก.พ.',
    'มี.ค.',
    'เม.ย.',
    'พ.ค.',
    'มิ.ย.',
    'ก.ค.',
    'ส.ค.',
    'ก.ย.',
    'ต.ค.',
    'พ.ย.',
    'ธ.ค.',
  ]);

  const [chartData, setChartData] = useState([]);

  const [onShow, setOonShow] = useState(true);

  const [fildFilter, setFildFilter] = useState([]);

  const [dataFilterList, setDataFilterList] = useState([]);

  const [searchBy, setSearchBy] = useState('day');

  const [subheader, setSubheader] = useState('barChart.day');

  const { i18n, t } = useTranslation();

  const criteriaMonthOfyear = (dataIn, tmpChartData) => {
    // criteria ////////////////////////////////////////////////////////////////
    if (dataIn.length) {
      // 1. fetch to day data

      // 2. put criteria to array data
      const putCriteria = [];
      for (let i = 0; i < dataIn.length; i += 1) {
        const data = dataIn[i];
        data.criteria = `c${new Date(data.borrowedDate).getMonth().toString()}`; //  the month (from 0 to 11)
        putCriteria.push(data);
      }
      console.log('1 criteriaMonthOfyear putCriteria =>', putCriteria);

      // 3. group by hour
      const groupCriteria = groupBy(putCriteria, ({ criteria }) => criteria);
      console.log('2 criteriaMonthOfyear groupCriteria =>', groupCriteria);

      // 4. create hours object
      // const tmpChartData = [];
      const tmpChartDataObj = {
        year: 'Year',
        xaxis: [],
        data: [],
      };
      const dataArry = [];
      const criteriaRange = 12; // the month (from 0 to 11)
      for (let i = 0; i < criteriaRange; i += 1) {
        const key = `c${i}`;
        dataArry[i] = groupCriteria[key] ? groupCriteria[key].length : 0;
      }
      const tmpDataObj = {
        name: 'Transection',
        data: dataArry,
      };
      tmpChartDataObj.data.push(tmpDataObj);
      if (i18n.language === 'th') {
        tmpChartDataObj.xaxis = criteriaTh.xaxis.months;
        setXaxis(criteriaTh.xaxis.months);
      } else {
        tmpChartDataObj.xaxis = criteriaEn.xaxis.months;
        setXaxis(criteriaEn.xaxis.months);
      }
      tmpChartData.push(tmpChartDataObj);

      console.log('criteriaMonthOfyear =>', tmpChartDataObj);
      // setChartData(tmpChartData);
    }
  };

  const criteriaDayOfWeek = (dataIn, tmpChartData) => {
    // criteria ////////////////////////////////////////////////////////////////
    if (dataIn.length) {
      // 1. fetch to day data

      // 2. put criteria to array data
      const lDate = new Date();
      console.log('lDate ->', lDate);
      const sDate = new Date();
      const eDate = new Date();
      // sDate.setHour(0);
      // sDate.setMinutes(0);
      // sDate.setSeconds(0);
      // eDate.setHours(23);
      // eDate.setMinutes(59);
      // eDate.setSeconds(59);
      sDate.setDate(lDate.getDate() - (6 - lDate.getDay()));
      eDate.setDate(lDate.getDate() + (6 - lDate.getDay()));

      console.log('2sDate ->', sDate);
      console.log('2eDate ->', eDate);

      const putCriteria = [];
      for (let i = 0; i < dataIn.length; i += 1) {
        const data = dataIn[i];
        const nDate = new Date(data.borrowedDate);
        if (nDate >= sDate && sDate <= eDate) {
          data.criteria = `c${nDate.getDay().toString()}`; // day of the week (from 0 to 6
          putCriteria.push(data);
        }
      }
      console.log('1 criteriaDayOfWeek  putCriteria =>', putCriteria);

      // 3. group by hour
      const groupCriteria = groupBy(putCriteria, ({ criteria }) => criteria);
      console.log('2 criteriaDayOfWeek groupCriteria =>', groupCriteria);

      // 4. create hours object
      // const tmpChartData = [];
      const tmpChartDataObj = {
        year: 'week',
        xaxis: [],
        data: [],
      };
      const dataArry = [];
      const criteriaRange = 7; // day of the week (from 0 to 6
      for (let i = 0; i < criteriaRange; i += 1) {
        const key = `c${i}`;
        dataArry[i] = groupCriteria[key] ? groupCriteria[key].length : 0;
      }
      const tmpDataObj = {
        name: 'Transection',
        data: dataArry,
      };
      tmpChartDataObj.data.push(tmpDataObj);
      // tmpChartDataObj.xaxis = ["อา","จ", "อ", "พ", "พฤ", "ศ", "ส"];
      if (i18n.language === 'th') {
        tmpChartDataObj.xaxis = criteriaTh.xaxis.dayOfWeek;
      } else {
        tmpChartDataObj.xaxis = criteriaEn.xaxis.dayOfMonth;
      }
      tmpChartData.push(tmpChartDataObj);

      console.log('criteriaDayOfWeek =>', tmpChartDataObj);
      // setChartData(tmpChartData);
    }
  };

  const criteriaHourOfDay = (dataIn, tmpChartData) => {
    // criteria ////////////////////////////////////////////////////////////////
    if (dataIn.length) {
      // 1. fetch to day data

      // 2. put criteria to array
      const sDate = new Date();
      const eDate = new Date();
      sDate.setHours(0);
      sDate.setMinutes(0);
      sDate.setSeconds(0);
      eDate.setHours(23);
      eDate.setMinutes(59);
      eDate.setSeconds(59);
      const putCriteria = [];
      for (let i = 0; i < dataIn.length; i += 1) {
        const data = dataIn[i];
        const nDate = new Date(data.borrowedDate);
        if (nDate >= sDate && sDate <= eDate) {
          data.criteria = `c${nDate.getHours().toString()}`; // 1 day (from 0 to 23
          putCriteria.push(data);
        }
      }
      console.log('1 criteriaHourOfDay putCriteria =>', putCriteria);

      // 3. group by hour
      const groupCriteria = groupBy(putCriteria, ({ criteria }) => criteria);
      console.log('2 criteriaHourOfDay groupCriteria =>', groupCriteria);

      // 4. create hours object
      // const tmpChartData = [];
      const tmpChartDataObj = {
        year: 'day',
        xaxis: [],
        data: [],
      };
      const dataArry = [];
      const criteriaRange = 24; // 1 day (from 0 to 23
      for (let i = 0; i < criteriaRange; i += 1) {
        const key = `c${i}`;
        dataArry[i] = groupCriteria[key] ? groupCriteria[key].length : 0;
      }
      const tmpDataObj = {
        name: 'Transection',
        data: dataArry,
      };
      tmpChartDataObj.data.push(tmpDataObj);
      // tmpChartDataObj.xaxis = sumDay;
      if (i18n.language === 'th') {
        tmpChartDataObj.xaxis = criteriaTh.xaxis.hourOfDay;
        setXaxis(criteriaEn.xaxis.hourOfDay);
      } else {
        tmpChartDataObj.xaxis = criteriaEn.xaxis.hourOfDay;
        setXaxis(criteriaEn.xaxis.hourOfDay);
      }

      tmpChartData.push(tmpChartDataObj);

      console.log('criteriaHourOfDay =>', tmpChartDataObj);
    }
  };

  useEffect(() => {
    const sDate = new Date();
    const eDate = new Date();
    sDate.setHours(0);
    sDate.setMinutes(0);
    sDate.setSeconds(0);
    eDate.setHours(23);
    eDate.setMinutes(59);
    eDate.setSeconds(59);
    // setStartDate(sDate)
    // setEndDate(eDate)
    startDate = sDate;
    endDate = eDate;
    setChartData(defaultChartDataObj);
    clearOptionSelect();
    setTimeout(() => {
      refresh();
      setSearchBy('day');
    }, timeout);
  }, []);

  useEffect(() => {
    // console.log('change searchBy')
    // console.log('startDate ->', startDate);
    // console.log('endDate ->', endDate);
    if (searchBy !== nowSearchBy) {
      nowSearchBy = searchBy;
      // refresh();
    }
    switch (searchBy) {
      case 'year':
        setSubheader('barChart.year');
        break;
      case 'quarter':
        setSubheader('barChart.quarter');
        break;
      case 'month':
        setSubheader('barChart.month');
        break;
      case 'week':
        setSubheader('barChart.week');
        break;
      case 'day':
        setSubheader('barChart.day');
        break;
      case 'hours':
        setSubheader('barChart.hours');
        break;
      default:
        setSubheader('barChart.day');
        break;
    }
  }, [searchBy]);
  // },[startDate, endDate, searchBy]);

  // useEffect(() => {
  //   console.log('============dataResAPI ->',dataFilterList.length);
  //   // if (dataResAPI.length) {
  //   //   const tmpChartData = []
  //   //   criteriaMonthOfyear(dataResAPI, tmpChartData);
  //   //   console.log('oooooYYYYY ===> tmpChartData ->',tmpChartData.length);

  //   //   criteriaDayOfWeek(dataResAPI,tmpChartData);
  //   //   console.log('ooooooWWWWWW ===> tmpChartData ->',tmpChartData.length);

  //   //   criteriaHourOfDay(dataResAPI,tmpChartData);
  //   //   console.log('ooooooHHHHH ===> tmpChartData ->',tmpChartData.length);

  //   //   setChartData(tmpChartData);
  //   // }
  //   if(dataFilterList.length) {
  //     const tmpChartData = []
  //     switch (searchBy) {
  //       case 'year':
  //       criteriaMonthOfyear(dataFilterList, tmpChartData);
  //       console.log('oooooYYYYY ===> tmpChartData ->',tmpChartData.length);
  //       setChartData(tmpChartData);
  //       break;
  //       case 'quarter':

  //       break;
  //       case 'month':
  //         criteriaDayOfWeek
  //       break;
  //       case 'day':
  //         criteriaHourOfDay(dataFilterList, tmpChartData);
  //         console.log('ooooooHHHHH ===> tmpChartData ->',tmpChartData.length);
  //         setChartData(tmpChartData);
  //       break;
  //       case 'hours':

  //       break;
  //       default:
  //       break
  //     }
  //   }
  // },[dataFilterList]);

  useEffect(() => {
    const tmpDataList = [];
    const tmpLocation = [];
    const tmpBookCategory = [];
    const tmpBorrowingType = [];

    if (dataResAPI.length) {
      dataResAPI.forEach((dataRow, count) => {
        const tmpObj = {
          id: '',
          borrowedDate: '-',
          borrowedTime: '-',
          memberCode: '-',
          memberName: '-',
          barcode: '-',
          bookList: '-',
          bookCallNumber: '-',
          bookCategory: '-',
          borrowingType: '-',
          bookSize: '-',
          channelRemoved: '-',
          returnDate: '-',
          returnTime: '-',
          location: '-',
        };
        // console.log('dataRow [',count,'] ->',dataRow);
        tmpObj.id = dataRow._id;
        tmpObj.dateTr = dataRow.borrowDate;
        tmpObj.groupCallNo = dataRow.groupCallNo;

        if (dataRow.borrowDate == null) tmpObj.borrowedDate = '-';
        else tmpObj.borrowedDate = dataRow.borrowDate;
        if (dataRow.borrowDate == null) tmpObj.borrowedTime = '-';
        else tmpObj.borrowedTime = dataRow.borrowDate;
        if (dataRow.memberId == null) tmpObj.memberCode = '-';
        else tmpObj.memberCode = dataRow.memberId;
        if (dataRow.memberName == null) tmpObj.memberName = '-';
        else tmpObj.memberName = dataRow.memberName;
        if (dataRow.barcode == null) tmpObj.barcode = '-';
        else tmpObj.barcode = dataRow.barcode;
        if (dataRow.bookName == null) tmpObj.bookList = '-';
        else tmpObj.bookList = dataRow.bookName;
        if (dataRow.bookNumber == null) tmpObj.bookCallNumber = '-';
        else tmpObj.bookCallNumber = dataRow.bookNumber;
        if (dataRow.bookCategory == null) tmpObj.bookCategory = '-';
        else tmpObj.bookCategory = dataRow.bookCategory;
        if (dataRow.borrowType == null) tmpObj.borrowingType = '-';
        else tmpObj.borrowingType = dataRow.borrowType;
        if (dataRow.bookSize == null) tmpObj.bookSize = '-';
        else tmpObj.bookSize = dataRow.bookSize;
        if (dataRow.channel == null) tmpObj.channelRemoved = '-';
        else tmpObj.channelRemoved = dataRow.channel;
        if (dataRow.returnDate == null) tmpObj.returnDate = '-';
        else tmpObj.returnDate = dataRow.returnDate;
        if (dataRow.returnDate == null) tmpObj.returnTime = '-';
        else tmpObj.returnTime = dataRow.returnDate;
        if (dataRow.location == null) tmpObj.location = '-';
        else tmpObj.location = dataRow.location;
        tmpDataList.push(tmpObj);

        // add option filter
        // filter first
        // ===========================
        if (tmpLocation.length) {
          const resultObject = tmpLocation.findIndex((x) => x.value === dataRow.location);
          if (resultObject === -1) {
            const tmpOption = { value: dataRow.location, label: dataRow.location };
            tmpLocation.push(tmpOption);
          }
        } else {
          tmpLocation.push({ value: 'All', label: t('filter.all') });
          const tmpOption = { value: dataRow.location, label: dataRow.location };
          tmpLocation.push(tmpOption);
        }
        // ===========================
        // filter second
        // ===========================
        if (tmpBookCategory.length) {
          const resultObject = tmpBookCategory.findIndex((x) => x.value === dataRow.bookCategory);
          if (resultObject === -1) {
            const tmpOption = { value: dataRow.bookCategory, label: dataRow.bookCategory };
            tmpBookCategory.push(tmpOption);
          }
        } else {
          tmpBookCategory.push({ value: 'All', label: t('filter.all') });
          const tmpOption = { value: dataRow.bookCategory, label: dataRow.bookCategory };
          tmpBookCategory.push(tmpOption);
        }
        // ===========================
        // filter third
        // ===========================
        if (tmpBorrowingType.length) {
          const resultObject = tmpBorrowingType.findIndex((x) => x.value === dataRow.borrowType);
          if (resultObject === -1) {
            const tmpOption = { value: dataRow.borrowType, label: dataRow.borrowType };
            tmpBorrowingType.push(tmpOption);
          }
        } else {
          tmpBorrowingType.push({ value: 'All', label: t('filter.all') });
          const tmpOption = { value: dataRow.borrowType, label: dataRow.borrowType };
          tmpBorrowingType.push(tmpOption);
        }
        // ===========================
      });

      // update option filter
      // =======================================
      ConstFildFilter.forEach((item, index) => {
        // filter first
        // =========================
        if (item.type === 'select') {
          if (item.id === 'location') {
            ConstFildFilter[index].currencies = tmpLocation;
          }
        }
        // ==========================
        // filter second
        // =========================
        if (item.type === 'select') {
          if (item.id === 'bookCategory') {
            ConstFildFilter[index].currencies = tmpBookCategory;
          }
        }
        // ==========================
        // filter 	third
        // =========================
        if (item.type === 'select') {
          if (item.id === 'borrowingType') {
            ConstFildFilter[index].currencies = tmpBorrowingType;
          }
        }
        // ==========================
        // fiter more

        // ==========================
      });

      setFildFilter(ConstFildFilter);
    }

    setDataList(tmpDataList);
    setDataFilterList(tmpDataList);
  }, [dataResAPI]);

  useEffect(() => {
    // console.log('change dataFilterList =>',dataFilterList.length);
    if (dataFilterList.length) {
      const tmpColWidths = [
        { wch: 5 },
        { wch: 10 },
        { wch: 10 },
        { wch: 10 },
        { wch: 20 },
        { wch: 20 },
        { wch: 100 },
        { wch: 30 },
        { wch: 20 },
        { wch: 15 },
        { wch: 15 },
        { wch: 15 },
        { wch: 20 },
      ];
      setColWidths(tmpColWidths);
      const tmpDataList = [];
      const tmpEmty = {
        order: '',
        borrowedDate: '',
        borrowedTime: '',
        memberCode: '',
        memberName: '',
        barcode: '',
        bookList: '',
        bookCallNumber: '',
        bookCategory: '',
        borrowingType: '',
        bookSize: '',
        channelRemoved: '',
        location: '',
      };
      tmpDataList.push(tmpEmty);
      const reportName = JSON.parse(JSON.stringify(tmpEmty));
      reportName.bookList = t('memberbookborrowing.page.title');
      setFfileName(`${t('memberbookborrowing.page.title')}_${fileNameDate(new Date())}`);
      const criteria = JSON.parse(JSON.stringify(tmpEmty));
      criteria.bookList = `${t('filter.between')} ${showDateExcel(startDate)} - ${showDateExcel(endDate)}`;

      const tmpHeader = JSON.parse(JSON.stringify(tmpEmty));
      tmpHeader.order = t('memberbookborrowing.order');
      tmpHeader.borrowedDate = t('memberbookborrowing.borrowedDate');
      tmpHeader.borrowedTime = t('memberbookborrowing.borrowedTime');
      tmpHeader.memberCode = t('memberbookborrowing.memberCode');
      tmpHeader.memberName = t('memberbookborrowing.memberName');
      tmpHeader.barcode = t('memberbookborrowing.barcode');
      tmpHeader.bookList = t('memberbookborrowing.bookList');
      tmpHeader.bookCallNumber = t('memberbookborrowing.bookCallNumber');
      tmpHeader.bookCategory = t('memberbookborrowing.bookCategory');
      tmpHeader.borrowingType = t('memberbookborrowing.borrowingType');
      tmpHeader.bookSize = t('memberbookborrowing.bookSize');
      tmpHeader.channelRemoved = t('memberbookborrowing.channelRemoved');
      tmpHeader.location = t('memberbookborrowing.location');

      tmpDataList.push(reportName);
      tmpDataList.push(criteria);
      tmpDataList.push(tmpEmty);
      tmpDataList.push(tmpHeader);

      dataFilterList.forEach((dataRow, count) => {
        const tmpObj = {
          order: count + 1,
          borrowedDate: showDateExcel(dataRow.borrowedDate),
          borrowedTime: showTime(dataRow.borrowedTime),
          memberCode: dataRow.memberCode,
          memberName: dataRow.memberName,
          barcode: dataRow.barcode,
          bookList: dataRow.bookList,
          bookCallNumber: dataRow.bookCallNumber,
          bookCategory: dataRow.bookCategory,
          borrowingType: dataRow.borrowingType,
          bookSize: dataRow.bookSize,
          channelRemoved: dataRow.channelRemoved,
          location: dataRow.location,
        };
        tmpDataList.push(tmpObj);
      });
      setDataExport(tmpDataList);
    }
  }, [dataFilterList]);

  const genDataExport = (callback) => {
    // console.log('change dataSort =>',dataSort.length);
    if (dataSort.length) {
      const tmpColWidths = [
        { wch: 5 },
        { wch: 20 },
        // { wch: 10 },
        { wch: 10 },
        { wch: 20 },
        { wch: 20 },
        { wch: 100 },
        { wch: 30 },
        { wch: 20 },
        { wch: 15 },
        { wch: 15 },
        { wch: 15 },
        { wch: 20 },
      ];
      // setColWidths(tmpColWidths);
      const tmpDataList = [];
      const tmpEmty = {
        order: '',
        borrowedDate: '',
        // borrowedTime: '',
        memberCode: '',
        memberName: '',
        barcode: '',
        bookList: '',
        bookCallNumber: '',
        bookCategory: '',
        borrowingType: '',
        bookSize: '',
        channelRemoved: '',
        location: '',
      };
      tmpDataList.push(tmpEmty);
      const reportName = JSON.parse(JSON.stringify(tmpEmty));
      reportName.bookList = t('memberbookborrowing.page.title');
      // setFfileName(`${t('memberbookborrowing.page.title')}_${fileNameDate(new Date())}`);
      const criteria = JSON.parse(JSON.stringify(tmpEmty));
      criteria.bookList = `${t('filter.between')} ${showDateExcel(startDate)} - ${showDateExcel(endDate)}`;

      const tmpHeader = JSON.parse(JSON.stringify(tmpEmty));
      tmpHeader.order = t('memberbookborrowing.order');
      tmpHeader.borrowedDate = t('memberbookborrowing.borrowedDate');
      // tmpHeader.borrowedTime =  t('memberbookborrowing.borrowedTime');
      tmpHeader.memberCode = t('memberbookborrowing.memberCode');
      tmpHeader.memberName = t('memberbookborrowing.memberName');
      tmpHeader.barcode = t('memberbookborrowing.barcode');
      tmpHeader.bookList = t('memberbookborrowing.bookList');
      tmpHeader.bookCallNumber = t('memberbookborrowing.bookCallNumber');
      tmpHeader.bookCategory = t('memberbookborrowing.bookCategory');
      tmpHeader.borrowingType = t('memberbookborrowing.borrowingType');
      tmpHeader.bookSize = t('memberbookborrowing.bookSize');
      tmpHeader.channelRemoved = t('memberbookborrowing.channelRemoved');
      tmpHeader.location = t('memberbookborrowing.location');

      tmpDataList.push(reportName);
      tmpDataList.push(criteria);
      tmpDataList.push(tmpEmty);
      tmpDataList.push(tmpHeader);

      dataSort.forEach((dataRow, count) => {
        const tmpObj = {
          order: count + 1,
          borrowedDate: showDateExcel(dataRow.borrowedDate),
          // borrowedTime: showTime(dataRow.borrowedTime),
          memberCode: dataRow.memberCode,
          memberName: dataRow.memberName,
          barcode: dataRow.barcode,
          bookList: dataRow.bookList,
          bookCallNumber: dataRow.bookCallNumber,
          bookCategory: dataRow.bookCategory,
          borrowingType: dataRow.borrowingType,
          bookSize: dataRow.bookSize,
          channelRemoved: dataRow.channelRemoved,
          location: dataRow.location,
        };
        tmpDataList.push(tmpObj);
      });
      // setDataExport(tmpDataList);
      const fName = `${t('memberbookborrowing.page.title')}_${fileNameDates(new Date())}`;
      callback(tmpDataList, tmpColWidths, fName);
    } else {
      callback([], [], '');
    }
  };

  // useEffect(() => {
  //   console.log('dataList =>',dataList)
  // },[dataList])

  const searchApi = ({ sDate, eDate, val }) => {
    const data = {
      url: 'http://localhost:3001/api/borrow/',
      body: {},
    };

    // startDate.setHours(0);
    // startDate.setMinutes(0);
    // startDate.setSeconds(0);
    // endDate.setHours(23);
    // endDate.setMinutes(59);
    // endDate.setSeconds(59);

    // data.url = 'http://localhost:3001/api/borrow/range?notPickedUp=false&'
    data.url = `${config.backendUrl}/api/borrow/range?notPickedUp=false&`;
    data.startDate = new Date(sDate).toISOString();
    data.endDate = new Date(eDate).toISOString();

    getbyTime(data, (err, fn) => {
      // console.log('err, fn ->', err, fn.length);
      if (config.production) {
        if (err === null) {
          // console.log('getbyTime ->', fn.length);
          setDataResAPI(fn);
        }
      } else {
        setDataResAPI(LOGLIST);
      }
    });
  };

  const refresh = (search) => {
    const data = {
      url: 'http://localhost:3001/api/borrow/',
      body: {},
    };

    // startDate.setHours(0);
    // startDate.setMinutes(0);
    // startDate.setSeconds(0);
    // endDate.setHours(23);
    // endDate.setMinutes(59);
    // endDate.setSeconds(59);

    // data.url = 'http://localhost:3001/api/borrow/range?notPickedUp=false&'
    data.url = `${config.backendUrl}/api/borrow/range?notPickedUp=false&`;
    data.startDate = new Date(startDate).toISOString();
    data.endDate = new Date(endDate).toISOString();

    getbyTime(data, (err, fn) => {
      // console.log('err, fn ->', err, fn.length);
      if (config.production) {
        if (err === null) {
          // console.log('getbyTime ->', fn.length);
          setDataResAPI(fn);
        }
      } else {
        setDataResAPI(LOGLIST);
      }
    });
  };

  const handleCallbackValue = (dataIn) => {
    // console.log('onCallbackValue ->', dataIn);
    const tmpStartDate = new Date(dataIn[0].$d);
    // setStartDate(tmpStartDate);
    startDate = tmpStartDate;
    const tmpEndDate = new Date(dataIn[1].$d);
    // setEndDate(tmpEndDate);
    endDate = tmpEndDate;
  };

  const handleSearchClick = (event) => {
    // console.log('handleSearchClick event->', event);
    clearOptionSelect();
    const sDate = new Date(startDate);
    const eDate = new Date(endDate);
    const tDiff = eDate - sDate;
    const timePerDay = 24 * 60 * 60 * 1000;

    const tQuarter = timePerDay * 90;
    const tMonth = timePerDay * 30;
    const tWeek = timePerDay * 7;
    const tDay = timePerDay * 1;
    const tHours = 60 * 60 * 1000;
    if (tDiff > tQuarter) {
      setSearchBy('year');
    } else if (tDiff > tMonth) {
      setSearchBy('quarter');
    } else if (tDiff > tWeek) {
      setSearchBy('month');
    } else if (tDiff > tDay) {
      setSearchBy('week');
    } else if (tDiff > tHours) {
      setSearchBy('day');
    } else {
      setSearchBy('hours');
    }
    refresh(event);
  };
  const clearOptionSelect = () => {
    // console.log('=============clear')
    // clear option filter
    // =======================================
    ConstFildFilter.forEach((item, index) => {
      if (item.type === 'select') {
        ConstFildFilter[index].currencies = [{ value: 'All', label: t('filter.all') }];
        ConstFildFilter[index].value = 'All';
      }
      if (item.type === 'input') {
        item.value = '';
      }
    });

    setFildFilter(ConstFildFilter);
  };

  const onSearchByCallback = ({ sDate, eDate, val }) => {
    // console.log('onSearchByCallback val ->',val);
    // console.log('startDate ->', sDate);
    // console.log('endDate ->', eDate);
    clearOptionSelect();
    setSearchBy(val);
    searchApi({ sDate, eDate, val });
    // refresh();
  };
  const handleOpenMenu = (event) => {
    setOpen(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setOpen(null);
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = dataList.map((n) => n.name);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  // filter HandleChange
  // ===================================
  const onHandleChangeFild = (even) => {
    // console.log('onHandleChangeFild ->',even);
    const filterIndex = arryFilter.findIndex((x) => x.id === even.item.id);
    // console.log('filterIndex ->',filterIndex);
    if (filterIndex === -1) {
      if (even.item.id !== undefined && even.val.target.value !== undefined) {
        const tmpObj = { id: even.item.id, value: even.val.target.value };
        arryFilter.push(tmpObj);
      }
    } else if (even.val.target.value !== undefined) {
      arryFilter[filterIndex].value = even.val.target.value;
    }
    // console.log('arryFilter ->',arryFilter)
    ConstFildFilter.forEach((item, index) => {
      if (item.id === even.item.id) {
        ConstFildFilter[index].value = even.val.target.value;
      }
    });
    setFildFilter(ConstFildFilter);

    setPage(0);
    let tmpFilter = dataList;
    let xFilter = [];
    if (arryFilter.length) {
      // console.log('1tmpFilter length ->', tmpFilter.length)
      arryFilter.forEach((query) => {
        // console.log('query ->', query)
        if (query.value !== 'All') {
          xFilter = filter(
            tmpFilter,
            (_user) => _user[query.id].toLowerCase().indexOf(query.value.toLowerCase()) !== -1
          );
          tmpFilter = xFilter;
        }
      });
      // console.log('2tmpFilter length ->',tmpFilter.length)
    }
    setDataFilterList(tmpFilter);
  };

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1));
    }
    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setPage(0);
    setRowsPerPage(parseInt(event.target.value, 10));
  };

  const handleFilterByName = (event) => {
    setPage(0);
    setFilterName(event.target.value);
  };

  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - dataFilterList.length) : 0;

  const filteredUsers = applySortFilter(dataFilterList, getComparator(order, orderBy), filterName);

  // console.log('filteredUsers ->',filteredUsers)

  dataSort = filteredUsers;

  const isNotFound = !filteredUsers.length && !!filterName;

  // console.log('LOGLIST ->',LOGLIST)

  return (
    <>
      <Helmet>
        <title> {t('memberbookborrowing.page.title')} | Minimal UI </title>
      </Helmet>

      <Container maxWidth="xl">
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            {t('memberbookborrowing.page.title')}
          </Typography>
        </Stack>
        {/* <Card sx={{
          marginBottom: '2rem'
        }}> */}
        {/* <Grid item xs={12} md={8}>
            <ChartReports 
              isShow={onShow}
              title={t('memberbookborrowing.page.title')}
              subheader={''}
              xaxis={xaxis}
              chartData={chartData}
            />
          </Grid> */}

        <Grid container spacing={3}>
          <Grid item xs={12} md={12} lg={12}>
            <ChartBarReportNew
              isShow={onShow}
              title={t('memberbookborrowing.page.borrowTitle')}
              subheader={t(subheader)}
              xaxis={xaxis}
              chartData={chartData}
              rowDataChart={filteredUsers}
              searchBy={searchBy}
              startDate={startDate}
              endDate={endDate}
            />
          </Grid>

          <Grid item xs={12} md={12} lg={12}>
            <Grid container spacing={3}>
              <Grid item xs={12} md={6} lg={6}>
                {/* <ChartAreaReports */}
                <ChartDonutReports
                  isShow={onShow}
                  title={t('memberbookborrowing.page.categoryTitle')}
                  subheader={t(subheader)}
                  xaxis={xaxis}
                  chartData={chartData}
                  rowDataChart={filteredUsers}
                  searchBy={searchBy}
                />
              </Grid>

              <Grid item xs={12} md={6} lg={6}>
                <ChartPieReports
                  isShow={onShow}
                  title={t('memberbookborrowing.page.callNoTitle')}
                  subheader={t(subheader)}
                  xaxis={xaxis}
                  chartData={chartData}
                  rowDataChart={filteredUsers}
                  searchBy={searchBy}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        {/* </Card> */}

        <Card
          sx={{
            marginTop: '2rem',
            marginBottom: '2rem',
          }}
        >
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12} md={12}>
              {/* <DateFieldFilter
                startDate={new Date().toISOString()}
                endDate={new Date().toISOString()}
                onCallbackValue={handleCallbackValue}
                onSearchClick={handleSearchClick}
                fildFilter={fildFilter}
                onHandleChange={onHandleChangeFild}
              /> */}
              <DateTimeFieldFilter
                startDate={new Date(startDate).toISOString()}
                endDate={new Date(endDate).toISOString()}
                onCallbackValue={handleCallbackValue}
                onSearchClick={handleSearchClick}
                fildFilter={fildFilter}
                onHandleChange={onHandleChangeFild}
                onSearchByCallback={onSearchByCallback}
              />
            </Grid>
          </Grid>
        </Card>

        {/* <Card>
          <div style={{'margin-top':'1rem','margin-bottom':'1rem','margin-right': '2rem'}}>
            <Grid container spacing={1}>
              <Grid item xs={12} sm={12} md={12}>
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={9} md={10}>
                      <></>
                    </Grid>
                    <Grid item xs={12} sm={1} md={1}>
                      <Button variant="contained" startIcon={<Iconify icon="eva:refresh-fill" />} size="large" onClick={handleSearchClick}/>
                    </Grid>
                    <Grid item xs={12} sm={2} md={1}>
                      <ExportToExcel apiData={dataExport} colWidths={colWidths} fileName={fileName} />
                    </Grid>
                  </Grid>
              </Grid>
            </Grid>
          </div>
        </Card> */}

        <Card>
          <CardActions
            sx={{
              alignSelf: 'stretch',
              display: 'flex',
              justifyContent: 'flex-end',
              alignItems: 'flex-start',
              // 👇 Edit padding to further adjust position
              p: 2,
            }}
          >
            <Button
              variant="contained"
              startIcon={<Iconify icon="eva:refresh-fill" />}
              size="large"
              onClick={handleSearchClick}
            />
            {/* <ExportToExcel apiData={dataExport} colWidths={colWidths} fileName={fileName} /> */}
            <ExportToExcel apiData={dataExport} colWidths={colWidths} fileName={fileName} onCallback={genDataExport} />
          </CardActions>
          {/* <UserListToolbar numSelected={selected.length} filterName={filterName} onFilterName={handleFilterByName} /> */}
          <Scrollbar>
            <TableContainer sx={{ minWidth: 800, maxHeight: 500 }}>
              <Table stickyHeader>
                <UserListHead
                  order={order}
                  orderBy={orderBy}
                  headLabel={TABLE_HEAD}
                  rowCount={dataFilterList.length}
                  numSelected={selected.length}
                  onRequestSort={handleRequestSort}
                  onSelectAllClick={handleSelectAllClick}
                />
                <TableBody>
                  {filteredUsers.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, coun) => {
                    const {
                      id,
                      borrowedDate,
                      borrowedTime,
                      memberCode,
                      memberName,
                      barcode,
                      bookList,
                      bookCallNumber,
                      bookCategory,
                      borrowingType,
                      bookSize,
                      channelRemoved,
                      returnDate,
                      returnTime,
                      location,
                    } = row;
                    const selectedUser = -1;

                    return (
                      <TableRow hover key={id} tabIndex={-1} role="checkbox" selected={selectedUser}>
                        {/* <TableCell padding="checkbox">
                          <Checkbox checked={selectedUser} onChange={(event) => handleClick(event, id)} />
                        </TableCell> */}

                        <TableCell style={{ position: 'sticky', left: 0, background: '#ffe6e6' }} align="center">
                          {' '}
                          {page * rowsPerPage + (coun + 1)}
                        </TableCell>

                        <TableCell style={{ position: 'sticky', left: 65, background: '#ffe6e6' }} align="left">
                          {showDate(borrowedDate)}
                        </TableCell>

                        {/* <TableCell style={{ position: "sticky", left: 185, background: "#ffe6e6"}} align="left">{showTime(borrowedTime)}</TableCell> */}

                        <TableCell align="left">{memberCode}</TableCell>

                        <TableCell align="left">{memberName}</TableCell>

                        <TableCell align="left">{barcode}</TableCell>

                        <TableCell align="left">{bookList}</TableCell>

                        <TableCell align="left">{bookCallNumber}</TableCell>

                        <TableCell align="left">{bookCategory}</TableCell>

                        <TableCell align="left">{borrowingType}</TableCell>

                        <TableCell align="left">{bookSize}</TableCell>

                        <TableCell align="left">{channelRemoved}</TableCell>

                        {/* <TableCell align="left">{showDate(returnDate)}</TableCell>

                        <TableCell align="left">{showTime(returnTime)}</TableCell> */}

                        <TableCell align="left">{location}</TableCell>
                      </TableRow>
                    );
                  })}
                  {emptyRows > 0 && (
                    <TableRow style={{ height: 53 * emptyRows }}>
                      <TableCell colSpan={6} />
                    </TableRow>
                  )}
                </TableBody>

                {isNotFound && (
                  <TableBody>
                    <TableRow>
                      <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                        <Paper
                          sx={{
                            textAlign: 'center',
                          }}
                        >
                          <Typography variant="h6" paragraph>
                            Not found
                          </Typography>

                          <Typography variant="body2">
                            No results found for &nbsp;
                            <strong>&quot;{filterName}&quot;</strong>.
                            <br /> Try checking for typos or using complete words.
                          </Typography>
                        </Paper>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                )}
              </Table>
            </TableContainer>
          </Scrollbar>

          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={dataFilterList.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            showFirstButton={showFirstButtonConf}
            showLastButton={showLastButtonConf}
            labelRowsPerPage={t('branchDetail.Pagination.rowsPerPage')}
          />
        </Card>
      </Container>

      <Popover
        open={Boolean(open)}
        anchorEl={open}
        onClose={handleCloseMenu}
        anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        PaperProps={{
          sx: {
            p: 1,
            width: 140,
            '& .MuiMenuItem-root': {
              px: 1,
              typography: 'body2',
              borderRadius: 0.75,
            },
          },
        }}
      >
        <MenuItem>
          <Iconify icon={'eva:edit-fill'} sx={{ mr: 2 }} />
          Edit
        </MenuItem>

        <MenuItem sx={{ color: 'error.main' }}>
          <Iconify icon={'eva:trash-2-outline'} sx={{ mr: 2 }} />
          Delete
        </MenuItem>
      </Popover>
    </>
  );
}
