import * as Yup from 'yup';
import { Helmet } from 'react-helmet-async';
import { filter } from 'lodash';
import { sentenceCase } from 'change-case';
import { useState, useEffect, useCallback } from 'react';
// import { useSnackbar } from 'notistack5'; // notistack5
import { SnackbarProvider, enqueueSnackbar } from 'notistack';
import { useNavigate, useParams, useLocation } from 'react-router-dom';
import { Form, FormikProvider, useFormik } from 'formik'; //
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
// @mui
import {
  Box,
  Card,
  Grid,
  Table,
  Stack,
  Switch,
  Paper,
  Avatar,
  Button,
  Popover,
  Checkbox,
  TableRow,
  MenuItem,
  TableBody,
  TableCell,
  TextField,
  Container,
  FormLabel,
  FormGroup,
  FormControl,
  Typography,
  IconButton,
  TableContainer,
  TablePagination,
  FormControlLabel,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
// components
import Label from '../components/label';
import Iconify from '../components/iconify';
import Scrollbar from '../components/scrollbar';
// sections
import { UserListHead, UserListToolbar, UserMoreMenu, ConfirmDialog } from '../sections/@dashboard/user';
// mock
import USERLIST from '../_mock/userList';

// ----------------------------------------------------------------------

const config = window.config;

export default function UserCreate({ permission }) {
  if (!permission) permission = { create: false, update: false, delete: false };

  const { i18n, t } = useTranslation();

  const { pathname } = useLocation();
  const isEdit = pathname.includes('edit');
  const { userId } = useParams();
  const [currentUser, setCurrentUser] = useState({});

  // const [isEdit, setIsEdit] = useState(false);

  const navigate = useNavigate();
  // const { enqueueSnackbar } = useSnackbar();

  const NewUserSchema = Yup.object().shape({
    from: Yup.string().email().required('Frome is required'),
    to: Yup.string().email().required('To is required'),
    // enable: Yup.boolean().required('Enable is required'),
    // cc: Yup.string().required('CC is required'),
    subject: Yup.string().required('Subject is required'),
  });

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      from: currentUser?.from || 'appdev@ppetech.com',
      to: currentUser?.to || 'pop4mail@gmail.com',
      enable: currentUser?.enable || true,
      cc: currentUser?.cc || '',
      subject: currentUser?.subject || 'Vending out of service',
    },

    validationSchema: NewUserSchema,
    onSubmit: async (values, { setSubmitting, resetForm, setErrors }) => {
      // console.log('onSubmit values ->', values);

      try {
        const apiEditUsers = () => {
          // const _id = userForDelete._id;
          const url = `${config.backendUrl}/api/setting/${currentUser._id}`;
          try {
            // console.log('apiEditUsers: url ->', url);
            const valuesDup = JSON.parse(JSON.stringify(values));
            valuesDup.mailOptions = {
              from: valuesDup.from,
              to: valuesDup.to,
              cc: valuesDup.cc,
              subject: valuesDup.subject,
            };
            delete valuesDup.from;
            delete valuesDup.to;
            delete valuesDup.cc;
            delete valuesDup.subject;

            const update = {
              value: valuesDup,
            };
            // console.log('1 onSubmit update ->', update);
            fetch(url, {
              method: 'PUT',
              headers: { 'Content-Type': 'application/json' },
              body: JSON.stringify(update),
            })
              .then((response) => {
                return response.json();
              })
              .then((dataRes) => {
                // console.log(`apiEditUsers: dataRes ->`, dataRes);
                if (dataRes.error) {
                  const error = dataRes.error.message;
                  setSubmitting(false);
                  enqueueSnackbar(`Update Error: ${error}`, {
                    autoHideDuration: 2000,
                    variant: 'error',
                    persist: false,
                  });
                  return;
                }
                // resetForm();
                // console.log('1 onSubmit values ->', values);
                setCurrentUser({ ...values, _id: currentUser._id });

                setSubmitting(false);
                enqueueSnackbar(`Update success`, {
                  autoHideDuration: 2000,
                  variant: 'success',
                  persist: false,
                });
                navigate('/setting/issue-email');
              })
              .catch((err) => {
                console.error('apiEditUsers: catch Error ->', err);
                enqueueSnackbar('Update Error', { variant: 'error' });
              });
          } catch (e) {
            console.error('apiEditUsers: try Error -> ', e);
          }
        };

        apiEditUsers();

        // resetForm();
        // setSubmitting(false);
        // enqueueSnackbar(!isEdit ? 'Create success' : 'Update success', { variant: 'success' });
        // navigate(PATH_DASHBOARD.user.list);
      } catch (error) {
        console.error(error);
        setSubmitting(false);
        setErrors(error);
      }
    },
  });

  const { errors, values, touched, handleSubmit, isSubmitting, getFieldProps, setFieldValue } = formik;

  const handleChange = (event) => {
    if (event.target.name === 'isView') setFieldValue('isView', event.target.checked);
  };

  const handleRestore = () => {
    setFieldValue('from', currentUser.from);
    setFieldValue('to', currentUser.to);
    setFieldValue('cc', currentUser.cc);
    setFieldValue('subject', currentUser.subject);
    setFieldValue('enable', currentUser.enable);
  };

  useEffect(() => {
    // console.log('UserCreate: useEffect ');

    const apiGetUser = () => {
      // const _id = userForDelete._id;

      const url = `${config.backendUrl}/api/setting/range?name=IssueSendEmail`;
      try {
        // console.log('apiGetUser: url ->', url);

        fetch(url, {
          method: 'GET',
          // headers: { 'Content-Type': 'application/json' },
          // body: JSON.stringify(values),
        })
          .then((response) => {
            return response.json();
          })
          .then((dataRes) => {
            // console.log(`apiGetUser: dataRes ->`, dataRes);
            if (dataRes.error) {
              const error = dataRes.error.message;
            } else if (!dataRes.length) {
              enqueueSnackbar('Update Error, Setting not found', { variant: 'error' });
            } else {
              setCurrentUser({
                ...dataRes[0].value,
                _id: dataRes[0]._id,
                enable: dataRes[0].value.enable,
                from: dataRes[0].value.mailOptions.from,
                to: dataRes[0].value.mailOptions.to,
                cc: dataRes[0].value.mailOptions.cc,
                subject: dataRes[0].value.mailOptions.subject,
              });
            }
          })
          .catch((err) => {
            console.error('apiGetUser: catch Error ->', err);
            enqueueSnackbar('Update Error', { variant: 'error' });
          });
      } catch (e) {
        console.error('apiGetUser: try Error -> ', e);
      }
    };
    apiGetUser();
  }, []);

  useEffect(() => {
    // console.log('useEffect currentUser ->', currentUser);
    formik.setFieldValue('from', currentUser?.from);
    formik.setFieldValue('to', currentUser?.to);
    formik.setFieldValue('cc', currentUser?.cc);
    formik.setFieldValue('subject', currentUser?.subject);
    formik.setFieldValue('enable', currentUser?.enable);
  }, [currentUser]);

  return (
    <>
      <Helmet>
        <title>
          {t('settingIssueEmail.title')} | {t('title')}
        </title>
      </Helmet>

      <Container maxWidth="xl">
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            {t('settingIssueEmail.title')}
          </Typography>
        </Stack>

        <FormikProvider value={formik}>
          {/* {console.log('formik ->', values)} */}
          <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
            <Grid container spacing={3}>
              <Grid item xs={12} md={8}>
                <Card sx={{ p: 3 }}>
                  <Stack spacing={3}>
                    <Stack direction={{ xs: 'column', sm: 'row' }} spacing={{ xs: 3, sm: 2 }}>
                      <TextField
                        fullWidth
                        // disabled={isEdit}
                        label={t('settingIssueEmail.from')}
                        {...getFieldProps('from')}
                        error={Boolean(touched.from && errors.from)}
                        helperText={touched.from && errors.from}
                      />
                      <TextField
                        fullWidth
                        label={t('settingIssueEmail.to')}
                        {...getFieldProps('to')}
                        error={Boolean(touched.to && errors.to)}
                        helperText={touched.to && errors.to}
                      />
                    </Stack>
                    <Stack direction={{ xs: 'column', sm: 'row' }} spacing={{ xs: 3, sm: 2 }}>
                      <TextField
                        fullWidth
                        label={t('settingIssueEmail.subject')}
                        {...getFieldProps('subject')}
                        error={Boolean(touched.subject && errors.subject)}
                        helperText={touched.subject && errors.subject}
                      />
                      <TextField
                        fullWidth
                        label={t('settingIssueEmail.cc')}
                        {...getFieldProps('cc')}
                        error={Boolean(touched.cc && errors.cc)}
                        helperText={touched.cc && errors.cc}
                      />
                    </Stack>

                    <Typography variant="subtitle2" sx={{ mb: 0 }}>
                      {t('settingIssueEmail.enable')}{' '}
                      {<Switch {...getFieldProps('enable')} checked={values.enable} />}
                    </Typography>

                    <Box sx={{ mt: 3, display: 'flex', justifyContent: 'flex-end' }}>
                      <Button variant="outlined" color="primary" onClick={handleRestore} sx={{ mr: 2 }}>
                        {t('settingIssueEmail.btRestore')}
                      </Button>
                      <LoadingButton
                        disabled={!permission.update}
                        type="submit"
                        variant="contained"
                        loading={isSubmitting}
                      >
                        {t('settingIssueEmail.btSave')}
                      </LoadingButton>
                    </Box>
                  </Stack>
                  <SnackbarProvider
                    anchorOrigin={{
                      vertical: 'bottom',
                      horizontal: 'center',
                    }}
                  />
                </Card>
              </Grid>
            </Grid>
          </Form>
        </FormikProvider>
      </Container>
    </>
  );
}
