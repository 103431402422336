// import { merge } from 'lodash';
import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import ReactApexChart from 'react-apexcharts';
import PropTypes  from 'prop-types';
import { filter, find, groupBy } from 'lodash';
// material
import { Card, CardHeader, Box, TextField } from '@mui/material';
//
// import { BaseOptionChart } from '../../charts';
import { useChart } from '../chart';

// ----------------------------------------------------------------------

const CHART_DATA = [
  {
    year: 'Week',
    data: [
      { name: 'Check In', data: [10, 41, 35, 151, 49, 62, 69, 91, 48] },
      { name: 'Check Out', data: [10, 34, 13, 56, 77, 88, 99, 77, 45] }
    ]
  },
  {
    year: 'Month',
    data: [
      { name: 'Check In', data: [148, 91, 69, 62, 49, 51, 35, 41, 10] },
      { name: 'Check Out', data: [45, 77, 99, 88, 77, 56, 13, 34, 10] }
    ]
  },
  {
    year: 'Year',
    data: [
      { name: 'Check In', data: [76, 42, 29, 41, 27, 138, 117, 86, 63] },
      { name: 'Check Out', data: [80, 55, 34, 114, 80, 130, 15, 28, 55] }
    ]
  }
];

const criteriaTh = {
  "xaxis": {
    "year": [ "ม.ค.", "ก.พ.", "มี.ค.", "เม.ย.", "พ.ค.", "มิ.ย.", "ก.ค.", "ส.ค.", "ก.ย.", "ต.ค.", "พ.ย.", "ธ.ค."],
    "quarter": ["Q1", "Q2", "Q3", "Q4"],    
    "month": ["1", "2", "3", "4", "5", "6", "7", "8", "9", "10", "11", "12", "13", "14", "15", "16", "17", "18", "19", "20", "21", "22", "23", "24", "25", "26", "27", "28", "29", "30", "31"],
    "day": ["00", "01", "02", "03", "04", "05", "06", "07", "08", "09", "10", "11", "12", "13", "14", "15", "16", "17", "18", "19", "20", "21", "22", "23"],
    "week": ["อา","จ", "อ", "พ", "พฤ", "ศ", "ส"],
    "hours": ["05", "10", "15", "20", "25", "30", "35", "40", "45", "50", "55", "60"]
  }
}

const criteriaEn = {
  "xaxis": {
    "year": ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep","Oct", "Nov", "Dec"],
    "quarter": ["Q1", "Q2", "Q3", "Q4"],
    "month": ["1", "2", "3", "4", "5", "6", "7", "8", "9", "10", "11", "12", "13", "14", "15", "16", "17", "18", "19", "20", "21", "22", "23", "24", "25", "26", "27", "28", "29", "30", "31"],
    "day": ["00", "01", "02", "03", "04", "05", "06", "07", "08", "09", "10", "11", "12", "13", "14", "15", "16", "17", "18", "19", "20", "21", "22", "23"],
    "week": ["Sun","Mon", "Tue", "Wed", "Thu", "Tri", "Sat"],
    "hours": ["05", "10", "15", "20", "25", "30", "35", "40", "45", "50", "55", "60"]
  }
}

const groupCallNoConst = [
  {
    "no" : "c0",
    "category" : "เรื่องราวรอบตัว",
    "groupCallNo" : '0xx'
  },
  {
    "no" : "c1",
    "category" : "จิตวิทยาพัฒนาตนเอง",
    "groupCallNo" : '1xx'
  },
  {
    "no" : "c2",
    "category" : "ศาสนา",
    "groupCallNo" : '2xx'
  },
  {
    "no" : "c3",
    "category" : "สังคม",
    "groupCallNo" : '3xx'
  },
  {
    "no" : "c4",
    "category" : "ภาษาศาสตร์",
    "groupCallNo" : '4xx'
  },
  {
    "no" : "c5",
    "category" : "วิทยาศาสตร์",
    "groupCallNo" : '5xx'
  },
  {
    "no" : "c6",
    "category" : "สุขภาพ / อาหาร / ธุรกิจ",
    "groupCallNo" : '6xx'
  },
  {
    "no" : "c7",
    "category" : "ศิลปะ",
    "groupCallNo" : '7xx'
  },
  {
    "no" : "c8",
    "category" : "วรรณคดี",
    "groupCallNo" : '8xx'
  },
  {
    "no" : "c9",
    "category" : "ประวัติศาสตร์ ท่องเที่ยว",
    "groupCallNo" : '9xx'
  },
  {
    "no" : "c10",
    "category" : "หนังสือเด็กไทย",
    "groupCallNo" : 'ยว'
  },
  {
    "no" : "่c11",
    "category" : "หนังสือเด็กอังกฤษ",
    "groupCallNo" : 'J'
  },
  {
    "no" : "c12",
    "category" : "นวนิยายไทย",
    "groupCallNo" : 'นว'
  },
  {
    "no" : "c13",
    "category" : "เรื่องแปล",
    "groupCallNo" : 'รป'
  },
  {
    "no" : "c14",
    "category" : "เรื่องสั้น",
    "groupCallNo" : 'รส'
  },
  {
    "no" : "c15",
    "category" : "นวนิยายอังกฤษ",
    "groupCallNo" : 'Fic'
  },
  {
    "no" : "c16",
    "category" : "การ์ตูนอังกฤษ",
    "groupCallNo" : 'C'
  },
  {
    "no" : "c17",
    "category" : "การ์ตูนไทย",
    "groupCallNo" : 'ก'
  },
  {
    "no" : "c18",
    "category" : "-",
    "groupCallNo" : '-'
  }

]

// let xaxis = ["00", "01", "02", "03", "04", "05", "06", "07", "08", "09", "10", "11", "12", "13", "14", "15", "16", "17", "18", "19", "20", "21", "22", "23"];

let xaxis = [];

ChartPieReports.propTypes = {
  isShow: PropTypes.bool,
  title: PropTypes.string,
  subheader: PropTypes.string,
  chartData: PropTypes.array,
  rowDataChart: PropTypes.array,
  searchBy: PropTypes.string
}

export default function ChartPieReports({
  isShow,
  title,
  subheader,
  chartData,
  rowDataChart,
  searchBy
}) {
  const [seriesData, setSeriesData] = useState('');

  const [dataChart, setDataChart] = useState(null);

  const [optionChart, setOptionChart] = useState();

  // const [xaxis, setXaxis] = useState(["ม.ค.", "ก.พ.", "มี.ค.", "เม.ย.", "พ.ค.", "มิ.ย.", "ก.ค.", "ส.ค.", "ก.ย.", "ต.ค.", "พ.ย.", "ธ.ค."])

  const { i18n, t } = useTranslation();

  useEffect(() => {
    setSeriesData(searchBy);
  },[]);

  useEffect(() => {
    setOptionChart(chartOptions);
    genDataChart(rowDataChart);
    // setOptionChart(chartOptions);
  },[rowDataChart]);

  useEffect(() => {
 
  },[seriesData]);

  const handleChangeSeriesData = (event) => {
    setSeriesData(event.target.value);
  };

  const chartOptions = useChart({
    stroke: {
      show: true,
      width: 2,
      colors: ['transparent']
    },
    chart: {
      width: 380,
      type: 'pie',
    },
    labels: xaxis,
    responsive: [{
      breakpoint: 480,
      options: {
        chart: {
          width: 200
        },
        legend: {
          position: 'bottom'
        }
      }
    }],
    dataLabels : {
      enabled: true,
    }
    // dataLabels: {
    //   enabled: true,
    //   offsetX: 0,
    //   offsetY: 0,
    //   total: {
    //     enabled: true,
    //     style: {
    //       fontSize: '12px',
    //       colors: ['#234']
    //     }
    //   }
    // },

    // value: {
    //   formatter : function (val) {
    //     return val 
    //   },
    // },

  });

//   this.state = {
          
//     series: [44, 55, 13, 43, 22],
//     options: {
//       chart: {
//         width: 380,
//         type: 'pie',
//       },
//       labels: ['Team A', 'Team B', 'Team C', 'Team D', 'Team E'],
//       responsive: [{
//         breakpoint: 480,
//         options: {
//           chart: {
//             width: 200
//           },
//           legend: {
//             position: 'bottom'
//           }
//         }
//       }]
//     },
  
  
//   };
// }


  const getDataChartYear = (rowData) => {
    const tmpChartDataObj = {
      year: 'Year',
      xaxis: [],
      data: []
    };

    if (rowData.length) {
      // 1. fetch to day data

      // 2. put criteria to array data
      const putCriteria = [];
      for (let i = 0; i < rowData.length; i += 1) {
        const data = rowData[i];
        data.criteria = `c${new Date(data.dateTr).getMonth().toString()}`; //  the month (from 0 to 11) 
        putCriteria.push(data);
      }
      // console.log('1 criteriaMonthOfyear putCriteria =>', putCriteria);
  
      // 3. group by hour
      const groupCriteria = groupBy(putCriteria, ({ criteria }) => criteria);
      // console.log('2 criteriaMonthOfyear groupCriteria =>', groupCriteria);
  
      // 4. create hours object
      const dataArry = [];
      const criteriaRange = 12; // the month (from 0 to 11) 
      for (let i = 0; i < criteriaRange; i += 1) {
        const key = `c${i}`;
        dataArry[i] = groupCriteria[key] ? groupCriteria[key].length : 0;
      }
      const tmpDataObj = {
        name:'Transection',
        data: dataArry
      }

      tmpChartDataObj.data.push(tmpDataObj);
      if(i18n.language === 'th'){
        xaxis = criteriaTh.xaxis.year;
      }else{
        xaxis = criteriaEn.xaxis.year;
      }
      // console.log('criteriaMonthOfyear =>',tmpChartDataObj)

    }
    return tmpChartDataObj;
  }

  const getDataChartQuarter = (rowData) => {
    const tmpChartDataObj = {
      year: 'quarter',
      xaxis: [],
      data: []
    };

    if (rowData.length) {
      // 1. fetch to day data
  
      // 2. put criteria to array 
      // const sDate = new Date();
      // const eDate = new Date();
      const putCriteria = [];
      for (let i = 0; i < rowData.length; i += 1) {
        const data = rowData[i];
        const nDate = new Date(data.dateTr);
        data.criteria = `c${Math.floor(nDate.getMonth() / 3 + 1)}`; // 1 quarter (from 1 to 4
        putCriteria.push(data);
      }
      // console.log('1 criteriaHourOfDay putCriteria =>', putCriteria);
  
      // 3. group by hour
      const groupCriteria = groupBy(putCriteria, ({ criteria }) => criteria);
      // console.log('2 criteriaHourOfDay groupCriteria =>', groupCriteria);
  
      // 4. create hours object
      const dataArry = [];
      const criteriaRange = 4; // 1 quarter (from 1 to 4
      for (let i = 0; i < criteriaRange; i += 1) {
        const key = `c${i}`;
        dataArry[i] = groupCriteria[key] ? groupCriteria[key].length : 0;
      }
      const tmpDataObj = {
        name: 'Transection',
        data: dataArry
      }

      tmpChartDataObj.data.push(tmpDataObj);
      if (i18n.language === 'th') {
        xaxis = criteriaTh.xaxis.quarter;
      } else {
        xaxis = criteriaEn.xaxis.quarter;
      }
      // console.log('criteriaQuaterOfyear =>',tmpChartDataObj)
    }
    return tmpChartDataObj;
  }

  const getDataChartDayOfMonth = (rowData) => {
    const tmpChartDataObj = {
      year: 'month',
      xaxis: [],
      data: []
    };

    if (rowData.length) {
      // 1. fetch to day data
  
      // 2. put criteria to array 
      const putCriteria = [];
      for (let i = 0; i < rowData.length; i += 1) {
        const data = rowData[i];
        const groupCallNo = data.groupCallNo;
        // if( (nDate >= sDate ) && (sDate <= eDate)) {
          switch (groupCallNo.charAt(0)) {
            case '0': case '1': case '2': case '3': case '4': case '5': case '6': case '7': case '8': case '9':
              // data.gCNo = groupCallNo.charAt(0);
              data.gCNo = `c${groupCallNo.charAt(0)}`;
            break;
            case 'ย':
              data.gCNo = 'c10'
            break;
            case 'J':
              data.gCNo = 'c11'
            break;
            case 'น':
              data.gCNo = 'c12'
            break;
            case 'ร':
              if(groupCallNo.charAt(1) === 'ป') {
                data.gCNo = 'c13'
              }else {
                data.gCNo = 'c14'
              }
            break;
            case 'F':
              data.gCNo = 'c15'
            break;
            case 'C':
              data.gCNo = 'c16'
            break;
            case 'ก':
              data.gCNo = 'c17'
            break;
            default:
              data.gCNo = 'c18'
            break;
          }
          putCriteria.push(data);
        // }
      }
      // console.log('1 criteriaHourOfDay putCriteria =>', putCriteria);
  
      // groupCallNo
      // 3. group by hour
      const groupCriteria = groupBy(putCriteria, ({ gCNo }) => gCNo);
      // console.log('2 criteriaHourOfDay groupCriteria =>', groupCriteria);
  
      // 4. create hours object
      // const tmpChartData = [];
      // const tmpChartDataObj = {
      //   year: 'day',
      //   xaxis: [],
      //   data: []
      // };

      const dataArry = [];
      const tmpXaxis = []
      const criteriaRange = 16; // day of the week (from 0 to 6
      for (let i = 0; i < criteriaRange; i += 1) {
        const key = `c${i}`;
        if (groupCriteria[key]) {
          dataArry.push(groupCriteria[key].length);
          tmpXaxis.push(groupCallNoConst[i].category);
         }
      }
      // console.log('tmpXaxis ->',tmpXaxis)
      tmpChartDataObj.data.push(...dataArry);
      // tmpChartDataObj.xaxis = sumDay;
      if(i18n.language === 'th'){
        xaxis = tmpXaxis
      }else{
        xaxis = tmpXaxis
      }
  
      // console.log('criteriaDayOfMonth =>',tmpChartDataObj)
    }

    return tmpChartDataObj;
  
  }

  const getDataChartDayOfWeek = (rowData) => {
      const tmpChartDataObj = {
        year: 'week',
        xaxis: [],
        data: []
      };

    if (rowData.length) {
      // 1. fetch to day data
  
      // 2. put criteria to array data
      // const lDate = new Date();
      // console.log('lDate ->',lDate)
      // const sDate = new Date();
      // const eDate = new Date();
      // sDate.setDate( (lDate.getDate()) - (6-lDate.getDay() ) );
      // eDate.setDate( (lDate.getDate()) + (6-lDate.getDay() ) );
      // console.log('2sDate ->',sDate)
      // console.log('2eDate ->',eDate)
  
      const putCriteria = [];
      for (let i = 0; i < rowData.length; i += 1) {
        const data = rowData[i];
        const nDate = new Date(data.borrowedDate);
        // if( (nDate >= sDate ) && (sDate <= eDate)) {
          data.criteria = `c${nDate.getDay().toString()}`; // day of the week (from 0 to 6
          putCriteria.push(data);
        // }
      }
      // console.log('1 criteriaDayOfWeek  putCriteria =>', putCriteria);
  
      // 3. group by hour
      const groupCriteria = groupBy(putCriteria, ({ criteria }) => criteria);
      // console.log('2 criteriaDayOfWeek groupCriteria =>', groupCriteria);
  
      // 4. create hours object
      const dataArry = [];
      const criteriaRange = 7; // day of the week (from 0 to 6
      for (let i = 0; i < criteriaRange; i += 1) {
        const key = `c${i}`;
        dataArry[i] = groupCriteria[key] ? groupCriteria[key].length : 0;
      }
      const tmpDataObj = {
        name:'Transection',
        data: dataArry
      }
      tmpChartDataObj.data.push(tmpDataObj);
      // tmpChartDataObj.xaxis = ["อา","จ", "อ", "พ", "พฤ", "ศ", "ส"];
      if (i18n.language === 'th') {
        xaxis = criteriaTh.xaxis.week;
      } else {
        xaxis = criteriaEn.xaxis.week;
      }
  
      console.log('criteriaDayOfWeek =>',tmpChartDataObj)
    }
    return tmpChartDataObj;
  }

  const getDataChartHourOfDay = (rowData) => {
    const tmpChartDataObj = {
      year: 'day',
      xaxis: [],
      data: []
    };

    if (rowData.length) {
      // 1. fetch to day data
  
      // 2. put criteria to array 
      // const sDate = new Date();
      // const eDate = new Date();
      // sDate.setHours(0);
      // sDate.setMinutes(0);
      // sDate.setSeconds(0);
      // eDate.setHours(23);
      // eDate.setMinutes(59);
      // eDate.setSeconds(59);
      const putCriteria = [];
      for (let i = 0; i < rowData.length; i += 1) {
        const data = rowData[i];
        const nDate = new Date(data.dateTr);
        // if( (nDate >= sDate ) && (sDate <= eDate)) {
          data.criteria = `c${nDate.getHours().toString()}`; // 1 day (from 0 to 23
          putCriteria.push(data);
        // }
      }
      // console.log('1 criteriaHourOfDay putCriteria =>', putCriteria);
  
      // 3. group by hour
      const groupCriteria = groupBy(putCriteria, ({ criteria }) => criteria);
      // console.log('2 criteriaHourOfDay groupCriteria =>', groupCriteria);
  
      // 4. create hours object
      const dataArry = [];
      const criteriaRange = 24; // 1 day (from 0 to 23
      for (let i = 0; i < criteriaRange; i += 1) {
        const key = `c${i}`;
        dataArry[i] = groupCriteria[key] ? groupCriteria[key].length : 0;
      }
      const tmpDataObj = {
        name:'Transection',
        data: dataArry
      }
      tmpChartDataObj.data.push(tmpDataObj);
      // tmpChartDataObj.xaxis = sumDay;
      if(i18n.language === 'th'){
        xaxis = criteriaTh.xaxis.day;
      }else{
        xaxis = criteriaEn.xaxis.day;
      }
  
      console.log('criteriaHourOfDay =>',tmpChartDataObj)
    }
    return tmpChartDataObj;
  }

  const getDataChartHour = (rowData) => {
    const tmpChartDataObj = {
      year: 'hour',
      xaxis: [],
      data: []
    };

    if (rowData.length) {
      // 1. fetch to day data
  
      // 2. put criteria to array 
      // const sDate = new Date();
      // const eDate = new Date();
      // sDate.setMinutes(0);
      // sDate.setSeconds(0);
      // eDate.setMinutes(59);
      // eDate.setSeconds(59);
      const putCriteria = [];
      for (let i = 0; i < rowData.length; i += 1) {
        const data = rowData[i];
        const nDate = new Date(data.dateTr);
        // if( (nDate >= sDate ) && (sDate <= eDate)) {
          const Sample = Math.floor(nDate.getMinutes() / 5);
          // console.log('Sample ->',Sample)
          data.criteria = `c${Sample}`; // minutes (0 to 59) of a date.
          putCriteria.push(data);
        // }
      }
      // console.log('1 criteriaHourOfDay putCriteria =>', putCriteria);
  
      // 3. group by hour
      const groupCriteria = groupBy(putCriteria, ({ criteria }) => criteria);
      // console.log('2 criteriaHourOfDay groupCriteria =>', groupCriteria);
  
      // 4. create hours object
      const dataArry = [];
      const criteriaRange = 12; // minutes (0 to 59) of a date.
      for (let i = 0; i < criteriaRange; i += 1) {
        const key = `c${i}`;
        dataArry[i] = groupCriteria[key] ? groupCriteria[key].length : 0;
      }
      const tmpDataObj = {
        name:'Transection',
        data: dataArry
      }
      tmpChartDataObj.data.push(tmpDataObj);
      // tmpChartDataObj.xaxis = sumDay;
      if(i18n.language === 'th'){
        xaxis = criteriaTh.xaxis.hours;
      }else{
        xaxis = criteriaEn.xaxis.hours;
      }
  
      console.log('criteriaHourOfDay =>',tmpChartDataObj)
    }
    return tmpChartDataObj;
  }

  const getDataAll = (rowData) => {
    const tmpChartDataObj = {
      year: 'all',
      xaxis: [],
      data: []
    };
    xaxis = [];
    if( Array.isArray(rowData) ) { 
      if (rowData.length) {
        tmpChartDataObj.data = [];
        xaxis = [];
        // 1. fetch to day data
    
        // 2. put criteria to array 
        const putCriteria = [];
        for (let i = 0; i < rowData.length; i += 1) {
          const data = rowData[i];
          const groupCallNo = data.groupCallNo;
          // if( (nDate >= sDate ) && (sDate <= eDate)) {
            switch (groupCallNo.charAt(0)) {
              case '0': case '1': case '2': case '3': case '4': case '5': case '6': case '7': case '8': case '9':
                // data.gCNo = groupCallNo.charAt(0);
                data.gCNo = `c${groupCallNo.charAt(0)}`;
              break;
              case 'ย':
                data.gCNo = 'c10'
              break;
              case 'J':
                data.gCNo = 'c11'
              break;
              case 'น':
                data.gCNo = 'c12'
              break;
              case 'ร':
                if(groupCallNo.charAt(1) === 'ป') {
                  data.gCNo = 'c13'
                }else {
                  data.gCNo = 'c14'
                }
              break;
              case 'F':
                data.gCNo = 'c15'
              break;
              case 'C':
                data.gCNo = 'c16'
              break;
              case 'ก':
                data.gCNo = 'c17'
              break;
              default:
                data.gCNo = 'c18'
              break;
            }
            putCriteria.push(data);
          // }
        }
        // console.log('1 criteriaHourOfDay putCriteria =>', putCriteria);
    
        // groupCallNo
        // 3. group by hour
        const groupCriteria = groupBy(putCriteria, ({ gCNo }) => gCNo);
        // console.log('2 criteriaHourOfDay groupCriteria =>', groupCriteria);
    
        // 4. create hours object

        const dataArry = [];
        const tmpXaxis = []
        const criteriaRange = 16; // day of the week (from 0 to 6
        for (let i = 0; i < criteriaRange; i += 1) {
          const key = `c${i}`;
          if (groupCriteria[key]) {
            dataArry.push(groupCriteria[key].length);
            tmpXaxis.push(groupCallNoConst[i].category);
          }
        }
        // console.log('tmpXaxis ->',tmpXaxis)
        tmpChartDataObj.data.push(...dataArry);
        // tmpChartDataObj.xaxis = sumDay;
        chartOptions.labels = tmpXaxis;
        setOptionChart(chartOptions);
    
        // console.log('criteriaDayOfMonth =>',tmpChartDataObj)
      }
    }

    return tmpChartDataObj;
  
  }

  // const genDataChart = (rowData) => {
  //   // console.log('rowData ->',rowData.length)
  //   // console.log('searchBy ->',searchBy)
  //   const item = getDataAll(rowData);

  //   // item = {
  //   //   data: [44, 55, 13, 43]
  //   // }
    
  //   // item = {
  //   //   year: 'Year',
  //   //   data: [
  //   //     { name: 'Check In', data: [76, 42, 29, 0, 27, 138, 117, 86, 63] },
  //   //     { name: 'Check Out', data: [80, 55, 34, 114, 80, 130, 15, 28, 55] }
  //   //   ]
  //   // }
  //   // const item = getDataChartYear(rowData);
  //   // const item = getDataChartHourOfDay(rowData);
  //   // console.log('item ->',item);
  //   return(
  //     <>
  //       <Box key={item.year} sx={{ mt: 3, mx: 3 }} dir="ltr">
  //         {/* {item.data && ( */}
  //           <ReactApexChart type="pie" series={item.data} options={chartOptions}  height={350} />
  //         {/* )} */}
  //       </Box>
  //     </>
  //   )
  // }

  const genDataChart = (rowData) => {
    // console.log('rowData ->',rowData.length)
    // console.log('searchBy ->',searchBy)
    const item = getDataAll(rowData);

    // item = {
    //   data: [44, 55, 13, 43]
    // }
    setDataChart(item);
  }

  return (
    <>
      {isShow && (
        <Card>
          <CardHeader
            title={title}
            subheader={''}
            // action={
            //   <TextField
            //     select
            //     fullWidth
            //     value={seriesData}
            //     SelectProps={{ native: true }}
            //     onChange={handleChangeSeriesData}
            //     sx={{
            //       '& fieldset': { border: '0 !important' },
            //       '& select': { pl: 1, py: 0.5, pr: '24px !important', typography: 'subtitle2' },
            //       '& .MuiOutlinedInput-root': { borderRadius: 0.75, bgcolor: 'background.neutral' },
            //       '& .MuiNativeSelect-icon': { top: 4, right: 0, width: 20, height: 20 }
            //     }}
            //   >
            //     {chartData.map((option) => (
            //       <option key={option.year} value={option.year}>
            //         {option.year}
            //       </option>
            //     ))}
            //   </TextField>
            // }
          />

          {dataChart && 
            <Box sx={{ mt: 3, mx: 3 }} dir="ltr">
                <ReactApexChart type="pie" series={dataChart.data} options={optionChart}  height={350} />
            </Box>
          }

          {/* {rowDataChart && 
            <>
              {genDataChart(rowDataChart)}
            </>
          } */}
        </Card>
      )}
    </>
  );
}
