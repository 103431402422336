import { Helmet } from 'react-helmet-async';
import { useTranslation } from "react-i18next";
import { format } from "date-fns";
import { filter } from 'lodash';
import { sentenceCase } from 'change-case';
import { useState, useEffect } from 'react';
// @mui
import {
  Grid,
  Card,
  Table,
  Stack,
  Paper,
  Avatar,
  Button,
  Popover,
  Checkbox,
  TableRow,
  MenuItem,
  TableBody,
  TableCell,
  Container,
  Typography,
  IconButton,
  TableContainer,
  TablePagination,
} from '@mui/material';
// components
import Label from '../components/label';
import Iconify from '../components/iconify';
import Scrollbar from '../components/scrollbar';
import FilterDate from '../components/filter'
import { ExportToExcel } from '../components/exportExcel/ButtonExport';
// sections
import { UserListHead, UserListToolbar } from '../sections/@dashboard/report';
// mock
// import USERLIST from '../_mock/user';
// import USERLIST from '../_mock/logReport';

import LOGLIST from '../_mock/groupCallNumber';

// ----------------------------------------------------------------------
// ### get all รายงานหนังสือแยกตามเลขเรียก
// GET {{URL}}/api/borrow/sum/group-call-no?__start={{startDate}}&__end={{endDate}} HTTP/1.1
// {
//   "categoryeng" : "Society and Politics",
//   "categorythai" : "สังคมและการเมือง",
//   "count" : 1,
//   "_id" : "371.102"
// }

const TABLE_HEAD = [
  { id: 'order', label: 'groupCallNumber.order', alignRight: false },
  { id: 'bookCallNumber', label: 'groupCallNumber.bookCallNumber', alignRight: false },
  { id: 'categorythai', label: 'groupCallNumber.categorythai', alignRight: false },
  { id: 'categoryeng', label: 'groupCallNumber.categoryeng', alignRight: false },
  { id: 'count', label: 'groupCallNumber.count', alignRight: false },
];

// ----------------------------------------------------------------------

function showDate (date) {
  if(date === undefined) return '--/--/---- --:--:--';
  if(date === null) return '--/--/---- --:--:--';
  if(date === '') return '--/--/---- --:--:--';
  if(date === '-') return '--/--/---- --:--:--';
  return  format(new Date(date), "dd/MM/yyyy HH:mm:ss");
}

function showDateExcel (date) {
  if(date === undefined) return '--/--/----';
  if(date === null) return '--/--/----';
  if(date === '') return '--/--/----';
  if(date === '-') return '--/--/----';
  return  format(new Date(date), "dd/MM/yyyy");
}

function showTime (date) {
  if(date === undefined) return '--:--:--';
  if(date === null) return '--:--:--';
  if(date === '') return '--:--:--';
  if(date === '-') return '--:--:--';
  return  format(new Date(date), "HH:mm:ss");
}

function fileNameDate (date) {
  if(date === undefined) return '';
  if(date === null) return '';
  if(date === '') return '';
  if(date === '-') return '';
  return  format(new Date(date), "dd_MM_yyyy");
}

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  if (query) {
    return filter(array, (_user) => _user.name.toLowerCase().indexOf(query.toLowerCase()) !== -1);
  }
  return stabilizedThis.map((el) => el[0]);
}

const fetchUserData = (dataIn, fn) => {
  try {
    console.log('dataIn ->',dataIn)
    fetch(dataIn.url,{
      method: dataIn.method,
      body: JSON.stringify(dataIn.body),
      headers: {
          'Content-type': 'application/json; charset=UTF-8'
      }
    })
    .then(response => {
      return response.json()
    })
    .then(dataRes => {
      console.log('dataRes ->',dataRes)
      fn(dataRes.error, dataRes.data);
    })
    .catch(err => {
      console.log('catch Error ->' ,err);
      fn(err, null);
    })
  } catch (e) {
    console.log('try Error -> ',e);
    fn(e, null)
  }
}


const getAll = (dataIn, fn) => {
  try {
    console.log('dataIn ->',dataIn)
    fetch(dataIn.url,{
      method: 'GET',
    })
    .then(response => {
      return response.json()
    })
    .then(dataRes => {
      // console.log('dataRes ->',dataRes);
      if ( Array.isArray(dataRes) ) { 
        fn(null, dataRes);
      } else {
        fn(null, [])
      }
    })
    .catch(err => {
      console.log('catch Error ->' ,err);
      fn(err, null);
    })
  } catch (e) {
    console.log('try Error -> ',e);
    fn(e, null)
  }
}


const getbyTime = (dataIn, fn) => {
  try {
    // console.log('dataIn ->',dataIn)
    const url = dataIn.url.concat("__start=", dataIn.startDate, "&__end=", dataIn.endDate);
    // console.log('url ->',url)
    fetch(url,{
      method: 'GET',
    })
    .then(response => {
      return response.json()
    })
    .then(dataRes => {
      // console.log('dataRes ->',dataRes);
      if ( Array.isArray(dataRes) ) { 
        fn(null, dataRes);
      } else {
        fn(null, [])
      }
    })
    .catch(err => {
      console.log('catch Error ->' ,err);
      fn(err, null);
    })
  } catch (e) {
    console.log('try Error -> ',e);
    fn(e, null)
  }
}

const getbyId = (dataIn, fn) => {
  try {
    console.log('dataIn ->',dataIn)
    const url = dataIn.url.concat(dataIn.id);
    console.log('url ->',url)
    fetch(url,{
      method: 'GET',
    })
    .then(response => {
      return response.json()
    })
    .then(dataRes => {
      // console.log('dataRes ->',dataRes);
      if ( Array.isArray(dataRes) ) { 
        fn(null, dataRes);
      } else {
        fn(null, [])
      }
    })
    .catch(err => {
      console.log('catch Error ->' ,err);
      fn(err, null);
    })
  } catch (e) {
    console.log('try Error -> ',e);
    fn(e, null)
  }
}

const config = window.config;
const showFirstButtonConf = true;
const showLastButtonConf = true;

export default function UserPage() {
  const [open, setOpen] = useState(null);

  const [page, setPage] = useState(0);

  const [order, setOrder] = useState('asc');

  const [selected, setSelected] = useState([]);

  const [orderBy, setOrderBy] = useState('id');

  const [filterName, setFilterName] = useState('');

  const [rowsPerPage, setRowsPerPage] = useState(10);

  const [dataResAPI, setDataResAPI] = useState([]);

  const [dataList, setDataList] = useState([]); 

  const [startDate, setStartDate] = useState(new Date);

  const [endDate, setEndDate] = useState(new Date);
  
  const [dataExport, setDataExport] = useState([]);

  const [fileName, setFfileName] = useState('Export');

  const [colWidths, setColWidths] = useState([{ wch: 5}, { wch: 20 }, { wch: 20 }, { wch: 20 }, { wch: 20 }, { wch: 20 }]);

  const { i18n, t } = useTranslation();

  useEffect(() => {
    // console.log('USERLIST',USERLIST);
    console.log('config ->',config);
    refresh()
    
  }, [])

// ### get all รายงานหนังสือแยกตามเลขเรียก
// GET {{URL}}/api/borrow/sum/group-call-no?__start={{startDate}}&__end={{endDate}} HTTP/1.1
// {
//   "categoryeng" : "Society and Politics",
//   "categorythai" : "สังคมและการเมือง",
//   "count" : 1,
//   "_id" : "371.102"
// }
// {
//   "groupCallNumber": {
//     "order": "ลำดับ",
//     "bookCallNumber": "เลขเรียกหนังสือ",
//     "categorythai": "หมวดหมู่ภาษาไทย",
//     "categoryeng": "หมวดหมู่ภาษาอังกฤษ",
//     "count": "จำนวน",
//     "page": {
//       "title": "รายงานหนังสือแยกตามเลขเรียก"
//     }
//   }
// }

  useEffect(() => {
    // console.log('change dataResAPI =>',dataResAPI)
    const tmpDataList = []
    if (dataResAPI.length) {
      dataResAPI.forEach(((dataRow, count) => {
        const tmpObj = {
          order: '-',
          bookCallNumber: '-',
          categorythai: '-',
          categoryeng: '-',
          count: '-'
        }
        // console.log('dataRow [',count,'] ->',dataRow);
        tmpObj.id = dataRow._id;
        if(dataRow._id == null) tmpObj.bookCallNumber = '-'; else  tmpObj.bookCallNumber = dataRow._id;
        if(dataRow.categorythai == null) tmpObj.categorythai = '-'; else  tmpObj.categorythai = dataRow.categorythai;
        if(dataRow.categoryeng == null) tmpObj.categoryeng = '-'; else  tmpObj.categoryeng = dataRow.categoryeng;
        if(dataRow.count == null) tmpObj.count = '-'; else  tmpObj.count = dataRow.count;
        tmpDataList.push(tmpObj);
      }))
    }
    setDataList(tmpDataList)
  },[dataResAPI])


  useEffect(() => {
    // console.log('change dataList =>',dataList.length);
    if (dataList.length) {
      const tmpColWidths = [
        { wch: 5 }, 
        { wch: 30 }, 
        { wch: 40 }, 
        { wch: 40 }, 
        { wch: 15 }
      ]
      setColWidths(tmpColWidths);
      const tmpDataList = [];
      const tmpEmty = {
        order: '',
        bookCallNumber: '',
        categorythai: '',
        categoryeng: '',
        count: ''
      }
      const reportName = JSON.parse(JSON.stringify(tmpEmty));
      reportName.categorythai =t('groupCallNumber.page.title')
      setFfileName(`${t('groupCallNumber.page.title')}_${fileNameDate(new Date())}`);

      const criteria = JSON.parse(JSON.stringify(tmpEmty));
      criteria.categorythai = `${t('filter.between')} ${showDateExcel(startDate)} - ${showDateExcel(endDate)}`;

      const tmpHeader = JSON.parse(JSON.stringify(tmpEmty));
      tmpHeader.order =  t('groupCallNumber.order');
      tmpHeader.bookCallNumber =  t('groupCallNumber.bookCallNumber');
      tmpHeader.categorythai =  t('groupCallNumber.categorythai');
      tmpHeader.categoryeng =  t('groupCallNumber.categoryeng');
      tmpHeader.count =  t('groupCallNumber.count');

      tmpDataList.push(tmpEmty);
      tmpDataList.push(reportName);
      tmpDataList.push(criteria);
      tmpDataList.push(tmpEmty);
      tmpDataList.push(tmpHeader);

      dataList.forEach(((dataRow, count) => {
        const tmpObj = {
          order: (count + 1),
          bookCallNumber: dataRow.bookCallNumber,
          categorythai: dataRow.categorythai,
          categoryeng: dataRow.categoryeng,
          count: dataRow.count,
        }  
        tmpDataList.push(tmpObj);
      }))
      setDataExport(tmpDataList);
    }
  },[dataList]);

  const refresh = (search) => {

    const data = {
      url: 'http://localhost:3001/api/borrow/sum/group-call-no?',
      body: {}
    }

    startDate.setHours(0); startDate.setMinutes(0); startDate.setSeconds(0)
    endDate.setHours(23); endDate.setMinutes(59); endDate.setSeconds(59);

    data.url = `${config.backendUrl}/api/borrow/sum/group-call-no?`
    data.startDate = new Date(startDate).toISOString();
    data.endDate = new Date(endDate).toISOString();

    getbyTime (data, (err, fn) => {
      // console.log('err, fn ->',err, fn)
      if (config.production) {
        if(err === null){
          // console.log('getbyTime ->',fn)
          setDataResAPI(fn);
        }
      } else {
        setDataResAPI(LOGLIST);
      }
    })
  }

  const handleCallbackValue = (dataIn) => {
    // console.log('onCallbackValue ->', dataIn);
    const tmpStartDate = new Date(dataIn[0].$d);
    setStartDate(tmpStartDate)
    const tmpEndDate = new Date(dataIn[1].$d);
    setEndDate(tmpEndDate)
  }

  const handleSearchClick = (event) => {
    console.log('handleSearchClick ->', event);
    refresh(event);
  }

  const handleOpenMenu = (event) => {
    setOpen(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setOpen(null);
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = dataList.map((n) => n.name);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1));
    }
    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setPage(0);
    setRowsPerPage(parseInt(event.target.value, 10));
  };

  const handleFilterByName = (event) => {
    setPage(0);
    setFilterName(event.target.value);
  };

  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - dataList.length) : 0;

  const filteredUsers = applySortFilter(dataList, getComparator(order, orderBy), filterName);

  // console.log('filteredUsers ->',filteredUsers)

  const isNotFound = !filteredUsers.length && !!filterName;

  // console.log('LOGLIST ->',LOGLIST)

  return (
    <>
      <Helmet>
        <title> {t('groupCallNumber.page.title')} | Minimal UI </title>
      </Helmet>

      <Container maxWidth="xl">
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            {t('groupCallNumber.page.title')}
          </Typography>
        </Stack>

        <Card>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={7} md={7}>
              <FilterDate
                startDate={new Date().toISOString()}
                endDate={new Date().toISOString()}
                onCallbackValue={handleCallbackValue}
                onSearchClick={handleSearchClick}
              />
            </Grid>
            <Grid item xs={12} sm={5} md={5}>
              <div style={{'margin-top':'1.3rem'}}>
                <Grid container spacing={1}>
                  <Grid item xs={12} sm={3} md={3}>
                    <></>
                  </Grid>
                  <Grid item xs={12} sm={3} md={3}>
                    <Button variant="contained" size="large"  onClick={handleSearchClick}>{t("filter.Search")}</Button>
                  </Grid>
                  <Grid item xs={12} sm={3} md={3}>
                    <Button variant="contained" startIcon={<Iconify icon="eva:refresh-fill" />} size="large" onClick={handleSearchClick}/>
                  </Grid>
                  <Grid item xs={12} sm={3} md={3}>
                    <ExportToExcel apiData={dataExport} colWidths={colWidths} fileName={fileName} />
                  </Grid>
                </Grid>
              </div>
            </Grid>
          </Grid>
          {/* <UserListToolbar numSelected={selected.length} filterName={filterName} onFilterName={handleFilterByName} /> */}
        </Card>
        <Card>
          <Scrollbar>
            <TableContainer sx={{ minWidth: 800, maxHeight: 500 }}>
              <Table stickyHeader >
                <UserListHead
                  order={order}
                  orderBy={orderBy}
                  headLabel={TABLE_HEAD}
                  rowCount={dataList.length}
                  numSelected={selected.length}
                  onRequestSort={handleRequestSort}
                  onSelectAllClick={handleSelectAllClick}
                />
                <TableBody>
                  {filteredUsers.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, coun) => {
                    const { id , bookCallNumber, categorythai, categoryeng, count} = row;
                    const selectedUser = -1;

                    return (
                      <TableRow hover key={id} tabIndex={-1} role="checkbox" selected={selectedUser}>

                        {/* <TableCell padding="checkbox">
                          <Checkbox checked={selectedUser} onChange={(event) => handleClick(event, id)} />
                        </TableCell> */}

                        <TableCell style={{  position: "sticky", left: 0, background: "#ffe6e6"}} align="center">{((page*rowsPerPage) + (coun+1))}</TableCell>


                        <TableCell align="left">{bookCallNumber}</TableCell>


                        <TableCell align="left">{categorythai}</TableCell>


                        <TableCell align="left">{categoryeng}</TableCell>

                        <TableCell align="left">{count}</TableCell>

                        
                      </TableRow>
                    );
                  })}
                  {emptyRows > 0 && (
                    <TableRow style={{ height: 53 * emptyRows }}>
                      <TableCell colSpan={6} />
                    </TableRow>
                  )}
                </TableBody>

                {isNotFound && (
                  <TableBody>
                    <TableRow>
                      <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                        <Paper
                          sx={{
                            textAlign: 'center',
                          }}
                        >
                          <Typography variant="h6" paragraph>
                            Not found
                          </Typography>

                          <Typography variant="body2">
                            No results found for &nbsp;
                            <strong>&quot;{filterName}&quot;</strong>.
                            <br /> Try checking for typos or using complete words.
                          </Typography>
                        </Paper>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                )}
              </Table>
            </TableContainer>
          </Scrollbar>

          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={dataList.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            showFirstButton={showFirstButtonConf}
            showLastButton={showLastButtonConf}
            labelRowsPerPage={t('branchDetail.Pagination.rowsPerPage')}
          />
        </Card>
      </Container>

      <Popover
        open={Boolean(open)}
        anchorEl={open}
        onClose={handleCloseMenu}
        anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        PaperProps={{
          sx: {
            p: 1,
            width: 140,
            '& .MuiMenuItem-root': {
              px: 1,
              typography: 'body2',
              borderRadius: 0.75,
            },
          },
        }}
      >
        <MenuItem>
          <Iconify icon={'eva:edit-fill'} sx={{ mr: 2 }} />
          Edit
        </MenuItem>

        <MenuItem sx={{ color: 'error.main' }}>
          <Iconify icon={'eva:trash-2-outline'} sx={{ mr: 2 }} />
          Delete
        </MenuItem>
      </Popover>
    </>
  );
}
