import * as Yup from 'yup';
import { Helmet } from 'react-helmet-async';
import { filter } from 'lodash';
import { sentenceCase } from 'change-case';
import { useState, useEffect, useCallback } from 'react';
// import { useSnackbar } from 'notistack5'; // notistack5
import { SnackbarProvider, enqueueSnackbar } from 'notistack';
import { useNavigate, useParams, useLocation } from 'react-router-dom';
import { Form, FormikProvider, useFormik } from 'formik'; //
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
// @mui
import {
  Box,
  Card,
  Grid,
  Table,
  Stack,
  Switch,
  Paper,
  Avatar,
  Button,
  Popover,
  Checkbox,
  TableRow,
  MenuItem,
  TableBody,
  TableCell,
  TextField,
  Container,
  FormLabel,
  FormGroup,
  FormControl,
  Typography,
  IconButton,
  TableContainer,
  TablePagination,
  FormControlLabel,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
// components
import Label from '../components/label';
import Iconify from '../components/iconify';
import Scrollbar from '../components/scrollbar';
// sections
import { UserListHead, UserListToolbar, UserMoreMenu, ConfirmDialog } from '../sections/@dashboard/user';
// mock
import USERLIST from '../_mock/userList';

// ----------------------------------------------------------------------

const config = window.config;

export default function UserCreate({ permission }) {
  if (!permission) permission = { create: false, update: false, delete: false };

  const { i18n, t } = useTranslation();

  const { pathname } = useLocation();
  const isEdit = pathname.includes('edit');
  const { userId } = useParams();
  const [currentUser, setCurrentUser] = useState({});

  // const [isEdit, setIsEdit] = useState(false);

  const navigate = useNavigate();
  // const { enqueueSnackbar } = useSnackbar();

  const NewUserSchema = Yup.object().shape({
    host: Yup.string().required('Host is required'),
    port: Yup.number().required('Port is required'),
    // secure: Yup.boolean().required('Approved is required'),
    user: Yup.string().required('Username is required'),
    pass: Yup.string().required('Password is required'),
  });

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      host: currentUser?.host || '',
      port: currentUser?.port || 587,
      secure: currentUser?.secure || false,
      user: currentUser?.user || 'appdev@ppetech.com',
      pass: currentUser?.pass || 'P@ssw0rd1',
    },
    validationSchema: NewUserSchema,
    onSubmit: async (values, { setSubmitting, resetForm, setErrors }) => {
      // console.log('onSubmit values ->', values);

      try {
        const apiEditUsers = () => {
          // const _id = userForDelete._id;
          const url = `${config.backendUrl}/api/setting/${currentUser._id}`;
          try {
            // console.log('apiEditUsers: url ->', url);
            const valuesDup = JSON.parse(JSON.stringify(values));
            valuesDup.auth = { user: valuesDup.user, pass: valuesDup.pass };
            delete valuesDup.user;
            delete valuesDup.pass;
            // console.log('1 onSubmit valuesDup ->', valuesDup);
            const update = {
              value: valuesDup,
            };

            fetch(url, {
              method: 'PUT',
              headers: { 'Content-Type': 'application/json' },
              body: JSON.stringify(update),
            })
              .then((response) => {
                return response.json();
              })
              .then((dataRes) => {
                // console.log(`apiEditUsers: dataRes ->`, dataRes);
                if (dataRes.error) {
                  const error = dataRes.error.message;
                  setSubmitting(false);
                  enqueueSnackbar(`Update Error: ${error}`, {
                    autoHideDuration: 2000,
                    variant: 'error',
                    persist: false,
                  });
                  return;
                }
                // resetForm();
                setCurrentUser({ ...values, _id: currentUser._id });
                setSubmitting(false);
                enqueueSnackbar(`Update success`, {
                  autoHideDuration: 2000,
                  variant: 'success',
                  persist: false,
                });
                navigate('/setting/smtp');
              })
              .catch((err) => {
                console.error('apiEditUsers: catch Error ->', err);
                enqueueSnackbar('Update Error', { variant: 'error' });
              });
          } catch (e) {
            console.error('apiEditUsers: try Error -> ', e);
          }
        };

        apiEditUsers();

        // resetForm();
        // setSubmitting(false);
        // enqueueSnackbar(!isEdit ? 'Create success' : 'Update success', { variant: 'success' });
        // navigate(PATH_DASHBOARD.user.list);
      } catch (error) {
        console.error(error);
        setSubmitting(false);
        setErrors(error);
      }
    },
  });

  const { errors, values, touched, handleSubmit, isSubmitting, getFieldProps, setFieldValue } = formik;

  const handleChange = (event) => {
    if (event.target.name === 'isView') setFieldValue('isView', event.target.checked);
  };

  const handleRestore = () => {
    setFieldValue('host', currentUser.host);
    setFieldValue('port', currentUser.port);
    setFieldValue('secure', currentUser.secure);
    setFieldValue('user', currentUser.user);
    setFieldValue('pass', currentUser.pass);
  };

  useEffect(() => {
    // console.log('UserCreate: useEffect ');

    const apiGetUser = () => {
      // const _id = userForDelete._id;

      const url = `${config.backendUrl}/api/setting/range?name=smtp`;
      try {
        // console.log('apiGetUser: url ->', url);

        fetch(url, {
          method: 'GET',
          // headers: { 'Content-Type': 'application/json' },
          // body: JSON.stringify(values),
        })
          .then((response) => {
            return response.json();
          })
          .then((dataRes) => {
            // console.log(`apiGetUser: dataRes ->`, dataRes);
            if (dataRes.error) {
              const error = dataRes.error.message;
            } else if (!dataRes.length) {
              enqueueSnackbar('Update Error, Smtp setting not found', { variant: 'error' });
            } else {
              setCurrentUser({
                ...dataRes[0].value,
                _id: dataRes[0]._id,
                user: dataRes[0].value.auth.user,
                pass: dataRes[0].value.auth.pass,
              });
            }
          })
          .catch((err) => {
            console.error('apiGetUser: catch Error ->', err);
            enqueueSnackbar('Update Error', { variant: 'error' });
          });
      } catch (e) {
        console.error('apiGetUser: try Error -> ', e);
      }
    };
    apiGetUser();
  }, []);

  // useEffect(() => {
  //   console.log('useEffect currentUser ->', currentUser);
  // }, [currentUser]);

  return (
    <>
      <Helmet>
        <title>
          {t('settingSmtp.title')} | {t('title')}
        </title>
      </Helmet>

      <Container maxWidth="xl">
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            {t('settingSmtp.title')}
          </Typography>
        </Stack>

        <FormikProvider value={formik}>
          {/* {console.log('formik ->', values)} */}
          <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
            <Grid container spacing={3}>
              <Grid item xs={12} md={8}>
                <Card sx={{ p: 3 }}>
                  <Stack spacing={3}>
                    <Stack direction={{ xs: 'column', sm: 'row' }} spacing={{ xs: 3, sm: 2 }}>
                      <TextField
                        fullWidth
                        // disabled={isEdit}
                        label={t('settingSmtp.host')}
                        {...getFieldProps('host')}
                        error={Boolean(touched.host && errors.host)}
                        helperText={touched.host && errors.host}
                      />
                      <TextField
                        fullWidth
                        label={t('settingSmtp.port')}
                        {...getFieldProps('port')}
                        error={Boolean(touched.port && errors.port)}
                        helperText={touched.port && errors.port}
                      />
                    </Stack>
                    <Stack direction={{ xs: 'column', sm: 'row' }} spacing={{ xs: 3, sm: 2 }}>
                      <TextField
                        fullWidth
                        label={t('settingSmtp.user')}
                        {...getFieldProps('user')}
                        error={Boolean(touched.user && errors.user)}
                        helperText={touched.user && errors.user}
                      />
                      <TextField
                        fullWidth
                        label={t('settingSmtp.pass')}
                        type="password"
                        {...getFieldProps('pass')}
                        error={Boolean(touched.pass && errors.pass)}
                        helperText={touched.pass && errors.pass}
                      />
                    </Stack>

                    <Typography variant="subtitle2" sx={{ mb: 0 }}>
                      {t('settingSmtp.secure')} <Switch {...getFieldProps('secure')} checked={values.secure} />
                    </Typography>

                    <Box sx={{ mt: 3, display: 'flex', justifyContent: 'flex-end' }}>
                      <Button variant="outlined" color="primary" onClick={handleRestore} sx={{ mr: 2 }}>
                        {t('settingSmtp.btRestore')}
                      </Button>
                      <LoadingButton
                        disabled={!permission.update}
                        type="submit"
                        variant="contained"
                        loading={isSubmitting}
                      >
                        {t('settingSmtp.btSave')}
                      </LoadingButton>
                    </Box>
                  </Stack>
                  <SnackbarProvider
                    anchorOrigin={{
                      vertical: 'bottom',
                      horizontal: 'center',
                    }}
                  />
                </Card>
              </Grid>
            </Grid>
          </Form>
        </FormikProvider>
      </Container>
    </>
  );
}
