import { faker } from '@faker-js/faker';
import { sample } from 'lodash';

// ----------------------------------------------------------------------

// ### get all รายงานการยืมหนังสือของสมาชิก 
// GET http://localhost:3001/api/borrow/range?__start=2023-05-17T05:02:40.461Z&__end=2023-05-17T05:02:40.461Z

// [
//   {
//     "_id": "64645fcef468a74dbacf038f",
//     "borrowDate": "2023-05-17T05:02:40.461Z",
//     "memberId": "TK0001",
//     "memberName": "อารยา  สุขใจ",
//     "barcode": "32005002107028",
//     "bookName": "บันทึกเหตุการณ์สำคัญของโลก",
//     "bookNumber": "909 .ก25",
//     "bookCategory": "ประวัติศาสตร์",
//     "borrowType": "รับหนังสือ",
//     "bookSize": "A5",
//     "channel": "A23",
//     "returnDate": "2023-05-17T05:03:02.611Z",
//     "location": "Central World",
//     "notPickedUp": false,
//     "__v": 0
//   },

const logReport = [...Array(24)].map((_, index) => ({
  _id: faker.datatype.uuid(),
  borrowDate: new Date().toISOString(),
  memberId: sample(['TK0001', 'TK0002', 'TK0003', 'TK0004', 'TK0005','TK0005']),
  memberName: sample(['อารยา  สุขใจ','ลุกมัน อะแดม','อิสมาแอ อาแว','พีรมล  นิ่มนวล','พิทักษ์  ดำรงนาน']),
  barcode: faker.datatype.number(10000000000000),
  bookName: sample(['บันทึกเหตุการณ์สำคัญของโลก','จะไม่ทนเงียบอีกต่อไป','Bitcoin Cryptocurrency ชนะขาดตลาดคริปโทและบิตคอยน์','ยุทธศาสตร์สุขภาวะ LGBTION+ ในประเทศไทย','33 กฎทองของการลงทุนเก็งกำไรคอนโดแบบตัวจริง','มันไม่ง่ายขึ้นหรอก แต่เธอจะเก่งขึ้น','วานรศึกษา']),
  bookNumber: sample(['909 .ก25','362.76 .ฉ75','332.178 .ธ37','306.76 .ช55','333.3322 .ฐ34','895.7 .ค3624','599.8 .ว25']),
  bookCategory: sample(['ประวัติศาสตร์','สังคมและการเมือง','ธุรกิจและการลงทุน','เรื่องราวรอบตัว','ธุรกิจและการลงทุน','จิตวิทยาพัฒนาตนเอง','วิทยาศาสตร์']),
  borrowType: sample(['รับหนังสือ', 'ยืมหนังสือ']),
  bookSize: sample(['A4', 'A5']),
  channel: faker.datatype.number(380),
  returnDate: new Date().toISOString(),
  notPickedUp: faker.datatype.boolean(),
  location: sample([
    'Central World',
    'True  Digital  Park',
  ])
}));

export default logReport;
