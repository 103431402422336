import { Helmet } from 'react-helmet-async';
import { faker } from '@faker-js/faker';
import { useState, useEffect } from 'react';
import { withCookies, Cookies } from 'react-cookie';
import { useNavigate } from 'react-router-dom';
// @mui
import { useTheme } from '@mui/material/styles';
import { Grid, Container, Typography, Stack, FormControl, InputLabel, Select, MenuItem } from '@mui/material';
import { useTranslation } from 'react-i18next';
// components
import Iconify from '../components/iconify';
// sections
import {
  AppTasks,
  AppNewsUpdate,
  AppOrderTimeline,
  AppCurrentVisits,
  AppWebsiteVisits,
  AppTrafficBySite,
  AppWidgetSummary,
  AppCurrentSubject,
  AppConversionRates,
} from '../sections/@dashboard/app';

// ----------------------------------------------------------------------

const PAGE_ID = 'DashboardApp';
const config = window.config;

export default function DashboardAppPage({ permission }) {
  if (!permission) permission = { create: false, update: false, delete: false };

  const theme = useTheme();
  const { i18n, t } = useTranslation();

  const [sumCollection, setSumCollection] = useState(0);
  const [sumBorrow, setSumBorrow] = useState(0);
  const [sumMember, setSumMember] = useState(0);
  const [sumIssue, setSumIssue] = useState(0);

  const [branchSelect, setBranchSelect] = useState('ALL_BRANCH_SELECT');
  const [branchs, setBranchs] = useState([]);

  const navigate = useNavigate();

  const cookies = new Cookies();
  const authUser = cookies.get('user');
  // console.log('========== authUser', authUser)

  const [getOrBorrow, setGetOrBorrow] = useState([
    { label: 'ยืมหนังสือ', value: 1 },
    { label: 'รับหนังสือ', value: 1 },
  ]);

  const [booksAvailable, setBooksAvailable] = useState([
    { label: 'หมวดหมู่ 1', value: 1 },
    { label: 'หมวดหมู่ 2', value: 2 },
    { label: 'หมวดหมู่ 3', value: 5 },
  ]);

  // [
  //   {
  //     "_id": "รับหนังสือ",
  //     "count": 12
  //   },
  //   {
  //     "_id": "ยืมหนังสือ",
  //     "count": 7
  //   }
  // ]

  const [catalogs, setCatalogs] = useState([]);
  // [
  //   {
  //     "_id": "3ประวัติศาสตร์",
  //     "count": 8
  //   }
  // ]

  const fetchSum = (dataObj) => {
    try {
      // console.log('fetchSum dataObj ->', dataObj);
      fetch(dataObj.url, {
        method: 'GET',
      })
        .then((response) => {
          return response.json();
        })
        .then((dataRes) => {
          // console.log(`fetchSum: ${dataObj.cmd} dataRes ->`, dataRes); 
          const getOrBorrowArr = [];
          const booksAvailableArr = [];
          const catalogsArr = [];

          switch (dataObj.cmd) {
            case 'sumCollection':
              setSumCollection(dataRes.all);
              break;
            case 'sumBorrow':
              setSumBorrow(dataRes.all);
              break;
            case 'sumMember':
              setSumMember(dataRes.all);
              break;
            case 'sumIssue':
              setSumIssue(dataRes.all);
              break;
            case 'getOrBorrow':
              dataRes.forEach((b) => getOrBorrowArr.push({ label: b._id, value: b.count }));
              setGetOrBorrow(getOrBorrowArr);
              break;
            case 'booksAvailable':
              dataRes.forEach((b) => {  
                // console.log(`fetchSum: b.channel ->`, b.channel); 
                booksAvailableArr.push({ label: b._id, value: b.count }) 
              });
              setBooksAvailable(booksAvailableArr);
              // console.log(`fetchSum: booksAvailableArr ->`, booksAvailableArr); 
              break;
            case 'catalogs':
              dataRes.forEach((b) => {
                if (!b._id) b._id = t('dashboardPage.uncategorized');
                catalogsArr.push({ label: b._id, value: b.count });
              });
              catalogsArr.sort((a, b) => a.value - b.value);
              setCatalogs(catalogsArr);
              break;
            default:
              console.error('fetchSum: dataObj.cmd notfound');
          }
        })
        .catch((err) => {
          console.error('fetchSum: catch Error ->', err);
        });
    } catch (e) {
      console.error('fetchSum: try Error -> ', e);
    }
  };

  const handleChange = (e) => {
    const branchID = e.target.value;
    // console.log('handleChange e..target.value', e.target.value, branchID);
    setBranchSelect(e.target.value);

    const d = new Date();
    // const d = new Date('2023-07-12T17:00:00')// test
    const start = new Date(d.setHours(0, 0, 0)).toISOString();
    const end = new Date(d.setHours(23, 59, 59)).toISOString();
    // console.log('useEffect start, end =>', start, end);

    const dataObjArr = [
      { url: `${config.backendUrl}/api/dashboard/branch/`, cmd: 'sumCollection' },
      {
        url: `${config.backendUrl}/api/dashboard/borrow?__start=${start}&__end=${end}`,
        cmd: 'sumBorrow',
      },
      { url: `${config.backendUrl}/api/dashboard/member?__start=${start}&__end=${end}`, cmd: 'sumMember' },
      { url: `${config.backendUrl}/api/dashboard/issue?__start=${start}&__end=${end}`, cmd: 'sumIssue' },

      { url: `${config.backendUrl}/api/dashboard/borrow-type?__start=${start}&__end=${end}`, cmd: 'getOrBorrow' },
      { url: `${config.backendUrl}/api/dashboard/booksAvailable`, cmd: 'booksAvailable' },
      { url: `${config.backendUrl}/api/dashboard/catalog?__start=${start}&__end=${end}`, cmd: 'catalogs' },
    ];

    const dataObjArrBranchID = [
      { url: `${config.backendUrl}/api/dashboard/branch?branchID=${branchID}`, cmd: 'sumCollection' },
      {
        url: `${config.backendUrl}/api/dashboard/borrow?branchID=${branchID}&__start=${start}&__end=${end}`,
        cmd: 'sumBorrow',
      },
      {
        url: `${config.backendUrl}/api/dashboard/member?branchID=${branchID}&__start=${start}&__end=${end}`,
        cmd: 'sumMember',
      },
      {
        url: `${config.backendUrl}/api/dashboard/issue?branchID=${branchID}&__start=${start}&__end=${end}`,
        cmd: 'sumIssue',
      },

      {
        url: `${config.backendUrl}/api/dashboard/borrow-type?branchID=${branchID}&__start=${start}&__end=${end}`,
        cmd: 'getOrBorrow',
      },
      { url: `${config.backendUrl}/api/dashboard/booksAvailable?branchID=${branchID}`, 
        cmd: 'booksAvailable' 
      },
      {
        url: `${config.backendUrl}/api/dashboard/catalog?branchID=${branchID}&__start=${start}&__end=${end}`,
        cmd: 'catalogs',
      },
    ];

    if (branchID === 'ALL_BRANCH_SELECT') dataObjArr.forEach((dataObj) => fetchSum(dataObj));
    else dataObjArrBranchID.forEach((dataObj) => fetchSum(dataObj));
  };

  useEffect(() => {
    // console.log('useEffect config =>', config);

    const d = new Date();
    // const d = new Date('2023-07-12T17:00:00')// test
    const start = new Date(d.setHours(0, 0, 0)).toISOString();
    const end = new Date(d.setHours(23, 59, 59)).toISOString();
    // console.log('useEffect start, end =>', start, end); // test

    const dataObjArr = [
      { url: `${config.backendUrl}/api/dashboard/branch/`, cmd: 'sumCollection' },
      {
        url: `${config.backendUrl}/api/dashboard/borrow?__start=${start}&__end=${end}`,
        cmd: 'sumBorrow',
      },
      { url: `${config.backendUrl}/api/dashboard/member?__start=${start}&__end=${end}`, cmd: 'sumMember' },
      { url: `${config.backendUrl}/api/dashboard/issue?__start=${start}&__end=${end}`, cmd: 'sumIssue' },

      { url: `${config.backendUrl}/api/dashboard/borrow-type?__start=${start}&__end=${end}`, cmd: 'getOrBorrow' },     
      { url: `${config.backendUrl}/api/dashboard/booksAvailable`, 
        cmd: 'booksAvailable' 
      },
      { url: `${config.backendUrl}/api/dashboard/catalog?__start=${start}&__end=${end}`, cmd: 'catalogs' },
    ];

    dataObjArr.forEach((dataObj) => fetchSum(dataObj));

    const fetchBranchs = () => {
      const url = `${config.backendUrl}/api/branch/`;
      // console.log(`fetchBranchs: url ->`, url);
      try {
        // console.log('fetchSum dataObj ->', dataObj);
        fetch(url, {
          method: 'GET',
        })
          .then((response) => {
            return response.json();
          })
          .then((dataRes) => {
            // console.log(`fetchBranchs: dataRes ->`, dataRes);
            setBranchs(dataRes);
          })
          .catch((err) => {
            console.error('fetchBranchs: catch Error ->', err);
          });
      } catch (e) {
        console.error('fetchBranchs: try Error -> ', e);
      }
    };
    fetchBranchs();
  }, []);

  return (
    <>
      <Helmet>
        <title> {t('dashboardPage.title')} | TK Park </title>
      </Helmet>

      <Container maxWidth="xl">
        <Stack direction="row" justifyContent="space-between" alignItems="flex-start">
          <Typography variant="h4" sx={{ mb: 5 }}>
            {t('dashboardPage.welcomeBack')} {authUser.name}
          </Typography>

          {/* <Button variant="contained">Item 1</Button> */}
          <FormControl sx={{ minWidth: 250 }}>
            <InputLabel id="role-simple-select-label">{t('dashboardPage.selectVending')}</InputLabel>
            <Select
              labelId="role-simple-select-label"
              // id="demo-simple-select"
              value={branchSelect}
              label={t('dashboardPage.selectVending')}
              onChange={handleChange}
            >
              <MenuItem value="ALL_BRANCH_SELECT">{t('dashboardPage.all')}</MenuItem>
              {branchs.map((r) => (
                <MenuItem key={r._id} value={r.branchID}>
                  {r.branchName}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Stack>

        <Grid container spacing={3}>
          <Grid item xs={12} sm={6} md={3}>
            <AppWidgetSummary
              title={t('dashboardPage.widgetSummary.collection_title')}
              total={sumCollection}
              color="primary"
              icon={'ant-design:shop-filled'}
              onClick={() => navigate('/branch/list', { replace: true })}
              // onClick={() => navigate('/dashboard/monitoring', { replace: true })}
            />
          </Grid>

          <Grid item xs={12} sm={6} md={3}>
            <AppWidgetSummary
              title={t('dashboardPage.widgetSummary.borrow_title')}
              total={sumBorrow}
              color="info"
              icon={'ant-design:container-filled'}
              onClick={() => navigate('/report/memberBookBorrowingReport', { replace: true })}
            />
          </Grid>

          <Grid item xs={12} sm={6} md={3}>
            <AppWidgetSummary
              title={t('dashboardPage.widgetSummary.member_title')}
              total={sumMember}
              color="success"
              icon={'ant-design:idcard-filled'}
              onClick={() => navigate('/report/memberBoxAccessReport', { replace: true })}
            />
          </Grid>

          <Grid item xs={12} sm={6} md={3}>
            <AppWidgetSummary
              title={t('dashboardPage.widgetSummary.issue_title')}
              total={sumIssue}
              color="warning"
              icon={'ant-design:exclamation-circle-filled'}
              onClick={() => navigate('/report/systemlogreport', { replace: true })}
            />
          </Grid>

          <Grid item xs={12} md={6} lg={4}>
            <AppCurrentVisits
              title={t('dashboardPage.CurrentVisits.title')}
              chartData={getOrBorrow}
              // chartData={[
              //   { label: 'America', value: 4344 },
              //   { label: 'Asia', value: 5435 },
              // ]}
              chartColors={[theme.palette.error.main, theme.palette.info.main]}
            />
          </Grid>

          <Grid item xs={12} md={6} lg={4}>
            <AppCurrentVisits
              title={t('dashboardPage.BooksAvailable.title')}
              chartData={booksAvailable}
              // chartData={[
              //   { label: 'America', value: 4344 },
              //   { label: 'Asia', value: 5435 },
              // ]}
              chartColors={[
                theme.palette.primary.main,
                theme.palette.warning.main,
                theme.palette.info.main,
                theme.palette.error.main,
                theme.palette.success.main,
                theme.palette.warning.dark,
                theme.palette.success.darker,
                theme.palette.info.dark,
                theme.palette.info.darker,
              ]}
            />
          </Grid> 

          <Grid item xs={12} md={6} lg={4}>
            <AppConversionRates
              title={t('dashboardPage.ConversionRates.title')}
              // subheader="(+43%) than last year"
              chartData={catalogs}
            />
          </Grid>
        </Grid>
      </Container>
    </>
  );
}
