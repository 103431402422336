// component
import SvgColor from '../../../components/svg-color';

// ----------------------------------------------------------------------

const icon = (name) => <SvgColor src={`/assets/icons/navbar/${name}.svg`} sx={{ width: 1, height: 1 }} />;


const navConfig = [

  // GENERAL
  // ----------------------------------------------------------------------
  {
    subheader: 'general',
    items: [
      { title: 'menu.dashboard', path: '/dashboard/app', icon: icon('ic_analytics') },
      { title: 'menu.monitoring', path: '/dashboard/monitoring', icon: icon('ic_dashboard') },
      {
        title: 'menu.report',
        path: 'report',
        icon: icon('ic_file'),
        children: [
          { title: 'menu.systemLogReport', path: '/report/systemlogreport' },
          { title: 'menu.booksNotPicketUpReport', path: '/report/BooksNotPicketUpReport' },
          { title: 'menu.memberBookBorrowingReport', path: '/report/memberBookBorrowingReport' },
          { title: 'menu.memberBookSelectionReport', path: '/report/memberBookSelectionReport' },
          { title: 'menu.memberBoxAccessReport', path: '/report/memberBoxAccessReport' },
          { title: 'menu.noMemberBoxAccessReport', path: '/report/noMemberBoxAccessReport' },
          { title: 'menu.staffBookRemovalReport', path: '/report/staffBookRemovalReport' },
          { title: 'menu.staffImportBookReport', path: '/report/staffImportBookReport' },
          { title: 'menu.staffAccessToTheBoxReport', path: '/report/staffAccessToTheBoxReport' },
          // { title: 'menu.groupCallNumberReport', path: '/report/groupCallNumberReport' }
        ]
      }
    ]

  },

  // MANAGEMENT
  // ----------------------------------------------------------------------
  {
    subheader: 'management',
    items: [
      // MANAGEMENT : BRANCH
      {
        title: 'menu.branch',
        path: '/branch',
        icon: icon('ic_banking'),
        children: [
          { title: 'menu.branchList', path: '/branch/list' },
          // { title: 'menu.createBranch', path: '/branch/new' },
          // { title: 'menu.branch', path: '/branch' },
        ]
      },
      // MANAGEMENT : USER
      {
        title: 'menu.user',
        path: 'user',
        icon: icon('ic_user'),
        // onlyUser:['admin', 'uperadmin'], 
        children: [
          // { title: 'menu.profile', path: '/user/profile' },
          { title: 'menu.list', path: '/user/users' },
          // { title: 'menu.create', path: '/user/new' },
          // { title: 'menu.edit', path: '/user/edit' },
          // { title: 'menu.account', path: '/user/account' },
          // { title: 'menu.user', path: '/user' },        
          { title: 'menu.role', path: '/user/role' },
          { title: 'menu.permission', path: '/user/permission', onlyUser:['admin', 'uperadmin'] },
          // { title: 'menu.login', path: '/login' }
        ]
      },
      {
        title: 'menu.setting.title',
        path: 'setting',
        icon: icon('setting-2-svgrepo-com'),
        // onlyUser:['admin', 'uperadmin'], 
        children: [
          { title: 'menu.setting.smtp', path: '/setting/smtp' },     
          { title: 'menu.setting.issueEmail', path: '/setting/issue-email' },
        ]
      }
    ]
  }
];

export default navConfig;
