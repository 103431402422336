import * as React from 'react';
import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import ErrorIcon from '@mui/icons-material/ErrorOutline'
// import Typography from '@mui/material/Typography';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}));

function BootstrapDialogTitle(props) {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
}

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};


CustomizedDialogs.propTypes = {
  children: PropTypes.node,
  onOpen: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  title: PropTypes.string,
  buttonFooterName: PropTypes.string
};

export default function CustomizedDialogs(props) {
  const { children, onOpen, title, buttonFooterName, onClose, ...other } = props;
  const [open, setOpen] = React.useState(true);

  useEffect(() => {
    // console.log('children -',children);
    setOpen(onOpen);
  },[])


  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
    onClose();
  };

  return (
    <div>
      {/* <Button variant="outlined" onClick={handleClickOpen}>
        Open dialog
      </Button> */}
      {children}
      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
      >
        <BootstrapDialogTitle id="customized-dialog-title" onClose={handleClose}>
          {title}
        </BootstrapDialogTitle>
        <DialogContent dividers>
            <IconButton
              sx={{
                position: 'flex',
                color: (theme) => theme.palette.primary.main,
              }}
            >
              <ErrorIcon sx={{
                position: 'absolute',
                left:145,
                button: 20,
                width: '50px' ,
                height: '50px',
                textAlign: 'center',
                color: (theme) => theme.palette.primary.main,
              }} />
            </IconButton>
            {children}
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={handleClose}>
            {buttonFooterName}
          </Button>
        </DialogActions>
      </BootstrapDialog>
    </div>
  );
}
