import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import { format } from 'date-fns';
import { filter } from 'lodash';
import { sentenceCase } from 'change-case';
import { useState, useEffect } from 'react';
import { Cookies, useCookies } from 'react-cookie';
import { SnackbarProvider, enqueueSnackbar } from 'notistack';
// @mui
import {
  Grid,
  Card,
  CardActions,
  Table,
  Stack,
  Paper,
  Avatar,
  Button,
  Popover,
  Checkbox,
  TableRow,
  MenuItem,
  TableBody,
  TableCell,
  Container,
  Typography,
  IconButton,
  TableContainer,
  TablePagination,
} from '@mui/material';
// components
import Label from '../components/label';
import Iconify from '../components/iconify';
import Scrollbar from '../components/scrollbar';
// import FilterDate from '../components/filter';
import { DateFieldFilter, DateTimeFieldFilter } from '../components/filter';
// import ExportExcel from '../components/exportExcel';
import { ExportToExcel } from '../components/exportExcel/ButtonExports';
// sections
import { UserListHead, UserListToolbar, FixIssueDialog } from '../sections/@dashboard/report';
// mock
// import USERLIST from '../_mock/user';
// import USERLIST from '../_mock/logReport';

import LOGLIST from '../_mock/logReport';

// ----------------------------------------------------------------------

const TABLE_HEAD = [
  { id: 'order', label: 'systemlog.order', alignRight: false, sx: { maxWidth: '70px' } },
  { id: 'notificationDate', label: 'systemlog.notificationDate', alignRight: false, sx: { minWidth: '180px' } },
  // { id: 'notificationTime', label: 'systemlog.notificationTime', alignRight: false, sx:{minWidth: '150px', position: "sticky", left: 189, zIndex: 99}},
  { id: 'logType', label: 'systemlog.logType', alignRight: false, sx: { minWidth: '140px' } },
  { id: 'logEvent', label: 'systemlog.logEvent', alignRight: false, sx: { minWidth: '360px' } },
  { id: 'ServiceDate', label: 'systemlog.ServiceDate', alignRight: false, sx: { minWidth: '180px' } },
  // { id: 'ServiceTime', label: 'systemlog.ServiceTime', alignRight: false, sx:{minWidth: '140px'}},
  { id: 'location', label: 'systemlog.location', alignRight: false, sx: { minWidth: '100px' } },
  { id: 'note', label: 'systemlog.note', alignRight: false, sx: { minWidth: '100px', textAlign: 'center' } },
];

const arryFilter = [];
const ConstFildFilter = [
  {
    type: 'select',
    id: 'location',
    label: 'systemlog.location',
    defaultValue: 'All',
    helperText: '',
    currencies: [
      {
        value: 'All',
        label: 'All',
      },
    ],
  },
  {
    type: 'select',
    id: 'logType',
    label: 'systemlog.logType',
    defaultValue: 'All',
    helperText: '',
    currencies: [
      {
        value: 'All',
        label: 'All',
      },
    ],
  },
];

// ----------------------------------------------------------------------

function showDate(date) {
  if (date === undefined) return '--/--/---- --:--:--';
  if (date === null) return '--/--/---- --:--:--';
  if (date === '') return '--/--/---- --:--:--';
  if (date === '-') return '--/--/---- --:--:--';
  return format(new Date(date), 'dd/MM/yyyy HH:mm:ss');
}

function showDateExcel(date) {
  if (date === undefined) return '--/--/---- --:--:--';
  if (date === null) return '--/--/---- --:--:--';
  if (date === '') return '--/--/---- --:--:--';
  if (date === '-') return '--/--/---- --:--:--';
  return format(new Date(date), 'dd/MM/yyyy HH:mm:ss');
}

function showTime(date) {
  if (date === undefined) return '--:--:--';
  if (date === null) return '--:--:--';
  if (date === '') return '--:--:--';
  if (date === '-') return '--:--:--';
  return format(new Date(date), 'HH:mm:ss');
}

function fileNameDate(date) {
  if (date === undefined) return '';
  if (date === null) return '';
  if (date === '') return '';
  if (date === '-') return '';
  return format(new Date(date), 'dd_MM_yyyy');
}

function fileNameDates(date) {
  if (date === undefined) return '';
  if (date === null) return '';
  if (date === '') return '';
  if (date === '-') return '';
  return format(new Date(date), 'dd_MM_yyyy_HH_mm_ss');
}

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  if (query) {
    return filter(array, (_user) => _user.name.toLowerCase().indexOf(query.toLowerCase()) !== -1);
  }
  return stabilizedThis.map((el) => el[0]);
}

const fetchUserData = (dataIn, fn) => {
  try {
    console.log('dataIn ->', dataIn);
    fetch(dataIn.url, {
      method: dataIn.method,
      body: JSON.stringify(dataIn.body),
      headers: {
        'Content-type': 'application/json; charset=UTF-8',
      },
    })
      .then((response) => {
        return response.json();
      })
      .then((dataRes) => {
        console.log('dataRes ->', dataRes);
        fn(dataRes.error, dataRes.data);
      })
      .catch((err) => {
        console.log('catch Error ->', err);
        fn(err, null);
      });
  } catch (e) {
    console.log('try Error -> ', e);
    fn(e, null);
  }
};

const getAll = (dataIn, fn) => {
  try {
    console.log('dataIn ->', dataIn);
    fetch(dataIn.url, {
      method: 'GET',
    })
      .then((response) => {
        return response.json();
      })
      .then((dataRes) => {
        // console.log('dataRes ->',dataRes);
        if (Array.isArray(dataRes)) {
          fn(null, dataRes);
        } else {
          fn(null, []);
        }
      })
      .catch((err) => {
        console.log('catch Error ->', err);
        fn(err, null);
      });
  } catch (e) {
    console.log('try Error -> ', e);
    fn(e, null);
  }
};

const getbyTime = (dataIn, fn) => {
  try {
    // console.log('dataIn ->',dataIn)
    const url = dataIn.url.concat('__start=', dataIn.startDate, '&__end=', dataIn.endDate);
    // console.log('url ->',url)
    fetch(url, {
      method: 'GET',
    })
      .then((response) => {
        return response.json();
      })
      .then((dataRes) => {
        // console.log('dataRes ->',dataRes);
        if (Array.isArray(dataRes)) {
          fn(null, dataRes);
        } else {
          fn(null, []);
        }
      })
      .catch((err) => {
        console.log('catch Error ->', err);
        fn(err, null);
      });
  } catch (e) {
    console.log('try Error -> ', e);
    fn(e, null);
  }
};

const getbyId = (dataIn, fn) => {
  try {
    console.log('dataIn ->', dataIn);
    const url = dataIn.url.concat(dataIn.id);
    console.log('url ->', url);
    fetch(url, {
      method: 'GET',
    })
      .then((response) => {
        return response.json();
      })
      .then((dataRes) => {
        // console.log('dataRes ->',dataRes);
        if (Array.isArray(dataRes)) {
          fn(null, dataRes);
        } else {
          fn(null, []);
        }
      })
      .catch((err) => {
        console.log('catch Error ->', err);
        fn(err, null);
      });
  } catch (e) {
    console.log('try Error -> ', e);
    fn(e, null);
  }
};

const config = window.config;
const showFirstButtonConf = true;
const showLastButtonConf = true;

const timeout = 1000;

let startDate = new Date();

let endDate = new Date();

let dataSort = [];

export default function UserPage({ permission }) {
  if (!permission) permission = { create: false, update: false, delete: false };

  const [cookies, setCookie, removeCookie] = useCookies(['user']);
  // console.log('cookies-----', cookies)
  const currentUser = cookies.user;

  const [open, setOpen] = useState(null);

  const [page, setPage] = useState(0);

  const [order, setOrder] = useState('asc');

  const [selected, setSelected] = useState([]);

  const [orderBy, setOrderBy] = useState('id');

  const [filterName, setFilterName] = useState('');

  const [rowsPerPage, setRowsPerPage] = useState(10);

  const [dataResAPI, setDataResAPI] = useState([]);

  const [dataList, setDataList] = useState([]);

  // const [startDate, setStartDate] = useState(new Date);

  // const [endDate, setEndDate] = useState(new Date);

  const [dataExport, setDataExport] = useState([]);

  const [fileName, setFfileName] = useState('Export');

  const [colWidths, setColWidths] = useState([
    { wch: 5 },
    { wch: 20 },
    { wch: 20 },
    { wch: 20 },
    { wch: 20 },
    { wch: 20 },
  ]);

  const [fildFilter, setFildFilter] = useState([]);

  const [dataFilterList, setDataFilterList] = useState([]);

  const [searchBy, setSearchBy] = useState('day');

  const [subheader, setSubheader] = useState('barChart.day');

  const [fixIssueOpen, setFixIssueOpen] = useState(false);
  const [fixDate, setFixDate] = useState(new Date());
  const [noteDate, setNoteDate] = useState(new Date());
  const [noteBy, setNoteBy] = useState('');
  const [fixNote, setFixNote] = useState('');
  const [selectedId, setSelectedId] = useState(null);

  const { i18n, t } = useTranslation();

  useEffect(() => {
    const sDate = new Date();
    const eDate = new Date();
    sDate.setHours(0);
    sDate.setMinutes(0);
    sDate.setSeconds(0);
    eDate.setHours(23);
    eDate.setMinutes(59);
    eDate.setSeconds(59);
    // setStartDate(sDate)
    // setEndDate(eDate)
    startDate = sDate;
    endDate = eDate;
    clearOptionSelect();
    setTimeout(() => {
      refresh();
    }, timeout);
  }, []);

  useEffect(() => {
    // console.log('change dataResAPI =>',dataResAPI.length);
    const tmpDataList = [];
    const tmpLocation = [];
    const tmpLogType = [];

    if (dataResAPI.length) {
      dataResAPI.forEach((dataRow, count) => {
        const tmpObj = {
          notificationDate: '-',
          notificationTime: '-',
          ServiceDate: '-',
          ServiceTime: '-',
          logType: '-',
          logEvent: '-',
          location: '-',
        };
        // console.log('dataRow [',count,'] ->',dataRow);
        tmpObj.id = dataRow._id;
        if (dataRow.issueDate == null) tmpObj.notificationDate = '-';
        else tmpObj.notificationDate = dataRow.issueDate;
        if (dataRow.issueDate == null) tmpObj.notificationTime = '-';
        else tmpObj.notificationTime = dataRow.issueDate;
        if (dataRow.fixDate == null) tmpObj.ServiceDate = '-';
        else tmpObj.ServiceDate = dataRow.fixDate;
        if (dataRow.fixDate == null) tmpObj.ServiceTime = '-';
        else tmpObj.ServiceTime = dataRow.fixDate;
        if (dataRow.type == null) tmpObj.logType = '-';
        else tmpObj.logType = dataRow.type;
        if (dataRow.log == null) tmpObj.logEvent = '-';
        else tmpObj.logEvent = dataRow.log;
        if (dataRow.location == null) tmpObj.location = '-';
        else tmpObj.location = dataRow.location;
        tmpDataList.push({ ...tmpObj, ...dataRow });

        // add option filter
        // filter first
        // ===========================
        if (tmpLocation.length) {
          const resultObject = tmpLocation.findIndex((x) => x.value === dataRow.location);
          if (resultObject === -1) {
            const tmpOption = { value: dataRow.location, label: dataRow.location };
            tmpLocation.push(tmpOption);
          }
        } else {
          tmpLocation.push({ value: 'All', label: t('filter.all') });
          const tmpOption = { value: dataRow.location, label: dataRow.location };
          tmpLocation.push(tmpOption);
        }
        // ===========================
        // filter second
        // ===========================
        if (tmpLogType.length) {
          const resultObject = tmpLogType.findIndex((x) => x.value === dataRow.type);
          if (resultObject === -1) {
            const tmpOption = { value: dataRow.type, label: dataRow.type };
            tmpLogType.push(tmpOption);
          }
        } else {
          tmpLogType.push({ value: 'All', label: t('filter.all') });
          const tmpOption = { value: dataRow.type, label: dataRow.type };
          tmpLogType.push(tmpOption);
        }
        // ===========================
      });

      // update option filter
      // =======================================
      ConstFildFilter.forEach((item, index) => {
        // filter first
        // =========================
        if (item.type === 'select') {
          if (item.id === 'location') {
            ConstFildFilter[index].currencies = tmpLocation;
          }
        }
        // ==========================
        // filter second
        // =========================
        if (item.type === 'select') {
          if (item.id === 'logType') {
            ConstFildFilter[index].currencies = tmpLogType;
          }
        }
        // ==========================
        // fiter more

        // ==========================
      });

      setFildFilter(ConstFildFilter);
    }
    setDataFilterList(tmpDataList);
    setDataList(tmpDataList);
  }, [dataResAPI]);

  // useEffect(() => {
  //   // console.log('change dataFilterList =>',dataFilterList.length);
  //   if (dataFilterList.length) {
  //     const tmpColWidths = [
  //       { wch: 5 },
  //       { wch: 20 },
  //       { wch: 20 },
  //       { wch: 50 },
  //       { wch: 20 },
  //       { wch: 20 }
  //     ]
  //     setColWidths(tmpColWidths);
  //     const tmpDataList = [];
  //     const tmpEmty = {
  //       order: '',
  //       notificationDate: '',
  //       // notificationTime: '',
  //       logType: '',
  //       logEvent: '',
  //       ServiceDate: '',
  //       // ServiceTime: '',
  //       location: '',
  //     }
  //     const reportName = JSON.parse(JSON.stringify(tmpEmty));
  //     reportName.logEvent = t('menu.systemLogReport');
  //     setFfileName(t('menu.systemLogReport'));
  //     setFfileName(`${t('menu.systemLogReport')}_${fileNameDate(new Date())}`);
  //     const criteria = JSON.parse(JSON.stringify(tmpEmty));
  //     criteria.logEvent = `${t('filter.between')} ${showDateExcel(startDate)} - ${showDateExcel(endDate)}`;

  //     const tmpHeader = JSON.parse(JSON.stringify(tmpEmty));
  //     tmpHeader.order =  t('systemlog.order');
  //     tmpHeader.notificationDate =  t('systemlog.notificationDate');
  //     // tmpHeader.notificationTime =  t('systemlog.notificationTime');
  //     tmpHeader.logType =  t('systemlog.logType');
  //     tmpHeader.logEvent =  t('systemlog.logEvent');
  //     tmpHeader.ServiceDate =  t('systemlog.ServiceDate');
  //     // tmpHeader.ServiceTime =  t('systemlog.ServiceTime');
  //     tmpHeader.location =  t('systemlog.location');
  //     tmpDataList.push(reportName);
  //     tmpDataList.push(criteria);
  //     tmpDataList.push(tmpHeader);
  //     dataFilterList.forEach(((dataRow, count) => {
  //       const tmpObj = {
  //         order: (count + 1),
  //         notificationDate: showDateExcel(dataRow.notificationDate),
  //         // notificationTime: showTime(dataRow.notificationTime),
  //         ServiceDate: showDateExcel(dataRow.ServiceDate),
  //         // ServiceTime: showTime(dataRow.ServiceTime),
  //         logType: dataRow.logType,
  //         logEvent: dataRow.logEvent,
  //         location: dataRow.location
  //       }
  //       tmpDataList.push(tmpObj);
  //     }))
  //     setDataExport(tmpDataList);
  //   }
  // },[dataFilterList]);

  const genDataExport = (callback) => {
    // console.log('genDataExport =>')
    // Here, you have the function from the child.
    if (dataSort.length) {
      const tmpColWidths = [{ wch: 5 }, { wch: 20 }, { wch: 20 }, { wch: 50 }, { wch: 20 }, { wch: 20 }];
      // setColWidths(tmpColWidths);
      const tmpDataList = [];
      const tmpEmty = {
        order: '',
        notificationDate: '',
        // notificationTime: '',
        logType: '',
        logEvent: '',
        ServiceDate: '',
        // ServiceTime: '',
        location: '',
        note: '',
      };
      const reportName = JSON.parse(JSON.stringify(tmpEmty));
      reportName.logEvent = t('menu.systemLogReport');
      // setFfileName(`${t('menu.systemLogReport')}_${fileNameDate(new Date())}`);

      const criteria = JSON.parse(JSON.stringify(tmpEmty));
      criteria.logEvent = `${t('filter.between')} ${showDateExcel(startDate)} - ${showDateExcel(endDate)}`;

      const tmpHeader = JSON.parse(JSON.stringify(tmpEmty));
      tmpHeader.order = t('systemlog.order');
      tmpHeader.notificationDate = t('systemlog.notificationDate');
      // tmpHeader.notificationTime =  t('systemlog.notificationTime');
      tmpHeader.logType = t('systemlog.logType');
      tmpHeader.logEvent = t('systemlog.logEvent');
      tmpHeader.ServiceDate = t('systemlog.ServiceDate');
      // tmpHeader.ServiceTime =  t('systemlog.ServiceTime');
      tmpHeader.location = t('systemlog.location');

      tmpDataList.push(reportName);
      tmpDataList.push(criteria);
      tmpDataList.push(tmpHeader);

      dataSort.forEach((dataRow, count) => {
        const tmpObj = {
          order: count + 1,
          notificationDate: showDateExcel(dataRow.notificationDate),
          // notificationTime: showTime(dataRow.notificationTime),
          ServiceDate: showDateExcel(dataRow.ServiceDate),
          // ServiceTime: showTime(dataRow.ServiceTime),
          logType: dataRow.logType,
          logEvent: dataRow.logEvent,
          location: dataRow.location,
        };
        tmpDataList.push(tmpObj);
      });
      // setDataExport(tmpDataList);
      const fName = `${t('menu.systemLogReport')}_${fileNameDates(new Date())}`;
      callback(tmpDataList, tmpColWidths, fName);
    } else {
      callback([], [], '');
    }
  };

  const searchApi = ({ sDate, eDate, val }) => {
    const data = {
      url: 'http://localhost:3001/api/issue/',
      body: {},
    };

    data.url = `${config.backendUrl}/api/issue/range?`;
    data.startDate = new Date(sDate).toISOString();
    data.endDate = new Date(eDate).toISOString();

    getbyTime(data, (err, fn) => {
      // console.log('err, fn ->',err, fn)
      if (config.production) {
        if (err === null) {
          // console.log('getbyTime ->',fn);
          setDataResAPI(fn);
        }
      } else {
        setDataResAPI(LOGLIST);
      }
    });
  };

  const refresh = (search) => {
    const data = {
      url: 'http://localhost:3001/api/issue/',
      body: {},
    };

    // startDate.setHours(0); startDate.setMinutes(0); startDate.setSeconds(0)
    // endDate.setHours(23); endDate.setMinutes(59); endDate.setSeconds(59);

    data.url = `${config.backendUrl}/api/issue/range?`;
    data.startDate = new Date(startDate).toISOString();
    data.endDate = new Date(endDate).toISOString();

    getbyTime(data, (err, fn) => {
      // console.log('err, fn ->',err, fn)
      if (config.production) {
        if (err === null) {
          // console.log('getbyTime ->',fn)
          setDataResAPI(fn);
        }
      } else {
        setDataResAPI(LOGLIST);
      }
    });
  };

  const handleCallbackValue = (dataIn) => {
    // console.log('onCallbackValue ->', dataIn);
    const tmpStartDate = new Date(dataIn[0].$d);
    // setStartDate(tmpStartDate)
    startDate = tmpStartDate;
    const tmpEndDate = new Date(dataIn[1].$d);
    // setEndDate(tmpEndDate)
    endDate = tmpEndDate;
  };

  const handleSearchClick = (event) => {
    // console.log('handleSearchClick event->', event);
    clearOptionSelect();
    const sDate = new Date(startDate);
    const eDate = new Date(endDate);
    const tDiff = eDate - sDate;
    const timePerDay = 24 * 60 * 60 * 1000;

    const tQuarter = timePerDay * 90;
    const tMonth = timePerDay * 30;
    const tWeek = timePerDay * 7;
    const tDay = timePerDay * 1;
    const tHours = 60 * 60 * 1000;
    if (tDiff > tQuarter) {
      setSearchBy('year');
    } else if (tDiff > tMonth) {
      setSearchBy('quarter');
    } else if (tDiff > tWeek) {
      setSearchBy('month');
    } else if (tDiff > tDay) {
      setSearchBy('week');
    } else if (tDiff > tHours) {
      setSearchBy('day');
    } else {
      setSearchBy('hours');
    }
    refresh(event);
  };

  const clearOptionSelect = () => {
    // console.log('=============clear')
    // clear option filter
    // =======================================
    ConstFildFilter.forEach((item, index) => {
      if (item.type === 'select') {
        ConstFildFilter[index].currencies = [{ value: 'All', label: t('filter.all') }];
        ConstFildFilter[index].value = 'All';
      }
      if (item.type === 'input') {
        item.value = '';
      }
    });

    setFildFilter(ConstFildFilter);
  };

  const onSearchByCallback = ({ sDate, eDate, val }) => {
    // console.log('onSearchByCallback val ->',val);
    // console.log('startDate ->', sDate);
    // console.log('endDate ->', eDate);
    clearOptionSelect();
    setSearchBy(val);
    searchApi({ sDate, eDate, val });
    // refresh();
  };

  const handleOpenMenu = (event) => {
    setOpen(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setOpen(null);
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = dataList.map((n) => n.name);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  // filter HandleChange
  // ===================================
  const onHandleChangeFild = (even) => {
    // console.log('onHandleChangeFild ->',even);
    const filterIndex = arryFilter.findIndex((x) => x.id === even.item.id);
    // console.log('filterIndex ->',filterIndex);
    if (filterIndex === -1) {
      if (even.item.id !== undefined && even.val.target.value !== undefined) {
        const tmpObj = { id: even.item.id, value: even.val.target.value };
        arryFilter.push(tmpObj);
      }
    } else if (even.val.target.value !== undefined) {
      arryFilter[filterIndex].value = even.val.target.value;
    }
    // console.log('arryFilter ->',arryFilter)
    ConstFildFilter.forEach((item, index) => {
      if (item.id === even.item.id) {
        ConstFildFilter[index].value = even.val.target.value;
      }
    });
    setFildFilter(ConstFildFilter);

    setPage(0);
    let tmpFilter = dataList;
    let xFilter = [];
    if (arryFilter.length) {
      // console.log('1tmpFilter length ->', tmpFilter.length)
      arryFilter.forEach((query) => {
        // console.log('query ->', query)
        if (query.value !== 'All') {
          xFilter = filter(
            tmpFilter,
            (_user) => _user[query.id].toLowerCase().indexOf(query.value.toLowerCase()) !== -1
          );
          tmpFilter = xFilter;
        }
      });
      // console.log('2tmpFilter length ->',tmpFilter.length)
    }
    setDataFilterList(tmpFilter);
  };

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1));
    }
    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setPage(0);
    setRowsPerPage(parseInt(event.target.value, 10));
  };

  const handleFilterByName = (event) => {
    setPage(0);
    setFilterName(event.target.value);
  };

  const fixIssueNote = (row) => {
    const date = row.ServiceDate;
    if (date === undefined || date === null || date === '' || date === '-')
      return (
        <Button
          
          variant="outlined"
          // startIcon={<Iconify icon="dashicons:plugins-checked" />}
          size="large"
          onClick={() => onFixIssueOpen(row)}
        >
          {t('systemlog.note')}
        </Button>
      );

    return (
      <Button
        variant="outlined"
        color="success"
        // startIcon={<Iconify icon="dashicons:plugins-checked" />}
        size="large"
        onClick={() => onFixIssueOpen(row)}
      >
        {t('systemlog.edit')}
      </Button>
    );
  };

  const onFixIssueSave = () => {
    // console.log('onFixIssueSave..fixDate, fixNote ->', fixDate, fixNote);
    const values = {
      fixDate,
      fixNote,
      noteBy: currentUser.name,
      noteDate: new Date(),
    };
    // console.log('onFixIssueSave..values ->', values);

    const apiUpdate = () => {
      const _id = selectedId;
      const url = `${config.backendUrl}/api/issue/${_id}`;
      try {
        // console.log('apiUpdate: url ->', url);
        fetch(url, {
          method: 'PUT',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify(values),
        })
          .then((response) => {
            return response.json();
          })
          .then((dataRes) => {
            // console.log(`apiUpdate: dataRes ->`, dataRes);
            if (dataRes.error) {
              const error = dataRes.error.message;
              setFixIssueOpen(false);
              enqueueSnackbar(`Update Error: ${error}`, {
                autoHideDuration: 2000,
                variant: 'error',
                persist: false,
              });
              return;
            }

            refresh();

            setFixIssueOpen(false);
            enqueueSnackbar(`Update success`, {
              autoHideDuration: 2000,
              variant: 'success',
              persist: false,
            });
          })
          .catch((err) => {
            console.error('apiUpdate: catch Error ->', err);
            enqueueSnackbar('Update Error', { variant: 'error' });
          });
      } catch (e) {
        console.error('apiUpdate: try Error -> ', e);
      }
    };
    apiUpdate();
  };

  const onFixIssueOpen = (row) => {
    // console.log('onFixIssueOpen row ->', row);
    const { fixDate, noteDate, noteBy, _id, fixNote } = row;
    setSelectedId(_id);
    setFixDate(fixDate ? new Date(fixDate) : new Date());
    setNoteDate(noteDate ? new Date(noteDate) : new Date());
    setFixNote(fixNote);
    setNoteBy(noteBy === '' ? currentUser.name : noteBy);
    setFixIssueOpen(true);
  };

  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - dataFilterList.length) : 0;

  const filteredUsers = applySortFilter(dataFilterList, getComparator(order, orderBy), filterName);

  dataSort = filteredUsers;

  // console.log('filteredUsers ->',filteredUsers)

  const isNotFound = !filteredUsers.length && !!filterName;

  // console.log('LOGLIST ->',LOGLIST)

  // useEffect(() => {
  //   console.log('===== fixDate ->',fixDate)
  // },[fixDate])

  return (
    <>
      <Helmet>
        <title> {t('systemlog.page.title')} | Minimal UI </title>
      </Helmet>

      <Container maxWidth="xl">
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            {t('systemlog.page.title')}
          </Typography>
        </Stack>

        <Card
          sx={{
            marginTop: '2rem',
            marginBottom: '2rem',
          }}
        >
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12} md={12}>
              <DateTimeFieldFilter
                startDate={new Date(startDate).toISOString()}
                endDate={new Date(endDate).toISOString()}
                onCallbackValue={handleCallbackValue}
                onSearchClick={handleSearchClick}
                fildFilter={fildFilter}
                onHandleChange={onHandleChangeFild}
                onSearchByCallback={onSearchByCallback}
              />
            </Grid>
          </Grid>
        </Card>

        <Card>
          <CardActions
            sx={{
              alignSelf: 'stretch',
              display: 'flex',
              justifyContent: 'flex-end',
              alignItems: 'flex-start',
              // 👇 Edit padding to further adjust position
              p: 2,
            }}
          >
            <Button
              variant="contained"
              startIcon={<Iconify icon="eva:refresh-fill" />}
              size="large"
              onClick={handleSearchClick}
            />
            {/* <ExportToExcel apiData={dataExport} colWidths={colWidths} fileName={fileName} /> */}
            <ExportToExcel apiData={dataExport} colWidths={colWidths} fileName={fileName} onCallback={genDataExport} />
          </CardActions>
          {/* <UserListToolbar numSelected={selected.length} filterName={filterName} onFilterName={handleFilterByName} /> */}

          <Scrollbar>
            <TableContainer sx={{ minWidth: 800, maxHeight: 500 }}>
              <Table stickyHeader>
                <UserListHead
                  order={order}
                  orderBy={orderBy}
                  headLabel={TABLE_HEAD}
                  rowCount={dataFilterList.length}
                  numSelected={selected.length}
                  onRequestSort={handleRequestSort}
                  onSelectAllClick={handleSelectAllClick}
                />
                <TableBody>
                  {filteredUsers.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, coun) => {
                    const {
                      id,
                      notificationDate,
                      notificationTime,
                      logType,
                      logEvent,
                      ServiceDate,
                      ServiceTime,
                      location,
                    } = row;
                    const selectedUser = -1;
                    // console.log('======row ->',row)

                    return (
                      <TableRow hover key={id} tabIndex={-1} role="checkbox" selected={selectedUser}>
                        {/* <TableCell padding="checkbox">
                          <Checkbox checked={selectedUser} onChange={(event) => handleClick(event, id)} />
                        </TableCell> */}

                        <TableCell align="center">{page * rowsPerPage + (coun + 1)}</TableCell>

                        <TableCell align="left">{showDate(notificationDate)}</TableCell>

                        {/* <TableCell style={{position: "sticky", left: 190, background: "#ffe6e6"}} align="left">{showTime(notificationTime)}</TableCell> */}

                        <TableCell align="left">{logType}</TableCell>

                        <TableCell align="left">{logEvent}</TableCell>

                        <TableCell align="left">{showDate(ServiceDate)}</TableCell>

                        {/* <TableCell align="left">{showTime(ServiceTime)}</TableCell> */}

                        <TableCell align="left">{location}</TableCell>

                        <TableCell align="left">{fixIssueNote(row)}</TableCell>
                      </TableRow>
                    );
                  })}
                  {emptyRows > 0 && (
                    <TableRow style={{ height: 53 * emptyRows }}>
                      <TableCell colSpan={6} />
                    </TableRow>
                  )}
                </TableBody>

                {isNotFound && (
                  <TableBody>
                    <TableRow>
                      <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                        <Paper
                          sx={{
                            textAlign: 'center',
                          }}
                        >
                          <Typography variant="h6" paragraph>
                            Not found
                          </Typography>

                          <Typography variant="body2">
                            No results found for &nbsp;
                            <strong>&quot;{filterName}&quot;</strong>.
                            <br /> Try checking for typos or using complete words.
                          </Typography>
                        </Paper>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                )}
              </Table>
            </TableContainer>
          </Scrollbar>

          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={dataFilterList.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            showFirstButton={showFirstButtonConf}
            showLastButton={showLastButtonConf}
            labelRowsPerPage={t('branchDetail.Pagination.rowsPerPage')}
          />
        </Card>
      </Container>

      <Popover
        open={Boolean(open)}
        anchorEl={open}
        onClose={handleCloseMenu}
        anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        PaperProps={{
          sx: {
            p: 1,
            width: 140,
            '& .MuiMenuItem-root': {
              px: 1,
              typography: 'body2',
              borderRadius: 0.75,
            },
          },
        }}
      >
        <MenuItem>
          <Iconify icon={'eva:edit-fill'} sx={{ mr: 2 }} />
          Edit
        </MenuItem>

        <MenuItem sx={{ color: 'error.main' }}>
          <Iconify icon={'eva:trash-2-outline'} sx={{ mr: 2 }} />
          Delete
        </MenuItem>
      </Popover>

      <FixIssueDialog
        title={t('systemlog.FixIssue.title')}
        open={fixIssueOpen}
        setOpen={setFixIssueOpen}
        // onConfirm={deleteUser}
        txtYes={t('systemlog.save')}
        txtNo={t('systemlog.cancel')}
        fixDateLable={t('systemlog.FixIssue.fixDate')}
        fixDateValue={fixDate}
        setFixDateValue={setFixDate}
        noteDate={noteDate}
        noteDateLable={t('systemlog.FixIssue.noteDate')}
        noteBy={noteBy}
        noteByLabel={t('systemlog.FixIssue.noteBy')}
        fixNote={fixNote}
        setFixNote={setFixNote}
        fixNoteLabel={t('systemlog.FixIssue.fixNote')}
        onFixIssueSave={onFixIssueSave}
        permission={permission}
      >
        {/* {t('systemlog.FixIssue.content')} */}
      </FixIssueDialog>

      <SnackbarProvider
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
      />
    </>
  );
}
