import { useState } from 'react';
import { useCookies } from 'react-cookie';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
// @mui
import { Link, Stack, IconButton, InputAdornment, TextField, Checkbox } from '@mui/material';
import { LoadingButton } from '@mui/lab';
// components
import Iconify from '../../../components/iconify';
import Dialog from '../../../components/dialog';

const config = window.config;

// ----------------------------------------------------------------------

export default function LoginForm({ labelUsername, labelPassword, btLogin }) {
  const navigate = useNavigate();

  const [cookies, setCookie] = useCookies(['user']);

  const [showPassword, setShowPassword] = useState(false);

  const [isOpenDialog, setIsOpenDialog] = useState(false);

  const [username, setUsername] = useState('');

  const [password, stePassword] = useState('');

  const { i18n, t } = useTranslation();

  const handleClick = () => {
    const user = {
      username,
      password,
    };
    // console.log('handleClick: user ->', user);
    try {
      const url = `${config.backendUrl}/api/user/login/`;
      fetch(url, {
        method: 'POST',
        body: JSON.stringify(user),
        headers: {
          'content-type': 'application/json',
        },
      })
        .then((response) => {
          return response.json();
        })
        .then((dataRes) => {
          // console.log('dataRes ->',dataRes)
          if (dataRes.error) {
            setIsOpenDialog(true);
            console.log('dataRes.error ->', dataRes.error);
            return;
          }

          let cookieExpiresHour = 1;
          if (config.cookieExpiresHour) {
            cookieExpiresHour = config.cookieExpiresHour;
          }
          const tmpUser = {
            _id: dataRes._id,
            name: dataRes.name,
            username: dataRes.username,
            role: dataRes.role,
            token: dataRes.token,
            avatarUrl: dataRes.avatarUrl,
          };

          const url = `${config.backendUrl}/api/role/range?name=${tmpUser.role}`;
          // console.log('===== url ->', url);
          fetch(url, {
            method: 'GET',
            // body: JSON.stringify(user),
            // headers: {
            //   'content-type': 'application/json',
            // },
          })
            .then((response) => {
              return response.json();
            })
            .then((dataRes) => {
              // console.log('role: dataRes ->', dataRes);

              if (!dataRes.length) {
                setIsOpenDialog(true);
                console.log('get role !dataRes.length url ->', url);
                return;
              }

              const permissions = dataRes[0].permissions;
              // console.log('role: permissions ->', permissions);

              const d = new Date();
              // console.log('nowDate->',new Date());
              d.setTime(d.getTime() + cookieExpiresHour * 60 * 60 * 1000);
              // console.log('tmpUser, newDate ->', tmpUser, d)
              // setCookie('user', { ...tmpUser, permissions }, { path: '/', expires: d }); // setCookie ใส่ข้อมูลได้จำกัด
              setCookie('user', tmpUser, { path: '/', expires: d }); 
              setCookie('permissions',permissions, { path: '/', expires: d }); 
              
              navigate('/', { replace: true });
            })
            .catch((err) => {
              console.log('catch Error ->', err);
              setIsOpenDialog(true);
            });
        })
        .catch((err) => {
          console.log('catch Error ->', err);
          setIsOpenDialog(true);
        });
    } catch (e) {
      console.log('try Error -> ', e);
    }
  };

  const handleChangeUserName = (event) => {
    // console.log('evet ->',event.target.value);
    const userName = event.target.value.toLowerCase();
    setUsername(userName);
  };

  const handleChangePassword = (event) => {
    // console.log('evet ->',event.target.value);
    const password = event.target.value;
    stePassword(password);
  };

  const handleClose = () => {
    console.log('handleClose');
    setIsOpenDialog(false);
  };

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      // 👇 Get input value
      handleClick();
    }
  };

  return (
    <>
      <Stack spacing={3}>
        <TextField name="email" label={labelUsername} onChange={handleChangeUserName} />

        <TextField
          onChange={handleChangePassword}
          onKeyDown={handleKeyDown}
          name="password"
          label={labelPassword} 
          type={showPassword ? 'text' : 'password'}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton onClick={() => setShowPassword(!showPassword)} edge="end">
                  <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      </Stack>

      {/* <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ my: 2 }}>
        <Checkbox name="remember" label="Remember me" />
        <Link variant="subtitle2" underline="hover">
          Forgot password?
        </Link>
      </Stack> */}

      <LoadingButton fullWidth size="large" type="submit" variant="contained" onClick={handleClick} sx={{ my: 4 }}>
      {btLogin}
      </LoadingButton>
      {isOpenDialog && (
        <Dialog
          onOpen={isOpenDialog}
          onClose={handleClose}
          title={t('dialog.pageLogIn.errorTitle')}
          buttonFooterName={t('dialog.pageLogIn.btnFooter')}
        >
          <>
            <div>
              <h3>{t('dialog.pageLogIn.loginFailed')}</h3>
            </div>
          </>
        </Dialog>
      )}
    </>
  );
}
