import React from 'react';

import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';

const ConfirmDialog = (props) => {
  const { title, children, open, setOpen, onConfirm, txtYes, txtNo } = props;
  return (
    <Dialog fullWidth maxWidth="xs" open={open} onClose={() => setOpen(false)} aria-labelledby="confirm-dialog">
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>{children}</DialogContent>
      <DialogActions>
        <Button variant="outlined" onClick={() => setOpen(false)} color="primary">
          {txtNo}
        </Button>
        <Button
          variant="contained"
          onClick={() => {
            setOpen(false);
            onConfirm();
          }}
          color="error"
        >
          {txtYes}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ConfirmDialog;
