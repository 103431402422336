import { useFormik } from 'formik';
import { Helmet } from 'react-helmet-async';
import { faker } from '@faker-js/faker';
import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
// @mui
import { useTheme } from '@mui/material/styles';
import {
  Grid,
  Container,
  Box,
  Card,
  Stack,
  Button,
  Divider,
  TextField,
  CardHeader,
  Typography,
  CardContent,
  InputAdornment,
} from '@mui/material';

import { useTranslation } from 'react-i18next';
// components
import Iconify from '../components/iconify';
import Label from '../components/label';

import { ProductSort, ProductFilterSidebar } from '../sections/@dashboard/monitoring';

// ----------------------------------------------------------------------

const PAGE_ID = 'Monitoring';
const config = window.config;

export default function MonitoringPage() {
  const theme = useTheme();
  const { i18n, t } = useTranslation();
  const navigate = useNavigate();

  const [test, setTest] = useState('');

  const [branchs, setBranchs] = useState([]);

  const [branchsFilter, setBranchsFilter] = useState([]);
  const [branchBufFirst, setBranchBufFirst] = useState([]);

  const [openFilter, setOpenFilter] = useState(false);
  const [filters, setfilters] = useState({ status: ['inactivate', 'online', 'offline'], bookRemaining: 'All' }); // ['All', '<10', '<50', '<100'];

  const [isSort, setIsSort] = useState('idAsc');

  const formik = useFormik({
    initialValues: {
      status: filters.status,
      bookRemaining: filters.bookRemaining,
    },
    onSubmit: async (values, { setSubmitting }) => {
      try {
        // await fakeRequest(500);
        console.log('=======values ->', values);
        // const dataObjArr = [{ url: `${config.backendUrl}/api/branch/`, cmd: 'getฺBranch' }];
        // dataObjArr.forEach((dataObj) => fetchApi(dataObj));

        const fetchApiRealtime = (onStart) => {
          try {
            // console.log('fetchApiRealtime dataObj ->', dataObj);
            const url = `${config.backendUrl}/api/realtime/`;
            fetch(url, {
              method: 'GET',
            })
              .then((response) => {
                return response.json();
                // response.json();
              })
              .then((dataRes) => {
                console.log(`fetchApiRealtime: dataRes ->`, dataRes);

                console.log(`==== getRealtime branchs ->`, branchs);
                const branchBuf = [];
                for (let i = 0; i < branchs.length; i += 1) {
                  const b = branchs[i];
                  // console.log(`b ->`, b, i);
                  if (!b.activate) {
                    // console.log(`==== !b.activate ->`);
                    branchBuf.push({ ...b, status: 'inactivate' });
                  } else {
                    const branchUpdate = dataRes.find((br) => br.info.branchID === b.branchID);
                    console.log(`------- branchUpdate->`, branchUpdate);
                    if (branchUpdate) {
                      branchBuf.push({ ...b, ...branchUpdate, status: 'online' });
                    } else {
                      branchBuf.push({ ...b, status: 'offline' });
                    }
                  }
                }

                if (branchBuf.length) {
                  console.log(`1 xxxxxxxxxx branchBuf.length ->`, branchBuf.length);
                  setBranchs(branchBuf);
                  setBranchsFilter(branchBuf);
                }
              })
              .catch((err) => {
                console.error('fetchApiRealtime: catch Error ->', err);
              });
          } catch (e) {
            console.error('fetchApiRealtime: try Error -> ', e);
          }
        };

        fetchApiRealtime();

        setSubmitting(false);
      } catch (error) {
        console.error(error);
        setSubmitting(false);
      }
    },
  });

  const { values, resetForm, handleSubmit, isSubmitting, initialValues } = formik;

  const handleOpenFilter = () => {
    setOpenFilter(true);
  };

  const handleCloseFilter = () => {
    setOpenFilter(false);
  };

  const handleResetFilter = () => {
    // handleSubmit();
    setBranchsFilter(branchs);
    resetForm();
  };

  useEffect(() => {
    const tInterval = config.realtimeInterval ? config.realtimeInterval : 10;
    // console.log('========== onStart ->', tInterval);
    const fetchApiRealtime = (onStart) => {
      try {
        // console.log('fetchApiRealtime dataObj ->', dataObj);
        const url = `${config.backendUrl}/api/realtime/`;
        fetch(url, {
          method: 'GET',
        })
          .then((response) => {
            return response.json();
            // response.json();
          })
          .then((dataRes) => {
            console.log(`fetchApiRealtime: dataRes ->`, dataRes);

            if (dataRes.length) {
              setBranchs(dataRes);
              if (onStart) setBranchsFilter(dataRes);
            }
          })
          .catch((err) => {
            console.error('fetchApiRealtime: catch Error ->', err);
          });
      } catch (e) {
        console.error('fetchApiRealtime: try Error -> ', e);
      }
    };

    const interval = setInterval(() => {
      // console.log('setInterval =>', tInterval);

      // fetchApiRealtime(branchs);
      fetchApiRealtime(false);
    }, tInterval * 1000);

    fetchApiRealtime(true);
    return () => clearInterval(interval);
  }, []);

  // useEffect(() => {
  //   const timeout = setTimeout(() => {
  //     console.log('setTimeout =>');
  //     const dataObjArr = [{ url: `${config.backendUrl}/api/realtime/`, cmd: 'getRealtime' }];
  //     dataObjArr.forEach((dataObj) => fetchApi(dataObj));
  //   }, 3 * 1000);
  //   return () => clearTimeout(timeout);
  // }, []);

  // useEffect(() => {
  //   console.log('------branchs =>', branchs);
  // }, [branchs]);

  useEffect(() => {
    // console.log('------test =>', test, branchs);

    const branchBuf = [];
    for (let i = 0; i < branchsFilter.length; i += 1) {
      const result = branchs.find(({ branchID }) => branchID === branchsFilter[i].branchID);
      // console.info('xxx result ->', result);
      if (result) branchBuf.push(result);
    }
    if (branchBuf.length) setBranchsFilter(branchBuf);
  }, [test, branchs]);

  useEffect(() => {
    // console.log('****** branchsFilter =>', branchsFilter);
  }, [branchsFilter]);

  useEffect(() => {
    // console.log('===== filter values =>', values);
    // values = {
    //   bookRemaining: '<10',
    //   status: ['Inactive', 'Online', 'Offline'],
    // };
    let branchBuf = JSON.parse(JSON.stringify(branchs));
    switch (values.bookRemaining) {
      case '<10':
        branchBuf = branchBuf.filter((br) => br.vending.remaining < 10);
        break;
      case '<50':
        branchBuf = branchBuf.filter((br) => br.vending.remaining < 50);
        break;
      case '<100':
        branchBuf = branchBuf.filter((br) => br.vending.remaining < 100);
        break;
      case 'All':
      default:
        break;
    }
    branchBuf = branchBuf.filter((br) => values.status.indexOf(br.status) >= 0);
    // console.log('===== values branchBuf.length, branchs.length =>', branchBuf.length, branchs.length);
    setBranchsFilter(branchBuf);
  }, [values]);

  return (
    <>
      <Helmet>
        <title> {t('monitoringPage.title')} | TK Park </title>
      </Helmet>

      <Container maxWidth="xl">
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            {t('monitoringPage.title')}
          </Typography>

          {/* <Stack direction="row" flexWrap="wrap-reverse" alignItems="center" justifyContent="flex-end" sx={{ mb: 5 }}> */}
          <Stack direction="row" spacing={1} flexShrink={0} sx={{ my: 1 }}>
            <ProductFilterSidebar
              formik={formik}
              isOpenFilter={openFilter}
              onResetFilter={handleResetFilter}
              onOpenFilter={handleOpenFilter}
              onCloseFilter={handleCloseFilter}
            />
            <ProductSort branchsFilter={branchsFilter} setIsSort={setIsSort} />
          </Stack>
          {/* </Stack> */}
        </Stack>

        <Grid container spacing={3}>
          {isSort &&
            branchsFilter.map((b, index) => (
              <Grid item xs={12} sm={6} md={4} key={index}>
                <Card
                  sx={{
                    mb: 3,
                    backgroundColor:
                      (b.status === 'inactivate' && theme.palette.grey['400']) ||
                      (b.status === 'online' && theme.palette.success.lighter) ||
                      theme.palette.error.lighter,
                  }}
                >
                  <CardHeader
                    title={b.branchName}
                    // action={
                    //   enableEdit && (
                    //     <Button size="small" type="button" onClick={onEdit} startIcon={<Icon icon={editFill} />}>
                    //       Edit
                    //     </Button>
                    //   )
                    // }
                  />
                  <Label
                    variant="filled"
                    // color={('status' === 'sale' && 'error') || 'info'}
                    // ["default","primary","secondary","info","success","warning","error"]
                    color={(b.status === 'inactivate' && 'default') || (b.status === 'online' && 'success') || 'error'}
                    sx={{
                      zIndex: 9,
                      top: 24,
                      right: 16,
                      position: 'absolute',
                      textTransform: 'uppercase',
                    }}
                  >
                    {b.status}
                  </Label>
                  <CardContent>
                    <Stack spacing={1}>
                      <Stack direction="row" justifyContent="space-between">
                        <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                          {t('monitoringPage.branchID')}
                        </Typography>
                        <Typography variant="subtitle2">{b.branchID}</Typography>
                      </Stack>

                      <Stack direction="row" justifyContent="space-between">
                        <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                          {t('monitoringPage.location')}
                        </Typography>
                        <Typography variant="subtitle2">{b.location}</Typography>
                      </Stack>

                      <Divider />

                      <Stack direction="row" justifyContent="space-between">
                        <Typography variant="subtitle1">{t('monitoringPage.metrics.title')}</Typography>
                      </Stack>

                      <Stack direction="row" justifyContent="space-between">
                        <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                          {t('monitoringPage.metrics.hostname')}
                        </Typography>
                        <Typography variant="body2">{(b.metrics && b.metrics.hostname) || '---'}</Typography>
                      </Stack>

                      <Stack direction="row" justifyContent="space-between">
                        <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                          {t('monitoringPage.metrics.uptime')}/{t('monitoringPage.metrics.day')}
                        </Typography>
                        <Typography variant="body2">{(b.metrics && b.metrics.uptime) || '---'}</Typography>
                      </Stack>

                      <Stack direction="row" justifyContent="space-between">
                        <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                          %{t('monitoringPage.metrics.cpuUsage')}
                        </Typography>
                        <Typography variant="body2">
                          {b.metrics && b.metrics.cpuUsage ? b.metrics.cpuUsage.toFixed(2) : '---'}
                        </Typography>
                      </Stack>

                      <Stack direction="row" justifyContent="space-between">
                        <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                          %{t('monitoringPage.metrics.diskUsage')}
                        </Typography>
                        <Typography variant="body2">
                          {b.metrics && b.metrics.diskUsage ? b.metrics.diskUsage.toFixed(2) : '---'}
                        </Typography>
                      </Stack>

                      <Stack direction="row" justifyContent="space-between">
                        <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                          %{t('monitoringPage.metrics.ramUsage')}
                        </Typography>
                        <Typography variant="body2">
                          {b.metrics && b.metrics.ramUsage ? b.metrics.ramUsage.toFixed(2) : '---'}
                        </Typography>
                      </Stack>

                      <Divider />

                      <Stack direction="row" justifyContent="space-between">
                        <Typography variant="subtitle1">{t('monitoringPage.vending.title')}</Typography>
                      </Stack>

                      <Stack direction="row" justifyContent="space-between">
                        <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                          {t('monitoringPage.vending.remaining')}
                        </Typography>
                        <Typography variant="body2">{(b.vending && b.vending.remaining) || '---'}</Typography>
                      </Stack>

                      <Divider />
                      <Stack direction="row" justifyContent="center">
                        <Button
                          variant="outlined"
                          onClick={() => {
                            navigate(`/branch/detail/${ b.branchID}`, { replace: true });
                          }}
                          sx={{ mt: 1 }}
                        >
                          {t('branchDetail.title')}
                        </Button>
                      </Stack>
                    </Stack>
                  </CardContent>
                </Card>
              </Grid>
            ))}
        </Grid>
      </Container>
    </>
  );
}
