import { faker } from '@faker-js/faker';
import { sample } from 'lodash';

// ----------------------------------------------------------------------
// ### get all รายงานหนังสือแยกตามเลขเรียก
// GET {{URL}}/api/borrow/sum/group-call-no?__start={{startDate}}&__end={{endDate}} HTTP/1.1
// {
//   "categoryeng" : "Society and Politics",
//   "categorythai" : "สังคมและการเมือง",
//   "count" : 1,
//   "_id" : "371.102"
// }

const groupCallNumber = ['909 .ก25','362.76 .ฉ75','332.178 .ธ37','306.76 .ช55','333.3322 .ฐ34','895.7 .ค3624','599.8 .ว25'];

const logReport = [...Array(7)].map((_, index) => ({
  _id: (groupCallNumber[index]),
  categorythai: sample(['ประวัติศาสตร์','สังคมและการเมือง','ธุรกิจและการลงทุน','เรื่องราวรอบตัว','ธุรกิจและการลงทุน','จิตวิทยาพัฒนาตนเอง','วิทยาศาสตร์']),
  categoryeng: sample(['Computer & General Interest', 'Religion', 'Self-Help', 'Society & Politics', 'Language','Science', 'Health / Food / Business', 'Arts', 'Literature', 'Travel & History', 'Fiction', 'Translate Fiction', 'Short Stories', 'Children Books', 'Cartoons']),
  count: faker.datatype.number(100)
}));

export default logReport;
