import { useState } from 'react';
import { useCookies } from 'react-cookie';
import { useNavigate } from 'react-router-dom';
// @mui
import { alpha } from '@mui/material/styles';
import { Box, Divider, Typography, Stack, MenuItem, Avatar, IconButton, Popover } from '@mui/material';
// mocks_
// import account from '../../../_mock/account';

import { useTranslation } from 'react-i18next';

// ----------------------------------------------------------------------

const config = window.config;

// const MENU_OPTIONS = [
//   {
//     label: 'Home',
//     icon: 'eva:home-fill',
//   },
//   {
//     label: 'Profile',
//     icon: 'eva:person-fill',
//   },
//   {
//     label: 'Settings',
//     icon: 'eva:settings-2-fill',
//   },
// ];

// const MENU_OPTIONS = [
//   {
//     label: 'Home',
//     value: 'Home',
//     icon: 'eva:home-fill',
//   },
//   {
//     label: 'Profile',
//     value: 'Profile',
//     icon: 'eva:person-fill',
//   }
// ];

// ----------------------------------------------------------------------

export default function AccountPopover() {
  const [open, setOpen] = useState(null);

  const { i18n, t } = useTranslation();

  const navigate = useNavigate();

  const [cookies, setCookie] = useCookies(['user']);
  // console.log('cookies.user ->', cookies.user);

  const [account, setAccount] = useState(cookies.user);
  // console.log('account ->', account);

  const MENU_OPTIONS = [
    {
      label: t('accountPopover.home'),
      value: 'Home',
      icon: 'eva:home-fill',
    },
    {
      label: t('accountPopover.profile'),
      value: 'Profile',
      icon: 'eva:person-fill',
    },
  ];

  const handleOpen = (event) => { 
    // console.log('event.currentTarget ->', event.currentTarget);
    setOpen(event.currentTarget);
  };

  const handleClose = (event) => {
    // console.log('handleClose ->',event)
    setOpen(null);
    // switch(event.label){
    switch (event.value) {
      case 'Home':
        navigate('/', { replace: true });
        break;
      case 'Profile':
        navigate(`/user/users/edit/${account._id}`, { replace: true });
        break;
      default:
        // navigate('/', { replace: true });
        break;
    }
  };

  const handleCloseLogout = () => {
    setOpen(null);
    // console.log('handleCloseLogout');
    const d = new Date();
    setCookie('user', null, { path: '/', expires: d });
    if(config.resetUnreadNotifications) setCookie('notifications', null, { path: '/', expires: d });
    navigate('/login', { replace: true });
  };

  return (
    <>
      <IconButton
        onClick={handleOpen}
        sx={{
          p: 0,
          ...(open && {
            '&:before': {
              zIndex: 1,
              content: "''",
              width: '100%',
              height: '100%',
              borderRadius: '50%',
              position: 'absolute',
              bgcolor: (theme) => alpha(theme.palette.grey[900], 0.8),
            },
          }),
        }}
      >
        {/* <Avatar src={account.photoURL} alt="photoURL" /> */}
        {/* <Avatar src='/images/avatar/tkpark.jpg' /> */}
        {account ? <Avatar src={account.avatarUrl} alt="photoURL" />:<Avatar src='' />}
      </IconButton>

      <Popover
        open={Boolean(open)}
        anchorEl={open}
        onClose={handleClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        PaperProps={{
          sx: {
            p: 0,
            mt: 1.5,
            ml: 0.75,
            width: 180,
            '& .MuiMenuItem-root': {
              typography: 'body2',
              borderRadius: 0.75,
            },
          },
        }}
      >
        <Box sx={{ my: 1.5, px: 2.5 }}>
          {account? 
            <Typography variant="subtitle2" noWrap>
              {/* {account.displayName} */}
              {account.name}
            </Typography>
            :
            <Typography variant="subtitle2" noWrap>
              {/* {account.displayName} */}
              {' '}
            </Typography>
          }
          {/* <Typography variant="body2" sx={{ color: 'text.secondary' }} noWrap>
            {account.email}
          </Typography> */}
        </Box>

        <Divider sx={{ borderStyle: 'dashed' }} />

        <Stack sx={{ p: 1 }}>
          {MENU_OPTIONS.map((option) => (
            <MenuItem key={option.label} onClick={() => handleClose(option)}>
              {option.label}
            </MenuItem>
          ))}
        </Stack>

        <Divider sx={{ borderStyle: 'dashed' }} />

        <MenuItem onClick={handleCloseLogout} sx={{ m: 1 }}>
          {t('accountPopover.logout')}
        </MenuItem>
      </Popover>
    </>
  );
}
