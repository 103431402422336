import PropTypes, { string } from 'prop-types';
import { useRef, useState } from 'react';
// import PropTypes from 'prop-types';
import { Icon } from '@iconify/react';
import { Link as RouterLink } from 'react-router-dom';

// @mui
import { Menu, MenuItem, IconButton, ListItemIcon, ListItemText } from '@mui/material';

import Iconify from '../../../components/iconify';
// import { property } from 'lodash';

// ----------------------------------------------------------------------

UserMoreMenu.propTypes = {
  onDelete: PropTypes.func,
  editUrl: PropTypes.string,
  txtEdit: PropTypes.string,
  txtDelete: PropTypes.string,
  showDetail: PropTypes.bool,
  txtDeail: PropTypes.string,
  detailUrl: PropTypes.string
};

export default function UserMoreMenu({ onDelete, editUrl, txtEdit, txtDelete, showEdit, showDelete, showDetail, txtDeail, detailUrl }) {
  const ref = useRef(null);
  const [isOpen, setIsOpen] = useState(false);

  return (
    <>
      {(showEdit || showDelete) && (
        <IconButton ref={ref} onClick={() => setIsOpen(true)}>
          <Iconify icon={'eva:more-vertical-fill'} width={20} height={20} />
        </IconButton>
      )}

      <Menu
        open={isOpen}
        anchorEl={ref.current}
        onClose={() => setIsOpen(false)}
        PaperProps={{
          sx: { width: 200, maxWidth: '100%' },
        }}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      >
        {showDetail && (
          <MenuItem component={RouterLink} to={detailUrl} sx={{ color: 'text.secondary' }}> 
            <Iconify icon={'eva:list-outline'} sx={{ mr: 2 }} />
            {txtDeail}
          </MenuItem>
        )}
        {showEdit && (
          <MenuItem component={RouterLink} to={editUrl} sx={{ color: 'text.secondary' }}>
            <Iconify icon={'eva:edit-fill'} sx={{ mr: 2 }} />
            {txtEdit}
          </MenuItem>
        )}
        {showDelete && (
          <MenuItem
            onClick={() => {
              setIsOpen(false);
              onDelete();
            }}
            sx={{ color: 'error.main' }}
          >
            <Iconify icon={'eva:trash-2-outline'} sx={{ mr: 2 }} />
            {txtDelete}
          </MenuItem>
        )}
      </Menu>
    </>
  );
}
