import { faker } from '@faker-js/faker';
import { sample } from 'lodash';

// ----------------------------------------------------------------------

// {
//   "_id": "64643f8cca06b9e38fc2c852",
//   "issueDate": "2023-05-17T02:44:28.205Z",
//   "type": "Hardware",
//   "log": "ภายในเครื่องสูงผิดปกติ",
//   "fixDate": null,
//   "location": "PPETECH",
//   "__v": 0
// },

const logReport = [...Array(24)].map((_, index) => ({
  id: faker.datatype.uuid(),
  issueDate: new Date().toISOString(),
  fixDate: new Date().toISOString(),
  type: sample(['Hardware', 'Software', 'Other']),
  log: sample(['ความร้อนภายในเครื่องสูงผิดปกติ', 
  'เชื่อมต่อ API ไม่สำเร็จ', 
  'ไม่สามารถเชื่อมต่ออินเตอร์เน็ตได้', 
  'ความชื้นภายในเครื่องสูง',
  'ไฟฟ้าขัดข้อง',
  'Sensor  ประตูด้านซ้ายผิดปกติ',
  'Sensor  ช่องใส่หนังสือ A10 ไม่สามารถทำงานได้'
]),
  location: sample([
    'Central World',
    'True  Digital  Park',
  ])
}));

export default logReport;
