import { faker } from '@faker-js/faker';
import { sample } from 'lodash';

// ----------------------------------------------------------------------

// ### get all รายงานการเข้าใช้ตู้ (No login)
// GET http://localhost:3001/api/nologin-access/range?__start=2023-05-17T09:20:40.461Z&__end=2023-05-17T09:26:05.853Z

// [
//   {
//     "_id": "64659d5f3a5c99cda887fbfa",
//     "date": "2023-05-17T09:23:33.049Z",
//     "event": "กดปุ่ม  Login",
//     "location": "Central World",
//     "__v": 0
//   }
// ]

const logReport = [...Array(24)].map((_, index) => ({
  _id: faker.datatype.uuid(),
  date: new Date().toISOString(),
  event: sample(['กดปุ่ม  Login','กดปุ่ม  Cart','กดเลือกหนังสือ','กดปุ่ม  Logo']),
  location: sample([
    'Central World',
    'True  Digital  Park',
  ])
}));

export default logReport;
