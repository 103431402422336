import { Helmet } from 'react-helmet-async';
import { Link as RouterLink } from 'react-router-dom';
import { useTranslation } from "react-i18next";
// @mui
import { styled } from '@mui/material/styles';
import { Button, Typography, Container, Box } from '@mui/material';
import { t } from 'i18next';

// ----------------------------------------------------------------------

const StyledContent = styled('div')(({ theme }) => ({
  maxWidth: 480,
  margin: 'auto',
  minHeight: '100vh',
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
  padding: theme.spacing(12, 0),
}));

// ----------------------------------------------------------------------

export default function PermissionsDenied() {
  const { i18n, t } = useTranslation();
  return (
    <>
      <Helmet>
        <title>{t('permissionsDenied.title')}</title>
      </Helmet>

      <Container maxWidth="xl">
        <StyledContent sx={{ textAlign: 'center', alignItems: 'center' }}>
          <Typography variant="h3" paragraph>
            {t('permissionsDenied.header')}
          </Typography>

          <Typography sx={{ color: 'text.secondary' }}>
            {t('permissionsDenied.content')}
          </Typography>

          <Box
            component="img"
            src="/assets/illustrations/Delete-no-access-denied-road-sign-146891.svg"
            sx={{ height: 260, mx: 'auto', my: { xs: 5, sm: 10 } }}
          />

          <Button to="/" size="large" variant="contained" component={RouterLink}>
            {t('permissionsDenied.goHome')}
          </Button>
        </StyledContent>
      </Container>
    </>
  );
}
