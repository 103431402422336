import { faker } from '@faker-js/faker';
import { sample } from 'lodash';

// ----------------------------------------------------------------------

// ### get all event=import รายงานการนำหนังสือเข้าของเจ้าหน้าที่
// GET http://localhost:3001/api/employee-access/range?event=import&__start=2023-05-17T05:02:40.461Z&__end=2023-05-17T05:02:40.461Z

// [
//   {
//     "_id": "64648c19838f87437bda911f",
//     "date": "2023-05-17T08:11:05.522Z",
//     "employeeName": "อนุรักษ์ คงไทย",
//     "barcode": "32005002107028",
//     "bookName": "บันทึกเหตุการณ์สำคัญของโลก",
//     "bookNumber": "909 .ก25",
//     "bookCategory": "ประวัติศาสตร์",
//     "status": "Item being held",
//     "bookSize": "A5",
//     "channel": "A23",
//     "location": "Central World",
//     "event": "import",
//     "__v": 0
//   },

const logReport = [...Array(24)].map((_, index) => ({
  _id: faker.datatype.uuid(),
  date: new Date().toISOString(),
  employeeName: sample(['อารยา  สุขใจ','ลุกมัน อะแดม','อิสมาแอ อาแว','พีรมล  นิ่มนวล','พิทักษ์  ดำรงนาน']),
  barcode: faker.datatype.number(10000000000000),
  bookName: sample(['บันทึกเหตุการณ์สำคัญของโลก','จะไม่ทนเงียบอีกต่อไป','Bitcoin Cryptocurrency ชนะขาดตลาดคริปโทและบิตคอยน์','ยุทธศาสตร์สุขภาวะ LGBTION+ ในประเทศไทย','33 กฎทองของการลงทุนเก็งกำไรคอนโดแบบตัวจริง','มันไม่ง่ายขึ้นหรอก แต่เธอจะเก่งขึ้น','วานรศึกษา']),
  bookNumber: sample(['909 .ก25','362.76 .ฉ75','332.178 .ธ37','306.76 .ช55','333.3322 .ฐ34','895.7 .ค3624','599.8 .ว25']),
  bookCategory: sample(['ประวัติศาสตร์','สังคมและการเมือง','ธุรกิจและการลงทุน','เรื่องราวรอบตัว','ธุรกิจและการลงทุน','จิตวิทยาพัฒนาตนเอง','วิทยาศาสตร์']),
  status: sample(['Item being held']),
  bookSize: sample(['A4', 'A5']),
  channel: faker.datatype.number(380),
  event: "import",
  location: sample([
    'Central World',
    'True  Digital  Park',
  ])
}));

export default logReport;
