import { useState } from 'react';
// @mui
import { Menu, Button, MenuItem, Typography } from '@mui/material';
// component
import Iconify from '../../../components/iconify';

// ----------------------------------------------------------------------

const SORT_BY_OPTIONS = [
  { value: 'idAsc', label: 'ID: Low-High' },
  { value: 'idDesc', label: 'ID: High-Low' },
  { value: 'nameAsc', label: 'Name: Aa-Zz' },
  { value: 'nameDesc', label: 'Name: Zz-Aa' },
  
  // { value: 'priceDesc', label: 'Price: High-Low' },
  // { value: 'priceAsc', label: 'Price: Low-High' }
];

function renderLabel(label) {
  // console.log('renderLabel label ->', label)
  if (label === 'idDesc') {
    return 'ID: High-Low';
  }
  if (label === 'idAsc') {
    return 'ID: Low-High';
  }
  if (label === 'nameAsc') {
    return 'Name: Aa-Zz';
  }
  if (label === 'nameDesc') {
    return 'Name: Zz-Aa';
  }

  return 'ID: Low-High';
}

export default function ShopProductSort({ branchsFilter, setIsSort }) {
  const [open, setOpen] = useState(null);
  const [sortBy, setSortBy] = useState(null);

  const handleOpen = (event) => {
    setOpen(event.currentTarget);
  };

  const handleClose = () => {
    setOpen(null);
  };

  const handleSortBy = (value) => {
    console.log('handleSortBy value ->', value);
    handleClose();
    // dispatch(sortByProducts(value));
    setSortBy(value);

    if (value === 'idAsc') {
      branchsFilter.sort((a,b) => a.branchID - b.branchID);
      // console.log('=== handleSortBy idDesc branchsFilter ->', branchsFilter);
    }
    if (value === 'idDesc') {
      branchsFilter.sort((a,b) => b.branchID - a.branchID);
      // console.log('=== handleSortBy idAsc branchsFilter ->', branchsFilter);
    }
    if (value === 'nameAsc') {
      branchsFilter.sort((a,b) => a.branchID - b.branchID);
      // console.log('=== handleSortBy idDesc branchsFilter ->', branchsFilter);
    }
    if (value === 'nameDesc') {
      branchsFilter.sort((a,b) => b.branchID - a.branchID);
      // console.log('=== handleSortBy idAsc branchsFilter ->', branchsFilter);
    }
    setIsSort(value)
  };

  return (
    <>
      <Button
        color="inherit"
        disableRipple
        onClick={handleOpen}
        endIcon={<Iconify icon={open ? 'eva:chevron-up-fill' : 'eva:chevron-down-fill'} />}
      >
        Sort By:&nbsp;
        <Typography component="span" variant="subtitle2" sx={{ color: 'text.secondary' }}>
          {renderLabel(sortBy)}
        </Typography>
      </Button>
      <Menu
        keepMounted
        anchorEl={open}
        open={Boolean(open)}
        onClose={handleClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      >
        {SORT_BY_OPTIONS.map((option) => (
          <MenuItem
            key={option.value}
            selected={option.value === 'newest'}
            onClick={() => handleSortBy(option.value)}
            sx={{ typography: 'body2' }}
          >
            {option.label}
          </MenuItem>
        ))}
      </Menu>
    </>
  );
}
