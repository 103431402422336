import { useState, useEffect, useCallback } from 'react';
import { withCookies, Cookies } from 'react-cookie';
import * as Yup from 'yup';
import { Helmet } from 'react-helmet-async';
import { filter } from 'lodash';
import { sentenceCase } from 'change-case';
// import { useSnackbar } from 'notistack5'; // notistack5
import { SnackbarProvider, enqueueSnackbar } from 'notistack';
import { useNavigate, useParams, useLocation } from 'react-router-dom';
import { Form, FormikProvider, useFormik } from 'formik'; //
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import dayjs from 'dayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { MobileTimePicker } from '@mui/x-date-pickers/MobileTimePicker';

import { useTheme } from '@mui/material/styles';
// @mui
import {
  Box,
  Card,
  Grid,
  Table,
  Stack,
  Switch,
  Paper,
  Avatar,
  Button,
  Popover,
  Checkbox,
  TableRow,
  MenuItem,
  TableBody,
  TableCell,
  Tooltip,
  TextField,
  Container,
  Typography,
  IconButton,
  TableContainer,
  TablePagination,
  FormControlLabel,
} from '@mui/material';

import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import { LoadingButton } from '@mui/lab';
// components
import Label from '../components/label';
import Iconify from '../components/iconify';
import Scrollbar from '../components/scrollbar';
// sections
import { UserListHead, UserListToolbar, UserMoreMenu, ConfirmDialog } from '../sections/@dashboard/user';
// mock
import USERLIST from '../_mock/userList';

// ----------------------------------------------------------------------

const config = window.config;
const configVending = window.configVending;

export default function UserCreate({ permission }) {
  if (!permission) permission = { create: false, update: false, delete: false };

  const cookies = new Cookies();
  const authUser = cookies.get('user');
  // console.log('authUser ->', authUser)

  const theme = useTheme();
  const { i18n, t } = useTranslation();

  const { pathname } = useLocation();
  const isEdit = pathname.includes('edit');
  const { branchId } = useParams();
  const [currentUser, setCurrentUser] = useState({});

  // const [isEdit, setIsEdit] = useState(false);

  const navigate = useNavigate();
  // const { enqueueSnackbar } = useSnackbar();

  const NewUserSchema = Yup.object().shape({
    branchID: Yup.string().required('branchID is required'),
    location: Yup.string().required('location is required'),
    branchName: Yup.string().required('branchName is required'),
    organization: Yup.string().required('Organization is required'),
    centralUrl: Yup.string().required('Central Url is required'),

    screen_timeout: Yup.number().required('Screen timeout is required'),
    notification_time: Yup.number().required('Notification time is required'),
    notication_remain_book: Yup.number().required('Notication remain book is required'),
    noti_verify_time: Yup.number().required('Noti verify time is required'),
    takebook_time: Yup.number().required('Takebook time is required'),

    ads_enable: Yup.number().required('Ads enable is required'),
    ads_time: Yup.number().required('Ads time is required'),

    roll_enable: Yup.number().required('Roll enable is required'),
    roll_startworking: Yup.string().required('Roll start working is required'),
    roll_stopworking: Yup.string().required('Roll stop working is required'),

    walai_token: Yup.string().required('Token is required'),
    walai_verifyuser: Yup.string().required('Verify user is required'),
    walai_getareaitems: Yup.string().required('Get area items is required'),
    walai_itemabstract: Yup.string().required('Item abstract is required'),
    walai_viewbringback: Yup.string().required('View bring back is required'),

    walai_bringback: Yup.string().required('Bring back is required'),
    walai_loaddelivery: Yup.string().required('Load delivery is required'),
    walai_pickupdelivery: Yup.string().required('Pickup delivery is required'),
    walai_addtocart: Yup.string().required('Add to cart is required'),
    walai_checkin: Yup.string().required('Checkin is required'),

    walai_checkout: Yup.string().required('Checkout is required'),
    walai_viewfreespace: Yup.string().required('View freespace is required'),
    walai_putitem: Yup.string().required('Put item is required'),

    walai_getmember_uat_url: Yup.string().required('Url is required'),
    walai_getmember_uat_token: Yup.string().required('Token is required'),
    walai_getmember_uat_isused: Yup.string().required('Isused is required'),

    walai_getmember_production_url: Yup.string().required('Url is required'),
    walai_getmember_production_token: Yup.string().required('Token is required'),
    walai_getmember_production_isused: Yup.string().required('Isused is required'),
  });

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      branchID: currentUser?.branchID || '',
      branchName: currentUser?.branchName || '',
      locationID: currentUser?.locationID || '',
      location: currentUser?.location || '',

      organization: currentUser?.organization || configVending.organization,
      centralUrl: currentUser?.centralUrl || configVending.centralUrl,

      screen_timeout: currentUser?.screen_timeout || configVending.screen_timeout,
      notification_time: currentUser?.notification_time || configVending.notification_time,
      notication_remain_book: currentUser?.notication_remain_book || configVending.notication_remain_book,
      noti_verify_time: currentUser?.noti_verify_time || configVending.noti_verify_time,
      takebook_time: currentUser?.takebook_time || configVending.takebook_time,

      ads_enable: currentUser.ads_enable !== undefined ? currentUser.ads_enable : configVending.ads_enable,
      ads_time: currentUser?.ads_time || configVending.ads_time,

      roll_enable: currentUser.roll_enable !== undefined ? currentUser.roll_enable : configVending.roll_enable,
      roll_startworking: currentUser?.roll_startworking
        ? dayjs(currentUser?.roll_startworking, 'HH:mm')
        : dayjs(configVending.roll_startworking, 'HH:mm'),
      roll_stopworking: currentUser?.roll_stopworking
        ? dayjs(currentUser?.roll_stopworking, 'HH:mm')
        : dayjs(configVending.roll_stopworking, 'HH:mm'),

      walai_token: currentUser?.walai_token || configVending.walai_token,
      walai_verifyuser: currentUser?.walai_verifyuser || configVending.walai_verifyuser,
      walai_getareaitems: currentUser?.walai_getareaitems || configVending.walai_getareaitems,
      walai_itemabstract: currentUser?.walai_itemabstract || configVending.walai_itemabstract,
      walai_viewbringback: currentUser?.walai_viewbringback || configVending.walai_viewbringback,

      walai_bringback: currentUser?.walai_bringback || configVending.walai_bringback,
      walai_loaddelivery: currentUser?.walai_loaddelivery || configVending.walai_loaddelivery,
      walai_pickupdelivery: currentUser?.walai_pickupdelivery || configVending.walai_pickupdelivery,
      walai_addtocart: currentUser?.walai_addtocart || configVending.walai_addtocart,
      walai_checkin: currentUser?.walai_checkin || configVending.walai_checkin,

      walai_checkout: currentUser?.walai_checkout || configVending.walai_checkout,
      walai_viewfreespace: currentUser?.walai_viewfreespace || configVending.walai_viewfreespace,
      walai_putitem: currentUser?.walai_putitem || configVending.walai_putitem,

      walai_getmember_uat_url: currentUser?.getmember_uat?.url || configVending.getmember_uat.url,
      walai_getmember_uat_token: currentUser?.getmember_uat?.token || configVending.getmember_uat.token,
      walai_getmember_uat_isused: currentUser?.getmember_uat?.isused || configVending.getmember_uat.isused,

      walai_getmember_production_url: currentUser?.getmember_production?.url || configVending.getmember_production.url,
      walai_getmember_production_token:
        currentUser?.getmember_production?.token || configVending.getmember_production.token,
      walai_getmember_production_isused:
        currentUser?.getmember_production?.isused || configVending.getmember_production.isused,
    },
    validationSchema: NewUserSchema,
    onSubmit: async (values, { setSubmitting, resetForm, setErrors }) => {
      console.log('onSubmit values ->', values);

      try {
        // await fakeRequest(500);
        const apiSocketEmit = (data) => {
          // console.log('apiSocketEmit: data ->', data);

          const url = `${config.backendUrl}/api/realtime/socket-emit`;
          // console.log('apiCreateBranch: url ->', url);
          try {
            fetch(url, {
              method: 'POST',
              headers: { 'Content-Type': 'application/json' },
              body: JSON.stringify({
                branchID: data.branchID,
                command: 'sync',
                message: data,
              }),
            })
              .then((response) => {
                return response.json();
              })
              .then((dataRes) => {
                // console.log(`apiCreateUsers: dataRes ->`, dataRes);
                if (dataRes.error) {
                  const error = dataRes.error.message;
                  console.error('apiSocketEmit: dataRes.error ->', error);
                }
              })
              .catch((err) => {
                console.error('apiSocketEmit: catch Error ->', err);
                enqueueSnackbar(!isEdit ? 'Create Error' : 'Update Error', { variant: 'error' });
              });
          } catch (e) {
            console.error('apiSocketEmit: try Error -> ', e);
          }
        };

        const apiCreateUsers = (user) => {
          // const _id = userForDelete._id;
          const d = new Date();
          const createAt = d;
          const updateAt = d;
          const createBy = authUser.username;
          const updateBy = authUser.username;
          const saveDate = d;
          const syncDate = null;

          const getmemberUat = {
            url: values.walai_getmember_uat_url,
            token: values.walai_getmember_uat_token,
            isused: values.walai_getmember_uat_isused,
          };
          const getmemberProduction = {
            url: values.walai_getmember_production_url,
            token: values.walai_getmember_production_token,
            isused: values.walai_getmember_production_isused,
          };

          values.roll_startworking = dayjs(values.roll_startworking).format('HH:mm');
          values.roll_stopworking = dayjs(values.roll_stopworking).format('HH:mm');

          const data = {
            ...values,
            createAt,
            updateAt,
            createBy,
            updateBy,
            saveDate,
            syncDate,
            getmember_uat: getmemberUat,
            getmember_production: getmemberProduction,
          };
          // console.log('apiCreateBranch: data ->', data);

          const url = `${config.backendUrl}/api/branch/`;
          // console.log('apiCreateBranch: url ->', url);
          try {
            fetch(url, {
              method: 'POST',
              headers: { 'Content-Type': 'application/json' },
              body: JSON.stringify(data),
            })
              .then((response) => {
                return response.json();
              })
              .then((dataRes) => {
                // console.log(`apiCreateUsers: dataRes ->`, dataRes);
                if (dataRes.error) {
                  const error = dataRes.error.message;
                  setSubmitting(false);
                  enqueueSnackbar(!isEdit ? `Create Error: ${error}` : `Update Error: ${error}`, {
                    autoHideDuration: 2000,
                    variant: 'error',
                    persist: false,
                  });
                  return;
                }

                apiSocketEmit(data);

                resetForm();
                setSubmitting(false);
                enqueueSnackbar(!isEdit ? `Create success` : `Update success`, {
                  autoHideDuration: 2000,
                  variant: 'success',
                  persist: false,
                });
                navigate('/branch/list');
              })
              .catch((err) => {
                console.error('apiCreateUsers: catch Error ->', err);
                enqueueSnackbar(!isEdit ? 'Create Error' : 'Update Error', { variant: 'error' });
              });
          } catch (e) {
            console.error('apiCreateUsers: try Error -> ', e);
          }
        };

        const apiEditUsers = (branchId) => {
          // const _id = userForDelete._id;
          const d = new Date();
          const updateAt = d;
          const updateBy = authUser.username;
          const saveDate = d;
          const syncDate = null;

          const getmemberUat = {
            url: values.walai_getmember_uat_url,
            token: values.walai_getmember_uat_token,
            isused: values.walai_getmember_uat_isused,
          };
          const getmemberProduction = {
            url: values.walai_getmember_production_url,
            token: values.walai_getmember_production_token,
            isused: values.walai_getmember_production_isused,
          };

          const locationID = values.branchID;

          values.roll_startworking = dayjs(values.roll_startworking).format('HH:mm');
          values.roll_stopworking = dayjs(values.roll_stopworking).format('HH:mm');

          const data = {
            ...values,
            updateAt,
            updateBy,
            saveDate,
            syncDate,
            getmember_uat: getmemberUat,
            getmember_production: getmemberProduction,
            locationID,
          };
          // console.log('apiEditBranch: data ->', data);

          const url = `${config.backendUrl}/api/branch/${branchId}`;
          // console.log('apiEditBranch: url ->', url);
          try {
            fetch(url, {
              method: 'PUT',
              headers: { 'Content-Type': 'application/json' },
              body: JSON.stringify(data),
            })
              .then((response) => {
                return response.json();
              })
              .then((dataRes) => {
                // console.log(`apiEditUsers: dataRes ->`, dataRes);
                if (dataRes.error) {
                  const error = dataRes.error.message;
                  setSubmitting(false);
                  enqueueSnackbar(!isEdit ? `Create Error: ${error}` : `Update Error: ${error}`, {
                    autoHideDuration: 2000,
                    variant: 'error',
                    persist: false,
                  });
                  return;
                }

                apiSocketEmit(data);

                resetForm();
                setSubmitting(false);
                enqueueSnackbar(!isEdit ? `Create success` : `Update success`, {
                  autoHideDuration: 2000,
                  variant: 'success',
                  persist: false,
                });
                navigate('/branch/list');
              })
              .catch((err) => {
                console.error('apiEditUsers: catch Error ->', err);
                enqueueSnackbar(!isEdit ? 'Create Error' : 'Update Error', { variant: 'error' });
              });
          } catch (e) {
            console.error('apiEditUsers: try Error -> ', e);
          }
        };

        if (isEdit) {
          apiEditUsers(currentUser._id);
        } else {
          apiCreateUsers();
        }

        // resetForm();
        // setSubmitting(false);
        // enqueueSnackbar(!isEdit ? 'Create success' : 'Update success', { variant: 'success' });
        // navigate(PATH_DASHBOARD.user.list);
      } catch (error) {
        console.error(error);
        setSubmitting(false);
        setErrors(error);
      }
    },
  });

  const { errors, values, touched, handleSubmit, isSubmitting, getFieldProps } = formik;
  // console.log('values=======>', values);
  useEffect(() => {
    // console.log('UserCreate: useEffect config, isEdit, branchId =>', config, isEdit, branchId);

    const apiGetUser = (branchId) => {
      // const _id = userForDelete._id;
      const url = `${config.backendUrl}/api/branch/${branchId}`;
      try {
        // console.log('apiGetUser: url ->', url);

        fetch(url, {
          method: 'GET',
          // headers: { 'Content-Type': 'application/json' },
          // body: JSON.stringify(values),
        })
          .then((response) => {
            return response.json();
          })
          .then((dataRes) => {
            // console.log(`apiGetUser: dataRes ->`, dataRes);
            if (dataRes.error) {
              const error = dataRes.error.message;
            } else {
              setCurrentUser(dataRes);
            }
          })
          .catch((err) => {
            console.error('apiGetUser: catch Error ->', err);
            enqueueSnackbar(!isEdit ? 'Create Error' : 'Update Error', { variant: 'error' });
          });
      } catch (e) {
        console.error('apiGetUser: try Error -> ', e);
      }
    };
    apiGetUser(branchId);
  }, []);

  const PATTERN = 'walai_';
  const walaiFiltered = Object.keys(values)
    .filter((key) => key.includes(PATTERN))
    .reduce((obj, key) => {
      return Object.assign(obj, {
        [key]: values[key],
      });
    }, {});
  // console.log('walaiFiltered ->', walaiFiltered);

  const refreshSync = () => {
    const apiGetUser = (branchId) => {
      // const _id = userForDelete._id;
      const url = `${config.backendUrl}/api/branch/${branchId}`;
      try {
        // console.log('apiGetUser: url ->', url);

        fetch(url, {
          method: 'GET',
          // headers: { 'Content-Type': 'application/json' },
          // body: JSON.stringify(values),
        })
          .then((response) => {
            return response.json();
          })
          .then((dataRes) => {
            // console.log(`apiGetUser: dataRes ->`, dataRes);
            if (dataRes.error) {
              const error = dataRes.error.message;
            } else {
              setCurrentUser(dataRes);
            }
          })
          .catch((err) => {
            console.error('apiGetUser: catch Error ->', err);
            enqueueSnackbar(!isEdit ? 'Create Error' : 'Update Error', { variant: 'error' });
          });
      } catch (e) {
        console.error('apiGetUser: try Error -> ', e);
      }
    };
    apiGetUser(branchId);
  };

  return (
    <>
      <Helmet>
        <title>
          {!isEdit ? t('createBranch.titleCreate') : t('createBranch.titleEdit')} | {t('title')}
        </title>
      </Helmet>

      <Container maxWidth="xl">
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            {!isEdit ? t('createBranch.titleCreate') : t('createBranch.titleEdit')}
          </Typography>
        </Stack>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <FormikProvider value={formik}>
            {/* {console.log('formik ->', values)} */}
            <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
              <Grid container spacing={3}>
                <Grid item xs={12} md={8}>
                  <Card sx={{ p: 3 }}>
                    <Stack spacing={3}>
                      <Stack direction={{ xs: 'column', sm: 'row' }} spacing={{ xs: 3, sm: 2 }}>
                        <TextField
                          fullWidth
                          disabled={isEdit}
                          label={t('createBranch.branchId')}
                          {...getFieldProps('branchID')}
                          error={Boolean(touched.branchId && errors.branchId)}
                          helperText={touched.branchId && errors.branchId}
                        />
                        <TextField
                          fullWidth
                          label={t('createBranch.branchNane')}
                          {...getFieldProps('branchName')}
                          error={Boolean(touched.branchName && errors.branchName)}
                          helperText={touched.branchName && errors.branchName}
                        />
                      </Stack>
                      <Stack direction={{ xs: 'column', sm: 'row' }} spacing={{ xs: 3, sm: 2 }}>
                        <TextField
                          fullWidth
                          label={t('createBranch.location')}
                          {...getFieldProps('location')}
                          error={Boolean(touched.location && errors.location)}
                          helperText={touched.location && errors.location}
                        />
                        {/* <TextField
                        fullWidth
                        label={t('createBranch.organization')}
                        {...getFieldProps('organization')}
                        error={Boolean(touched.organization && errors.organization)}
                        helperText={touched.organization && errors.organization}
                      /> */}
                        <TextField
                          fullWidth
                          label={t('createBranch.centralUrl')}
                          {...getFieldProps('centralUrl')}
                          error={Boolean(touched.centralUrl && errors.centralUrl)}
                          helperText={touched.centralUrl && errors.centralUrl}
                        />
                      </Stack>

                      <Accordion>
                        <AccordionSummary
                          expandIcon={<ExpandMoreIcon />}
                          aria-controls="panel1a-content"
                          id="panel1a-header"
                        >
                          <Typography variant="h6">General</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                          <Stack spacing={3}>
                            <Stack direction={{ xs: 'column', sm: 'row' }} spacing={{ xs: 3, sm: 2 }}>
                              <TextField
                                fullWidth
                                label={t('createBranch.screen_timeout')}
                                {...getFieldProps('screen_timeout')}
                                error={Boolean(touched.screen_timeout && errors.screen_timeout)}
                                helperText={touched.screen_timeout && errors.screen_timeout}
                              />
                              <TextField
                                fullWidth
                                label={t('createBranch.notification_time')}
                                {...getFieldProps('notification_time')}
                                error={Boolean(touched.notification_time && errors.notification_time)}
                                helperText={touched.notification_time && errors.notification_time}
                              />
                            </Stack>
                            <Stack direction={{ xs: 'column', sm: 'row' }} spacing={{ xs: 3, sm: 2 }}>
                              <TextField
                                fullWidth
                                label={t('createBranch.notication_remain_book')}
                                {...getFieldProps('notication_remain_book')}
                                error={Boolean(touched.notication_remain_book && errors.notication_remain_book)}
                                helperText={touched.notication_remain_book && errors.notication_remain_book}
                              />
                              <TextField
                                fullWidth
                                label={t('createBranch.noti_verify_time')}
                                {...getFieldProps('noti_verify_time')}
                                error={Boolean(touched.noti_verify_time && errors.noti_verify_time)}
                                helperText={touched.noti_verify_time && errors.noti_verify_time}
                              />
                            </Stack>
                            <Stack direction={{ xs: 'column', sm: 'row' }} spacing={{ xs: 3, sm: 2 }}>
                              <TextField
                                fullWidth
                                label={t('createBranch.takebook_time')}
                                {...getFieldProps('takebook_time')}
                                error={Boolean(touched.takebook_time && errors.takebook_time)}
                                helperText={touched.takebook_time && errors.takebook_time}
                              />
                            </Stack>

                            <Stack direction={{ xs: 'column', sm: 'row' }} spacing={{ xs: 3, sm: 2 }}>
                              <TextField
                                fullWidth
                                label={t('createBranch.ads_enable')}
                                {...getFieldProps('ads_enable')}
                                error={Boolean(touched.ads_enable && errors.ads_enable)}
                                helperText={touched.ads_enable && errors.ads_enable}
                              />
                              <TextField
                                fullWidth
                                label={t('createBranch.ads_time')}
                                {...getFieldProps('ads_time')}
                                error={Boolean(touched.ads_time && errors.ads_time)}
                                helperText={touched.ads_time && errors.ads_time}
                              />
                            </Stack>

                            <Stack direction={{ xs: 'column', sm: 'row' }} spacing={{ xs: 3, sm: 2 }}>
                              <TextField
                                fullWidth
                                label={t('createBranch.roll_enable')}
                                {...getFieldProps('roll_enable')}
                                error={Boolean(touched.roll_enable && errors.roll_enable)}
                                helperText={touched.roll_enable && errors.roll_enable}
                              />
                            </Stack>
                            <Stack direction={{ xs: 'column', sm: 'row' }} spacing={{ xs: 3, sm: 2 }}>
                              <MobileTimePicker
                                label={t('createBranch.roll_startworking')}
                                ampm={false}
                                views={['hours', 'minutes']}
                                openTo="hours"
                                value={formik.values.roll_startworking}
                                // defaultValue={formik.values.roll_startworking}
                                onChange={(value) => formik.setFieldValue('roll_startworking', value, true)}
                                slotProps={{
                                  textField: {
                                    variant: 'outlined',
                                    fullWidth: true,
                                    error: formik.touched.roll_startworking && Boolean(formik.errors.roll_startworking),
                                    helperText: formik.touched.roll_startworking && formik.errors.roll_startworking,
                                  },
                                }}
                              />
                              <MobileTimePicker
                                label={t('createBranch.roll_stopworking')}
                                ampm={false}
                                views={['hours', 'minutes']}
                                openTo="hours"
                                value={formik.values.roll_stopworking}
                                // defaultValue={formik.values.roll_stopworking}
                                onChange={(value) => formik.setFieldValue('roll_stopworking', value, true)}
                                slotProps={{
                                  textField: {
                                    variant: 'outlined',
                                    fullWidth: true,
                                    error: formik.touched.roll_stopworking && Boolean(formik.errors.roll_stopworking),
                                    helperText: formik.touched.roll_stopworking && formik.errors.roll_stopworking,
                                  },
                                }}
                              />
                            </Stack>
                          </Stack>
                        </AccordionDetails>
                      </Accordion>
                      <Accordion>
                        <AccordionSummary
                          expandIcon={<ExpandMoreIcon />}
                          aria-controls="panel1a-content"
                          id="panel1a-header"
                        >
                          <Typography variant="h6">Walai API</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                          <Stack spacing={3}>
                            {Object.entries(walaiFiltered).map(([key, value]) => (
                              <TextField
                                fullWidth
                                // disabled={isEdit}
                                label={t(`createBranch.${key}`)}
                                {...getFieldProps(key)}
                                error={Boolean(touched[key] && errors[key])}
                                helperText={touched[key] && errors[key]}
                              />
                            ))}
                          </Stack>
                        </AccordionDetails>
                      </Accordion>

                      <Box sx={{ mt: 3, display: 'flex', justifyContent: 'flex-end' }}>
                        {isEdit && (
                          <Box sx={{ mt: 1, mr: 5 }}>
                            <Typography variant="h7" sx={{ mr: 2 }}>
                              {t('createBranch.configStatus')}:
                            </Typography>
                            {currentUser.saveDate === currentUser.syncDate && currentUser.syncDate ? (
                              <Typography variant="h7" color={theme.palette.success.main}>
                                Synchronous
                              </Typography>
                            ) : (
                              <>
                                <Typography variant="h7" color={theme.palette.grey[500]}>
                                  Wait...
                                </Typography>
                                <Tooltip title="Refresh">
                                  <IconButton
                                    aria-label="refresh"
                                    size="small"
                                    onClick={refreshSync}
                                    sx={{ mb: 1, ml: 1 }}
                                  >
                                    <Iconify icon="eva:refresh-fill" />
                                  </IconButton>
                                </Tooltip>
                              </>
                            )}
                          </Box>
                        )}
                        <Button
                          variant="outlined"
                          color="primary"
                          onClick={() => {
                            navigate('/branch/list');
                          }}
                          sx={{ mr: 2 }}
                        >
                          {t('btCancel')}
                        </Button>
                        <LoadingButton type="submit" variant="contained" loading={isSubmitting}>
                          {!isEdit ? t('createBranch.btCreateUser') : t('createBranch.btSaveChanges')}
                        </LoadingButton>
                      </Box>
                    </Stack>
                    <SnackbarProvider
                      anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'center',
                      }}
                    />
                  </Card>
                </Grid>
              </Grid>
            </Form>
          </FormikProvider>
        </LocalizationProvider>
      </Container>
    </>
  );
}
